import { createSelector } from "reselect";

const selectCategories = (state) => state.categories;

export const selectCategoriesList = createSelector(
  [selectCategories],
  (categories) => categories.categories
);

export const selectMainCategories = createSelector(
  [selectCategories],
  (categories) =>
    categories.categories?.map((category) => {
      return { value: category.id, label: category.name };
    })
);
