import { createSelector } from "reselect";

const selectOrders = (state) => state.orders;

export const selectOrdersList = createSelector(
  [selectOrders],
  (orders) => orders.orders
);

export const selectIsOrdersLoading = createSelector(
  [selectOrders],
  (orders) => orders.loading
);

export const selectHasMore = createSelector(
  [selectOrders],
  (orders) => orders.hasMore
);

export const selectIsOrdersLoadingMore = createSelector(
  [selectOrders],
  (orders) => orders.loadingMore
);
