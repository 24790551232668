import { CustomButton, Spinner, Typography } from "components/atoms";
import { FormInput, Modal } from "components/molecules";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsStart,
  loadMoreProductsStart,
  updateProductStart,
} from "Redux/products/actions/products.actions";
import { selectProductList } from "Redux/products/selectors/products.selectors";
import { getPayload, ImagesSrc } from "utils";
import { CloseIcon } from "utils/helpers/SVGsSrc";
import AddDiscountModal from "../AddDiscountModal";
import ProductCard from "../ProductCard/ProductCard";

function AddItemDiscountModal({
  modalOpen,
  ToggleModalVisibility,
  isProductsLoading,
  isProductLoadingMore,
  hasMore,
  page,
  queryParams,
  setQueryParams,
  getQueryParams,
}) {
  const dispatch = useDispatch();

  const products = useSelector(selectProductList);

  const [openAddDiscountModal, setOpenAddDiscountModal] = useState(false);
  const [product, setProduct] = useState();

  const handleAddDiscount = (payload) => {
    dispatch(updateProductStart(payload));
  };
  return (
    <Modal
      isOpen={modalOpen}
      ToggleModalVisibility={() => ToggleModalVisibility(!modalOpen)}
    >
      <div className="bg-[#f8f8fe] w-11/12 px-10 py-10 my-40 ">
        <div className="flex justify-start container mb-10">
          <div className="w-6 h-6 mt-1 ml-8">
            <CloseIcon
              className="cursor-pointer "
              width={"100%"}
              height="100%"
              title="close"
              onClick={() => ToggleModalVisibility(!modalOpen)}
            />
          </div>

          <Typography
            as="span"
            size="2xl"
            color="text-text_3b3b3b"
            lineHeight="leading-[1.21]"
            classes=""
            text={"اختر العنصر الذي تريد إضافة الخصم عليه"}
          />
        </div>
        <div className=" flex mb-10">
          <FormInput
            width="w-full"
            height="h-10"
            placeholder="أدخل اسم العنصر"
            name="search_key"
            onValueChange={(e) => {
              getQueryParams(e);
            }}
          />
          <CustomButton
            label="بحث"
            width="w-2/12"
            height="h-10"
            action={() =>
              dispatch(
                getProductsStart({ ...queryParams, have_discount: false })
              )
            }
          />
        </div>
        <AddDiscountModal
          modalOpen={openAddDiscountModal}
          ToggleModalVisibility={setOpenAddDiscountModal}
          setItemDiscountModalState={ToggleModalVisibility}
          itemDiscountModalState={modalOpen}
          handleAddDiscount={handleAddDiscount}
          parent={"AddItemDiscountModal"}
          product={product}
        />
        <div className="container pl-6 max-h-screen">
          {
            <InfiniteScroll
              dataLength={products && products?.length}
              hasMore={hasMore}
              loader={<Spinner loading={isProductLoadingMore} />}
              next={() => {
                dispatch(
                  loadMoreProductsStart({
                    ...queryParams,
                    page_number: page,
                  })
                );
              }}
            >
              <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2">
                {products?.map((product) => {
                  return (
                    <ProductCard
                      product={product}
                      setProduct={setProduct}
                      openAddDiscountModal={openAddDiscountModal}
                      setOpenAddDiscountModal={setOpenAddDiscountModal}
                      discountedPrice={product.price.price_after_discount}
                      img={
                        product.image
                          ? product.image
                          : ImagesSrc.PlaceholderLogo
                      }
                      name={product.name}
                      price={product.price.base_price}
                      description={product.description}
                      id={product.id}
                      parent={"AddItemDiscount"}
                    />
                  );
                })}
              </div>
            </InfiniteScroll>
          }
        </div>
      </div>
    </Modal>
  );
}

export default AddItemDiscountModal;
