import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";

import { Typography, CustomButton, Img } from "components/atoms";

import { ImagesSrc } from "utils";
import { formatAMPM } from "utils/helpers/getTimeAMPM";
import { useNavigate } from "react-router";
import { useState } from "react";
import { ReasonsOfCancellingModal } from "components/organisms";

const NotificationCard = ({
  restaurantImg,
  notificationTitle,
  notificationTime,
  notificationDescription,
  notificationType,
  dataId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const ToggleModalVisibility = () => setIsOpen(!isOpen);

  return (
    <div className="py-6">
      {/* INFO */}
      <div className="py-6  border-b-[1px] border-bg_e8e8e8">
        {/* header */}
        <div className="flex justify-between items-center">
          <Typography
            as="p"
            size="3xl"
            color="text-text_3b3b3b"
            fontWeight="font-bold"
            lineHeight="leading-[1.64]"
            text={notificationTitle}
          />
          <Typography
            as="p"
            size="xl"
            color="text-warm_grey"
            fontWeight="font-bold"
            lineHeight="leading-[1.5]"
            classes="font-SFProText"
            text={notificationTime}
          />
        </div>
        {/* description */}
        <div>
          <Typography
            as="p"
            size="sm"
            color="text-text_3b3b3b"
            lineHeight="leading-[1.33]"
            classes="mb-4 lg:mb-8 mt-2 lg:mb-6 w-[198px] max-w-[198px] lg:w-[600px] lg:max-w-[600px] ellipseText line-clamp-2"
            text={notificationDescription}
          />
        </div>
        {/* buttons */}
        {/* <div className="flex">
            {notificationType == "order_deliver_nt" ? (
              <>
                <CustomButton
                  height="h-9 3xl:h-12"
                  buttonTypeClass="outlined"
                  textColor="text-primary-900"
                  borderColor="border-transparent"
                  classes={"w-fit rtl:pl-5 ltr:pr-5"}
                  label={t("Track Order")}
                  action={() => navigate(`/Order_details/${dataId}`)}
                />
                <CustomButton
                  height="h-9 3xl:h-12"
                  buttonTypeClass="outlined"
                  textColor="text-warm_grey"
                  borderColor="border-transparent"
                  classes={"w-fit rtl:pl-5 ltr:pr-5"}
                  label={t("Cancel Order")}
                  // action={ToggleModalVisibility}
                />
              </>
            ) : notificationType == "order_rate_nt" ? (
              <CustomButton
                height="h-9 3xl:h-12"
                buttonTypeClass="outlined"
                textColor="text-primary-900"
                borderColor="border-transparent"
                classes={"w-fit rtl:pl-5 ltr:pr-5"}
                label={t("Rate Order")}
                action={() => navigate(`/Order_rate/${dataId}`)}
              />
            ) : notificationType == "new_offer_nt" ? (
              ""
            ) : (
              ""
            )}
          </div> */}
      </div>
    </div>
  );
};

NotificationCard.propTypes = {
  restaurantImg: PropTypes.string,
  notificationTitle: PropTypes.string,
  notificationTime: PropTypes.string,
  notificationDescription: PropTypes.string,
};

export default NotificationCard;
