import { CustomButton, Img, Spinner, Typography } from "components/atoms";
import { NavLink, useNavigate } from "react-router-dom";

import AddCategoryModal from "components/organisms/AddCategoryModal/AddCategoryModal";
import { ImagesSrc } from "utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { PlusSign } from "utils/helpers/SVGsSrc";
import ProductCard from "components/organisms/ProductCard/ProductCard";
import { loadMoreProductsStart } from "Redux/products/actions/products.actions";
import { useDispatch } from "react-redux";
import { useState } from "react";

const EmptyMenuTemplate = ({}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col justify-center my-20 ">
        <div className="flex justify-center">
          <Img
            fileName={ImagesSrc.EmptyMenuLogo}
            containerClasses={"w-96 h-96"}
          />
        </div>
        <div className="flex  justify-center my-10">
          <Typography
            as="span"
            size="7xl"
            color={"text-warm_grey"}
            fontWeight="font-normal"
            lineHeight="leading-[1.21]"
            classes=" ml-4 "
            text={"القائمة فارغة ! إبدأ بإضافة العناصر الآن"}
          />
        </div>
        <div className="flex justify-center mb-20">
          <CustomButton
            label={"إضافة عنصر"}
            width="w-3/12"
            action={() => navigate("/add_item")}
          />
        </div>
      </div>
    </>
  );
};

const MenuTemplate = ({
  displayedCategories,
  setDisplayedCategories,
  activeFilter,
  setActiveFilter,
  products,
  handleDelete,
  vendorMenuCategories,
  handleSave,
  selectedCategories,
  setSelectedCategories,
  highlightedCategory,
  setHighlightedCategory,
  handleUpdate,
  parent,
  isProductsLoading,
  isProductLoadingMore,
  isVendorLoading,
  hasMore,
  queryParams,
  page,
  setQueryParams,
  handleAvailability,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const ToggleModalVisibility = () => setModalOpen(!modalOpen);
  const dispatch = useDispatch();

  return (
    <div className="container mx-auto mt-10 bg-[#f8f8fe]">
      {isProductsLoading ? (
        <Spinner loading={isProductsLoading} />
      ) : (
        <div className="mx-10">
          {/* Filter */}
          {/*flex justify-between container overflow-auto*/}
          <div className="grid 2xl:grid-cols-12 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 overflow-hidden">
            <Typography
              as="span"
              size="2xl"
              color={
                activeFilter == "all" ? "text-text_3b3b3b" : "text-text_bfbfbf"
              }
              lineHeight="leading-[1.21]"
              classes="cursor-pointer ml-4 "
              text={"الكل"}
              click={() => {
                setActiveFilter("all");
                setQueryParams({
                  ...queryParams,
                  menu_category_id: "",
                });
              }}
            />
            {isVendorLoading ? (
              <Spinner isLoading={isVendorLoading} />
            ) : (
              vendorMenuCategories?.map((category) => {
                return (
                  <Typography
                    name="menu_category_id"
                    key={category.value}
                    as="span"
                    size="2xl"
                    color={
                      activeFilter == category.label
                        ? "text-text_3b3b3b"
                        : "text-text_bfbfbf"
                    }
                    lineHeight="leading-[1.21]"
                    classes="cursor-pointer ml-4"
                    text={category.label}
                    click={() => {
                      setActiveFilter(category.label);
                      setQueryParams({
                        ...queryParams,
                        menu_category_id: category.value,
                      });
                      // const filteredProducts = products.filter((product) =>
                      //   product.menu_categories.some(
                      //     (cat) => cat.id == category.value
                      //   )
                      // );
                      // setDisplayedProducts(filteredProducts);
                    }}
                  />
                );
              })
            )}
            <div
              className="flex justify-start cursor-pointer"
              onClick={ToggleModalVisibility}
            >
              <div className="ml-4  mt-1">
                <PlusSign height="100%" width="100%" className="w-5 h-5 " />
              </div>
              <Typography
                as="span"
                size="2xl"
                color="text-primary-900"
                lineHeight="leading-[1.21]"
                text={"إضافة فئة"}
              />
            </div>
          </div>
          {products != null && products.length > 0 ? (
            <div className="mt-9 mb-10 inline-block">
              <NavLink to="/add_item">
                <div className="flex cursor-pointer ">
                  <div className="ml-4  mt-1">
                    <PlusSign height="100%" width="100%" className="w-5 h-5 " />
                  </div>
                  <Typography
                    as="span"
                    size="2xl"
                    color="text-primary-900"
                    lineHeight="leading-[1.21]"
                    text={"إضافة عنصر"}
                  />
                </div>
                {/* <Typography
                as="span"
                size="xl"
                color="text-primary-900"
                lineHeight="leading-[1.21]"
                classes="cursor-pointer ml-4 font-bold"
                text={"+ إضافة عنصر جديد"}
              /> */}
              </NavLink>
            </div>
          ) : (
            <></>
          )}
          <AddCategoryModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            ToggleModalVisibility={ToggleModalVisibility}
            displayedCategories={displayedCategories}
            setHighlightedCategory={setHighlightedCategory}
            highlightedCategory={highlightedCategory}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            setDisplayedCategories={setDisplayedCategories}
            handleSave={handleSave}
          />
          {products?.length > 0 ? (
            <div>
              {
                <InfiniteScroll
                  dataLength={products && products?.length}
                  hasMore={hasMore}
                  scrollThreshold={1.0}
                  loader={<Spinner loading={isProductLoadingMore} />}
                  next={() => {
                    dispatch(
                      loadMoreProductsStart({
                        ...queryParams,
                        page_number: page,
                      })
                    );
                  }}
                >
                  {isProductsLoading ? (
                    <Spinner loading={isProductsLoading} />
                  ) : (
                    <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 overflow-hidden">
                      {products?.map((product) => {
                        return (
                          <ProductCard
                            parent={parent}
                            img={
                              product?.image
                                ? product?.image
                                : ImagesSrc?.PlaceholderLogo
                            }
                            name={product?.name}
                            price={product?.price?.base_price}
                            description={product?.description}
                            id={product?.id}
                            handleDelete={handleDelete}
                            handleUpdate={handleUpdate}
                            productAvailable={product?.is_available_by_vendor}
                            handleAvailability={handleAvailability}
                          />
                        );
                      })}
                    </div>
                  )}
                </InfiniteScroll>
              }
            </div>
          ) : products?.length === 0 ? (
            <EmptyMenuTemplate />
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default MenuTemplate;
