import { CustomButton, Typography } from "components/atoms";

import { FormInput } from "components/molecules";
import { Formik } from "formik";
import ProfileCard from "components/molecules/Cards/ProfileCard";
import { SuccessConfirmationModal } from "components/organisms";
import { ValidationsWrapper } from "components/Containers";
import { getPayload } from "utils";
import { selectTags } from "Redux/products/selectors/products.selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

function SettingsTemplate({
  isPasswordUpdated,
  closeConfirmation,
  setIsPasswordUpdated,
  UpdatePasswordHandler,
  PhoneUpdateHandler,
  currentUser,
  handleSetNotificationOnReview,
}) {
  // Password
  const [UpdatePassword, setUpdatePassword] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });

  // Mobile Number
  const [UpdatePhone, setUpdatePhone] = useState({
    phone: "",
    password: "",
  });

  const [NotOnReview, setNotOnReview] = useState(false);
  const [NotOnArrive, setNotOnArrive] = useState(true);
  const [NotBeforeArrive, setNotBeforeArrive] = useState(true);

  useEffect(() => {
    setNotOnReview(currentUser?.notification_on_review);
  }, [currentUser]);
  return (
    <div className="w-full flex flex-col">
      <Typography
        as="h1"
        size="2xl"
        color="text-text_464646"
        fontWeight="font-bold"
        lineHeight="leading-[2]"
        classes={"text-right px-6 md:p-0 mt-10 md:m-0"}
        text={"تعديل كلمة المرور"}
      />
      <ValidationsWrapper
        initialValues={{
          old_password: UpdatePassword.old_password,
          password: UpdatePassword.password,
          confirm_password: UpdatePassword.confirm_password,
        }}
        rules={["old_password", "password", "confirm_password"]}
      >
        {({ FormikHandler }) => {
          return (
            <div className="w-full mt-10 bg-text_f8f8fe p-6 text-right">
              <div className="mx-6">
                <FormInput
                  font="font-SFProText"
                  id="Password"
                  name="password"
                  type="password"
                  width="w-full"
                  height="h-9"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label="كلمة المرور الحالية"
                  labelSize={"2xl"}
                  textColor={"text-warm_grey"}
                  value={FormikHandler.values.old_password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("old_password", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "old_password",
                          value: e.target.value,
                        },
                      },
                      UpdatePassword,
                      setUpdatePassword
                    );
                  }}
                  error={FormikHandler.errors.old_password}
                />
              </div>
              <div className="mx-6 pt-6">
                <FormInput
                  font="font-SFProText"
                  id="Password"
                  name="password"
                  type="password"
                  width="w-full"
                  height="h-9"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label="كلمة المرور الجديدة"
                  labelSize={"2xl"}
                  textColor={"text-warm_grey"}
                  value={FormikHandler.values.password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("password", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "password",
                          value: e.target.value,
                        },
                      },
                      UpdatePassword,
                      setUpdatePassword
                    );
                  }}
                  error={FormikHandler.errors.password}
                />
              </div>
              <div className="mx-6 pt-6">
                <FormInput
                  font="font-SFProText"
                  id="Password"
                  name="password"
                  type="password"
                  width="w-full"
                  height="h-9"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label="أعد كتابة كلمة المرور الجديدة"
                  labelSize={"2xl"}
                  textColor={"text-warm_grey"}
                  value={FormikHandler.values.confirm_password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue(
                      "confirm_password",
                      e.target.value
                    );
                    getPayload(
                      {
                        target: {
                          name: "confirm_password",
                          value: e.target.value,
                        },
                      },
                      UpdatePassword,
                      setUpdatePassword
                    );
                  }}
                  error={FormikHandler.errors.confirm_password}
                />
              </div>
              <div className="w-full flex justify-center mt-8">
                <CustomButton
                  width="w-52"
                  height="h-10"
                  action={() => UpdatePasswordHandler(UpdatePassword)}
                  label={"تحديث كلمة المرور"}
                  disabled={!FormikHandler.isValid}
                />
              </div>
            </div>
          );
        }}
      </ValidationsWrapper>

      <SuccessConfirmationModal
        isOpen={isPasswordUpdated}
        ToggleModalVisibility={() => setIsPasswordUpdated(false)}
        onClosAction={closeConfirmation}
        heading={"لقد تم تجديد كلمة المرور"}
      />

      <Typography
        as="h1"
        size="2xl"
        color="text-text_464646"
        fontWeight="font-bold"
        lineHeight="leading-[2]"
        classes={"mt-10 text-right px-6 md:p-0"}
        text={"تغيير رقم الهاتف"}
      />

      <ValidationsWrapper
        initialValues={{
          phone: UpdatePhone.phone,
          password: UpdatePhone.password,
        }}
        rules={["phone", "password"]}
      >
        {({ FormikHandler }) => {
          return (
            <div className="w-full mt-10 bg-text_f8f8fe p-6 text-right">
              <div className="mx-6">
                <FormInput
                  font="font-SFProText"
                  label="رقم الهاتف الجديد"
                  labelSize={"2xl"}
                  textColor={"text-warm_grey"}
                  id="old password"
                  name="old password"
                  width="w-full"
                  height="h-9"
                  value={FormikHandler.values.phone}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("phone", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "phone",
                          value: e.target.value,
                        },
                      },
                      UpdatePhone,
                      setUpdatePhone
                    );
                  }}
                  error={FormikHandler.errors.phone}
                />
              </div>
              <div className="mx-6 pt-6">
                <FormInput
                  font="font-SFProText"
                  id="Password"
                  name="password"
                  type="password"
                  width="w-full"
                  height="h-9"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label="كلمة المرور"
                  labelSize={"2xl"}
                  textColor={"text-warm_grey"}
                  value={FormikHandler.values.password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("password", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "password",
                          value: e.target.value,
                        },
                      },
                      UpdatePhone,
                      setUpdatePhone
                    );
                  }}
                  error={FormikHandler.errors.password}
                />
              </div>
              <div className="w-full flex justify-center mt-8">
                <CustomButton
                  width="w-52"
                  height="h-10"
                  action={() => PhoneUpdateHandler(UpdatePhone)}
                  label={"تحديث رقم الهاتف"}
                />
              </div>
            </div>
          );
        }}
      </ValidationsWrapper>
      <Typography
        as="h1"
        size="2xl"
        color="text-text_464646"
        fontWeight="font-bold"
        lineHeight="leading-[2]"
        classes={"mt-10 text-right px-6 md:p-0"}
        text={"الإشعارات"}
      />

      <div className="w-full mt-10 bg-text_f8f8fe text-right">
        {/* <ProfileCard
          Type="switch"
          Title={"تلقي إشعار عند اقتراب مندوب التوصيل"}
          Value={NotBeforeArrive}
          setValue={setNotBeforeArrive}
        />
        <ProfileCard
          Type="switch"
          Title={"تلقي إشعار عند وصول مندوب التوصيل"}
          Value={NotOnArrive}
          setValue={setNotOnArrive}
        /> */}
        <ProfileCard
          Type="switch"
          Title={"تلقي إشعار عند تقييم الطلب"}
          Value={NotOnReview}
          setValue={() => {
            setNotOnReview(!NotOnReview);
            handleSetNotificationOnReview(!NotOnReview);
          }}
        />
      </div>
    </div>
  );
}

export default SettingsTemplate;
