import { CircleCheck_false, CircleCheck_true } from "utils/helpers/SVGsSrc";

import { PropTypes } from "prop-types";
import { Switch } from "@headlessui/react";
import { Typography } from "components/atoms";

const ProfileCard = ({ Title, Value, Type = "text", setValue }) => {
  return (
    <>
      {Type == "text" ? (
        <div className="w-full border-b-[1px] border-warm_grey/23 flex p-6">
          <Typography
            as="h1"
            size="xl"
            color="text-text_3b3b3b"
            fontWeight="font-semibold"
            lineHeight="leading-[2]"
            classes={"md:w-[400px] ml-4 md:m-0"}
            text={Title}
          />
          <Typography
            as="h1"
            size="xl"
            color="text-text_3b3b3b"
            fontWeight="font-semibold"
            lineHeight="leading-[2]"
            classes={"font-SFProText"}
            text={Value}
          />
        </div>
      ) : Type == "paragraph" ? (
        <div className="w-full border-b-[1px] border-warm_grey/23 flex p-6">
          <Typography
            as="h1"
            size="xl"
            color="text-text_3b3b3b"
            fontWeight="font-semibold"
            lineHeight="leading-[2]"
            classes={"md:w-[400px] ml-4 md:m-0"}
            text={Title}
          />
          {Value}
        </div>
      ) : Type == "switch" ? (
        <div className="w-full border-b-[1px] border-warm_grey/23 flex p-6 justify-between items-center">
          <Typography
            as="h1"
            size="xl"
            color="text-text_3b3b3b"
            fontWeight="font-semibold"
            lineHeight="leading-[2]"
            classes={"ml-4 md:m-0"}
            text={Title}
          />
          <Switch
            checked={Value}
            onChange={() => setValue()}
            className={`${
              Value ? "bg-primary-700" : "bg-primary-200"
            } relative inline-flex h-4 w-11 items-center rounded-full rtl:ml-10`}
          >
            <span
              className={`${
                Value ? "-translate-x-5" : ""
              } inline-block h-6 w-6 transform rounded-full bg-primary-900`}
            />
          </Switch>
        </div>
      ) : (
        <div className="w-full border-b-[1px] border-warm_grey/23 flex p-6">
          {Value ? (
            <>
              <CircleCheck_true className="w-[25px] ml-6" />
              <Typography
                as="h1"
                size="xl"
                color="text-text_3b3b3b"
                fontWeight="font-semibold"
                lineHeight="leading-[2]"
                classes={"font-SFProText"}
                text={Title}
              />
            </>
          ) : (
            <>
              <CircleCheck_false className="w-[25px] ml-6" />
              <Typography
                as="h1"
                size="xl"
                color="text-text_3b3b3b"
                fontWeight="font-semibold"
                lineHeight="leading-[2]"
                text={Title}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

ProfileCard.propTypes = {
  Title: PropTypes.string,
  Value: PropTypes.string,
  Type: PropTypes.string,
};

export default ProfileCard;
