import {
  LoginPage,
  SignUpPage,
  VerifyMobileNumberPage,
  PrivacyPolicyPage,
  ResetPasswordPage,
  HomePage,
  MyAccountPage,
  AddItemPage,
  EditItemPage,
  UnderReviewPage,
  ContactUsPage,
  FAQPage,
  VerifyMobileForEditPage,
  NotificationsPage,
  ProductDataPage,
  MenuPage,
  PromotionsPage,
  TheMainPage,
  NewOrdersPage,
  CurrentOrdersPage,
  CompletedOrdersPage,
  UnderReivewOrdersPage,
  AboutPage,
} from "pages";

import OrdersPage from "pages/App/Orders/Orders.page";

const routes = [
  {
    name: "login",
    path: "/login",
    exact: true,
    element: <LoginPage />,
  },
  {
    name: "Home",
    path: "/",
    exact: true,
    children: [
      {
        name: "main_page",
        path: "/main",
        element: <TheMainPage />,
        exact: true,
        children: [
          {
            name: "Orders_page",
            path: "/main/orders",
            exact: true,
            element: <OrdersPage />,
            children: [
              {
                name: "New_Orders",
                path: "/main/orders/new",
                exact: true,
                element: <NewOrdersPage />,
              },
              {
                name: "Current_Orders",
                path: "/main/orders/current",
                exact: true,
                element: <CurrentOrdersPage />,
              },
              {
                name: "Complete",
                path: "/main/orders/completed",
                exact: true,
                element: <CompletedOrdersPage />,
              },
              {
                name: "Under_Review",
                path: "/main/orders/under_review",
                exact: true,
                element: <UnderReivewOrdersPage />,
              },
            ],
          },
          {
            name: "Menu_Page",
            path: "/main/menu",
            exact: true,
            element: <MenuPage />,
          },
          {
            name: "Promotions_page",
            path: "/main/promotions",
            exact: true,
            element: <PromotionsPage />,
          },
        ],
      },
      {
        name: "my_account",
        path: "/my_account",
        element: <MyAccountPage />,
        exact: true,
      },
      {
        name: "edit_profile",
        path: "/edit_profile",
        element: <MyAccountPage />,
        exact: true,
      },
      {
        name: "settings",
        path: "/settings",
        element: <MyAccountPage />,
        exact: true,
      },
      {
        name: "wallet",
        path: "/wallet",
        element: <MyAccountPage />,
        exact: true,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        name: "Add_Item",
        path: "/Add_Item",
        exact: true,
        element: <AddItemPage />,
      },
      {
        name: "Edit_Item",
        path: "Edit_Item/:id",
        exact: true,
        element: <EditItemPage />,
      },
      {
        name: "Under_Review",
        path: "Under_Review",
        exact: true,
        element: <UnderReviewPage />,
      },
      {
        name: "Notifications",
        path: "/Notifications",
        exact: true,
        element: <NotificationsPage />,
      },
      {
        name: "Product_Data",
        path: "/Product_Data/:id",
        exact: true,
        element: <ProductDataPage />,
      },
      {
        name: "contactUs",
        path: "/help",
        exact: true,
        element: <ContactUsPage />,
      },
    ],
    element: <HomePage />,
  },
  {
    name: "signup",
    path: "/SignUp",
    exact: true,
    element: <SignUpPage />,
  },
  {
    name: "mobileVerification",
    path: "/Verify_mobile_number",
    exact: true,
    element: <VerifyMobileNumberPage />,
  },
  {
    name: "mobileVerification After Editing",
    path: "/verify_mobile",
    exact: true,
    element: <VerifyMobileForEditPage />,
  },
  {
    name: "privacy_policy",
    path: "/privacy_policy",
    exact: true,
    element: <PrivacyPolicyPage />,
  },
  {
    name: "Reset_Password",
    path: "/Reset_Password",
    exact: true,
    element: <ResetPasswordPage />,
  },
  {
    name: "Add_Item",
    path: "/Add_Item",
    exact: true,
    element: <AddItemPage />,
  },

  {
    name: "FAQ",
    path: "/FAQ",
    exact: true,
    element: <FAQPage />,
  },
];

export default routes;
