import OrdersActionTypes from "../types/orders.types";

const INITIAL_STATE = {
  orders: null,
  error: null,
  loading: false,
  loadingMore: false,
  hasMore: true,
  total_pages: 0,
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrdersActionTypes.UPDATE_ORDER_START:
      return {
        ...state,
        // loading: true,
      };
    case OrdersActionTypes.UPDATE_ORDER_SUCCESS:
      // const filteredOrders = state.orders.filter(
      //   (order) => order.id !== action.payload
      // );

      return {
        ...state,
        // loading: false,
        // orders: filteredOrders,
      };
    case OrdersActionTypes.UPDATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OrdersActionTypes.LIST_ORDERS_START:
      return {
        ...state,
        loading: true,
      };
    case OrdersActionTypes.LIST_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        loading: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };

    case OrdersActionTypes.LIST_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case OrdersActionTypes.LOAD_MORE_ORDERS_START:
      return {
        ...state,
        loadingMore: true,
      };
    case OrdersActionTypes.LOAD_MORE_ORDERS_SUCCESS:
      const newOrdersList = action.payload.orders;
      const { orders } = state;
      return {
        ...state,
        orders: [...orders, ...newOrdersList],
        loadingMore: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };
    case OrdersActionTypes.LOAD_MORE_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingMore: false,
      };
    case OrdersActionTypes.UPDATE_ORDER_STATUS_START:
      return {
        ...state,
        loading: true,
      };
    case OrdersActionTypes.UPDATE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case OrdersActionTypes.UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default ordersReducer;
