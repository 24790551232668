import { Spinner } from "components/atoms";
import { OrderDetails } from "components/organisms";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { loadMoreOrdersStart } from "Redux/orders/actions/order.actions";

function CurrentOrdersTemplate({
  queryParams,
  setQueryParams,
  orders,
  isLoading,
  page,
  hasMore,
  handleChangeTimeSubmit,
  isLoadingMore,
  handleDeliver,
}) {
  const dispatch = useDispatch();
  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <>
          <InfiniteScroll
            dataLength={orders && orders?.length}
            hasMore={hasMore}
            scrollThreshold={0.5}
            loader={<Spinner loading={isLoadingMore} />}
            next={() => {
              dispatch(
                loadMoreOrdersStart({
                  ...queryParams,
                  page_number: page,
                })
              );
            }}
          >
            {orders?.map((order) => {
              return (
                <OrderDetails
                  order={order}
                  queryParams={queryParams}
                  handleChangeTimeSubmit={handleChangeTimeSubmit}
                  handleDeliver={handleDeliver}
                />
              );
            })}
          </InfiniteScroll>
        </>
      )}
    </>
  );
}

export default CurrentOrdersTemplate;
