import { SubNav } from "components/molecules";
import { Outlet, useLocation, useNavigate } from "react-router";

const MainTemplate = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <>
      {/* Order Details Organism */}
      {
        <div className="container mx-auto mt-10 bg-[#f8f8fe]">
          <SubNav
            buttonList={[
              {
                name: "طلبات جديدة",
                handleClick: () => {
                  // setQueryParams({ ...queryParams, status: "new" });
                  navigate("/main/orders/new");
                },
                style:
                  pathname === "/main/orders/new"
                    ? "bg-[#f8f8fe] text-[#bf0022]  border border-[#bf0022] my-4 border-b-2 border-l-0 border-r-0 border-t-0  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full"
                    : "bg-[#f8f8fe] text-[#bfbfbf]  hover:text-[#bf0022] border-b-2 my-4 hover:border-[#bf0022]  border-l-0 border-r-0 border-t-0  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full",
              },
              {
                name: "طلبات حالية",
                handleClick: () => {
                  // setQueryParams({ ...queryParams, status: "current" });
                  navigate("/main/orders/current");
                },
                style:
                  pathname === "/main/orders/current"
                    ? "bg-[#f8f8fe] text-[#bf0022]  border border-[#bf0022] my-4 border-b-2 border-l-0 border-r-0 border-t-0  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full"
                    : "bg-[#f8f8fe] text-[#bfbfbf]  hover:text-[#bf0022] border-b-2 my-4 hover:border-[#bf0022]  border-l-0 border-r-0 border-t-0  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full",
              },
              {
                name: "طلبات تم تسليمها",
                handleClick: () => {
                  // setQueryParams({ ...queryParams, status: "completed" });
                  navigate("/main/orders/completed");
                },
                style:
                  pathname === "/main/orders/completed"
                    ? "bg-[#f8f8fe] text-[#bf0022]  border border-[#bf0022] my-4 border-b-2 border-l-0 border-r-0 border-t-0  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full"
                    : "bg-[#f8f8fe] text-[#bfbfbf]  hover:text-[#bf0022] border-b-2 my-4 hover:border-[#bf0022]  border-l-0 border-r-0 border-t-0  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full",
              },
              {
                name: "طلبات تحت المراجعه",
                handleClick: () => {
                  // setQueryParams({
                  //   ...queryParams,
                  //   status: "request_changes",
                  // });
                  navigate("/main/orders/under_review");
                },
                style:
                  pathname === "/main/orders/under_review"
                    ? "bg-[#f8f8fe] text-[#bf0022]  border border-[#bf0022] my-4 border-b-2 border-l-0 border-r-0 border-t-0  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full"
                    : "bg-[#f8f8fe] text-[#bfbfbf]  hover:text-[#bf0022] border-b-2 my-4 hover:border-[#bf0022]  border-l-0 border-r-0 border-t-0  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full",
              },
            ]}
            // eslint-disable-next-line react/style-prop-object
            style={
              "bg-[#f8f8fe] text-[#bfbfbf]  hover:text-[#bf0022] border-b-2 my-4 hover:border-[#bf0022]  border-l-0 border-r-0 border-t-0  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full"
            }
          />
          {/* {isLoading ? (
            <Spinner loading={isLoading} />
          ) : (
            <>
              <InfiniteScroll
                dataLength={orders && orders?.length}
                hasMore={hasMore}
                scrollThreshold={0.5}
                loader={<Spinner loading={isLoadingMore} />}
                next={() => {
                  dispatch(
                    loadMoreOrdersStart({
                      ...queryParams,
                      page_number: page,
                    })
                  );
                }}
              >
                {orders?.map((order) => {
                  return (
                    <OrderDetails
                      order={order}
                      queryParams={queryParams}
                      handleChangeTimeSubmit={handleChangeTimeSubmit}
                    />
                  );
                })}
              </InfiniteScroll>
            </>
          )} */}

          <Outlet />
        </div>
      }
    </>
  );
};

export default MainTemplate;
