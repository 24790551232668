import { CustomButton } from "components/atoms";
import { ValidationsWrapper } from "components/Containers";
import { FormInput, Modal } from "components/molecules";
import { useState } from "react";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getProductsStart } from "Redux/products/actions/products.actions";
import { selectVendorId } from "Redux/user/selectors/user.selectors";
import { getDiscountStart } from "Redux/vendor/actions/vendor.actions";
import { CloseIcon } from "utils/helpers/SVGsSrc";
import { RadioGroup } from "..";
import SuccessConfirmationModal from "../Modals/SuccessConfirmationModal";

function AddDiscountModal({
  modalOpen,
  ToggleModalVisibility,
  handleAddDiscount,
  parent,
  product,
  setItemDiscountModalState,
  itemDiscountModalState,
  queryParams,
}) {
  const selectedVendorId = useSelector(selectVendorId);
  const [date, setDate] = useState();
  const [successConfirmationModalOpen, setSuccessConfirmationModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeAction = () => {
    dispatch(getDiscountStart());
    dispatch(
      getProductsStart({
        ...queryParams,
        search_key: "",
        have_discount: true,
      })
    );
  };
  return (
    <Modal isOpen={modalOpen} ToggleModalVisibility={ToggleModalVisibility}>
      <div className="bg-white 2xl:w-7/12  md:w-8/12 w-11/12 px-10 py-10 my-40">
        <CloseIcon
          className="cursor-pointer"
          title="close"
          onClick={() => ToggleModalVisibility(!modalOpen)}
        />
        <ValidationsWrapper
          initialValues={{
            amount: "",
            exp_date: "",
            discount_type: 0,
          }}
          rules={["amount", "exp_date"]}
        >
          {({ FormikHandler }) => {
            return (
              <>
                <div className="my-10">
                  <div className="mb-10">
                    <FormInput
                      label="المبلغ المراد خصمه"
                      labelSize={"2xl"}
                      textColor={"text-warm_grey"}
                      id="name"
                      name="name"
                      width="w-full"
                      height="h-9"
                      placeholder={
                        FormikHandler.values.discount_type === 1
                          ? "مثال ٪ ١٠"
                          : "مثال ١٠ ج.م"
                      }
                      error={FormikHandler.errors.amount}
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue("amount", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-10">
                    <RadioGroup
                      name={"Discount"}
                      id={"Discount"}
                      state={FormikHandler.values.discount_type}
                      onValueChange={(e) => {
                        console.log(e.target.value);
                        FormikHandler.setFieldValue(
                          "discount_type",
                          Number(e.target.value)
                        );
                      }}
                      options={[
                        { id: 0, name: "خصم بقيمة ثابتة" },
                        { id: 1, name: "خصم بنسبة مئوية" },
                      ]}
                    />
                  </div>
                  <div>
                    <FormInput
                      label="تاريخ أنتهاء الخصم"
                      labelSize={"2xl"}
                      textColor={"text-warm_grey"}
                      id="name"
                      name="name"
                      type="date"
                      width="w-full"
                      height="h-9"
                      value={date}
                      placeholder=" مثال ٢٢/١١/٢٠٢٢"
                      onValueChange={(e) => {
                        setDate(e.target.value);
                        console.log(
                          new Date(e.target.value).toLocaleDateString("en-UK")
                        );
                        FormikHandler.setFieldValue(
                          "exp_date",
                          new Date(e.target.value).toLocaleDateString("en-UK")
                        );
                      }}
                    />
                  </div>
                </div>

                <CustomButton
                  height="h-10"
                  width="sm:w-52 w-full"
                  label={"حفظ"}
                  textColor="text-primary-50"
                  disabled={!FormikHandler.isValid}
                  action={() => {
                    if (parent === "AddItemDiscountModal") {
                      handleAddDiscount({
                        id: product.id,
                        admin_vendor_id: selectedVendorId,
                        body: {
                          product: {
                            value: FormikHandler.values.amount,
                            discount_exp_date: FormikHandler.values.exp_date,
                            discount_type: FormikHandler.values.discount_type,
                            discount_status: "active",
                          },
                        },
                      });

                      // setItemDiscountModalState(!itemDiscountModalState);
                    } else {
                      handleAddDiscount({
                        admin_vendor_id: selectedVendorId,
                        vendor_discount: {
                          ...FormikHandler.values,
                          discount_type: FormikHandler.values.discount_type,
                        },
                      });
                    }
                    // ToggleModalVisibility(!modalOpen);
                    setSuccessConfirmationModalOpen(
                      !successConfirmationModalOpen
                    );
                  }}
                />
              </>
            );
          }}
        </ValidationsWrapper>
        <SuccessConfirmationModal
          isOpen={successConfirmationModalOpen}
          ToggleModalVisibility={() => {
            setSuccessConfirmationModalOpen(!successConfirmationModalOpen);
            ToggleModalVisibility(!modalOpen);
            if (setItemDiscountModalState) {
              setItemDiscountModalState(!itemDiscountModalState);
            }
          }}
          onClosAction={closeAction}
          heading={"لقد تلقينا تعديلاتك بنجاح"}
          subHeadings={["تم تحديث الخصم بنجاح"]}
        />
      </div>
    </Modal>
  );
}

export default AddDiscountModal;
