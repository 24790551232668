import {
  ImageSelector,
  LogoSelector,
  allCountriesSelector,
  allCurrenciesSelector,
  allPayoutMethodsSelector,
  selectIsVendorLoading,
  selectIsVendorUpdated,
  selectServingsList,
  selectVendorProfile,
} from "Redux/vendor/selectors/vendor.selectors";
import { ImagesSrc, eventEmitter } from "utils";
import {
  listAllCountriesStart,
  listAllCurrenciesStart,
  listAllPayoutMethodsStart,
  listProfileStart,
  listServingTypesStart,
  updateProfileInformed,
  updateProfileStart,
  uploadImageStart,
  uploadLogoStart,
} from "Redux/vendor/actions/vendor.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { EditProfileTemplate } from "templates";
import { listCategoriesStart } from "Redux/categories/actions/categories.actions";
import { selectMainCategories } from "Redux/categories/selectors/categories.selectors";
import { selectVendorId } from "Redux/user/selectors/user.selectors";
import { useNavigate } from "react-router";

function EditProfilePage() {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(1); // 1 = ar , 2 = en
  const navigate = useNavigate();
  const ProfileData = useSelector(selectVendorProfile);
  const isVendorLoading = useSelector(selectIsVendorLoading);
  const isVendorUpdated = useSelector(selectIsVendorUpdated);
  const servingTypes = useSelector(selectServingsList);
  const mainCategories = useSelector(selectMainCategories);
  const selectedVendorId = useSelector(selectVendorId);
  const countries = useSelector(allCountriesSelector);
  const currencies = useSelector(allCurrenciesSelector);
  const payoutMethods = useSelector(allPayoutMethodsSelector);

  const [queryParams, setQueryParams] = useState({
    admin_vendor_id: selectedVendorId,
  });
  useEffect(() => {
    dispatch(listProfileStart(queryParams));
    dispatch(listServingTypesStart());
    dispatch(listCategoriesStart());
    dispatch(listAllCountriesStart());
    dispatch(listAllCurrenciesStart());
    dispatch(listAllPayoutMethodsStart());
  }, []);

  const EditProfileHandler = (newProfileData) => {
    console.log(newProfileData);
    dispatch(updateProfileStart(newProfileData));
  };
  // const handleUpdateVendorDeliveryAreas = (areas) => {
  //   dispatch(updateVendorAreaStart(areas));
  // };
  const closeConfirmation = () => {
    dispatch(updateProfileInformed());
    navigate("/my_account");
  };

  const [LogoPercent, setLogoPercent] = useState(0);
  const [ImagePercent, setImagePercent] = useState(0);
  const [LogoPicture, setLogoPicture] = useState(ImagesSrc.UploadImage);
  const [ImagePicture, setImagePicture] = useState();
  const LogoUrl = useSelector(LogoSelector);
  const ImageUrl = useSelector(ImageSelector);
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImagePercent(1);
      dispatch(uploadImageStart(img));
      setImagePicture(URL.createObjectURL(img));
    }
  };
  const onLogoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setLogoPercent(1);
      dispatch(uploadLogoStart(img));
      setLogoPicture(URL.createObjectURL(img));
    }
  };

  const [CLickedName, setCLickedName] = useState("");
  useEffect(() => {
    eventEmitter.on("UPLOAD_PROGRESS", (percent) => {
      CLickedName == "logo"
        ? setLogoPercent(percent)
        : setImagePercent(percent);
    });

    return function cleanup() {
      eventEmitter.off("UPLOAD_PROGRESS");
    };
  }, [CLickedName]);

  return (
    <EditProfileTemplate
      ProfileData={ProfileData}
      language={language}
      setLanguage={setLanguage}
      servingTypes={servingTypes}
      mainCategories={mainCategories}
      EditProfileHandler={EditProfileHandler}
      isVendorLoading={isVendorLoading}
      isVendorUpdated={isVendorUpdated}
      closeConfirmation={closeConfirmation}
      LogoPercent={LogoPercent}
      ImagePercent={ImagePercent}
      setLogoPercent={setLogoPercent}
      setImagePercent={setImagePercent}
      setCLickedName={setCLickedName}
      LogoPicture={LogoPicture}
      setLogoPicture={setLogoPicture}
      ImagePicture={ImagePicture}
      setImagePicture={setImagePicture}
      onImageChange={onImageChange}
      onLogoChange={onLogoChange}
      LogoUrl={LogoUrl}
      ImageUrl={ImageUrl}
      selectedVendorId={selectedVendorId}
      countries={countries}
      currencies={currencies}
      payoutMethods={payoutMethods}
      // areas={areas}
      // deliveryMethods={deliveryMethods}
      // vendorAreas={vendorAreas}
      // handleUpdateVendorDeliveryAreas={handleUpdateVendorDeliveryAreas}
      // cities={cities}
      // areasByCity={areasByCity}
    />
  );
}

export default EditProfilePage;
