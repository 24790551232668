import classNames from "classnames";

function SubNav({ buttonList, style, direction = "row", fullWidth = true }) {
  return (
    <div
      className={classNames(
        { "flex-col": direction == "col" },
        "flex justify-around items-stretch rounded-lg text-lg"
      )}
      role="group"
    >
      {buttonList.map((button) => {
        return (
          <>
            <div className={button.style} onClick={button.handleClick}>
              <div
                className={classNames({
                  "flex items-center": button.beforeIcon || button.afterIcon,
                })}
              >
                {button.beforeIcon}
                <button
                  className={classNames({ "w-full": fullWidth }, "h-full ")}
                >
                  {button.name}
                </button>
                {button.afterIcon}
              </div>
            </div>
            {button.subButtonsShow && (
              <div className="flex flex-col">
                {button?.subButtons?.map((sub) => {
                  return (
                    <div className={sub.style} onClick={sub.handleClick}>
                      <button className="h-full">{sub.name}</button>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
}

export default SubNav;
