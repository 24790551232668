import { PropTypes } from "prop-types";
import { Dialog, Transition } from "@headlessui/react";

const Modal = ({ isOpen = false, ToggleModalVisibility, children }) => {
  return (
    <Transition appear show={isOpen} as="div">
      <Dialog
        as="div"
        className="fixed  inset-0 z-30 overflow-auto"
        onClose={ToggleModalVisibility}
      >
        <Transition.Child
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0  bg-bg_323232 opacity-60" />
        </Transition.Child>
        <div className="min-h-screen px-4 text-center">
          <span
            className="flex items-center justify-center h-2"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as="div"
            className="h-full flex items-start lg:items-center justify-center relative my-8"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
  children: PropTypes.node,
};

export default Modal;
