import { CustomButton, Img, Typography } from "components/atoms";
import { ImagesSrc } from "utils";

function UnderReviewTemplate() {
  return (
    <div className="min-h-full">
      <div className="flex flex-col justify-center">
        <div className="mt-52">
          <div className="flex justify-center">
            <Img
              fileName={ImagesSrc.UnderReviewLogo}
              containerClasses={"w-96 h-96"}
            />
          </div>
          <div className="flex justify-center">
            <div className="flex justify-center my-10 w-3/12 ">
              <Typography
                as="span"
                size="7xl"
                color={"text-warm_grey"}
                fontWeight="font-normal"
                lineHeight="leading-[1.21]"
                classes=" ml-4 text-center"
                text={
                  "يتم الآن مراجعة بياناتك . سنقوم بتفعيل الحساب عند الإنتهاء من مراجعتها"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnderReviewTemplate;
