import { DropDownInput, FormInput } from "components/molecules";

import { Delete } from "utils/helpers/SVGsSrc";
import { RadioGroup } from "components/organisms";
import { Typography } from "components/atoms";
import { useState } from "react";

const DeliveryAreas = ({
  areas,
  deliveryMethods,
  deliveryRange,
  formik,
  id,
  doesDelivery,
}) => {
  const [nowLater, setNowLater] = useState(0);
  const handleDelete = (id) => {
    const newDeliveryRanges = formik.values.delivery_ranges_attributes.filter(
      (range, index) => index !== id
    );

    formik.setFieldValue("delivery_ranges_attributes", newDeliveryRanges);
  };
  return (
    <div className="w-full mt-10 bg-text_f8f8fe text-right">
      <div className="mx-6  mt-6">
        <Typography
          as="span"
          size="2xl"
          color="text-warm_grey"
          lineHeight="leading-[1.21]"
          text={"نوع الطلبات "}
        />
        <RadioGroup
          containerClasses={"flex"}
          itemWidth="w-[20%] md:w-[20%] lg:w-[20%] xl:w-[10%]"
          name={`delivery_type${id}`}
          id={`delivery_type${id}`}
          options={[
            { id: 1, name: "الآن" },
            { id: 2, name: "لاحقا" },
          ]}
          state={
            formik.values.delivery_ranges_attributes[id].delivery_type === "now"
              ? 1
              : 2
          }
          onValueChange={(e) => {
            formik.setFieldValue(
              `delivery_ranges_attributes[${id}].delivery_type`,
              e.target.value == 1 ? "now" : "later"
            );
          }}
        />
      </div>
      <div className="mx-6">
        <Typography
          as="span"
          size="2xl"
          color="text-warm_grey"
          lineHeight="leading-[1.21]"
          text={"قيمة التوصيل"}
        />
        <RadioGroup
          containerClasses={"flex"}
          itemWidth="w-[20%] md:w-[20%] lg:w-[20%] xl:w-[10%]"
          name={`delivery_value${id}`}
          id={`delivery_value${id}`}
          options={[
            { id: 1, name: "ثابتة" },
            { id: 2, name: "متغيرة" },
          ]}
          state={
            formik.values.delivery_ranges_attributes[id].fixed_price === true
              ? 1
              : 2
          }
          onValueChange={(e) => {
            formik.setFieldValue(
              `delivery_ranges_attributes[${id}].fixed_price`,
              e.target.value == 1 ? true : false
            );
          }}
        />
      </div>
      <div className="mx-6 flex mb-6">
        <div className="w-[44%] ml-6">
          <FormInput
            label="من"
            font="font-SFProText"
            labelSize={"2xl"}
            textColor={"text-warm_grey"}
            borderColor="border-warm_grey/50"
            width="w-full"
            height="h-9"
            onValueChange={(e) => {
              formik.setFieldValue(
                `delivery_ranges_attributes[${id}].from_radius`,
                e.target.value
              );
            }}
            value={deliveryRange.from_radius}
          />
        </div>
        <div className="w-[44%]">
          <FormInput
            label="إلى"
            font="font-SFProText"
            labelSize={"2xl"}
            textColor={"text-warm_grey"}
            borderColor="border-warm_grey/50"
            width="w-full"
            height="h-9"
            onValueChange={(e) => {
              formik.setFieldValue(
                `delivery_ranges_attributes[${id}].radius`,
                e.target.value
              );
            }}
            value={deliveryRange.radius}
          />
        </div>
        <div className="w-[44%] mr-6">
          <FormInput
            label={
              formik.values.delivery_ranges_attributes[id].fixed_price === true
                ? "قيمة التوصيل"
                : " قيمة التوصيل لكل كيلومتر"
            }
            font="font-SFProText"
            labelSize={"2xl"}
            textColor={"text-warm_grey"}
            borderColor="border-warm_grey/50"
            width="w-full"
            height="h-9"
            onValueChange={(e) => {
              formik.setFieldValue(
                `delivery_ranges_attributes[${id}].price`,
                e.target.value
              );
            }}
            value={deliveryRange.price}
          />
        </div>
      </div>

      <div className="flex justify-end ml-6 mb-6">
        <Delete
          className="cursor-pointer"
          onClick={() => {
            formik.setFieldValue(
              `delivery_ranges_attributes[${id}]._destroy`,
              true
            );
          }}
        />
        <Typography
          as="span"
          size="2xl"
          color="text-[#ff0009]"
          lineHeight="leading-[1.21]"
          classes="cursor-pointer mr-4"
          text={"حذف"}
          click={() => {
            handleDelete(id);
          }}
        />
      </div>
    </div>
  );
};

export default DeliveryAreas;

//OLD Delivery Areas Component (If Needed)

// <div className="w-full mt-10 bg-text_f8f8fe text-right">
//     <div className="mx-6 my-6">
//       <DropDownInput
//         value={{
//           value: vendorArea?.area_id,
//           label: vendorArea?.area_name_en,
//         }}
//         label="المنطقة"
//         labelSize={"2xl"}
//         textColor={"text-warm_grey"}
//         closeOnSelect={true}
//         placeholder="إختر من القائمة"
//         options={areas}
//         onValueChange={(e) => {
//           formik.setFieldValue(`areas[${id}].area_id`, e.value);
//           formik.setFieldValue(`areas[${id}.area_name_en]`, e.label);
//         }}
//       />
//     </div>
//     <div className="mx-6 my-6">
//       <DropDownInput
//         value={{
//           value: vendorArea?.delivery_third_party_id,
//           label: vendorArea?.delivery_third_party_name,
//         }}
//         classes="font-SFProText"
//         label="طريقة التوصيل"
//         labelSize={"2xl"}
//         textColor={"text-warm_grey"}
//         searchable={false}
//         closeOnSelect={true}
//         placeholder="إختر من القائمة"
//         options={deliveryMethods}
//         onValueChange={(e) => {
//           formik.setFieldValue(
//             `areas[${id}].delivery_third_party_name`,
//             e.label
//           );
//           formik.setFieldValue(
//             `areas[${id}].delivery_third_party_id`,
//             e.value
//           );
//         }}
//       />
//     </div>

// <div className="mx-6 my-6">
//   <FormInput
//     label="قيمة التوصيل"
//     font="font-SFProText"
//     labelSize={"2xl"}
//     textColor={"text-warm_grey"}
//     borderColor="border-warm_grey/50"
//     width="w-full"
//     height="h-9"
//     onValueChange={(e) => {
//       formik.setFieldValue(
//         `areas[${id}].vendor_delivery_price`,
//         e.target.value
//       );
//     }}
//     value={vendorArea.vendor_delivery_price}
//   />
// </div>
// <div className="flex justify-end ml-6 mb-6">
//   <Delete
//     className="cursor-pointer"
//     onClick={() => {
//       formik.setFieldValue(`areas[${id}]._destroy`, true);
//     }}
//   />
//   <Typography
//     as="span"
//     size="2xl"
//     color="text-[#ff0009]"
//     lineHeight="leading-[1.21]"
//     classes="cursor-pointer mr-4"
//     text={"حذف"}
//     click={() => {
//       formik.setFieldValue(`areas[${id}]._destroy`, true);
//     }}
//   />
// </div>
//     <div className="mx-6 my-6">
//       <RadioGroup
//         name={"delivery-areas-radio" + id}
//         id={"delivery-areas-radio" + id}
//         options={[
//           { id: 0, name: "الآن" },
//           { id: 1, name: "لاحقا" },
//         ]}
//         state={nowLater}
//         onValueChange={(e) => {
//           setNowLater(e.target.value);
//         }}
//       />
//     </div>
//   </div>
