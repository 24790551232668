import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetFAQStart,
  sendContactUsMessageStart,
  sendContactUsMessageSuccess,
} from "Redux/user/actions/user.actions";
import { selectIsContactUsMessageSent } from "Redux/user/selectors/user.selectors";

import { ContactUsTemplate } from "templates";

const ContactUsPage = () => {
  const dispatch = useDispatch();

  const isMessageSentSuccessfully = useSelector(selectIsContactUsMessageSent);
  const [IsMessageSent, setIsMessageSent] = useState(false);

  useEffect(() => {
    if (isMessageSentSuccessfully) {
      setIsMessageSent(true);
      dispatch(sendContactUsMessageSuccess(false));
    }
  }, [isMessageSentSuccessfully]);

  useEffect(() => {
    dispatch(GetFAQStart());
  }, []);

  const SendMessage = (NewMessage) =>
    dispatch(sendContactUsMessageStart(NewMessage));

  const SendMessageHandle = async (e, NewMessage) => {
    e.preventDefault();
    SendMessage(NewMessage);
  };
  return (
    <ContactUsTemplate
      IsMessageSent={IsMessageSent}
      setIsMessageSent={setIsMessageSent}
      SendMessageHandle={SendMessageHandle}
    />
  );
};

export default ContactUsPage;
