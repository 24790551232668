const CustomSwitch = ({ enabled, setEnabled }) => {
  return (
    <div className="bg-white rounded-full h-[50px] w-[50px] py-[15px] pr-[10px]  absolute bottom-3/4 right-2 top-2">
      <label
        for="checked-toggle"
        class="inline-flex relative items-center cursor-pointer"
      >
        <input
          type="checkbox"
          value=""
          id="default-toggle"
          class="sr-only peer"
          checked={enabled}
        />
        <div
          onClick={setEnabled}
          class=" w-8 h-3 bg-gray-200 rounded-full   peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:bg-[#BF0022] after:content-[''] after:absolute after:bottom-[-4px] after:left-[-4px] after:bg-gray-600  after:rounded-full after:h-[20px] after:w-[20px] after:transition-all dark:border-gray-600 peer-checked:bg-[#dc7c90]"
        ></div>
      </label>
    </div>
  );
};

export default CustomSwitch;
