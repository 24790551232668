import { initializeApp } from "firebase/app";

import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAb4OShjBHwRokxkFE6I9qTphCp1n-v69w",
  authDomain: "ablanazira.firebaseapp.com",
  projectId: "ablanazira",
  storageBucket: "ablanazira.appspot.com",
  messagingSenderId: "379559296492",
  appId: "1:379559296492:web:546be96bec494a2143d4c9",
  measurementId: "G-KZZ0CRDNY8",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const appleProvider = new OAuthProvider("apple.com");

const auth = getAuth();

export const signInWithApplePopup = () => {
  signInWithPopup(auth, appleProvider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;

      // Apple credential
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;

      console.log({ user }, { idToken }, { accessToken });

      return { accessToken, idToken };
    })
    .catch((error) => {
      // Handle Errors here.
      console.log({ error });
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The credential that was used.
      const credential = OAuthProvider.credentialFromError(error);

      console.log({ errorCode }, { errorMessage }, { email }, { credential });
    });
};

export const GetToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BArpyLwYCoQwB3jxpSRwRyRT9IbRVg2d6IjjUnS0oZXVNwaZvP6ENd_9jfV8Ge5SHig5vugE6aZPF9ZdbVB8XDg",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(null);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("listening");
      resolve(payload);
    });
  });
