import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { signoutStart } from "Redux/user/actions/user.actions";

import {
  DownArrow,
  ProfileIcon,
  ProfileIcon_grey,
  RedSettings,
  Settings,
  SignOut,
  UpArrow,
} from "utils/helpers/SVGsSrc";

import { SubNav } from "components/molecules";
import { EditProfilePage, ProfilePage, SettingsPage } from "pages";

const MyAccountTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="container text-center mx-auto mt-[88px] md:mt-32 md:grid grid-cols-4 gap-10">
      <div className="w-full h-fit md:pt-4 md:pb-40 bg-text_f8f8fe hidden md:block">
        <SubNav
          direction="col"
          fullWidth={false}
          buttonList={[
            {
              name: "حسابي",
              handleClick: () => {
                navigate("/my_account");
              },
              style:
                location.pathname == "/my_account" ||
                location.pathname == "/edit_profile" ||
                location.pathname == "/wallet"
                  ? "h-full text-[#bf0022] px-4 py-5 mx-0 outline-none focus:shadow-outline w-full"
                  : "h-full text-warm_grey border-b-[1px] px-4 py-5 mx-0 outline-none focus:shadow-outline w-full",
              beforeIcon:
                location.pathname == "/my_account" ||
                location.pathname == "/edit_profile" ||
                location.pathname == "/wallet" ? (
                  <ProfileIcon className="w-[40px] ml-10 ltr:mr-16" />
                ) : (
                  <ProfileIcon_grey className="w-[40px] ml-10 ltr:mr-16" />
                ),
              afterIcon:
                location.pathname == "/my_account" ||
                location.pathname == "/edit_profile" ||
                location.pathname == "/wallet" ? (
                  <UpArrow className={`mx-4`} />
                ) : (
                  <DownArrow className={`mx-4`} />
                ),
              subButtonsShow:
                location.pathname == "/my_account" ||
                location.pathname == "/edit_profile" ||
                location.pathname == "/wallet",
              subButtons: [
                // {
                //   name: "المحفظة",
                //   handleClick: () => {
                //     navigate("/wallet");
                //   },
                //   style:
                //     location.pathname == "/wallet"
                //       ? "h-full text-[#bf0022] text-[15px] text-right pr-[95px] pb-2 mx-0 outline-none focus:shadow-outline w-full"
                //       : "h-full text-warm_grey text-[15px] text-right pr-[95px] pb-2 mx-0 outline-none focus:shadow-outline w-full",
                // },
                {
                  name: "تعديل الحساب",
                  handleClick: () => {
                    navigate("/edit_profile");
                  },
                  style:
                    location.pathname == "/edit_profile"
                      ? "h-full text-[#bf0022] text-[15px] text-right pr-[95px] border-b-[1px] pb-4 mx-0 outline-none focus:shadow-outline w-full"
                      : "h-full text-warm_grey text-[15px] text-right pr-[95px] border-b-[1px] pb-4 mx-0 outline-none focus:shadow-outline w-full",
                },
              ],
            },
            {
              name: "الإعدادات",
              handleClick: () => {
                navigate("/settings");
              },
              style:
                location.pathname == "/settings"
                  ? "h-full text-[#bf0022] border-b-[1px] px-4 py-5 mx-0 outline-none focus:shadow-outline w-full"
                  : "h-full text-warm_grey border-b-[1px] px-4 py-5 mx-0 outline-none focus:shadow-outline w-full",
              beforeIcon:
                location.pathname == "/settings" ? (
                  <RedSettings className="w-[40px] ml-10 ltr:mr-16" />
                ) : (
                  <Settings className="w-[40px] ml-10 ltr:mr-16" />
                ),
            },
            {
              name: "تسجيل الخروج",
              handleClick: () => {
                dispatch(signoutStart());
              },
              style:
                "h-full text-warm_grey border-b-[1px] px-4 py-5 mx-0 outline-none focus:shadow-outline w-full",
              beforeIcon: <SignOut className="w-[40px] ml-10 ltr:mr-16" />,
            },
          ]}
        />
      </div>
      <div className="w-full col-span-3">
        {location.pathname == "/my_account" ? (
          <ProfilePage />
        ) : location.pathname == "/wallet" ? (
          <h1>المحفظة</h1>
        ) : location.pathname == "/edit_profile" ? (
          <EditProfilePage />
        ) : (
          <SettingsPage />
        )}
      </div>
    </div>
  );
};

export default MyAccountTemplate;
