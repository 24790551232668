import { Img, Typography } from "components/atoms";

import CustomSwitch from "components/atoms/Switch/Switch";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useState } from "react";

function ProductCard({
  price,
  description,
  name,
  img,
  id,
  handleDelete,
  handleUpdate,
  parent,
  discountedPrice,
  openAddDiscountModal,
  setOpenAddDiscountModal,
  setProductId,
  product,
  setProduct,
  productAvailable,
  handleAvailability,
}) {
  const navigate = useNavigate();
  const [available, setAvailable] = useState(true);
  useEffect(() => {
    setAvailable(productAvailable);
  }, [productAvailable]);
  return (
    <div className=" container h-auto w-11/12 mx-6 bg-[#ffffff] mb-10">
      <div className="relative">
        <Img
          fileName={img}
          containerClasses="h-48 cursor-pointer"
          onClick={() => {
            if (parent !== "menu") {
              setProduct(product);
              setOpenAddDiscountModal(!openAddDiscountModal);
            } else {
              navigate(`/product_data/${id}`);
            }
          }}
        />
        <CustomSwitch
          enabled={available}
          setEnabled={() => {
            handleAvailability(id, { product: { is_available: !available } });
            setAvailable(!available);
          }}
        />
      </div>

      <div className="flex-col">
        {/* Product name */}
        <div className=" flex justify-center my-6">
          {" "}
          <Typography
            as="span"
            size="xl"
            color="#000000"
            lineHeight="leading-[1.21]"
            classes="cursor-pointer font-bold"
            text={name}
            click={() => {
              if (parent !== "menu") {
                setProduct(product);
                setOpenAddDiscountModal(!openAddDiscountModal);
              } else {
                navigate(`/product_data/${id}`);
              }
            }}
          />
        </div>
        {/* Product description */}
        <div className=" flex justify-center my-6 mx-6">
          {" "}
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            lineHeight="leading-[1.21]"
            classes="font-normal"
            text={description}
          />
        </div>
        {/* Price */}
        <div
          className={
            parent === "menu" ||
            parent === "AddItemDiscount" ||
            ((parent !== "menu" || parent !== "AddItemDiscount") && price === 0)
              ? "flex justify-center my-6"
              : "flex justify-between my-6 mx-14"
          }
        >
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            lineHeight="leading-[1.21]"
            classes={
              parent === "menu" ||
              parent === "AddItemDiscount" ||
              ((parent !== "menu" || parent !== "AddItemDiscount") &&
                price === 0)
                ? "font-semibold"
                : "font-semibold line-through"
            }
            text={price === 0 ? "Price on Selection" : price + " ج .م"}
          />

          {parent !== "menu" && parent !== "AddItemDiscount" && price !== 0 ? (
            <Typography
              as="span"
              size="xl"
              color="text-warm_grey"
              lineHeight="leading-[1.21]"
              classes="font-semibold"
              text={discountedPrice + " ج .م"}
            />
          ) : (
            <></>
          )}
        </div>
        {parent !== "AddItemDiscount" ? (
          <div
            className={
              parent === "menu"
                ? "flex justify-center mb-6 container"
                : "flex justify-center mb-6 container"
            }
          >
            {parent === "menu" ? (
              <Typography
                as="span"
                size="xl"
                color="text-primary-900"
                lineHeight="leading-[1.21]"
                classes="cursor-pointer font-semibold mr-20 ml-4"
                text={"تعديل"}
                click={() => {
                  handleUpdate(id);
                }}
              />
            ) : (
              <></>
            )}

            <Typography
              as="span"
              size="xl"
              color="text-primary-900"
              lineHeight="leading-[1.21]"
              classes={
                parent === "menu"
                  ? "cursor-pointer font-semibold ml-20"
                  : "cursor-pointer font-semibold"
              }
              text={"حذف"}
              click={() => {
                handleDelete(id);
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
