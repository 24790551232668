import React from "react";

import { t } from "i18next";
import { PropTypes } from "prop-types";
import { NavLink } from "react-router-dom";

import {
  AblaNaziraLogo,
  FacebookLogoRed,
  InstagramLogoRed,
  LinkedinLogoRed,
  TwitterLogoRed,
} from "utils/helpers/SVGsSrc";

import { CustomButton, Typography } from "components/atoms";
import { FormInput } from "components/molecules";

import styles from "./Footer.module.css";
import { useSelector } from "react-redux";
import { selectSystemConfiguration } from "Redux/user/selectors/user.selectors";

const footerNavItems = [
  {
    element: (idx) => (
      <NavLink key={idx} to="/Offers">
        <Typography
          size="2xl"
          as="span"
          color="text-warm_grey"
          lineHeight="leading-[1.18]"
          fontWeight="font-semibold"
          classes="cursor-pointer"
          text={t("Offers")}
        />
      </NavLink>
    ),
  },
  {
    element: (idx) => (
      <Typography
        key={idx}
        size="2xl"
        as="a"
        color="text-warm_grey"
        lineHeight="leading-[1.18]"
        fontWeight="font-semibold"
        classes="cursor-pointer"
        text={t("Become a partner")}
      />
    ),
  },
  {
    element: (idx) => (
      <NavLink key={idx} to="/About_Us">
        <Typography
          size="2xl"
          as="span"
          color="text-warm_grey"
          lineHeight="leading-[1.18]"
          fontWeight="font-semibold"
          classes="cursor-pointer"
          text={t("About Us")}
        />
      </NavLink>
    ),
  },
  {
    element: (idx) => (
      <NavLink key={idx} to="/Contact_Us">
        <Typography
          size="2xl"
          as="span"
          color="text-warm_grey"
          lineHeight="leading-[1.18]"
          fontWeight="font-semibold"
          classes="cursor-pointer"
          text={t("Contact Us")}
        />
      </NavLink>
    ),
  },
];

const SocialMediaLink = ({ icon, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={styles.socialMedialogo}
    >
      {icon}
    </a>
  );
};

const Footer = () => {
  const SystemConfiguration = useSelector(selectSystemConfiguration);
  return (
    <footer className={styles.footer}>
      <div className={styles.footerMainContainer}>
        {/* <div className={styles.newsLetter}>
          <div className={styles.newsLetterHeading}>
            <NavLink to="/">
              <div className={styles.logo}>
                <AblaNaziraLogo
                  width="100%"
                  height="100%"
                  title="Abla Nazira"
                />
              </div>
            </NavLink>

            <Typography
              as="p"
              size="xl"
              color="text-warm_grey"
              lineHeight="leading-[1.5]"
              text={t(
                "Subscribe to our newsletter and receive exclusive offers every week"
              )}
            />
          </div> 
           <div className={styles.newsLetterFormContainer}>
            <form className={styles.newsLetterForm}>
              <FormInput
                bgColor="bg-bg_e8e8e8"
                borderColor="border-text_bfbfbf"
                borderRadius="4px"
                height="h-10 md:h-12"
                width="w-full md:w-[440px] lg:w-[658px]"
                placeholder={t("Enter your email")}
              />
              <CustomButton
                label={t("Subscribe")}
                borderRadius="4px"
                width="w-40 md:w-52"
                height="h-10 md:h-12"
                classes="mt-4 md:mt-0"
              />
            </form>
          </div> 
        </div> */}
        {/* <div className={styles.footerNavlinksContiner}>
          {footerNavItems.map((navItem, idx) => navItem.element(idx))}
        </div> */}
        <div className={styles.socialMediaLinksContainer}>
          <SocialMediaLink
            icon={
              <FacebookLogoRed
                width="100%"
                height="100%"
                title="Abla Nazira Facebook"
              />
            }
            href={
              SystemConfiguration?.find((item) => item.name == "facebook_link")
                ?.value
            }
          />

          <SocialMediaLink
            icon={
              <TwitterLogoRed
                width="100%"
                height="100%"
                title="Abla Nazira twitter"
              />
            }
            href={
              SystemConfiguration?.find((item) => item.name == "twitter_link")
                ?.value
            }
          />

          <SocialMediaLink
            icon={
              <LinkedinLogoRed
                width="100%"
                height="100%"
                title="Abla Nazira Linkedin"
              />
            }
            href={
              SystemConfiguration?.find((item) => item.name == "linkedin_link")
                ?.value
            }
          />

          <SocialMediaLink
            icon={
              <InstagramLogoRed
                width="100%"
                height="100%"
                title="Abla Nazira Instagram"
              />
            }
            href={
              SystemConfiguration?.find((item) => item.name == "instagram_link")
                ?.value
            }
          />
        </div>
        {/* <div className="text-center mt-8 lg:mt-10 xl:mt-16">
          <Typography
            as="a"
            href="http://localhost:3000/privacy_policy"
            target="_blank"
            size="xl"
            color="text-warm_grey"
            classes="cursor-pointer"
            text={t("Terms of use & Privacy policy")}
          />
        </div> */}
      </div>
      <div className={styles.copyRights}>
        <Typography
          as="a"
          href="https://inovaeg.com/"
          target="_blank"
          size="lg"
          color="text-text_b2b2b2"
          fontWeight="font-semibold"
          lineHeight="leading-[1.67]"
          text={t("By Inova LLC - © 2022 ALL RIGHTS RESERVED")}
        />
      </div>
    </footer>
  );
};

export default Footer;

SocialMediaLink.propTypes = {
  icon: PropTypes.node,
  href: PropTypes.string,
};
