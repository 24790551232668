import { useOrders } from "Hooks/useOrders/useOrders";

import { useDispatch, useSelector } from "react-redux";
import {
  listOrdersStart,
  updateOrderStart,
} from "Redux/orders/actions/order.actions";
import { selectVendorId } from "Redux/user/selectors/user.selectors";
import { updateOrderStatusStart } from "Redux/orders/actions/order.actions";
import { NewOrdersTemplate } from "templates";

function NewOrdersPage() {
  const dispatch = useDispatch();
  const selectedVendorId = useSelector(selectVendorId);
  const [isLoading, isLoadingMore, hasMore, page, queryParams, orders] =
    useOrders("new");
  // const orders = useSelector(selectOrdersList);
  // const isLoading = useSelector(selectIsOrdersLoading);
  // const isLoadingMore = useSelector(selectIsOrdersLoadingMore);
  // const hasMore = useSelector(selectHasMore);
  // const selectedVendorId = useSelector(selectVendorId);
  // const [page, setPage] = useState(1);
  // const [queryParams, setQueryParams] = useState({
  //   status: "new",
  //   page_number: 1,
  //   admin_vendor_id: selectedVendorId,
  // });
  // useEffect(() => {
  //   setQueryParams({ ...queryParams, admin_vendor_id: selectedVendorId });
  // }, [selectedVendorId]);

  // useEffect(() => {
  //   if (orders) setPage((prev) => prev + 1);
  // }, [orders]);

  // useEffect(() => {
  //   setPage(1);
  // }, [queryParams]);

  // useEffect(() => {
  //   dispatch(listOrdersStart(queryParams));
  // }, [dispatch, queryParams]);

  const handleChangeTimeSubmit = (id, time) => {
    dispatch(
      updateOrderStart({
        id: id,
        body: {
          order: { time_in_minutes: time },
          admin_vendor_id: selectedVendorId,
        },
      })
    );
    dispatch(
      listOrdersStart({ status: "new", admin_vendor_id: selectedVendorId })
    );
  };

  const handleAccept = async (orderId) => {
    await dispatch(
      updateOrderStatusStart({
        order: { status: "accepted" },
        id: orderId,
        admin_vendor_id: selectedVendorId,
        action: () => {
          dispatch(
            listOrdersStart({
              status: "new",
              admin_vendor_id: selectedVendorId,
            })
          );
        },
      })
    );
  };

  const handleReject = async (orderId) => {
    dispatch(
      updateOrderStatusStart({
        order: { status: "rejected" },
        id: orderId,
        admin_vendor_id: selectedVendorId,
        action: () => {
          dispatch(
            listOrdersStart({
              status: "new",
              admin_vendor_id: selectedVendorId,
            })
          );
        },
      })
    );
  };

  return (
    <NewOrdersTemplate
      handleChangeTimeSubmit={handleChangeTimeSubmit}
      hasMore={hasMore}
      queryParams={queryParams}
      // setQueryParams={setQueryParams}
      orders={orders}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      page={page}
      handleAccept={handleAccept}
      handleReject={handleReject}
    />
  );
}

export default NewOrdersPage;
