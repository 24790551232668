import { useEffect } from "react";
import { MainTemplate } from "templates";

import {} from "Redux/orders/actions/order.actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { allVendorsSelector } from "Redux/vendor/selectors/vendor.selectors";
import { getAllVendorsStart } from "Redux/vendor/actions/vendor.actions";
import { selectCurrentUser } from "Redux/user";

const OrdersPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vendors = useSelector(allVendorsSelector);
  const currentUser = useSelector(selectCurrentUser);
  // useEffect(() => {
  //   dispatch(getAllVendorsStart());
  // }, []);
  useEffect(() => {
    if (pathname === "/main/orders") {
      navigate("/main/orders/new");
    } else {
      navigate(pathname);
    }
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (location.state?.currentView) {
  //     setCurrentView(location.state?.currentView);
  //   }
  // }, [location.state?.currentView, setCurrentView]);

  // useEffect(() => {
  //   if (orders) setPage((prev) => prev + 1);
  // }, [orders]);

  // useEffect(() => {
  //   setPage(1);
  // }, [queryParams]);

  // useEffect(() => {
  //   dispatch(listOrdersStart(queryParams));
  // }, [dispatch, queryParams]);

  return <MainTemplate />;
};

export default OrdersPage;
