import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import ReactCodeInput from "react-verification-code-input";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { getPayload, StorageUtils } from "utils";

import { useCountDown } from "Hooks";

import {
  resendVerificationMessageStart,
  verifyOTPStart,
} from "Redux/user/actions/user.actions";
import {
  selectCurrentUser,
  selectMobileVerificationStatus,
  selectResetPasswordVerifyOTPStatus,
} from "Redux/user/selectors/user.selectors";

import { CustomButton, Typography } from "components/atoms";
import { ValidationsWrapper } from "components/Containers";

import styles from "./VerifyMobileNumberForm.module.css";

const VerifyMobileNumberForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isMobileVerified = useSelector(selectMobileVerificationStatus);
  const isResetPasswordVerifyOTPSuccess = useSelector(
    selectResetPasswordVerifyOTPStatus
  );

  const { timer, handleCountDown } = useCountDown(75);

  const [verifyOTPPayload, setPayload] = useState({
    verification_code: "",
    user: {
      mobile_number: currentUser?.mobile_number ?? state?.mobile,
      type: "vendor",
    },
    code_scope:
      state?.scope === "signup-verification" ? "verification" : state?.scope,
  });

  const verifyOTPStartHandler = (verifyOTPPayload) => {
    dispatch(verifyOTPStart(verifyOTPPayload));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    verifyOTPStartHandler(verifyOTPPayload);
  };

  const resendOTPHandler = () => {
    dispatch(resendVerificationMessageStart(verifyOTPPayload));
  };

  useEffect(() => {
    if (isResetPasswordVerifyOTPSuccess && state.scope === "reset_password") {
      navigate("/Reset_Password", {
        state: {
          mobile: state?.mobile,
        },
      });
    } else if (
      isMobileVerified &&
      verifyOTPPayload.code_scope === "verification"
    )
      navigate("/");
  }, [
    isMobileVerified,
    isResetPasswordVerifyOTPSuccess,
    navigate,
    state,
    verifyOTPPayload,
  ]);

  /* 
    useEffect(() => {
      if (isMobileVerified) navigate("/");
    }, [isMobileVerified, navigate]);
 */

  useEffect(() => {
    if (state.scope === "verification" || state?.scope === "reset_password")
      dispatch(resendVerificationMessageStart(verifyOTPPayload));
  }, [dispatch, state]);

  return (
    <ValidationsWrapper
      className="h-full"
      initialValues={{ OTP_code: "" }}
      rules={["OTP_code"]}
    >
      {({ FormikHandler }) => {
        return (
          <div className={styles.form}>
            <div className={styles.formcontainer}>
              <div className={styles.formHeader}>
                <Typography
                  as="h1"
                  size="9xl"
                  color="text-text_white"
                  text={t("Enter a verification code")}
                />

                <Typography
                  as="p"
                  size="xl"
                  color="text-text_grey"
                  fontWeight="font-normal"
                  lineHeight="leading-[1.23]"
                  className="w-4/5"
                  classes="mt-2 lg:mt-4 3xl:mt-8 lg:w-[73%]"
                  text={t(
                    "Enter the verification code we just sent you on your mobile number"
                  )}
                />

                <Typography
                  as="p"
                  size="5xl"
                  color="text-text_grey"
                  classes="mt-2 lg:mt-4 3xl:mt-8"
                  style={{ direction: "initial" }}
                  text={`•••••••${
                    currentUser?.mobile_number?.substr(
                      currentUser?.mobile_number?.length - 4
                    ) ?? state?.mobile?.substr(state?.mobile?.length - 4)
                  }`}
                />
              </div>

              <ReactCodeInput
                type="number"
                fields={5}
                className={styles.formInputsContainer}
                onChange={(data) => {
                  FormikHandler.setFieldValue("OTP_code", data);
                }}
                onComplete={(data) => {
                  FormikHandler.setFieldValue("OTP_code", data);
                  getPayload(
                    {
                      target: { name: "verification_code", value: data },
                    },
                    verifyOTPPayload,
                    setPayload
                  );
                }}
              />
              {FormikHandler.errors.OTP_code && (
                <small className="text-fail text-xs ml-1">
                  {FormikHandler.errors.OTP_code}
                </small>
              )}

              <div className="mt-5 lg:mt-8 3xl:mt-10 ">
                <CustomButton
                  width="w-56"
                  height="h-10"
                  label={t("Submit")}
                  disabled={!FormikHandler.isValid}
                  action={handleSubmit}
                />
              </div>

              <div className="mt-5 3xl:mt-12 flex w-max flex-wrap justify-center ">
                <Typography
                  as="p"
                  size="sm"
                  color="text-text_white"
                  text={t(
                    "It may take a minute to receive the code ? Haven’t received it?"
                  )}
                />

                <button
                  onClick={() => {
                    window.location.reload();
                    resendOTPHandler();
                    handleCountDown();
                  }}
                  disabled={timer !== 0}
                >
                  <Typography
                    as="span"
                    size="sm"
                    color="text-text_white"
                    fontWeight="font-bold"
                    classes={`ml-1 rtl:mr-1 ${
                      timer !== 0 ? "cursor-not-allowed" : "cursor-pointer"
                    }  `}
                    text={t("Re-send a new code") + " بعد "}
                  />
                </button>
                <Typography
                  as="span"
                  size="sm"
                  color="text-text_white"
                  fontWeight="font-bold"
                  classes={`ml-1 rtl:mr-1 ${
                    timer !== 0 ? "cursor-not-allowed" : "cursor-pointer"
                  }  `}
                  text={timer}
                />
              </div>
            </div>
          </div>
        );
      }}
    </ValidationsWrapper>
  );
};

export default VerifyMobileNumberForm;
