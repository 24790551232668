import {
  getMainCategoryStart,
  getProductStart,
  getSavingTemperatureStart,
  getSizeUnitsStart,
  getSizesStart,
  getTagsStart,
} from "Redux/products/actions/products.actions";
import {
  getVendorMenuCategoriesStart,
  listServingTypesStart,
} from "Redux/vendor/actions/vendor.actions";
import {
  selectAddProductError,
  selectIsProductsLoading,
  selectProduct,
  selectSavingTemp,
  selectSizeUnits,
  selectSizes,
  selectTags,
} from "Redux/products/selectors/products.selectors";
import {
  selectServingsList,
  selectVendorMenuCategories,
} from "Redux/vendor/selectors/vendor.selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { AddItemTemplate } from "templates";
import axios from "axios";

const EditItemPage = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState(1);
  const dispatch = useDispatch();
  const servingTypesOptions = useSelector(selectServingsList);
  const vendorMenuCategoriesOptions = useSelector(selectVendorMenuCategories);
  const sizeUnitsOptions = useSelector(selectSizeUnits);
  const savingTempsOptions = useSelector(selectSavingTemp);
  const tags = useSelector(selectTags);
  const sizesOptions = useSelector(selectSizes);
  const selectedProduct = useSelector(selectProduct);
  const isProductsLoading = useSelector(selectIsProductsLoading);
  const isError = useSelector(selectAddProductError);
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  useEffect(() => {
    dispatch(getTagsStart());
    dispatch(getSavingTemperatureStart());
    dispatch(listServingTypesStart());
    dispatch(getMainCategoryStart());
    dispatch(getSizeUnitsStart());
    dispatch(getVendorMenuCategoriesStart());
  }, []);
  useEffect(() => {
    dispatch(getProductStart(id));
  }, []);
  useEffect(() => {
    setProduct(selectedProduct);
  }, [selectedProduct]);
  return (
    <AddItemTemplate
      isError={isError}
      sizesOptions={sizesOptions}
      navigate={navigate}
      parent={"edit"}
      product={product}
      language={language}
      setLanguage={setLanguage}
      servingTypesOptions={servingTypesOptions}
      vendorMenuCategoriesOptions={vendorMenuCategoriesOptions}
      sizeUnitsOptions={sizeUnitsOptions}
      savingTempsOptions={savingTempsOptions}
      tags={tags}
      isProductsLoading={isProductsLoading}
    />
  );
};

export default EditItemPage;
