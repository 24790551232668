import { PropTypes } from "prop-types";

const Img = ({
  fileName,
  fileName3x,
  fileName2x,
  altText,
  action,
  containerClasses = "",
  picClasses = "",
  onClick,
}) => {
  return (
    <div onClick={action} className={containerClasses}>
      <picture className={` ${picClasses} w-full h-full`} onClick={onClick}>
        <source srcSet={fileName3x} media="(min-width: 1200px)" />
        <source srcSet={fileName2x} media="(min-width: 700px)" />
        <img
          className={` ${picClasses} w-full h-full`}
          srcSet={fileName}
          alt={altText}
        />
      </picture>
    </div>
  );
};

Img.propTypes = {
  fileName: PropTypes.string,
  fileName3x: PropTypes.string,
  fileName2x: PropTypes.string,
  altText: PropTypes.string,
  action: PropTypes.func,
  containerClasses: PropTypes.string,
  picClasses: PropTypes.string,
};

export default Img;
