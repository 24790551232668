import { FormInput, Modal } from "components/molecules";

import { CloseIcon } from "utils/helpers/SVGsSrc";
import { CustomButton } from "components/atoms";
import SuccessConfirmationModal from "../Modals/SuccessConfirmationModal";
import { ValidationsWrapper } from "components/Containers";
import { listOrdersStart } from "Redux/orders/actions/order.actions";
import { useDispatch } from "react-redux";

function ChangeDurationModal({
  modalOpen,
  ToggleModalVisibility,
  id,
  handleChangeTimeSubmit,
  successModalOpen,
  setSuccessModalOpen,
}) {
  const dispatch = useDispatch();
  return (
    <Modal isOpen={modalOpen} ToggleModalVisibility={ToggleModalVisibility}>
      <div className="bg-white 2xl:w-2/5 xl:w-2/5 lg:w-2/5 w-4/5  my-40 px-10 py-10 ">
        <CloseIcon
          className="cursor-pointer"
          title="close"
          onClick={() => ToggleModalVisibility(!modalOpen)}
        />
        <ValidationsWrapper
          initialValues={{
            order_time: "",
          }}
          rules={["order_time"]}
        >
          {({ FormikHandler }) => {
            return (
              <div className="my-8">
                <div className="mb-9">
                  <FormInput
                    font="font-SFProText"
                    label="مدة العمل على الطلب"
                    labelSize={"2xl"}
                    textColor={"text-warm_grey"}
                    id="name"
                    name="name"
                    width="w-full"
                    height="h-9"
                    placeholder="مثال ٦٠ دقيقة"
                    error={FormikHandler.errors.order_time}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("order_time", e.target.value);
                    }}
                  />
                </div>
                <CustomButton
                  height="h-10"
                  width="w-full"
                  label={"حفظ"}
                  textColor="text-primary-50"
                  disabled={!FormikHandler.isValid}
                  action={() => {
                    handleChangeTimeSubmit(id, FormikHandler.values.order_time);
                    setSuccessModalOpen(!successModalOpen);
                  }}
                />
              </div>
            );
          }}
        </ValidationsWrapper>

        <SuccessConfirmationModal
          isOpen={successModalOpen}
          ToggleModalVisibility={() => {
            setSuccessModalOpen(!successModalOpen);
          }}
          heading={"لقد تلقينا تعديلاتك بنجاح"}
          subHeadings={["تم تغيير الوقت بنجاح"]}
          onClosAction={() => {
            dispatch(
              listOrdersStart({
                status: "new",
                page_number: 1,
              })
            );
            ToggleModalVisibility(!modalOpen);
          }}
        />
      </div>
    </Modal>
  );
}

export default ChangeDurationModal;
