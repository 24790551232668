export const LOGIN = "/vendor/login";

export const LOGOUT = "/vendor/logout";

export const REGISTER = "/vendor/register";

export const VERIFY_OTP = "/auth/verify_otp";

export const SEND_VERIFICATION_MESSAGE = "/auth/send_verification_message";

export const VERIFY_MOBILE = "/verify_mobile";

export const RESET_PASSWORD = "/auth/reset_password";

export const CHANGE_PASSWORD = "/auth/change_password";

export const UPDATE_MOBILE = "/update_mobile";

export const MAIN_CATEGORIES = "/product/main_categories";

export const S3 = "/presigned_url";

export const ORDERS = "/order/orders/";

export const UPDATE_ORDER = "/order/update";

export const UPDATE_ORDER_STATUS = "order/update_status";

export const ADD_PRODUCT = "/product/new";

export const GET_PRODUCT_MAIN_CATEGORIES = "/vendor/menu_categories";

export const GET_VENDOR_MENU_CATEGORIES = "/vendor/vendor_menu_categories";

export const GET_SAVING_TEMP = "/vendor/saving_temperatures";

export const GET_TAGS = "/vendor/tags";

export const ADD_MENU_CATEGORY = "/vendor/add_menu_category";

export const GET_DISCOUNT = "vendor/get_discount";

export const UPDATE_DISCOUNT = "/vendor/update_discount";

export const ADD_DISCOUNT = "/vendor/new_discount";

export const DELETE_PRODUCT_DISCOUNT = "/product/delete_discount";

export const GET_PRODUCT = "/product/products/";

export const GET_PRODUCT_SIZE_UNITS = "/product/size_units";

export const GET_PRODUCT_SIZES = "/product/sizes/";

export const GET_PRODUCTS = "/product/vendor_products";

export const REMOVE_PRODUCT = "/product/products/";

export const UPDATE_PRODUCT = "/product/products";

export const SERVING_TYPES = "/vendor/serving_types";

export const GET_PROFILE = "/vendor/vendor_profile";

export const FAQ = "/faq";

export const CONTACT_MESSAGE = "/contact_us";

export const NOTIFICATION = "/notifications";

export const NOTIFICATION_COUNT = "/notifications_count";

export const NOTIFICATION_READ = "/set_read_notifications";

export const SET_FCM = "/set_fcm_token";

export const GET_ALL_VENDORS = "/vendor/vendors";

export const SYSTEM_CONFIGURATION = "/system_configurations";

export const LIST_ALL_AREAS = "/areas";

export const LIST_ALL_DELIVERY_METHODS = "/rider/delivery_third_parties";

export const LIST_VENDOR_AREAS = "/vendor/areas";

export const LIST_DELIVERY_METHOD_BY_AREA = "vendor/delivery_parties_by_area";

export const LIST_ALL_CITIES = "/cities";

export const LIST_ALL_COUNTRIES = "/lookups/countries";

export const LIST_ALL_CURRENCIES = "/lookups/currencies";

export const LIST_ALL_PAYOUT_METHODS = "/lookups/vendor_payout_methods";
