import * as yup from "yup";

import {
  AdditionsSection,
  CustomSection,
  MainSection,
  SizeSection,
  SuccessConfirmationModal,
} from "components/organisms";
import { CustomButton, Img, Spinner, Typography } from "components/atoms";
import { DropDownInput, FormInput } from "components/molecules";
import {
  addProductStart,
  getSizesStart,
  resetSuccessConfirmationModal,
  updateProductStart,
  uploadProductImageStart,
} from "Redux/products/actions/products.actions";
import { getIn, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { Circle } from "rc-progress";
import { ImagesSrc } from "utils";
import { PlusSign } from "utils/helpers/SVGsSrc";
import lodash from "lodash";
import { productImageSelector } from "Redux/products/selectors/products.selectors";
import { selectVendorId } from "Redux/user/selectors/user.selectors";
import { toast } from "react-toastify";

const AddItemTemplate = ({
  successConfirmationModalState,
  vendorMenuCategoriesOptions,
  savingTempsOptions,
  servingTypesOptions,
  sizeUnitsOptions,
  language,
  setLanguage,
  tags,
  product,
  parent,
  percent = 0,
  navigate,
  sizesOptions,
  isProductsLoading,
  isError,
}) => {
  const productImageUrl = useSelector(productImageSelector);
  const selectedVendorId = useSelector(selectVendorId);
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [profilePicture, setProfilePicture] = useState(ImagesSrc.UploadImage);
  console.log(isError);
  const [successConfirmationModalOpen, setSuccessConfirmationModalOpen] =
    useState(false);

  // states to display in drop downs because it accepts only ({label, value})
  const [menuCategoriesDisplay, setMenuCategoriesDisplay] = useState([]);
  const [savingTemperatureDisplay, setSavingTemperatureDisplay] = useState([]);
  const [servingTypeDisplay, setServingTypeDisplay] = useState([]);
  const [sizeUnitsDisplay, setSizeUnitsDisplay] = useState([]);
  const [sizeDisplay, setSizeDisplay] = useState([]);
  const [disable, setDisable] = useState(false);
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      dispatch(uploadProductImageStart(img));
      setProfilePicture(URL.createObjectURL(img));
    }
  };

  const validationSchema = yup.object().shape({
    product: yup.object().shape({
      time_in_minutes: yup.number().required("الرجاء إدخال وقت التحضير"),
      default_daily_quantity: yup
        .number()
        .required("الرجاء إدخال الكمية اليومية"),
      en: yup.object().shape({
        name_en: yup
          .string()
          .required("الرجاء إدخال اسم المنتج باللغة الإنجليزية"),
        description_en: yup
          .string()
          .required("الرجاء إدخال وصف المنتج باللغة الإنجليزية"),
      }),
      ar: yup.object().shape({
        name_ar: yup
          .string()
          .required("الرجاء إدخال اسم المنتج باللغة العربية"),
        description_ar: yup
          .string()
          .required("الرجاء إدخال وصف المنتج باللغة العربية"),
      }),
    }),
    product_sizes: yup.array().of(
      yup.object().shape({
        price: yup
          .number()
          .typeError("يجب أن يكون السعر رقمًا")
          .required("الرجاء إدخال سعر الحجم"),
      })
    ),

    ar: yup.object().shape({
      product_options: yup.array().of(
        yup.object().shape({
          name: yup.string().required("الرجاء إدخال اسم الإضافة بالعربية"),

          option_values: yup.array().of(
            yup.object().shape({
              name: yup.string().required("الرجاء إدخال اسم الإضافة بالعربية"),
            })
          ),
        })
      ),
    }),

    en: yup.object().shape({
      product_options: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .required("الرجاء إدخال اسم الإضافة باللغة الإنجليزية"),

          option_values: yup.array().of(
            yup.object().shape({
              name: yup
                .string()
                .required("الرجاء إدخال اسم الإضافة باللغة الإنجليزية"),
            })
          ),
        })
      ),
    }),
  });
  const formik = useFormik({
    initialValues: {
      product: {
        serving_type_id: 0,
        base_price: 0,
        image: "",
        time_in_minutes: "",
        default_daily_quantity: 0,
        tag_ids: [],
        saving_temperature_id: 0,
        size_unit_id: 1,
        week_day_ids: [],
        menu_category_ids: [],
        discount_status: "inactive",
        en: {
          name_en: "",
          description_en: "",
        },
        ar: {
          name_ar: "",
          description_ar: "",
        },
      },
      product_sizes: [],
      en: {
        product_options: [
          {
            name: "Extra",
            description: "",
            option_values: [],
          },
          {
            name: "Combo With",
            option_values: [],
          },
        ],
      },

      ar: {
        product_options: [
          {
            name: "اضافات",
            description: "",
            option_values: [],
          },
          {
            name: "عناصر يمكن جمعها",
            option_values: [],
          },
        ],
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = { ...values, admin_vendor_id: selectedVendorId };
      if (parent === "edit") {
        const action = () => {
          window.history.back();
        };
        await dispatch(
          updateProductStart({ id: product?.id, body: body, action })
        );
        // setDisable(true);
        // if (isError) {
        //   toast.error("حدث خطأ ما ، يرجى المحاولة مرة أخرى");
        //   setDisable(false);
        // } else {
        //   setSuccessConfirmationModalOpen(!successConfirmationModalOpen);
        // }
      } else {
        await dispatch(addProductStart(body));
        // setDisable(true);
        // if (isError) {
        //   toast.error("حدث خطأ ما ، يرجى المحاولة مرة أخرى");
        //   setDisable(false);
        // } else {
        //   console.log("when does this run");
        //   setSuccessConfirmationModalOpen(!successConfirmationModalOpen);
        // }
      }
    },
  });

  //Use effect to get the values of sizes when the size unit changes
  useEffect(() => {
    dispatch(getSizesStart(formik.values.product.size_unit_id));
  }, [formik.values.product.size_unit_id]);
  //Use effect to set the image url inside the form when it is recieved from the S3
  useEffect(() => {
    formik.setFieldValue("product.image", productImageUrl);
  }, [productImageUrl]);

  //Use effect to set all the values coming from get product with their matching values in the body
  // that will be sent to update product
  useEffect(() => {
    if (product) {
      //Setting Arabic name and description
      formik.setFieldValue("product.ar.name_ar", product?.name_ar);
      formik.setFieldValue(
        "product.ar.description_ar",
        product?.description_ar
      );
      //Setting English name and description
      formik.setFieldValue("product.en.name_en", product?.name_en);
      formik.setFieldValue(
        "product.en.description_en",
        product?.description_en
      );

      // Modifying values of key names coming from get product to match the ones accepted by
      // update product
      const product_options = product?.product_options;
      const modifiedProductOptions = product_options.map((option) => {
        const product_option_id = option.id;
        let newOption = { ...option, product_option_id };
        newOption = lodash.omit(newOption, ["id"]);
        const option_values = option.option_values.map((option_value) => {
          const product_option_value_id = option_value.id;
          let newValueOption = { ...option_value, product_option_value_id };
          newValueOption = lodash.omit(newValueOption, ["id"]);

          return newValueOption;
        });
        newOption = { ...newOption, option_values };
        return newOption;
      });
      //making productOptions English
      const product_options_en = modifiedProductOptions?.map((option) => {
        const option_values_en = option?.option_values?.map((option_value) => {
          return {
            name: option_value.name_en,
            price_addition: option_value.price_addition,
            product_option_value_id: option_value.product_option_value_id,
          };
        });
        return {
          name: option.name_en,
          description: option.description_en,
          product_option_id: option.product_option_id,
          min: option.min,
          max: option.max,
          option_values: option_values_en,
        };
      });
      //Setting Product Options EN
      formik.setFieldValue("en.product_options", product_options_en);

      // making product options Arabic
      const product_options_ar = modifiedProductOptions?.map((option) => {
        const option_values_ar = option?.option_values?.map((option_value) => {
          return {
            name: option_value.name_ar,
            price_addition: option_value.price_addition,
            product_option_value_id: option_value.product_option_value_id,
          };
        });
        return {
          name: option.name_ar,
          description: option.description_ar,
          product_option_id: option.product_option_id,
          min: option.min,
          max: option.max,
          option_values: option_values_ar,
        };
      });
      //Setting product options Arabic
      formik.setFieldValue("ar.product_options", product_options_ar);

      //Setting menu category ids for display and formik body
      const menuCategoryIDS = product?.menu_categories.map(
        (category) => category.id
      );
      formik.setFieldValue("product.menu_category_ids", menuCategoryIDS);
      setMenuCategoriesDisplay(
        product?.menu_categories.map((category) => {
          return { value: category.id, label: category.name };
        })
      );

      //Setting saving temperature for display and formik body
      formik.setFieldValue(
        "product.saving_temperature_id",
        product?.saving_temperature_id
      );
      setSavingTemperatureDisplay({
        label: product?.saving_temperature,
        value: product?.saving_temperature_id,
      });

      //Setting Serving type for display and formik body
      formik.setFieldValue("product.serving_type_id", product?.serving_type_id);
      setServingTypeDisplay({
        label: product?.serving_type,
        value: product?.serving_type_id,
      });

      //Setting time in formik
      formik.setFieldValue("product.time_in_minutes", product?.time_in_minutes);

      //Setting tags in formik
      formik.setFieldValue(
        "product.tag_ids",
        product?.tags?.map((tag) => tag.id)
      );

      //Setting weekdays in formik
      formik.setFieldValue(
        "product.week_day_ids",
        product?.week_days.map((day) => day.id)
      );
      //Setting Size Unit for display and formik body

      formik.setFieldValue(
        "product.size_unit_id",
        product?.sizes[0]?.size_unit_id
      );
      setSizeUnitsDisplay({
        value: product?.sizes[0]?.size_unit_id,
        label: product?.sizes[0]?.size_unit_name,
      });
      formik.setFieldValue("product_sizes", product?.sizes);
      const sizesToBeDisplayed = product?.sizes?.map((object) => {
        return { label: object.size_name, value: object.size_id };
      });
      setSizeDisplay(sizesToBeDisplayed);

      // formik.setFieldValue(
      //   "product.discount_type",
      //   product?.discount?.type === "percentage" ? 2 : 1
      // );
      formik.setFieldValue(
        "product.default_daily_quantity",
        product?.default_daily_quantity
      );
      // formik.setFieldValue("product.value", product?.discount?.value);
      if (product?.image) {
        setProfilePicture(product?.image);
        formik.setFieldValue("product.image", product?.image);
      } else {
        setProfilePicture(ImagesSrc.UploadImage);
        formik.setFieldValue("product.image", null);
      }
    }
  }, [product]);

  return (
    <>
      {isProductsLoading ? (
        <div className="h-screen">
          <Spinner loading={isProductsLoading} />
        </div>
      ) : (
        <main className="flex 2xl:px-28  mt-32">
          <SuccessConfirmationModal
            ToggleModalVisibility={() =>
              setSuccessConfirmationModalOpen(!successConfirmationModalOpen)
            }
            isOpen={successConfirmationModalState}
            heading={"تم استلام البيانات الخاصة بك بنجاح"}
            subHeadings={
              parent === "edit"
                ? ["تم تغيير منتجك بنجاح"]
                : ["تمت إضافة منتجك بنجاح"]
            }
            onClosAction={() => {
              dispatch(resetSuccessConfirmationModal(false));
              navigate("/main/menu");
            }}
          />
          <div className="container mx-auto mt-10 w-1/2">
            <div className="flex justify-end mb-8">
              <DropDownInput
                styles={{
                  option: (base, { isFocused }) => ({
                    ...base,
                    color: "#707070",
                    fontSize: "18px",
                    fontWeight: "500",
                    backgroundColor: isFocused ? "#cbcbcb" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#777",
                  }),
                  // Language dropdown styles
                  menu: (base) => ({
                    ...base,
                    backgroundColor: "#f8f8fe",
                    border: 0,
                  }),
                  menuList: (base) => ({
                    ...base,
                    border: 0,
                  }),

                  control: (base) => ({
                    ...base,
                    flexDirection: "row",
                    width: "10.938rem",
                    height: "3.938rem",
                    backgroundColor: "#f8f8fe",
                    border: 0,
                    boxShadow: "none",
                  }),
                  menuList: (base) => ({ ...base, border: "0px" }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#707070",
                    fontWeight: "350",
                    fontSize: "22px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                  }),
                }}
                searchable={false}
                closeOnSelect={true}
                name="menu"
                id="menu"
                options={[
                  { value: 1, label: "العربية" },
                  { value: 2, label: "الإنجليزية" },
                ]}
                defaultValue={{ value: 1, label: "العربية" }}
                width="w-full"
                placeholder="اللغة"
                onValueChange={(e) => {
                  setLanguage(e.value);
                }}
              />
            </div>
            <MainSection
              name_en_error={
                getIn(formik.touched, "product.en.name_en") &&
                getIn(formik.errors, "product.en.name_en")
              }
              name_ar_error={
                getIn(formik.touched, "product.ar.name_ar") &&
                getIn(formik.errors, "product.ar.name_ar")
              }
              description_en_error={
                getIn(formik.touched, "product.en.description_en") &&
                getIn(formik.errors, "product.en.description_en")
              }
              description_ar_error={
                getIn(formik.touched, "product.ar.description_ar") &&
                getIn(formik.errors, "product.ar.description_ar")
              }
              quantity_error={
                getIn(formik.touched, "product.default_daily_quantity") &&
                getIn(formik.errors, "product.default_daily_quantity")
              }
              time_error={
                getIn(formik.touched, "product.time_in_minutes") &&
                getIn(formik.errors, "product.time_in_minutes")
              }
              parent={parent}
              language={language}
              name={
                language === 1
                  ? formik.values.product.ar.name_ar
                  : formik.values.product.en.name_en
              }
              description={
                language === 1
                  ? formik.values.product.ar.description_ar
                  : formik.values.product.en.description_en
              }
              time={formik.values.product.time_in_minutes}
              quantity={formik.values.product.default_daily_quantity}
              tagIDS={formik.values.product.tag_ids}
              weekDayIDS={formik.values.product.week_day_ids}
              temp={product?.saving_temperature}
              setFieldValue={formik.setFieldValue}
              menuCategoriesDisplay={menuCategoriesDisplay}
              savingTemperatureDisplay={savingTemperatureDisplay}
              servingTypeDisplay={servingTypeDisplay}
              setMenuCategoriesDisplay={setMenuCategoriesDisplay}
              setSavingTemperatureDisplay={setSavingTemperatureDisplay}
              setServingTypeDisplay={setServingTypeDisplay}
              vendorMenuCategoriesOptions={vendorMenuCategoriesOptions}
              savingTempsOptions={savingTempsOptions}
              servingTypesOptions={servingTypesOptions}
              tags={tags}
            />
            <div className="my-14">
              <Typography
                as="span"
                size="2xl"
                color="text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"الحجم"}
              />
              <SizeSection
                productSizes={formik.values.product_sizes}
                setFieldValue={formik.setFieldValue}
                sizeUnitsOptions={sizeUnitsOptions}
                sizeUnitsDisplay={sizeUnitsDisplay}
                sizeDisplay={sizeDisplay}
                setSizeDisplay={setSizeDisplay}
                setSizeUnitsDisplay={setSizeUnitsDisplay}
                sizesOptions={sizesOptions}
                formik={formik}
              />
            </div>
            <div className="my-14">
              <Typography
                as="span"
                size="2xl"
                color="text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={" الإضافات  ( إختياري )"}
              />
              <AdditionsSection
                formik={formik}
                language={language}
                title="الإضافة"
                productOptionsAr={formik.values.ar.product_options[0]}
                productOptionsEn={formik.values.en.product_options[0]}
                setFieldValue={formik.setFieldValue}
                id={0}
              />
            </div>
            <div className="my-14">
              <Typography
                as="span"
                size="2xl"
                color="text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"عناصر يمكن جمعها مع العنصر"}
              />
              <AdditionsSection
                formik={formik}
                language={language}
                title="العنصر"
                productOptionsAr={formik.values.ar.product_options[1]}
                productOptionsEn={formik.values.en.product_options[1]}
                setFieldValue={formik.setFieldValue}
                id={1}
              />
            </div>
            <div className="mt-14 mb-9">
              <Typography
                as="span"
                size="2xl"
                color="text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"اختيارات تخص العنصر ( إختياري )"}
              />
              {language === 1
                ? formik.values.ar.product_options.map((data, index) => {
                    if (index > 1) {
                      return (
                        <CustomSection
                          formik={formik}
                          productOptionsAr={formik.values.ar.product_options}
                          productOptionsEn={formik.values.en.product_options}
                          language={language}
                          setFieldValue={formik.setFieldValue}
                          data={data}
                          id={index}
                        />
                      );
                    }
                  })
                : formik.values.en.product_options.map((data, index) => {
                    if (index > 1) {
                      return (
                        <CustomSection
                          formik={formik}
                          productOptionsAr={formik.values.ar.product_options}
                          productOptionsEn={formik.values.en.product_options}
                          language={language}
                          setFieldValue={formik.setFieldValue}
                          data={data}
                          id={index}
                        />
                      );
                    }
                  })}
            </div>
            <div className="mt-8 mb-10 flex justify-items-start">
              <div className="ml-4  mt-1">
                <PlusSign
                  height="100%"
                  width="100%"
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => {
                    formik.setFieldValue("ar.product_options", [
                      ...formik.values.ar.product_options,
                      {
                        name: "",
                        description: "",
                        min: 1,
                        max: 0,
                        option_values: [],
                      },
                    ]);
                    formik.setFieldValue("en.product_options", [
                      ...formik.values.en.product_options,
                      {
                        name: "",
                        description: "",
                        min: 1,
                        max: 0,
                        option_values: [],
                      },
                    ]);
                  }}
                />
              </div>

              <Typography
                as="span"
                size="2xl"
                color="text-primary-900"
                lineHeight="leading-[1.21]"
                classes="ml-36 cursor-pointer"
                text={"إضافة المزيد"}
                click={() => {
                  formik.setFieldValue("ar.product_options", [
                    ...formik.values.ar.product_options,
                    {
                      name: "",
                      description: "",
                      min: 1,
                      max: 0,
                      option_values: [],
                    },
                  ]);
                  formik.setFieldValue("en.product_options", [
                    ...formik.values.en.product_options,
                    {
                      name: "",
                      description: "",
                      min: 1,
                      max: 0,
                      option_values: [],
                    },
                  ]);
                }}
              />
            </div>
            {/* <div className="flex justify-items-start mb-6">
            <Typography
              as="span"
              size="3xl"
              classes="ml-4"
              color="text_3b3b3b"
              lineHeight="leading-[1.21]"
              text={"الخصم"}
            />
            <Typography
              as="span"
              size="2xl"
              color="text-warm_grey"
              lineHeight="leading-[1.21]"
              text={"( إختياري )"}
            />
          </div> */}
            {/* <div className="bg-[#f8f8fe] py-6">
            <div className="mx-6 my-6">
              <RadioGroup
                name={"Discount"}
                id={"Discount"}
                state={formik.values.product.discount_type}
                onValueChange={(e) => {
                  formik.setFieldValue(
                    "product.discount_type",
                    Number(e.target.value)
                  );
                }}
                options={[
                  { id: 1, name: "خصم بقيمة ثابتة" },
                  { id: 2, name: "خصم بنسبة مئوية" },
                ]}
              />
              <div className="mx-6 my-6 ">
                <FormInput
                  width="w-40"
                  height="h-9"
                  value={formik.values.product.value}
                  placeholder={
                    formik.values.product.discount_type == 1
                      ? " مثال  25 جنيه المصري %"
                      : "٪ ١٠مثال"
                  }
                  onValueChange={(e) => {
                    let value = Number(e.target.value);
                    formik.setFieldValue("product.value", value);
                  }}
                />
              </div>
            </div>
          </div> */}
            <div className="flex justify-center mt-16">
              <CustomButton
                disabled={disable}
                width="w-52"
                height="h-10"
                action={formik.handleSubmit}
                classes={disable ? "bg-red-900" : ""}
                label={"حفظ"}
              />
            </div>
          </div>

          <div className="container mx-auto mt-10  w-1/3 ">
            <div className="mx-6">
              {percent === 0 || percent === 100 ? (
                <>
                  {" "}
                  <Img
                    fileName={profilePicture}
                    containerClasses="cursor-pointer "
                    action={() => inputFile.current.click()}
                  />
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      onImageChange(event);
                    }}
                  />
                  <div className="flex justify-center">
                    <Typography
                      as="span"
                      size="2xl"
                      color="text-primary-900"
                      lineHeight="leading-[1.21]"
                      text={"إضافة صورة"}
                      classes={"cursor-pointer"}
                      click={() => inputFile.current.click()}
                    />
                  </div>
                </>
              ) : (
                <div className="mx-6">
                  <Typography
                    as="span"
                    size="2xl"
                    color="text-primary-900"
                    lineHeight="leading-[1.21]"
                    classes="flex justify-center"
                    text={percent + "%"}
                  />
                  <div className="flex justify-center">
                    <Circle
                      percent={percent}
                      strokeWidth={4}
                      strokeColor="#D3D3D3"
                      className="w-60 h-60"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default AddItemTemplate;
