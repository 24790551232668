import { Popover, Transition } from "@headlessui/react";

import { Fragment } from "react";
import { PropTypes } from "prop-types";

const PopOver = ({ children, label, panelClasses = "", show }) => {
  return (
    <Popover className="relative">
      <Popover.Button>{label}</Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          static
          className={`absolute z-10 rounded-lg bg-primary-50 transform-translate-x-full left-1/2`}
        >
          <div
            style={{
              boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            }}
            className="overflow-hidden rounded-lg  ring-1 ring-black ring-opacity-5"
          >
            {children}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

Popover.propTypes = {
  label: PropTypes.any,
  children: PropTypes.node.isRequired,
  panelClasses: PropTypes.string,
};

export default PopOver;
