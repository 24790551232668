import ProductActionTypes from "../types/products.types";
import { createAction } from "utils";

export const progressBarValue = (payload) =>
  createAction(ProductActionTypes.PROGRESS_BAR_START, payload);

export const deleteProductDiscountStart = (payload) =>
  createAction(ProductActionTypes.DELETE_PRODUCT_DISCOUNT_START, payload);

export const deleteProductDiscountSuccess = (payload) =>
  createAction(ProductActionTypes.DELETE_PRODUCT_DISCOUNT_SUCCESS, payload);

export const deleteProductDiscountFailure = (error) =>
  createAction(ProductActionTypes.DELETE_PRODUCT_DISCOUNT_FAILURE, error);

export const uploadProductImageStart = (payload) =>
  createAction(ProductActionTypes.UPLOAD_PRODUCT_IMAGE_START, payload);

export const uploadProductImageSuccess = (imageUrl) =>
  createAction(ProductActionTypes.UPLOAD_PRODUCT_IMAGE_SUCCESS, imageUrl);

export const uploadProductImageFailure = (error) =>
  createAction(ProductActionTypes.UPLOAD_PRODUCT_IMAGE_FAILURE, error);

export const loadMoreProductsStart = (payload) =>
  createAction(ProductActionTypes.LOAD_MORE_PRODUCTS_START, payload);

export const loadMoreProductsSuccess = (products) =>
  createAction(ProductActionTypes.LOAD_MORE_PRODUCTS_SUCCESS, products);

export const loadMoreProductsFailure = (error) =>
  createAction(ProductActionTypes.LOAD_MORE_PRODUCTS_FAILURE, error);

export const removeProductStart = (payload) =>
  createAction(ProductActionTypes.REMOVE_PRODUCT_START, payload);

export const removeProductSuccess = (payload) =>
  createAction(ProductActionTypes.REMOVE_PRODUCT_SUCCESS, payload);

export const removeProductFailure = (error) =>
  createAction(ProductActionTypes.REMOVE_PRODUCT_FAILURE, error);

export const getProductsStart = (payload) =>
  createAction(ProductActionTypes.GET_PRODUCTS_START, payload);

export const getProductsSuccess = (products) =>
  createAction(ProductActionTypes.GET_PRODUCTS_SUCCESS, products);

export const getProductsFailure = (error) =>
  createAction(ProductActionTypes.GET_PRODUCTS_FAILURE, error);

export const addProductStart = (payload) =>
  createAction(ProductActionTypes.ADD_PRODUCT_START, payload);

export const addProductSuccess = (product) =>
  createAction(ProductActionTypes.ADD_PRODUCT_SUCCESS, product);

export const addProductFailure = (payload) =>
  createAction(ProductActionTypes.ADD_PRODUCT_FAILURE, payload);

export const getProductStart = (payload) =>
  createAction(ProductActionTypes.GET_PRODUCT_START, payload);

export const getProductSuccess = (product) =>
  createAction(ProductActionTypes.GET_PRODUCT_SUCCESS, product);

export const getProductFailure = (error) =>
  createAction(ProductActionTypes.GET_PRODUCT_FAILURE, error);

export const getSavingTemperatureStart = () =>
  createAction(ProductActionTypes.GET_SAVING_TEMPERATURE_START);

export const getSavingTemperatureSuccess = (savingTemp) =>
  createAction(ProductActionTypes.GET_SAVING_TEMPERATURE_SUCCESS, savingTemp);

export const getSavingTemperatureFailure = (error) =>
  createAction(ProductActionTypes.GET_SAVING_TEMPERATURE_FAILURE, error);

export const getTagsStart = () =>
  createAction(ProductActionTypes.GET_TAGS_START);

export const getTagsSuccess = (tags) =>
  createAction(ProductActionTypes.GET_TAGS_SUCCESS, tags);

export const getTagsFailure = (error) =>
  createAction(ProductActionTypes.GET_TAGS_FAILURE, error);

export const getMainCategoryStart = () =>
  createAction(ProductActionTypes.GET_CATEGORY_START);

export const getMainCategorySuccess = (mainCategories) =>
  createAction(ProductActionTypes.GET_CATEGORY_SUCCESS, mainCategories);

export const getMainCategoryFailure = (error) =>
  createAction(ProductActionTypes.GET_CATEGORY_FAILURE, error);

export const getSizeUnitsStart = () =>
  createAction(ProductActionTypes.GET_SIZE_UNITS_START);

export const getSizeUnitsSuccess = (sizeUnits) =>
  createAction(ProductActionTypes.GET_SIZE_UNITS_SUCCESS, sizeUnits);

export const getSizeUnitsFailure = (error) =>
  createAction(ProductActionTypes.GET_SIZE_UNITS_FAILURE, error);

export const getSizesStart = (payload) =>
  createAction(ProductActionTypes.GET_SIZES_START, payload);

export const getSizesSuccess = (sizes) =>
  createAction(ProductActionTypes.GET_SIZES_SUCCESS, sizes);

export const getSizesFailure = (error) =>
  createAction(ProductActionTypes.GET_SIZES_FAILURE, error);

export const updateProductStart = (payload) =>
  createAction(ProductActionTypes.UPDATE_PRODUCT_START, payload);

export const updateProductSuccess = (payload) =>
  createAction(ProductActionTypes.UPDATE_PRODUCT_SUCCESS, payload);

export const updateProductFailure = (error) =>
  createAction(ProductActionTypes.UPDATE_PRODUCT_FAILURE, error);

export const resetSuccessConfirmationModal = (payload) =>
  createAction(ProductActionTypes.RESET_SUCCESS_CONFIRMATION_MODAL, payload);
