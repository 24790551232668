import { CustomButton, Img, Typography } from "components/atoms";
import { ImagesSrc, getPayload } from "utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormInput } from "components/molecules";
import { SuccessConfirmationModal } from "components/organisms";
import { ValidationsWrapper } from "components/Containers";
import { selectIsContactUsMessageSent } from "Redux/user/selectors/user.selectors";
import { sendContactUsMessageSuccess } from "Redux/user/actions/user.actions";
import styles from "./Contact.module.css";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ContactUsTemplate = ({
  IsMessageSent,
  setIsMessageSent,
  SendMessageHandle,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMessageSentSuccessfully = useSelector(selectIsContactUsMessageSent);

  const [NewMessage, setNewMessage] = useState({
    full_name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    if (isMessageSentSuccessfully) {
      setIsMessageSent(true);
      dispatch(sendContactUsMessageSuccess(false));
      setNewMessage({
        full_name: "",
        email: "",
        message: "",
      });
    }
  }, [isMessageSentSuccessfully]);
  return (
    <>
      <main className={styles.main}>
        <SuccessConfirmationModal
          isOpen={IsMessageSent}
          ToggleModalVisibility={() => setIsMessageSent(!IsMessageSent)}
          heading={
            "شكرًا لك على رسالتك ، سنبذل قصارى جهدنا للرد عليك في أقرب فرصة"
          }
        />
        <div className="w-full">
          <Typography
            as="h1"
            size="2xl"
            lineHeight="leading-none"
            fontWeight="font-bold"
            color="text-text_3b3b3b"
            classes={"text-center"}
            text={"أبقى على تواصل معنا"}
          />
          <Typography
            as="h2"
            size="lg"
            lineHeight="leading-none"
            color="text-text_3b3b3b"
            classes={"text-center mt-4 mb-10"}
            text={"مرحبا بك ! أخبرنا كيف يمكننا مساعدتك ؟"}
          />
        </div>
        <div className="w-full flex flex-col md:flex-row justify-between items-center mb-10">
          <div
            className="w-[48%] bg-bg_white flex flex-col items-center justify-center p-4 cursor-pointer mb-4"
            onClick={() => navigate(`/faq`)}
          >
            <div className="h-[65px] md:h-[200px] flex items-center">
              <Img
                containerClasses="h-full max-w-[130px]"
                fileName={ImagesSrc.FAQLogo}
                fileName2x={ImagesSrc.FAQLogo2x}
                fileName3x={ImagesSrc.FAQLogo3x}
                altText={"FAQ"}
              />
            </div>
            <Typography
              as="h2"
              size="lg"
              lineHeight="leading-none"
              fontWeight="font-bold"
              color="text-text_3b3b3b"
              classes={"text-center mt-4"}
              text={" قراءة الأسئلة الأكثر تكراراً"}
            />
          </div>

          <div
            className="w-[48%] bg-bg_white flex flex-col items-center justify-center p-4 cursor-pointer mb-4"
            // onClick={() => navigate(`/chat`)}
          >
            <a href="https://wa.me/message/E6BZSITNZGUND1" target="_blank">
              <div className="h-[50px] md:h-[200px] flex items-center">
                <Img
                  containerClasses="h-full max-w-[170px] md:h-fit"
                  fileName={ImagesSrc.ChatLogo}
                  fileName2x={ImagesSrc.ChatLogo2x}
                  fileName3x={ImagesSrc.ChatLogo3x}
                  altText={"Live Chat"}
                />
              </div>

              <Typography
                as="h2"
                size="lg"
                lineHeight="leading-none"
                fontWeight="font-bold"
                color="text-text_3b3b3b"
                classes={"text-center mt-4"}
                text={"التحدث مع أحد أفراد خدمة العملاء"}
              />
            </a>
          </div>
        </div>
        <div className="w-full">
          <Typography
            as="h1"
            size="2xl"
            lineHeight="leading-none"
            fontWeight="font-bold"
            color="text-text_3b3b3b"
            classes={"text-center"}
            text={"تواصل معنا عبر البريد الإلكتروني"}
          />
          <ValidationsWrapper
            className={"w-full"}
            initialValues={{
              full_name: NewMessage.full_name,
              email: NewMessage.email,
              message: NewMessage.message,
            }}
            rules={["full_name", "email"]}
          >
            {({ FormikHandler }) => {
              return (
                <div className="w-full bg-bg_white mt-6 py-6 flex justify-center">
                  <div className="w-full max-w-[750px] px-10">
                    <Typography
                      as="h2"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4"
                      text={"الاسم بالكامل"}
                    />
                    <FormInput
                      id={"name"}
                      key={"name"}
                      value={NewMessage.full_name}
                      type="text"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      containerClasses="AddressInput"
                      width="w-full"
                      inputTypeClass={"AddressInputColor"}
                      height="h-[35px]"
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue(
                          "full_name",
                          e.target.value
                        );
                        getPayload(
                          {
                            target: {
                              name: "full_name",
                              value: e.target.value,
                            },
                          },
                          NewMessage,
                          setNewMessage
                        );
                      }}
                      error={FormikHandler.errors.full_name}
                    />
                    <Typography
                      as="h2"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4"
                      text={"البريد الإلكتروني"}
                    />
                    <FormInput
                      id={"email"}
                      key={"email"}
                      value={NewMessage.email}
                      type="text"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      containerClasses="AddressInput"
                      width="w-full"
                      inputTypeClass={"AddressInputColor"}
                      height="h-[35px]"
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue("email", e.target.value);
                        getPayload(
                          {
                            target: {
                              name: "email",
                              value: e.target.value,
                            },
                          },
                          NewMessage,
                          setNewMessage
                        );
                      }}
                      error={FormikHandler.errors.email}
                    />
                    <Typography
                      as="h2"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4"
                      text={"الرسالة"}
                    />
                    <FormInput
                      id={"message"}
                      key={"message"}
                      value={NewMessage.message}
                      type="textarea"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      width="w-full"
                      Resize={true}
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue("message", e.target.value);
                        getPayload(
                          {
                            target: {
                              name: "message",
                              value: e.target.value,
                            },
                          },
                          NewMessage,
                          setNewMessage
                        );
                      }}
                    />
                    <div className="w-full flex justify-center">
                      <CustomButton
                        width="w-full max-w-[250px]"
                        height="h-[46px]"
                        borderRadius="8px"
                        classes={"mt-6"}
                        label={"إرسال"}
                        action={(e) => SendMessageHandle(e, NewMessage)}
                        disabled={!FormikHandler.isValid}
                      />
                    </div>
                  </div>
                </div>
              );
            }}
          </ValidationsWrapper>
        </div>
      </main>
    </>
  );
};

export default ContactUsTemplate;
