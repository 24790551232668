import { DropDownInput, FormInput } from "components/molecules";
import { PlusSign, RedClose } from "utils/helpers/SVGsSrc";

import { Typography } from "components/atoms";
import { getIn } from "formik";

function SizeRow({
  price,
  id,
  setFieldValue,
  sizesOptions,
  sizeDisplay,
  setSizeDisplay,
  sizeID,
  formik,
  productSizes,
}) {
  return (
    <div className="mx-6 my-6 ">
      <div className="lg:flex lg:justify-between ">
        <DropDownInput
          label={"الحجم"}
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          closeOnSelect={true}
          name="sizeID"
          id="sizeID"
          width="w-full"
          height="h-9"
          containerClasses="w-full"
          placeholder={"إختر من القائمة"}
          value={sizeDisplay[id]}
          options={sizesOptions}
          onValueChange={(e) => {
            setFieldValue(`product_sizes[${id}].size_id`, e.value);
            let sizeDisplayCopy = sizeDisplay;
            sizeDisplayCopy[id] = e;
            setSizeDisplay(sizeDisplayCopy);
          }}
        />
        <div className="lg:w-1/4 lg:mr-10">
          <FormInput
            font={"font-SFProText"}
            label={"السعر"}
            labelSize={"2xl"}
            textColor={"text-warm_grey"}
            width="w-full"
            height="h-9"
            value={price}
            onValueChange={(e) => {
              setFieldValue(`product_sizes[${id}].price`, e.target.value);
            }}
            error={
              getIn(formik.touched, `product_sizes[${id}].price`) &&
              getIn(formik.errors, `product_sizes[${id}].price`)
            }
          />
        </div>
        <div
          className="lg:mt-14 mt-8 lg:mr-6 cursor-pointer flex justify-center"
          onClick={() => {
            setSizeDisplay(sizeDisplay.filter((size, index) => index != id));
            setFieldValue(
              `product_sizes`,
              productSizes.filter((size, index) => index != id)
            );
          }}
        >
          <RedClose />
        </div>
      </div>
    </div>
  );
}

function SizeSection({
  sizeUnitsDisplay,
  sizeDisplay,
  setSizeUnitsDisplay,
  setSizeDisplay,
  productSizes,
  setFieldValue,
  sizeUnitsOptions,
  sizes,
  formik,
  sizesOptions,
}) {
  return (
    <div className="bg-[#f8f8fe]">
      <div className="mx-6 my-6 pt-6">
        <DropDownInput
          label={"وحدة الحجم"}
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          closeOnSelect={true}
          name="sizeUnitId"
          id="sizeUnitId"
          value={sizeUnitsDisplay}
          width="w-full"
          height="h-9"
          options={sizeUnitsOptions}
          placeholder="إختر من القائمة"
          onValueChange={(e) => {
            setSizeUnitsDisplay(e);
            setFieldValue("product.size_unit_id", e.value);
          }}
        />
      </div>
      {productSizes.map((Size, index) => {
        return (
          <SizeRow
            sizeDisplay={sizeDisplay}
            setSizeDisplay={setSizeDisplay}
            sizesOptions={sizesOptions}
            productSizes={productSizes}
            formik={formik}
            key={Size.id}
            price={Size.price}
            id={index}
            setFieldValue={setFieldValue}
            sizeID={productSizes[index].size_id}
          />
        );
      })}
      <div className="mt-8 flex justify-items-start">
        <div className="ml-4 mr-6 mt-1">
          <PlusSign
            height="100%"
            width="100%"
            className="w-5 h-5 cursor-pointer"
            onClick={() => {
              setFieldValue("product_sizes", [
                ...productSizes,
                { size_id: 0, price: 0 },
              ]);
            }}
          />
        </div>
        <Typography
          as="span"
          size="2xl"
          color="text-primary-900"
          lineHeight="leading-[1.21]"
          classes="ml-36 cursor-pointer mb-6"
          text={"إضافة حجم آخر"}
          click={() => {
            setFieldValue("product_sizes", [
              ...productSizes,
              { size_id: 0, price: 0 },
            ]);
          }}
        />
      </div>
    </div>
  );
}

export default SizeSection;
