import { DropDownInput, FormInput } from "components/molecules";

import CheckBoxesGroup from "components/atoms/CheckBoxesGroup";
import { Typography } from "components/atoms";

function MainSection({
  language,
  setFieldValue,
  name,
  description,
  time,
  tagIDS,
  weekDayIDS,
  servingTypesOptions,
  vendorMenuCategoriesOptions,
  savingTempsOptions,
  tags,
  quantity,
  menuCategoriesDisplay,
  savingTemperatureDisplay,
  servingTypeDisplay,
  setMenuCategoriesDisplay,
  setSavingTemperatureDisplay,
  setServingTypeDisplay,
  name_en_error,
  name_ar_error,
  description_ar_error,
  description_en_error,
  quantity_error,
  time_error,
}) {
  return (
    <div className="bg-[#f8f8fe]">
      <div className="mx-6 pt-6">
        <FormInput
          label="إسم العنصر"
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          id="name"
          name="name"
          width="w-full"
          height="h-9"
          onValueChange={(e) => {
            language === 1
              ? setFieldValue("product.ar.name_ar", e.target.value)
              : setFieldValue("product.en.name_en", e.target.value);
          }}
          value={name}
          error={name_ar_error ? name_ar_error : name_en_error}
        />
      </div>
      <div className="mx-6 my-6">
        <FormInput
          label="المكونات و الوصف"
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          id="description"
          name="description"
          width="w-full"
          height="h-32"
          type="textarea"
          onValueChange={(e) => {
            language === 1
              ? setFieldValue("product.ar.description_ar", e.target.value)
              : setFieldValue("product.en.description_en", e.target.value);
          }}
          value={description}
          error={
            description_ar_error ? description_ar_error : description_en_error
          }
        />
      </div>
      <div className="mx-6 my-6">
        <DropDownInput
          classes="basic-multi-select"
          classNamePrefix="select"
          label="الفئة"
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          name="menu"
          id="menu"
          options={vendorMenuCategoriesOptions}
          width="w-full"
          placeholder="إختر من القائمة"
          isMulti={true}
          value={menuCategoriesDisplay}
          onValueChange={(e) => {
            // if (parent === "edit") {
            //   const menu_categories = e.map((object) => {
            //     return { id: object.value, name: object.label };
            //   });
            //   setFieldValue("product.menu_category_ids", menu_categories);
            // } else {
            //   console.log("menu category event", e);
            //   const ids = e.map((object) => object.value);
            //   setFieldValue("product.menu_category_ids", ids);
            // }
            const ids = e.map((object) => object.value);
            setFieldValue("product.menu_category_ids", ids);
            setMenuCategoriesDisplay(e);
          }}
        />
      </div>
      <div className="mx-6 my-6">
        <DropDownInput
          label="درجة حرارة الحفظ"
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          closeOnSelect={true}
          name="temp"
          id="temp"
          width="w-full"
          value={savingTemperatureDisplay}
          placeholder={"إختر من القائمة"}
          options={savingTempsOptions}
          onValueChange={(e) => {
            setSavingTemperatureDisplay(e);
            setFieldValue("product.saving_temperature_id", e.value);
          }}
        />
      </div>
      <div className="mx-6 my-6">
        <DropDownInput
          label={"نوع الخدمة"}
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          closeOnSelect={true}
          name="serving"
          id="serving"
          width="w-full"
          value={servingTypeDisplay}
          placeholder={"إختر من القائمة"}
          options={servingTypesOptions}
          onValueChange={(e) => {
            setFieldValue("product.serving_type_id", e.value);
            setServingTypeDisplay(e);
          }}
        />
      </div>
      <div className="mx-6 my-6">
        <FormInput
          font={"font-SFProText"}
          label={"وقت التحضير"}
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          id="time"
          name="time"
          width="w-full"
          height="h-9"
          placeholder="مثال ٣٠:٠٠ دقيقة"
          value={time}
          onValueChange={(e) => {
            setFieldValue("product.time_in_minutes", e.target.value);
          }}
          error={time_error}
        />
      </div>
      <div className="mx-6 my-6">
        <FormInput
          label={"الكمية"}
          font={"font-SFProText"}
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          id="time"
          name="time"
          width="w-full"
          height="h-9"
          value={quantity}
          error={quantity_error}
          onValueChange={(e) => {
            setFieldValue(
              "product.default_daily_quantity",
              Number(e.target.value)
            );
          }}
        />
      </div>
      <div className="mx-6 my-6">
        <Typography
          as="span"
          size="2xl"
          color="text-warm_grey"
          lineHeight="leading-[1.21]"
          text={"خواص تميز العنصر"}
        />
        <div className="lg:flex mt-6 w-full">
          {" "}
          <CheckBoxesGroup
            name={"tags"}
            Options={tags}
            selectedItems={tagIDS}
            setFieldValue={setFieldValue}
            state={"product.tag_ids"}
            // getSelectedItems={getQueryParams}
          />
        </div>
      </div>
      <div className="mx-6">
        <Typography
          as="span"
          size="2xl"
          color="text-warm_grey"
          lineHeight="leading-[1.21]"
          text={"الأيام التي يكون متوفر بها"}
        />
        <div className="xl:flex mt-6">
          {" "}
          <CheckBoxesGroup
            name={"weekdays"}
            Options={[
              {
                id: 1,
                name: "السبت",
              },
              {
                id: 2,
                name: "الأحد",
              },
              {
                id: 3,
                name: "الاثنين",
              },
              {
                id: 4,
                name: "الثلاثاء",
              },
              {
                id: 5,
                name: "الأربعاء",
              },
              {
                id: 6,
                name: "الخميس",
              },
              {
                id: 7,
                name: "الجمعة",
              },
            ]}
            selectedItems={weekDayIDS}
            setFieldValue={setFieldValue}
            state={"product.week_day_ids"}
          />
        </div>
      </div>
    </div>
  );
}

export default MainSection;
