import { useTranslation } from "react-i18next";

import { FullScreenBgLayout } from "components/Layouts";
import { /* CustomButton, */ Typography } from "components/atoms";

const PrivacyPolicyTemplate = () => {
  const { t } = useTranslation();

  return (
    <FullScreenBgLayout
      backgroundImage="PrivacyPolicyBg"
      glassContainerWidth="md:w-4/5 xl:w-7/12"
      glassContainerHight="md:h-max"
    >
      <main className="h-full">
        <div className="h-full flex flex-col justify-between">
          <Typography
            as="h1"
            size="9xl"
            color="text-text_white"
            classes="text-center"
            text={"الشروط و الأحكام و سياسة الخصوصية"}
          />
          <div className="h-96 w-full whiteScroll overflow-y-scroll my-4 lg:my-8 3xl:my-16">
            <div className=" px-5 md:px-8 lg:px-14 3xl:px-20 ">
              <Typography
                as="p"
                size="4xl"
                color="text-text_grey"
                classes={"font-bold my-4 text-center"}
                text={`شروط الاستخدام`}
              />
              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                text={`المقدمّة :
منصة ابلة نظيرة المملوكة شركة - Grow business development and solutions جرو بيزنس ديفلوبمينت اند سليوشنز للتسويق،  مقرها مكتب E08 الكائن بالطابق الرابع من العقار رقم 100 لشارع الميرغني و يمثلها قانوناً السيد / وائل مجدى فؤاد غالى بصفته  رئيس مجلس الادارة بموجب السجل التجاري رقم 180645 الصادر من مكتب استثمار القاهرة ترحّب بكم، و تبلغكم بأنكم سوف تجدون أدناه الشروط والأحكام المُنظّمة لاستخدامكم لمنصة ابلة نظيرة وكافة الآثار القانونية التي تنتج عن استخدامك لخدمات منصة ابلة نظيرة في الشبكة الانترنت.
نظرًا لاستخدام أي شخص يستخدم منصة ابلة نظيرة، سواء كان مستهلكًا أو تاجرًا أو غير ذلك، فإن هذا يعتبر موافقة وقبولًا منهم، وهم مؤهلون تمامًا قانونًا  لجميع المواد والشروط، وأحكام هذه الاتفاقية وهي تأكيد لالتزامكم بلوائحها وما ورد فيها.
`}
              />
              <Typography
                as="p"
                size="2xl"
                color="text-text_grey"
                classes={"font-bold my-4"}
                text={`المادة 1 :- أهلية التاجر القانونية:
`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	يقر التاجر بأنه ذا أهلية قانونية، وأن عمره لا يقل عن ثمانية عشرة عاماً، كما هو معتبر في القانون المصري، وأنه ذا دراية كافية لإنشاء وتأسيس و إدارة متجره الإلكتروني عبر منصة ابلة`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	يجوز للتاجر أن يمارس التجارة إذا كان يقل عن سن الرشد المعتبر القانون المصري (ثمانية عشرة عاماً)، حيث أنه يلتزم بأن يقدّم ما يثبت موافقة وليه على تأسيسه للمتجر الإلكتروني.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	يقر التاجر بأنه ذا أهلية سليمة وأنه غير مصاب بأي عارض من عوارض الأهلية.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`4)	في حال قيام التاجر بالتسجيل كمؤسسة أو شركة، فإن هذه المؤسسة أو الشركة عن طريقه يجب أن تتوافر فيه الأهلية القانونية اللازمة للقيام بأعمال التجارة عبر منصة ابلة نظيرة.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`5)	يوافق التاجر بأنه في حال مخالفته لهذه المادة ” أهلية التاجر القانونية ” فإنه يتحمّل تبعات هذه المخالفة أمام المستهلكين أو المستخدمين أو الغير، حيث أن منصة ابلة نظيرة  لا علاقة لها بتعاملات التاجر مع المستهلك أو الغير من الناحية، كما أنه يحق لمنصة ابلة نظيرة في هذه الحالة مسألة التاجر بموجب أحكام المسؤولية العقدية، ومطالبته بالتعويض عن الأضرار التي قد تنشأ نتيجة هذه المخالفة، و من قبيل هذه الأضرار ما يصيب منصة ابلة نظيرة في سمعتها أمام التجار الآخرين أو المستهلكين أو المستخدمين أو أمام الغير`}
              />
              <Typography
                as="p"
                size="2xl"
                color="text-text_grey"
                classes={"font-bold my-4"}
                text={`المادة 2 :- نطاق الخدمات`}
              />
              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                text={`تستخدم  منصتنا لتقديم تسويق و ترويج المنتجات و الخدمات من خلال تكنولوجيا المعلومات داخل جمهوريه مصر العربية.
يتعين على المستخدمين إدخال معلومات شخصية. يجب أن تكون المعلومات صحيحة أو صحيحة بشكل عشوائي. يتعين عليك توافق على:
`}
              />
              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                classes="my-2"
                text={`1)	شروط الاستخدام المتبعة لدينا.`}
              />
              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                classes="my-2"
                text={`2)	سياسة الخصوصية المتبعة لدينا.`}
              />
              <Typography
                as="p"
                size="2xl"
                color="text-text_grey"
                classes="font-bold my-4"
                text={`المادة 3 – الحسابات والتزامات التسجيل:`}
              />
              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                text={`فور التقدم بطلب الانضمام إلى عضوية منصة ابلة نظيرة تكون مطالباً بالإفصاح عن معلومات محددة واختيار اسم مستخدم وكلمة مرور سرية لاستعمالها عند الوصول لخدمات منصة ابلة نظيرة وبعد تنشيط حسابك سوف تصبح مستخدمًا لخدمات منصة ابلة نظيرة، وبذلك تكون قد وافقت على:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	أن تكون مسؤولاً عن المحافظة على سرية معلومات حسابك وسرية كلمة المرور، و تكون بذلك موافقاً على إعلام منصة ابلة نظيرة حالاً بأي استخدام غير مفوض به لمعلومات حسابك لدى منصة ابلة نظيرة أو أي اختراق آخر لمعلوماتك السرية.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	لن تكون منصة ابلة نظيرة بأي حال من الأحوال مسؤولًة عن أي خسارة قد تلحق بك بشكل مباشر أو غير مباشر معنوية أو مادية نتيجة كشف معلومات اسم المستخدم أو كلمة الدخول أو في حال إساءة استخدام المتجر.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	أنت تلتزم باستخدام حسابك لدى منصة ابلة نظيرة بنفسك، حيث أنك مسؤولاً عنه مسؤولية كاملة، وفي حال استخدام غيرك له فهذه يعني أنك قد فوّضته باستخدام باسمك ولحسابك مالم تقوم بإبلاغ إدارة منصة ابلة نظيرة بعكس ذلك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={
                  "4)	أنت تلتزم عند استخدام منصة ابلة نظيرة أن تستخدمها بكل جدية ومصداقية، وأن تلتزم بقواعد وأحكام اتفاقية الاستخدام وأن تلتزم بالضوابط النظامية و القانونية المعمول بها في جمهورية مصر العربية، وتعتبر ملزماً بتعويض منصة ابلة نظيرة عن أي خسائر مباشرة أو غير مباشرة قد تلحق بمنصة ابلة نظيرة نتيجة أي استخدام غير شرعي أو غير حقيقي أو غير مفوض لحسابك من طرفك أو من طرف أي شخص آخر حصل على مفاتيح الوصول إلى حسابك بالمنصة سواء كان لإنجاز الخدمات باستعمال اسم المستخدم وكلمة المرور أو نتيجة لإهمالك المحافظة على سرية اسم المستخدم وكلمة المرور، وسواء بتفويض منك أو بلا تفويض."
                }
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`5)	أنت تلتزم بالإفصاح عن معلومات حقيقية وصحيحة ومحدثة و كاملة وقانونية عن نفسك حسبما هو مطلوب أثناء التسجيل لدى منصة ابلة نظيرة وتلتزم بتحديث بياناتك في حال تغييرها في الواقع أو في حال الحاجة إلى ذلك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`6)	ألا تدرج ضمن اسم المستخدم أي من تفاصيل الاتصال بك كعناوين البريد الإلكتروني أو أرقام هواتفك أو أي تفاصيل شخصية، أو أي عبارة تُشير إلى علاقة شخصية أو تجارية بينك وبين منصة ابلة نظيرة أو مُلّاكها.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`7)	ألا تضع ما يشير في متجرك إلى أي علاقة مباشرة أو غير مباشرة بين المتجر و منصة ابلة نظيرة أو إدارتها أو مُلّاكها، حيث أن منصة ابلة نظيرة لا علاقة لها بما تقوم به في متجرك وهي غير مسؤولة عن نشاط متجرك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`8)	إن منصة ابلة نظيرة تلتزم بمعالجة معلوماتك الشخصية وعناوين الاتصال بك بطريقة سريّة وفقاً لأحكام سياسة الخصوصية و شروط الاستخدام المعمول بها لدى منصة ابلة نظيرة`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`9)	سوف تكون ملزمًا بالحفاظ على بيانات التسجيل و تحديثها دائمًا للإبقاء عليها حقيقية و صحيحة و راهنة و كاملة و قانونية، و إذا أفصحت عن معلومات غير حقيقية أو غير صحيحة أو غير راهنة أو غير كاملة أو غير قانونية او مخالفة لما جاء في اتفاقية الاستخدام، فإن منصة ابلة نظيرة تمتلك الحق في وقف أو تجميد أو إلغاء عضويتك أو متجرك و حسابك في المنصة، وذلك دون إلحاق الأضرار بحقوق منصة ابلة نظيرة الأخرى و وسائله المشروعة في استرداد حقوقها وحماية باقي المستخدمين.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`10)	لمنصة ابلة نظيرة الحق في أي وقت في أن تجري أي تحقيقات تراها ضرورية و يطالبك بالإفصاح عن أي معلومات إضافية أو وثائق لإثبات هويتك أو ملكيتك لأموالك أو للحساب الخاص بك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`11)	في حالة عدم الالتزام بأي مما ورد أعلاه فإن لإدارة منصة ابلة نظيرة الحق في إيقاف أو إلغاء متجرك أو عضويتك أو حجبك من الولوج لخدمات منصة ابلة نظيرة مرة أخرى. و تحتفظ المنصة كذلك بالحق في إلغاء أي حسابات غير مؤكدة أو عمليات أو حسابات مر عليها مدة طويلة دون نشاط.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 4 – الحقوق:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	كافة المحتويات المتواجدة على منصة ابلة نظيرة هي ملك لمنصة ابلة نظيرة و حقوقها محفوظة لمنصة ابلة نظيرة، سواء كانت مكتوبة أو غير مكتوبة، على سبيل المثال لا الحصر: النصوص المكتوبة وغير المكتوبة – التصاميم الجرافيكية – الأفكار التقنية – الشعارات – العروض – أيقونات الأزرار – الرموز – المقاطع الصوتية – البيانات المجمّعة والبرامج، ولا يحق لكائنًا من كان أن يستخدمها بأي شكل من الأشكال سواء كان استخدام مباشر أو غير مباشر أو عن طريق طرف ثالث.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	تُنوه إدارة منصة ابلة نظيرة على أنها سوف تتخذ اللازم حيال التجاوز والتعدي على أي من حقوق منصة ابلة نظيرة أو التعدي على حقوق ملكيتها الفكرية.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	لا تتحمّل منصة ابلة نظيرة المسؤولية في حال التعدي على حقوق الملكية الفكرية المملوكة للتُجّار الأعضاء في المنصة أو المملوكة للمتاجر المسجّلة لديها.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 5 – الملكية الفكرية:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	تحترم إدارة منصة ابلة نظيرة حقوق الملكية الفكرية الخاصة بالتجار والتي كوّنوها عبر متاجرهم، سواءً كانت مملوكة لهم قبل تأسيس المتجر أم بعد تأسيسه.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	يحترم التاجر حقوق الملكية الفكرية الخاصة بمنصة ابلة نظيرة، والتي من ضمنها منصة ابلة نظيرة نفسها، والكلمات والشعارات والرموز الأخرى الخاصة بمنصة ابلة نظيرة أو المعروضة على منصة ابلة نظيرة، حيث أن منصة ابلة نظيرة، وكل حق يتبعها، هي حقوق محمية بموجب حقوق الملكية الفكرية وقوانين العلامات التجارية وتعد ملكية خالصة لمنصة ابلة نظيرة و لا يحق بأي حال من الأحوال التعدي عليها أو استخدامها دون تفويض من إدارة منصة مخازن.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 6 – سرية المعلومات:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	تحيطكم منصة ابلة نظيرة علمًا بأن الشبكة العنكبوتية (فضاء الإنترنت) ليس وسيلة آمنة، وسرية المعلومات الشخصية لا يمكن أن تكون مضمونة 100% في فضاء الإنترنت`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	تتخذ منصة ابلة نظيرة معايير ذات جودة عالية لحماية المستخدمين والمستهلكين ومنع وصول شخص غير مفوض إلى معلومات المستخدمين الشخصية، وحفظها.`}
              />

              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	منصة ابلة نظيرة ليس لها سيطرة على أفعال أي طرف ثالث، أو الغير، مثل صفحات الانترنت الأخرى الموصولة عن طريق روابط إلى المنصة أو أطراف ثالثة تدعي أنها تمثلك وتمثل آخرين.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`4)	أنت تعلم وتوافق على أن منصة ابلة نظيرة قد تستخدم معلوماتك التي زودتها بها، بهدف تقديم الخدمات لك في منصة ابلة نظير، ولإرسال رسائل تسويقية لك، وان سياسة الخصوصية في منصة ابلة نظيرة تضبط عمليات الجمع والمعالجة والاستخدام والتحويل لمعلومات هويتك الشخصية.  `}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 7 – إلغاء العضوية أو المتجر:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`إن منصة ابلة نظيرة -بحسب اتفاقية الاستخدام وبحسب القوانين بجمهورية مصر العربية- قد تلجأ إلى وقف مؤقت أو دائم للتاجر أو سحب وإلغاء عضوية التاجر أو تحديد قدرات التجار على الولوج إلى خدمات منصة ابلة نظيرة، في حالة:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	انتهاك قواعد وأحكام اتفاقية الاستخدام`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	إذا لم يكن بإمكان منصة ابلة نظيرة توثيق أي من معلومات المستخدم المقدمة إليها.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	إذا قررت منصة ابلة نظيرة أن نشاطات المستخدم مخالفة للقانون أو أن نشاطات المستخدم قد تتسبب لمستخدمين آخرين أو لمنصة ابلة نظيرة بمتاعب أو مخالفات قانونية.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`4)	قد تقوم  منصة ابلة نظيرة “بحسب تقييمها” إلى إعادة نشاط المستخدمين التى اوقفتها سابقاً، حيث أن المستخدم الذي أوقف نشاطه نهائياً أو أُلغيت عضويته، قد لا يكون بإمكانه التسجيل أو استعادة حسابه في منصة ابلة نظيرة أو استخدام المنصة بأي طريقة كانت مهما كانت الظروف، لحين السماح له بإعادة نشاطه في منصة ابلة نظيرة من قبل إدارة منصة ابلة نظيرة و مع ذلك فإن المستخدم في حال انتهاكه لشروط الاستخدام  هذه فإن منصة ابلة نظيرة تحتفظ بحقها في استعادة أو المطالبة بأي مبالغ مستحقة لمنصة ابلة نظيرة على المستخدم وأي خسائر أو أضرار تسبب بها التاجر لمنصة ابلة نظيرة كما أن لمنصة ابلة نظيرة الحق باتخاذ الإجراءات القانونية في جمهورية مصر العربية ضد المستخدم.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`5)	إن منصة ابلة نظيرة لا تتنازل عن حقها في اتخاذ الإجراءات المناسبة حيال أي انتهاك يحدث لقواعد وأحكام شروط الاستخدام ولغيرها من أفعال الانتهاك المشابهة، وكذلك إن منصة ابلة نظيرة لا تلتزم في القيام باتخاذ أي اجراءات ضد أي انتهاك يحدث لشروط الاستخدام وإنما يخضع هذا الأمر لتقدير إدارة منصة ابلة نظيرة و إدارتها القانونية.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 8 – قانون مكافحة جرائم الانترنت و قانون حماية البيانات الشخصية:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`يلتزم التجار بعدم مخالفة أي من أحكام القوانين المصرية و بالأخص قانون مكافحة جرائم الانترنت و قانون حماية البيانات الشخصية و لائحتهما و ما يرد عليهما من تعديلات، وفي حال مخالفتهم، يتحمل التاجر المخالفة المسئولية  دون أدنى مسئوليه على منصة ابلة نظيرة من جراء مخالفة المتجر للقوانين السارية المفعول، حيث أن التاجر هو المسئول عن متجره وعن تعامله مع المستهلكين مسؤولية كاملة، ولمنصة ابلة نظيرة دائماً الحق في اتخاذ ما تراه مناسباً عن أي متجر أو تاجر يخالف أحكام للقوانين السارية المفعول، سواءً عن طريق ابلاغ السلطات الرسمية أو الاكتفاء بإغلاق المتجر الإلكتروني أو إلغاء عضوية التاجر لدى المنصة.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 9 – الاتصالات ووسائل التواصل الرسمية:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	يوافق التاجر في منصة ابلة نظيرة على أن تقوم المنصة بالتواصل معه عبر البريد الإلكتروني، أو من خلال قيام إدارة منصة ابلة نظيرة ببث رسائل عامة لرد إلى كافة المستخدمين أو إلى مستخدمين محددين كما يوافق التاجر ايضاً في منصة ابلة نظيرة على أن جميع الاتفاقيات والإعلانات والبيانات والاتصالات الأخرى التي تزود بها الكترونياً تقوم مقام مثيلاتها المكتوبة و لها نفس الحجيه القانونية، وهي حجة في تلبية الاحتياجات القانونية`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	سوف تقوم منصة ابلة نظيرة خلال فترة عضويتك لدى المنصة بإرسال رسائل إلكترونية ترويجية لتعريفك بأي تغيرات أو إجراءات أو نشاطات دعائية جديدة قد تضاف إلى منصة مخازن.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 10 – الضمان:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	لا تضمن منصة ابلة نظيرة إصلاح الأعطال ولا تضمن خلو المنتجات التي يقدّمها التجار من أي عيوب أخرى وإنما يضمنها المتجر أو التاجر في حالة ذكر الضمان ومدته وذلك بوصف المنتج، وعلى التاجر أن يلتزم بحسن الجودة في متجره الإلكتروني.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	يلتزم المتجر بوضع سياسة الاستبدال والاسترجاع التي تخص متجره وحده، كما يجب عليه أن يلتزم بأحكام نظام التجارة وغير ذلك من الأنظمة المرعية التي تتعلّق بالضمان أو بالتجارة.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 11 – العلاقة والإشعارات بين منصة ابلة نظيرة والتجار:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	لا تتضمن أي من قواعد وأحكام اتفاقية الاستخدام هذه إشارة إلى وجود شراكة بين أي تاجر ومنصة ابلة نظيرة، ولا تسمح منصة ابلة نظيرة لأي تاجر في أي حال من الأحوال بالإشارة المباشرة أو الغير مباشرة إلى وجود علاقة أي كان نوعها سواء كانت مباشرة أو غير مباشرة بينه كتاجر وبين منصة ابلة نظيرة أو إدارتها. `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	وأنت كتاجر توافق على أن أي إشعارات ترسل إليك من منصة ابلة نظيرة سوف تُسلّم إليك بواسطة البريد الإلكتروني أو رسائل الواتساب او الاتصال الذي قمت بتزويد منصة ابلة نظيرة به خلال عملية التسجيل.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 12 – نقل الحقوق والالتزامات:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`أنت كتاجر لا تملك الحق في الاعتراض على تصرفات إدارة منصة ابلة نظيرة اياً كانت هذه التصرفات على سبيل المثال لا الحصر: تمس كيان منصة ابلة نظيرة أو التزاماتها أو ملكيتها أو مسئولياتها أو تصرفات تقنية أو تصرفات إدارية تتعلّق بمنصة ابلة نظيرة، وليست مُلزَمة منصة ابلة نظيرة بإبلاغك، و لمنصة ابلة نظيرة إن رأت أهمية إبلاغك عن أي من هذه التصرفات أن تقوم بإبلاغك وفقاً لإرادتها المحضة، وذلك بحسب قواعد وأحكام اتفاقية الاستخدام.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 13 -القابلية للفصل `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ما لم يُذكر خلاف ذلك في هذه الشروط، في حال تم اعتبار أي حكم من هذه الشروط غير صالح أو غير قابل للتطبيق لأي سبب أو إلى أي مدى، لن تتأثر الأحكام المتبقية من هذه الشروط، وسيتم تطبيق هذا الحكم إلى الحد الذي يسمح به القانون.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 14 - التنازل`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	يحق لمنصة ابلة نظيرة التنازل عن أي من هذه الشروط أو جميعها، كما يحق لها التنازل أو التفويض - كليًا أو جزئيًا - فيما يتعلق بأي حق من حقوقها أو التزاماتها بموجب هذه الشروط.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	ولا يحق لك التنازل عن هذه الشروط -كليًا أو جزئيًا- ولا تحويل حقوقك أو ترخيصها من الباطن بموجب هذه الشروط، إلى أي جهة خارجية.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 15-التغييرات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`حق لنا إجراء تغييرات على هذه الشروط (بما في ذلك أي شروط وأحكام إضافية من منصة ابلة نظيرة) من وقت إلى آخر من خلال إخطارك بهذه التغييرات بأي وسيلة معقولة (قبل سريانها)، بما في ذلك عن طريق نشر اتفاقية منقحة على خدمة منصة ابلة نظيرة المعمول بها  `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	لن تنطبق أي تغييرات من هذا القبيل على أي نزاع بينك وبيننا ينشأ قبل التاريخ الذي نشرنا فيه الشروط والأحكام المنقحة، أو شروط وأحكام منصة ابلة نظيرة الأخرى، التي تتضمن هذه التغييرات، أو قبل إخطارك بوسيلة أخرى بهذه التغييرات. `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	إن استخدامك منصة ابلة نظيرة بعد أي تغييرات في هذه الشروط سيعبر عن قبولك هذه التغييرات. إذا كنت لا ترغب في متابعة استخدام خدمة منصة ابلة نظيرة بموجب الشروط المحدثة، فيمكنك إنهاء حسابك عن طريق الاتصال بنا. يشير تاريخ النفاذ المحدد في أعلى هذا المستند إلى تاريخ آخر تغيير لهذه الشروط.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 16-الاتفاق الكامل`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`تشكّل هذه الشروط كل الشروط والأحكام المتفق عليها بينك وبين منصة ابلة نظيرة وتحل محلّ أي اتفاقيات سابقة متعلقة بموضوع هذه الشروط، سواء أكانت خطية أم شفهية. وكما هو مذكور أعلاه، فقد أُدرجت الشروط والأحكام الأخرى التي تحكم استخدام خدمة منصة ابلة نظيرة في هذه الشروط بموجب الإشارة، متضمنة الشروط و سياسة الخصوصية المتبعة لدينا.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 17 – القانون والتشريع المُطبّق `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`اتفاقية الاستخدام هذه محكومة ومصاغة بحسب القوانين المعمول بها والسارية في جمهورية مصر العربية.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 18 – حل الخلافات:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`في حال النزاع يتم حل الخلاف عن طريق التوفيق والمفاوضات أو التسوية الودية أو التحكيم و ذلك في مركز للتحكيم و  وفقاً لقواعد المركز.`}
              />
              <Typography
                as="p"
                size="4xl"
                color="text-text_grey"
                classes={"font-bold my-4 text-center"}
                text={`سياسة خاصة`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`تريد منصة ابلة نظيرة مساعدتك على فهم أفضل لكيفية جمع بياناتك الشخصية واستخدامها وحمايتها ومشاركتها. تم تصميم سياسة الخصوصية هذه لمساعدتك على فهم خيارات الخصوصية الخاصة بك عند زيارة موقعنا 
نحن نشجعك على قراءة سياسة الخصوصية هذه بعناية وخذ الوقت الكافي للتعرف على ممارساتنا. إذا كانت لديك أسئلة حول سياسة الخصوصية هذه أو ممارسات الخصوصية لدينا ، فيرجى الاتصال بنا على البريد الإلكتروني الاتي :-  
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`ماده 1 :- التعاريف.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`"معلومات الحساب" تعني بيانات حول كيفية ووقت الوصول إلى منصة ابلة نظيرة  والميزات المستخدمة ، بما في ذلك معلومات المتجر.
تعني "معلومات المتصفح" التي يوفرها المستعرض ، بما في ذلك عنوان IP ، وموقع الويب الذي تمت زيارته ، واتصال الشبكة ، ومعلومات الجهاز ، والبيانات الأخرى ، بما في ذلك ملفات تعريف الارتباط.
"معلومات الاتصال" تعني المعلومات الشخصية والتجارية الأساسية ، مثل الاسم الأول واسم العائلة واسم الشركة وعنوان البريد الإلكتروني والعنوان البريدي ورقم الهاتف ، وقد تتضمن معلومات حساب وسائل التواصل الاجتماعي.
"معلومات الجهاز" تعني معلومات حول جهازك ، مثل رقم معرف الجهاز ، والطراز ، والشركة المصنعة ، وإصدار نظام التشغيل والمنطقة الجغرافية ، التي يتم جمعها من أي أجهزة عند الوصول إلى موقعنا على الويب ، أو استخدام تطبيق الهاتف المحمول ، أو أي من خدماتنا.
"معلومات الدفع" تعني ، على سبيل المثال ، بطاقة الائتمان أو معلومات الدفع الأخرى.
"معلومات الأمان" تعني معرف المستخدم وكلمة المرور ومعلومات الأمان الأخرى المستخدمة للمصادقة والوصول إلى الحساب.
"معلومات المتجر" تعني معلومات حول متجرك ومنتجاته وبنيته.
تتضمن "معلومات الدعم" معلومات حول أجهزتك وبرامجك ، و محتويات جلسة الدردشة ، و تقارير الأخطاء ، و بيانات الأداء ، و غيرها من الاتصالات أو المعلومات الفنية ، و قد تتضمن ، بإذن صريح ، الوصول عن بُعد لتسهيل استكشاف الأخطاء وإصلاحها.
"معلومات الصفقة " تعني البيانات المتعلقة بالمعاملات و الصفقات التي تحدث على منصتنا ، بما في ذلك المنتج والطلب ومعلومات الشحن ومعلومات الاتصال ومعلومات الدفع.
"معلومات الاستخدام" تعني المعلومات التي يتم جمعها عند تفاعلك مع موقع منصة ابلة نظيرة  أو أي من خدماتنا ، بما في ذلك الوظائف التي تم الوصول إليها والصفحات التي تمت زيارتها وبيانات التفاعل الأخرى.
. "اتخاذ القرار الآلي" يعني القرار الذي يتم اتخاذه بالوسائل الآلية فقط دون تدخل بشري.
. "المتحكم" يعني الكيان الذي يحدد أغراض ووسائل معالجة البيانات الشخصية.
. "ملف تعريف الارتباط" ملف صغير موجود على محرك الأقراص الثابتة بجهاز الكمبيوتر الخاص بك والذي يحتوي غالبًا على معرف فريد مجهول يمكن الوصول إليه بواسطة موقع الويب الذي وضعه هناك ، ولكن لا يمكن الوصول إليه من قبل المواقع الأخرى.
"التاجر" يعني الكيان الذي استخدم أو يستخدم الخدمات في التجارة الإلكترونية.
"البيانات الشخصية" أو "المعلومات الشخصية" تعني المعلومات التي (1) تتعلق بشخص طبيعي محدد أو يمكن التعرف عليه ، أو (2) يحدد أو يرتبط أو يصف أو يمكن أن يرتبط به أو يمكن ربطه بشكل معقول ، بشكل مباشر أو غير مباشر ، مع مستهلك أو أسرة معينة.
. "المعالجة" تعني أي عملية أو مجموعة من العمليات التي يتم إجراؤها على البيانات الشخصية ، سواء بالوسائل الآلية أم لا ، بما في ذلك ، على سبيل المثال لا الحصر ، التغيير والجمع والتنظيم والتسجيل والاسترجاع والتخزين والنقل والاستخدام.
. "البيانات الشخصية الحساسة" تعني أي بيانات تكشف عن الأصل العرقي ، أو الآراء السياسية ، أو المعتقدات الدينية أو الفلسفية ، أو العضوية النقابية ، أو البيانات المتعلقة بالصحة أو الحياة الجنسية للشخص الطبيعي.
. "المتسوق" يعني كيانًا أو شخصًا طبيعيًا يتفاعل مع عرض التجارة الإلكترونية للتاجر من خلال منصة ابلة نظيرة  
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`ماده 2 :- المعلومات المجمعة.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`عندما يتفاعل التاجر مع موقعنا ، على سبيل المثال ، عن طريق التسجيل للحصول على نسخة تجريبية أو اشتراك أو رسالة إخبارية أو محتوى آخر ، أو إجراء معاملات ، قد تجمع منصتنا وتتحكم في المعلومات مثل معلومات الحساب ، معلومات المتصفح ، معلومات الاتصال ، الدفع المعلومات ومعلومات الدعم ومعلومات الجهاز ومعلومات الأمان ومعلومات المعاملات ومعلومات الاستخدام وتعيين ملف تعريف الارتباط.
استخدام المعلومات. نحن نستخدم هذه المعلومات كمراقب لتزويد التجار بخدماتنا ، وتأكيد الهويات ، وتقديم الدعم مثل تصحيح الأخطاء ، واستكشاف الأخطاء وإصلاحها ، واتخاذ القرار الآلي مثل اكتشاف إنشاء حساب احتيالي عند الاشتراك في خدمتنا ، للإعلان والتسويق ، والفواتير ، لحل الحوادث المتعلقة باستخدام موقعنا وخدماتنا ، لتحسين خدماتنا و إضفاء الطابع الشخصي عليها ، مثل الإشعارات الفورية المتعلقة بأنشطة متجرك ، والامتثال للمتطلبات القانونية. 
قد نكشف عن بعض المعلومات ، بما في ذلك معلومات الحساب ومعلومات الاتصال ومعلومات الدعم ومعلومات المعاملات إلى الشركاء الخاضعين لالتزامات السرية التي تحيل التجار إلينا أو يشاركون من قبل التاجر لتقديم الخدمات أو المنتجات المتعلقة بمتجر أو استخدام موقعنا وخدماتنا ، أو لتأكيد الهويات وتحسين وتخصيص تفاعلاتنا وخدماتنا. قد نستخدم هذه المعلومات في حالات أخرى يكون فيها التاجر قد أعطى موافقة صريحة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 3:-  الشركاء`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`عندما يسجل أحد الشركاء للحصول على حساب شريك أو يحيل تاجرًا إلينا ، يجوز لـمنصتنا جمع معلومات والتحكم فيها مثل معلومات الحساب ومعلومات المتصفح ومعلومات الاتصال ومعلومات الدفع ومعلومات الدعم ومعلومات الاستخدام.
استخدام المعلومات. نحن نستخدم هذه المعلومات كمراقب لتزويد الشركاء بخدماتنا ، وتأكيد الهويات ، وتقديم الدعم ، للإعلان والتسويق ، وإعداد الفواتير ، لحل الحوادث المتعلقة باستخدام موقعنا وخدماتنا ، لتحسين خدماتنا وإضفاء الطابع الشخصي عليها ، والامتثال مع المتطلبات القانونية. قد نستخدم هذه المعلومات في حالات أخرى يكون فيها الشريك قد أعطى موافقة صريحة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 4:- الزوار`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`عندما يتصفح الزائرون موقعنا الإلكتروني ، أو ينخرطون في اتصالات معنا عبر الإنترنت أو دون الاتصال بالإنترنت ، فقد نجمع ونتحكم ، حسب الاقتضاء ، في معلومات المتصفح ، ومعلومات الدعم ، ومعلومات الاتصال ، ومعلومات الاستخدام المقدمة أو المرسلة إلينا.
استخدام المعلومات. نستخدم هذه المعلومات كمراقب لتقديم خدماتنا ، وتحسين وتخصيص الاتصالات والتفاعلات وخدماتنا وتقديم الدعم إذا لزم الأمر. قد نستخدم هذه المعلومات في حالات أخرى يكون فيها الزائر قد أعطى موافقة صريحة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 5:- المتسوقون`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`عندما يتفاعل المتسوقون مع عرض التجارة الإلكترونية للتاجر من خلال منصة ابلة نظيرة  ، يجوز لنا جمع ومعالجة معلومات المتصفح ومعلومات المعاملات للمتسوق نيابة عن التاجر.
استخدام المعلومات. نستخدم هذه المعلومات بصفتنا معالجًا لتقديم خدماتنا إلى التجار ودعم ومعالجة الطلبات وإدارة المخاطر والاحتيال.
التاجر هو المتحكم في هذه المعلومات والمتسوقون الذين لديهم أسئلة حول استخدامنا لهذه المعلومات يجب عليهم الاتصال بالتاجر. يجوز لنا أيضًا استخدام معلومات معينة كمراقب لتحسين خدماتنا وتخصيصها وإدارة المخاطر والاحتيال.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 6:- الاتصالات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`قد نرسل اتصالات ترويجية إلى التجار والشركاء والزوار الحاليين والمحتملين عن طريق البريد الإلكتروني والهاتف والقنوات الأخرى ، مثل لينكد ان. على سبيل المثال ، يجوز لنا إخطار التاجر عند انتهاء الاشتراك ، أو دعوة المستلم للمشاركة في استطلاع. يمكنك إلغاء الاشتراك في تلقي الاتصالات الترويجية منا في أي وقت.  
الاتصالات المطلوبة  بخصوص بعض البيانات المطلوبة ، مثل إشعارات الحساب أو المعلومات ، إلى مستخدمي خدماتنا. لا يجوز لك إلغاء الاشتراك في تلقي هذه الاتصالات إذا كانت لديك واجهة متجر نشطة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 7:- تبادل المعلومات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={` في بعض الأحيان يكون من الضروري مشاركة بيانات التاجر أو الشريك أو المتسوق الشخصية معهم لدعم خدماتنا. يجوز لنا الوصول إلى تلك البيانات الشخصية ونقلها و / أو الكشف عنها و / أو الاحتفاظ بها بموافقة أو في الظروف التالية.
الامتثال للأنظمة المعمول بها :- إذا كان لدينا اعتقاد حسن النية أن القيام بذلك ضروري من أجل: (1) الامتثال للأنظمة المعمول بها  أو الاستجابة للإجراءات القانونية الصالحة ، بما في ذلك من جهات إنفاذ القانون أو الوكالات الحكومية الأخرى ؛ أو (2) حماية حقوق أو ممتلكات منصة ابلة نظيرة  ، بما في ذلك فرض الشروط التي تحكم استخدام الخدمات.
توفير الحماية. إذا كان لدينا اعتقاد حسن النية أن القيام بذلك ضروري من أجل: (1) حماية التجار أو الشركاء أو المتسوقين أو الزوار ؛ على سبيل المثال ، لمنع البريد العشوائي أو محاولات الاحتيال علينا أو لمستخدمي خدماتنا ، أو ردًا على تهديدات سلامة أي شخص ؛ أو (2) تشغيل وصيانة أمان منتجاتنا ، بما في ذلك منع أو وقف هجوم على أنظمة الكمبيوتر أو الشبكات الخاصة بنا.
مجموعات تطوير البرمجيات وواجهات برمجة التطبيقات:- قد تتضمن منصة ابلة نظيرة  مكتبات جهات خارجية مثل مجموعات تطوير البرامج  للأغراض الموضحة في سياسة الخصوصية هذه ، بما في ذلك ما يلي:
أطقم تطوير برامج التحليلات: هذه مكتبات معالجات فرعية خارجية تابعة لجهات خارجية نستخدمها في جمع بيانات الجهاز والاستخدام لمراقبة أداء التطبيق.
معالجة الدفع. نشارك بيانات الدفع مع البنوك والكيانات الأخرى التي تعالج معاملات الدفع أو تقدم خدمات مالية أخرى لمنع الاحتيال والحد من مخاطر الائتمان.
الاندماج. أو البيع . قد نكشف أيضًا عن البيانات الشخصية كجزء من معاملة الشركة مثل الدمج أو بيع الأصول.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 8:-  آلية صنع القرار`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`يمكن استخدام بعض البيانات الشخصية في آلية اتخاذ القرار لمساعدتنا في فحص الحسابات بحثًا عن مخاوف تتعلق بالمخاطر أو الاحتيال أو إساءة الاستخدام. مع مراعاة القانون المعمول به ، يمكننا تزويدك بالتفاصيل الكامنة وراء المراجعة الآلية لصنع القرار وتصحيح أي أخطاء.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 9:- ملفات تعريف الارتباط`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`تستخدم منصتنا ومقدمو خدمات الطرف الثالث ملفات تعريف الارتباط وإشارات الويب وتقنيات التتبع المماثلة للتعرف عليك عند زيارة موقعنا على الويب وتذكر تفضيلاتك ومنحك تجربة مخصصة.
عندما تزور مواقعنا الإلكترونية ، يجوز لنا ، أو لطرف ثالث مرخص له ، وضع ملف تعريف ارتباط على جهازك يجمع المعلومات ، بما في ذلك البيانات الشخصية ، حول أنشطتك عبر الإنترنت بمرور الوقت وعبر مواقع مختلفة. تتيح لنا ملفات تعريف الارتباط تتبع الاستخدام واستنتاج تفضيلات التصفح وتحسين تجربة التصفح الخاصة بك وتخصيصها.
تظل ملفات تعريف الارتباط الدائمة على جهاز الكمبيوتر الخاص بك عندما تكون غير متصل بالإنترنت ، بينما يتم حذف ملفات تعريف الارتباط بمجرد إغلاق متصفح الويب الخاص بك.
قد يقوم موقع ويب بتعيين ملف تعريف ارتباط إذا كانت تفضيلات المتصفح تسمح بذلك. يسمح المستعرض لموقع الويب فقط بالوصول إلى ملفات تعريف الارتباط التي قام بتعيينها ، وليس تلك التي حددتها مواقع الويب الأخرى.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`الأنواع.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`أ- أساسى:- ملفات تعريف الارتباط هذه ضرورية لموقعنا الإلكتروني للعمل على النحو المنشود.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ب- وظيفي:- تعمل ملفات تعريف الارتباط هذه على تمكين وظائف محسنة ، مثل مقاطع الفيديو والدردشة الحية. و بدون ملفات تعريف الارتباط هذه ، قد تصبح بعض الوظائف غير متاحة.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ج. التحليل . تُستخدم ملفات تعريف الارتباط هذه لإنشاء ملفات تعريف أو تخصيص محتوى لتحسين تجربتك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`د-  الاستهداف والإعلان. تُستخدم ملفات تعريف الارتباط هذه لإنشاء ملفات تعريف أو تخصيص محتوى لتحسين تجربتك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ه-  التحكم. من الممكن تعطيل ملفات تعريف الارتباط من خلال جهازك أو إعدادات المتصفح ، ولكن القيام بذلك قد يؤثر على قدرتك على استخدام موقعنا. على سبيل المثال ، قد لا نتمكن من التعرف على جهاز الكمبيوتر أو الجهاز المحمول الخاص بك وقد تحتاج إلى تسجيل الدخول في كل مرة تزور فيها موقعنا على الويب. قد تختلف طريقة تعطيل ملفات تعريف الارتباط حسب الجهاز والمتصفح ، ولكن يمكن العثور عليها عادةً في التفضيلات أو إعدادات الأمان. الرجاء استخدام الروابط التالية لمزيد من التعليمات:`}
              />

              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`أ-  . جوجل كروم`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ب أبل سفاري`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`جــــ موزيلا فايرفوكس`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`د. متصفح الانترنت الخاص بمايكروسفت`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ه- متاجر تطبيقات الطرف الثالث: قد تقوم متاجر التطبيقات التابعة لجهات خارجية ، مثل متجر تطبيقات Apple أو Google Play من Google ، بجمع معلومات إضافية ومشاركتها معنا. يرجى الرجوع إلى سياسة الخصوصية لمتجر التطبيقات لفهم كيفية معالجة أي من المعلومات الشخصية التي يجمعونها ومشاركتها مع ناشري التطبيقات أمثالنا بشكل أفضل.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 10:- حماية المعلومات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`نحافظ على تدابير أمنية إدارية وفنية ومادية مصممة لتوفير حماية معقولة للبيانات الشخصية ضد الوصول غير المصرح به والكشف والتعديل والفقدان وسوء الاستخدام. 
لسوء الحظ ، لا توجد طريقة آمنة تمامًا لاستخدام الإنترنت أو نقل البيانات أو التخزين الإلكتروني ، لذلك لا يمكننا ضمان الأمان المطلق للبيانات الشخصية.
بينما نحن ملتزمون بتأمين موقعنا وخدماتنا ، فأنت مسؤول عن تأمين والحفاظ على خصوصية كلمات المرور ومعلومات الحساب الخاصة بك. نحن لسنا مسؤولين عن حماية البيانات الشخصية التي تتم مشاركتها مع طرف ثالث بناءً على اتصال الحساب الذي صرحت به.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 11:-  المساءلة والضمانات للتحويل الداخلي`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={` نحن نقدم خدمات في جميع أنحاء العالم لذا قد يكون من الضروري نقل البيانات الشخصية خارج الدولة التي تم تلقي البيانات فيها. نحن نخضع للأنظمة المعمول بها في المملكة العربية السعودية
معالجات البيانات. لن نشارك البيانات الشخصية أو نكشف عنها إلا للمعالجين المحددين هنا ، والملتزمين تعاقديًا بتوفير نفس مستوى حماية الخصوصية على الأقل الذي تتطلبه المبادئ الأساسية للخصوصية. علاوة على ذلك ، سنلزم أي معالج بالأغراض المحددة والصريحة والشرعية المتوافقة مع موافقتك.
التدابير العلاجية. إذا علمنا أن البيانات الشخصية ليست محمية وفقًا لعقدنا أو تتم معالجتها بما يتجاوز موافقتك ، فسنتخذ خطوات معقولة لحماية معلوماتك و / أو التوقف عن معالجتها غير المشروعة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 12:-. حقوق صاحب البيانات`}
              />

              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`. بشكل عام. يمكنك ممارسة الحقوق على بياناتك الشخصية. نقدم خطوات معقولة للسماح لك بالوصول إلى بياناتك الشخصية أو تصحيحها أو محوها أو نقلها أو تقييد استخدامها. ويحق لك الاعتراض على استخدام بياناتك الشخصية في أي وقت ، وفقًا للأنظمة  المعمول بها.  
التجار والشركاء والزوار:-. التجار والشركاء قادرون على تحديث العديد من أنواع البيانات الشخصية المجمعة مباشرة داخل حساباتهم. يرجى الاتصال بنا إذا كنت زائرًا أو غير قادر على الوصول إلى بياناتك الشخصية أو تغييرها بطريقة أخرى داخل حسابك.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 13:- معلومات للتواصل`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={` امتثالاً لمبادئ الخصوصية ، تلتزم منصتنا بحل الشكاوى المتعلقة بجمعنا أو استخدامنا لمعلوماتك الشخصية للاستفسارات أو الشكاوى المتعلقة بسياسة الخصوصية الخاصة بنا ، يجب الاتصال بمنصتنا على:`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 14:- التحديثات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`. قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر استجابة للتطورات القانونية أو الفنية أو التجارية المتغيرة. عندما نقوم بتحديث سياسة الخصوصية الخاصة بنا ، لن نبلغك بذلك.
يمكنك معرفة تاريخ آخر تحديث لسياسة الخصوصية هذه عن طريق التحقق من تاريخ "تاريخ آخر مراجعة" المعروض في الجزء العلوي من سياسة الخصوصية هذه.
`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={``}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={``}
              />
            </div>
          </div>
          {/* 
          <div className=" mx-auto w-full lg:w-9/12 flex justify-evenly">
            <CustomButton
              label={t("Decline")}
              buttonTypeClass="outlined"
              width="w-40 lg:w-[215px]"
              height="h-12"
            />

            <CustomButton
              label={t("Accept")}
              width="w-40 lg:w-[215px]"
              height="h-12"
            />
          </div> */}
        </div>
      </main>
    </FullScreenBgLayout>
  );
};

export default PrivacyPolicyTemplate;
