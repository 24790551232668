import { Spinner, Typography } from "components/atoms";
import AddDiscountModal from "components/organisms/AddDiscountModal";
import AddItemDiscountModal from "components/organisms/AddItemDiscountModal";
import ProductCard from "components/organisms/ProductCard/ProductCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { loadMoreProductsStart } from "Redux/products/actions/products.actions";
import { ImagesSrc } from "utils";
import { CloseIcon, PlusSign } from "utils/helpers/SVGsSrc";

function PromotionsTemplate({
  products,
  openModal,
  setOpenModal,
  handleAddDiscount,
  isProductsLoading,
  openItemDiscountModal,
  setOpenItemDiscountModal,
  hasMore,
  queryParams,
  page,
  getQueryParams,
  setQueryParams,
  discount,
  handleDeleteVendorDiscount,
  handleDeleteProductDiscount,
  isProductLoadingMore,
}) {
  const dispatch = useDispatch();
  return (
    <>
      <div className="container mx-auto mt-10 bg-[#f8f8fe] py-10">
        {discount?.status[0] === "active" ? (
          <div className="flex ">
            <div className="grid col-1">
              <Typography
                as="span"
                size="2xl"
                color="text-text_464646"
                lineHeight="leading-[1.21]"
                classes="mr-10"
                text={discount?.amount + "   على جميع المنتجات "}
              />
              <Typography
                as="span"
                size="2xl"
                color="text-text_464646"
                lineHeight="leading-[1.21]"
                classes="mr-10"
                text={"حتى      " + discount?.exp_date}
              />
            </div>
            <CloseIcon
              className="mt-2 mr-10 cursor-pointer"
              onClick={() => handleDeleteVendorDiscount(discount?.id)}
            />
          </div>
        ) : (
          <div className="flex justify-start mr-10">
            <div className="ml-4  mt-1">
              <PlusSign
                height="100%"
                width="100%"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <Typography
              as="span"
              size="2xl"
              color="text-primary-900"
              lineHeight="leading-[1.21]"
              classes="ml-36 cursor-pointer"
              text={"إضافة خصم"}
              click={() => {
                setOpenModal(!openModal);
              }}
            />
          </div>
        )}
      </div>
      <div className="container mx-auto mt-10 pr-4 pl-10 bg-[#f8f8fe]">
        <div className="flex justify-start mr-6 my-10 pt-10">
          <div className="ml-4  mt-1">
            <PlusSign
              height="100%"
              width="100%"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <Typography
            as="span"
            size="2xl"
            color="text-primary-900"
            lineHeight="leading-[1.21]"
            classes="ml-36 cursor-pointer"
            text={"إضافة عنصر جديد"}
            click={() => {
              setOpenItemDiscountModal(true);
            }}
          />
        </div>

        <AddDiscountModal
          modalOpen={openModal}
          ToggleModalVisibility={setOpenModal}
          handleAddDiscount={handleAddDiscount}
          queryParams={queryParams}
        />
        <AddItemDiscountModal
          queryParams={queryParams}
          page={page}
          hasMore={hasMore}
          modalOpen={openItemDiscountModal}
          ToggleModalVisibility={setOpenItemDiscountModal}
          isProductsLoading={isProductsLoading}
          isProductLoadingMore={isProductLoadingMore}
          products={products}
          dispatch={dispatch}
          getQueryParams={getQueryParams}
          setQueryParams={setQueryParams}
        />
        <InfiniteScroll
          dataLength={products && products?.length}
          hasMore={hasMore}
          scrollThreshold={0.5}
          loader={<Spinner loading={isProductLoadingMore} />}
          next={() => {
            dispatch(
              loadMoreProductsStart({
                ...queryParams,
                page_number: page,
              })
            );
          }}
        >
          {isProductsLoading ? (
            <Spinner loading={isProductsLoading} />
          ) : (
            <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2">
              {products?.map((product) => {
                return (
                  <ProductCard
                    handleDelete={handleDeleteProductDiscount}
                    discountedPrice={product.price.price_after_discount}
                    img={
                      product.image ? product.image : ImagesSrc.PlaceholderLogo
                    }
                    name={product.name}
                    price={product.price.base_price}
                    description={product.description}
                    id={product.id}
                  />
                );
              })}
            </div>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
}

export default PromotionsTemplate;
