import OrdersActionTypes from "../types/orders.types";

import { createAction } from "utils";

export const updateOrderStart = (payload) =>
  createAction(OrdersActionTypes.UPDATE_ORDER_START, payload);

export const updateOrderSuccess = (payload) =>
  createAction(OrdersActionTypes.UPDATE_ORDER_SUCCESS, payload);

export const updateOrderFailure = (error) =>
  createAction(OrdersActionTypes.UPDATE_ORDER_FAILURE, error);

export const listOrdersStart = (payload) =>
  createAction(OrdersActionTypes.LIST_ORDERS_START, payload);

export const listOrdersSuccess = (orders) =>
  createAction(OrdersActionTypes.LIST_ORDERS_SUCCESS, orders);

export const listOrdersFailure = (error) =>
  createAction(OrdersActionTypes.LIST_ORDERS_FAILURE, error);

export const loadMoreOrdersStart = (payload) =>
  createAction(OrdersActionTypes.LOAD_MORE_ORDERS_START, payload);

export const loadMoreOrdersSuccess = (orders) =>
  createAction(OrdersActionTypes.LOAD_MORE_ORDERS_SUCCESS, orders);

export const loadMoreOrdersFailure = (error) =>
  createAction(OrdersActionTypes.LOAD_MORE_ORDERS_FAILURE, error);

export const updateOrderStatusStart = (payload) =>
  createAction(OrdersActionTypes.UPDATE_ORDER_STATUS_START, payload);

export const updateOrderStatusSuccess = (payload) =>
  createAction(OrdersActionTypes.UPDATE_ORDER_STATUS_SUCCESS, payload);

export const updateOrderStatusFailure = (error) =>
  createAction(OrdersActionTypes.UPDATE_ORDER_STATUS_FAILURE, error);
