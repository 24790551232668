import * as URL from "services/URLs";

import {
  addDiscountFailure,
  addDiscountSuccess,
  addMenuCategoriesFailure,
  addMenuCategoriesSuccess,
  getAllVendorsFailure,
  getAllVendorsSuccess,
  getDiscountFailure,
  getDiscountSuccess,
  getVendorMenuCategoriesFailure,
  getVendorMenuCategoriesSuccess,
  listAllCountriesFailure,
  listAllCountriesSuccess,
  listAllCurrenciesFailure,
  listAllCurrenciesSuccess,
  listAllPayoutMethodsFailure,
  listAllPayoutMethodsSuccess,
  listProfileFailure,
  listProfileSuccess,
  listServingTypesFailure,
  listServingTypesSuccess,
  updateDiscountFailure,
  updateDiscountSucess,
  updateProfileFailure,
  updateProfileSuccess,
  uploadImageFailure,
  uploadImageSuccess,
  uploadLogoFailure,
  uploadLogoSuccess,
} from "../actions/vendor.actions";
import { all, call, put, takeLatest } from "redux-saga/effects";

import VendorActionTypes from "../types/vendor.types";
import getAxios from "utils/Axios";

const axios = getAxios();

export function* updateDiscount({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "put",
      `${URL.UPDATE_DISCOUNT}/${payload.id}/?admin_vendor_id=${payload.admin_vendor_id}`,
      { vendor_discount: payload.body }
    );
    if (res?.status === 200) yield put(updateDiscountSucess(res?.data?.data));
  } catch (error) {
    yield put(updateDiscountFailure(error));
  }
}

export function* onUpdateDiscountStart() {
  yield takeLatest(VendorActionTypes.UPDATE_DISCOUNT_START, updateDiscount);
}

export function* getDiscountStart({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_DISCOUNT}/?admin_vendor_id=${payload}`
    );
    if (res?.status === 200) yield put(getDiscountSuccess(res?.data?.data));
  } catch (error) {
    yield put(getDiscountFailure(error));
  }
}

export function* onGetDiscountStart() {
  yield takeLatest(VendorActionTypes.GET_DISCOUNT_START, getDiscountStart);
}

export function* getVendorMenuCategories() {
  try {
    const res = yield call(axios.call, "get", URL.GET_VENDOR_MENU_CATEGORIES);
    if (res?.status === 200)
      yield put(getVendorMenuCategoriesSuccess(res?.data?.data));
  } catch (error) {
    yield put(getVendorMenuCategoriesFailure(error));
  }
}

export function* onGetVendorMenuCategoriesStart() {
  yield takeLatest(
    VendorActionTypes.GET_VENDOR_MENU_CATEGORIES_START,
    getVendorMenuCategories
  );
}

export function* listServingTypes() {
  try {
    const res = yield call(axios.call, "get", URL.SERVING_TYPES);
    if (res?.status === 200)
      yield put(listServingTypesSuccess(res?.data?.data));
  } catch (error) {
    yield put(listServingTypesFailure(error));
  }
}

export function* onListServingTypesStart() {
  yield takeLatest(
    VendorActionTypes.LIST_SERVING_TYPES_START,
    listServingTypes
  );
}

export function* addDiscount({ payload }) {
  try {
    const res = yield call(axios.call, "post", `${URL.ADD_DISCOUNT}`, payload);
    if (res?.status === 200) yield put(addDiscountSuccess(res?.data?.data));
  } catch (error) {
    yield put(addDiscountFailure(error));
  }
}

export function* onAddDiscountStart() {
  yield takeLatest(VendorActionTypes.ADD_DISCOUNT_START, addDiscount);
}

export function* addMenuCategory({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "post",
      `${URL.ADD_MENU_CATEGORY}`,
      payload
    );
    if (res?.status === 200)
      yield put(addMenuCategoriesSuccess(res?.data?.data));
  } catch (error) {
    yield put(addMenuCategoriesFailure(error));
  }
}

export function* onAddMenuCategoryStart() {
  yield takeLatest(
    VendorActionTypes.ADD_VENDOR_MENU_CATEGORY_START,
    addMenuCategory
  );
}

export function* onAddMenuCategorySuccess() {
  yield takeLatest(
    VendorActionTypes.ADD_VENDOR_MENU_CATEGORY_SUCCESS,
    getVendorMenuCategories
  );
}

export function* listProfile({ payload }) {
  const queryParams = Object.entries(payload).map((item, idx) => {
    return `${
      item[1]
        ? `${item[0]}=${
            Array.isArray(item[1]) ? `[${item[1].join(",")}]` : item[1]
          }${idx === Object.entries(payload).length - 1 ? "" : "&"}`
        : ""
    }`;
  });

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_PROFILE}?${queryParams.join("")}`
    );
    if (res?.status === 200) yield put(listProfileSuccess(res?.data?.data));
  } catch (error) {
    yield put(listProfileFailure(error));
  }
}

export function* onListProfileStart() {
  yield takeLatest(VendorActionTypes.LIST_PROFILE_START, listProfile);
}

export function* UpdateProfile({ payload }) {
  const data = {
    admin_vendor_id: payload.admin_vendor_id,
    //vendor_delivery_detail: payload.delivery_details,
    vendor: {
      iban: payload.iban,
      swift_code: payload.swift_code,
      bank_name: payload.bank_name,
      bank_address: payload.bank_address,
      allow_pickup: payload.allow_pickup,
      beneficiary_account_number: payload.beneficiary_account_number,
      beneficiary_address: payload.beneficiary_address,
      vendor_payout_method_id: payload.payoutMethods.value,
      country_id: payload.country.value,
      currency_id: payload.currency.value,
      max_delivery_distance: payload.max_delivery_distance,
      delivery_ranges_attributes: payload.delivery_ranges_attributes,
      area_id: payload.area_id,
      city_id: payload.city_id,
      lat: payload.lat,
      lng: payload.lng,
      logo: payload.logo,
      image: payload.image,
      full_name: payload.full_name,
      vendor_type: payload.vendor_type,
      email: payload.email,
      address: payload.address,
      manual_address: payload.manual_address,
      website: payload.website,
      minimum_order: payload.minimum_order,
      auto_accept_orders: payload.auto_accept_orders,
      accept_online_payment: payload.accept_online_payment,
      does_delivery: payload.does_delivery,
      serving_type_ids: payload.serving_type_ids,
      main_category_ids: payload.main_category_ids,
      en: payload.en,
      ar: payload.ar,
      notification_on_review: payload.notification_on_review,
    },
    vendor_working_times: payload.vendor_working_times,
  };
  console.log(data.vendor.image);
  try {
    const res = yield call(axios.call, "put", `${URL.GET_PROFILE}`, data);
    if (res?.status === 200) yield put(updateProfileSuccess(res?.data?.data));
    else yield put(updateProfileFailure());
  } catch (error) {}
}

export function* onUpdateProfileStart() {
  yield takeLatest(VendorActionTypes.UPDATE_PROFILE_START, UpdateProfile);
}

export function* uploadLogoImage({ payload }) {
  try {
    const res = yield call(axios.call, "post", `${URL.S3}`, {
      files: [
        { extension: payload.name.substring(payload.name.indexOf(".") + 1) },
      ],
    });
    if (res?.status === 200) {
      const url = res?.data?.data[0]?.presigned_url;
      console.log(url);
      yield call(axios.callS3, `${url}`, payload);
      yield put(uploadLogoSuccess(res?.data?.data[0]?.file_url));
    }
  } catch (error) {
    yield put(uploadLogoFailure(error));
  }
}

export function* onUploadLogoImageStart() {
  yield takeLatest(VendorActionTypes.UPLOAD_LOGO_START, uploadLogoImage);
}

export function* uploadImage({ payload }) {
  try {
    const res = yield call(axios.call, "post", `${URL.S3}`, {
      files: [
        { extension: payload.name.substring(payload.name.indexOf(".") + 1) },
      ],
    });
    if (res?.status === 200) {
      const url = res?.data?.data[0]?.presigned_url;
      console.log(url);
      yield call(axios.callS3, `${url}`, payload);
      yield put(uploadImageSuccess(res?.data?.data[0]?.file_url));
    }
  } catch (error) {
    yield put(uploadImageFailure(error));
  }
}

export function* onUploadImageStart() {
  yield takeLatest(VendorActionTypes.UPLOAD_IMAGE_START, uploadImage);
}

export function* getAllVendorsStart(payload) {
  try {
    const res = yield call(axios.call, "get", URL.GET_ALL_VENDORS);
    if (res?.status === 200) {
      yield put(getAllVendorsSuccess(res?.data?.data));
    }
  } catch (error) {
    yield put(getAllVendorsFailure(error));
  } finally {
    payload?.payload?.action && payload?.payload?.action();
  }
}

export function* onGetAllVendorsStart() {
  yield takeLatest(VendorActionTypes.GET_ALL_VENDORS_START, getAllVendorsStart);
}

export function* listAllCountries() {
  try {
    const res = yield call(axios.call, "get", URL.LIST_ALL_COUNTRIES);
    if (res?.status === 200) {
      yield put(listAllCountriesSuccess(res?.data?.data));
    }
  } catch (error) {
    yield put(listAllCountriesFailure(error));
  }
}

export function* onListAllCountries() {
  yield takeLatest(
    VendorActionTypes.LIST_ALL_COUNTRIES_START,
    listAllCountries
  );
}

export function* listAllCurrencies() {
  try {
    const res = yield call(axios.call, "get", URL.LIST_ALL_CURRENCIES);
    if (res?.status === 200) {
      yield put(listAllCurrenciesSuccess(res?.data?.data));
    }
  } catch (error) {
    yield put(listAllCurrenciesFailure(error));
  }
}

export function* onListAllCurrencies() {
  yield takeLatest(
    VendorActionTypes.LIST_ALL_CURRENCIES_START,
    listAllCurrencies
  );
}
export function* listAllPayoutMethods() {
  try {
    const res = yield call(axios.call, "get", URL.LIST_ALL_PAYOUT_METHODS);
    if (res?.status === 200) {
      yield put(listAllPayoutMethodsSuccess(res?.data?.data));
    }
  } catch (error) {
    yield put(listAllPayoutMethodsFailure(error));
  }
}

export function* onListAllPayoutMethods() {
  yield takeLatest(
    VendorActionTypes.LIST_ALL_PAYOUT_METHODS_START,
    listAllPayoutMethods
  );
}

// export function* listAllAreas() {
//   try {
//     const res = yield call(axios.call, "get", URL.LIST_ALL_AREAS);
//     if (res?.status === 200) yield put(listAllAreasSuccess(res?.data?.data));
//   } catch (error) {
//     yield put(listAllAreasFailure(error));
//   }
// }

// export function* onListAllAreasStart() {
//   yield takeLatest(VendorActionTypes.LIST_ALL_AREAS_START, listAllAreas);
// }

// export function* listAllDeliveryMethods({ payload }) {
//   try {
//     const res = yield call(
//       axios.call,
//       "get",
//       `${URL.LIST_ALL_DELIVERY_METHODS}`
//     );
//     if (res?.status === 200)
//       yield put(listAllDeliveryMethodsSuccess(res?.data?.data));
//   } catch (error) {
//     yield put(listAllDeliveryMethodsFailure(error));
//   }
// }

// export function* onListAllDeliveryMethods() {
//   yield takeLatest(
//     VendorActionTypes.LIST_ALL_DELIVERY_METHODS_START,
//     listAllDeliveryMethods
//   );
// }

// export function* listVendorAreas() {
//   try {
//     const res = yield call(axios.call, "get", URL.LIST_VENDOR_AREAS);
//     if (res?.status === 200) yield put(listVendorAreasSuccess(res?.data?.data));
//   } catch (error) {
//     yield put(listVendorAreasFailure(error));
//   }
// }

// export function* onListVendorAreas() {
//   yield takeLatest(VendorActionTypes.LIST_VENDOR_AREAS_START, listVendorAreas);
// }

// export function* updateVendorArea({ payload }) {
//   const vendor_delivery_area = payload.map((area) => {
//     if (area?._destroy) {
//       return {
//         id: area.id,
//         area_id: area.area_id,
//         delivery_third_party_id: area.delivery_third_party_id,
//         vendor_delivery_price: Number(area.vendor_delivery_price),
//         _destroy: true,
//       };
//     } else if (area?.id) {
//       return {
//         id: area.id,
//         area_id: area.area_id,
//         delivery_third_party_id: area.delivery_third_party_id,
//         vendor_delivery_price: Number(area.vendor_delivery_price),
//       };
//     } else {
//       return {
//         id: area.id,
//         area_id: area.area_id,
//         delivery_third_party_id: area.delivery_third_party_id,
//         vendor_delivery_price: Number(area.vendor_delivery_price),
//       };
//     }
//   });
//   const data = { vendor_delivery_area };
//   console.log(data);
//   try {
//     const res = yield call(axios.call, "put", `${URL.LIST_VENDOR_AREAS}`, data);
//     if (res?.status === 200) yield put(updateVendorAreaSucces(res?.data?.data));
//     else yield put(updateVendorAreaFailure());
//   } catch (error) {}
// }

// export function* onUpdateVendorArea() {
//   yield takeLatest(
//     VendorActionTypes.UPDATE_VENDOR_AREA_START,
//     updateVendorArea
//   );
// }

// export function* listAllCities() {
//   try {
//     const res = yield call(axios.call, "get", URL.LIST_ALL_CITIES);
//     if (res?.status === 200) yield put(listALLCitiesSuccess(res?.data?.data));
//   } catch (error) {
//     yield put(listAllCitiesFailure(error));
//   }
// }

// export function* onListAllCities() {
//   yield takeLatest(VendorActionTypes.LIST_VENDOR_AREAS_START, listAllCities);
// }

// export function* listAreasByCity({ payload }) {
//   try {
//     const res = yield call(
//       axios.call,
//       "get",
//       `${URL.LIST_ALL_CITIES}/${payload}`
//     );
//     if (res?.status === 200) yield put(listAreasByCitySuccess(res?.data?.data));
//   } catch (error) {
//     yield put(listAreasByCityFailure(error));
//   }
// }

// export function* onListAreasByCity() {
//   yield takeLatest(VendorActionTypes.LIST_AREAS_BY_CITY_START, listAreasByCity);
// }

export function* vendorSagas() {
  yield all([
    call(onListServingTypesStart),
    call(onGetVendorMenuCategoriesStart),
    call(onAddMenuCategoryStart),
    call(onAddMenuCategorySuccess),
    call(onAddDiscountStart),
    call(onGetDiscountStart),
    call(onUpdateDiscountStart),
    call(onListProfileStart),
    call(onUpdateProfileStart),
    call(onUploadLogoImageStart),
    call(onUploadImageStart),
    call(onGetAllVendorsStart),
    call(onListAllCountries),
    call(onListAllCurrencies),
    call(onListAllPayoutMethods),
    // call(onListAllAreasStart),
    // call(onListAllDeliveryMethods),
    // call(onListVendorAreas),
    // call(onUpdateVendorArea),
    // call(onListAllCities),
    // call(onListAreasByCity),
  ]);
}
