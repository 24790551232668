import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectMobileVerificationStatus = createSelector(
  [selectUser],
  (user) => user.mobile_verified
);

export const selectNewMobileVerificationStatus = createSelector(
  [selectUser],
  (user) => user.new_mobile_verified
);

export const selectPasswordResetStatus = createSelector(
  [selectUser],
  (user) => user.password_reset
);

export const selectResetPasswordVerifyOTPStatus = createSelector(
  [selectUser],
  (user) => user.reset_password_verifyOTPSuccess
);

export const selectIsContactUsMessageSent = createSelector(
  [selectUser],
  (user) => user.isContactUsMessageSend
);

export const selectIsFAQLoading = createSelector(
  [selectUser],
  (user) => user.isFAQLoading
);

export const selectFAQs = createSelector([selectUser], (user) => user.FAQs);

export const selectIsPasswordChanged = createSelector(
  [selectUser],
  (user) => user.isPasswordChanged
);

export const selectUnverifiedMobile = createSelector(
  [selectUser],
  (user) => user.un_verified_mobile
);

export const selectNotifications = createSelector(
  [selectUser],
  (user) => user.notifications
);

export const selectNotificationsCount = createSelector(
  [selectUser],
  (user) => user.notificationsCount
);

export const selectIsNotificationsLoading = createSelector(
  [selectUser],
  (user) => user.isNotificationLoading
);

export const selectFCM = createSelector([selectUser], (user) => user.FCMToken);

export const selectVendorId = createSelector(
  [selectUser],
  (user) => user.selectedVendorId
);

export const selectSystemConfiguration = createSelector(
  [selectUser],
  (user) => user.systemConfiguration
);
