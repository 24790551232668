import { CustomButton, Typography } from "components/atoms";
import { Modal } from "components/molecules";
import {
  CloseIcon,
  DoubleArrowLeft,
  DoubleArrowRight,
  ArrowLeft,
  ArrowRight,
} from "utils/helpers/SVGsSrc";

const AddCategoryModal = ({
  modalOpen,
  setModalOpen,
  ToggleModalVisibility,
  displayedCategories,
  setDisplayedCategories,
  setHighlightedCategory,
  highlightedCategory,
  selectedCategories,
  setSelectedCategories,
  handleSave,
}) => {
  return (
    <Modal isOpen={modalOpen} ToggleModalVisibility={ToggleModalVisibility}>
      <div className="bg-white w-8/12  my-40 ">
        <div className="p-10 ">
          <div
            onClick={ToggleModalVisibility}
            className="flex justify-start cursor-pointer"
          >
            <CloseIcon title="close" />
          </div>
          <div className="flex justify-start mt-10 mb-6">
            {" "}
            <Typography
              as="h3"
              size="2xl"
              color="text-warm_grey"
              clsses="font-normal"
              text={"الفئات التي تقدمها"}
            />
          </div>
          <div className="flex justify-between">
            {/* First list */}
            <div
              className="w-full greyScroll overflow-y-scroll border h-52 "
              style={{ direction: "ltr" }}
            >
              {displayedCategories?.map((category) => {
                return (
                  <div
                    className={
                      highlightedCategory.label === category.label
                        ? "border border-b-0 h-12 flex justify-end bg-primary-900 text-white "
                        : "border border-b-0 h-12 flex justify-end"
                    }
                    onClick={() => {
                      setHighlightedCategory(category);
                    }}
                  >
                    {category.label}
                  </div>
                );
              })}
            </div>
            {/* Button List */}
            <div className="mx-10">
              <div>
                <DoubleArrowLeft
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedCategories([
                      ...displayedCategories,
                      ...selectedCategories,
                    ]);
                    setDisplayedCategories([]);
                  }}
                />
              </div>
              {/* <CustomButton
                classes="bg-white mb-2"
                height="h-12"
                width="w-full"
                BeforeIcon={<DoubleArrowLeft />}
                action={() => {
                  setSelectedCategories([
                    ...displayedCategories,
                    ...selectedCategories,
                  ]);
                  setDisplayedCategories([]);
                }}
              /> */}
              <ArrowLeft
                className="cursor-pointer"
                onClick={() => {
                  {
                    if (!selectedCategories?.includes(highlightedCategory)) {
                      setSelectedCategories([
                        ...selectedCategories,
                        highlightedCategory,
                      ]);
                      const filteredCategories = displayedCategories.filter(
                        (category) =>
                          category.value !== highlightedCategory.value
                      );
                      setDisplayedCategories(filteredCategories);
                    }
                  }
                }}
              />
              {/* <CustomButton
                classes="bg-white mb-6"
                height="h-12"
                width="w-full"
                BeforeIcon={<ArrowLeft />}
                action={() => {
                  {
                    if (!selectedCategories?.includes(highlightedCategory)) {
                      setSelectedCategories([
                        ...selectedCategories,
                        highlightedCategory,
                      ]);
                      const filteredCategories = displayedCategories.filter(
                        (category) =>
                          category.value !== highlightedCategory.value
                      );
                      setDisplayedCategories(filteredCategories);
                    }
                  }
                }}
              /> */}
              <ArrowRight
                className="cursor-pointer"
                onClick={() => {
                  if (!displayedCategories.includes(highlightedCategory)) {
                    setDisplayedCategories([
                      ...displayedCategories,
                      highlightedCategory,
                    ]);
                    const filteredCategories = selectedCategories.filter(
                      (category) => category.value !== highlightedCategory.value
                    );
                    setSelectedCategories(filteredCategories);
                  }
                }}
              />
              {/* <CustomButton
                classes="bg-white mb-6"
                height="h-12"
                width="w-full"
                BeforeIcon={<ArrowRight />}
                action={() => {
                  if (!displayedCategories.includes(highlightedCategory)) {
                    setDisplayedCategories([
                      ...displayedCategories,
                      highlightedCategory,
                    ]);
                    const filteredCategories = selectedCategories.filter(
                      (category) => category.value !== highlightedCategory.value
                    );
                    setSelectedCategories(filteredCategories);
                  }
                }}
              /> */}
              <DoubleArrowRight
                className="cursor-pointer"
                onClick={() => {
                  setDisplayedCategories([
                    ...displayedCategories,
                    ...selectedCategories,
                  ]);
                  setSelectedCategories([]);
                }}
              />
              {/* <CustomButton
                classes="bg-white mb-6"
                height="h-12"
                width="w-full"
                BeforeIcon={<DoubleArrowRight />}
                action={() => {
                  setDisplayedCategories([
                    ...displayedCategories,
                    ...selectedCategories,
                  ]);
                  setSelectedCategories([]);
                }}
              /> */}
            </div>
            {/* Second list */}
            <div
              className="w-full greyScroll overflow-y-scroll h-52 border"
              style={{ direction: "ltr" }}
            >
              {selectedCategories?.map((category) => {
                return (
                  <div
                    className={
                      highlightedCategory.label === category.label
                        ? "border border-b-0 h-12 flex justify-end bg-primary-900 text-white "
                        : "border border-b-0 h-12 flex justify-end"
                    }
                    onClick={() => setHighlightedCategory(category)}
                  >
                    {category.label}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="w-full greyScroll overflow-y-scroll p-6"></div> */}
        <div className="px-6 pb-6">
          <CustomButton
            height="h-10"
            width="w-52"
            label={"حفظ"}
            textColor="text-primary-50"
            action={(e) => {
              let selectedCategoriesIds = selectedCategories.map(
                (category) => category.value
              );
              handleSave(e, {
                menu_category: { menu_category_ids: selectedCategoriesIds },
              });
              setModalOpen(!modalOpen);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddCategoryModal;
