import { Spinner } from "components/atoms";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { listOrders } from "Redux/orders/sagas/orders.sagas";
import { selectCurrentUser } from "Redux/user";
import { setSelectedVendor } from "Redux/user/actions/user.actions";
import {
  getAllVendorsStart,
  listProfileStart,
} from "Redux/vendor/actions/vendor.actions";
import {
  allVendorsSelector,
  selectVendorProfile,
} from "Redux/vendor/selectors/vendor.selectors";
import { TheMainTemplate } from "templates";

function TheMainPage() {
  const dispatch = useDispatch();
  const [currentView, setCurrentView] = useState("الطلبات");
  const [isLoading, setIsLoading] = useState(false);

  const vendorProfile = useSelector(selectVendorProfile);
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const location = useLocation();

  const vendors = useSelector(allVendorsSelector);

  useEffect(() => {
    setIsLoading(true);
    const action = () => {
      setIsLoading(false);
    };
    dispatch(getAllVendorsStart({ action }));
  }, []);

  useEffect(() => {
    dispatch(listProfileStart({}));
  }, [dispatch]);

  // useEffect(()=>{dispatch(listOrders())},[])

  useEffect(() => {
    if (vendorProfile?.extras?.account_status[2] === "approved") {
      navigate(location.pathname);
    } else if (!localStorage.getItem("access_token")) {
      navigate("/login");
    } else if (!currentUser.extras.account_status[2]) {
      navigate("/Under_Review");
    }
  }, []);

  const handleSelectVendor = (id) => {
    dispatch(setSelectedVendor(id));
  };

  return isLoading ? (
    <Spinner loading={isLoading} />
  ) : (
    <TheMainTemplate
      currentView={currentView}
      setCurrentView={setCurrentView}
      currentUser={currentUser}
      vendors={vendors}
      handleSelectVendor={handleSelectVendor}
    />
  );
}

export default TheMainPage;
