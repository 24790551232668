import * as URL from "services/URLs";

import {
  addProductFailure,
  addProductSuccess,
  deleteProductDiscountFailure,
  deleteProductDiscountSuccess,
  getMainCategoryFailure,
  getMainCategorySuccess,
  getProductFailure,
  getProductSuccess,
  getProductsFailure,
  getProductsSuccess,
  getSavingTemperatureFailure,
  getSavingTemperatureSuccess,
  getSizeUnitsFailure,
  getSizeUnitsSuccess,
  getSizesFailure,
  getSizesSuccess,
  getTagsFailure,
  getTagsSuccess,
  loadMoreProductsSuccess,
  removeProductFailure,
  removeProductSuccess,
  resetSuccessConfirmationModal,
  updateProductFailure,
  updateProductSuccess,
  uploadProductImageFailure,
  uploadProductImageSuccess,
} from "../actions/products.actions";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import ProductActionTypes from "../types/products.types";
import getAxios from "utils/Axios";
import { toast } from "react-toastify";

const axios = getAxios();

export function* updateProduct({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "put",
      `${URL.UPDATE_PRODUCT}/${payload.id}`,
      payload.body
    );
    if (res?.status === 200) {
      yield put(updateProductSuccess(res?.data?.data));
      payload?.action && payload?.action();
    } else {
      toast.error("حدث خطأ ما ، يرجى المحاولة مرة أخرى");
      yield put(updateProductFailure(1));
    }
  } catch (error) {
    yield put(updateProductFailure(error));
  }
}

export function* onUpdateProductStart() {
  yield takeLatest(ProductActionTypes.UPDATE_PRODUCT_START, updateProduct);
}

export function* deleteProductDiscount({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "put",
      `${URL.DELETE_PRODUCT_DISCOUNT}/ ${payload}`
    );
    if (res?.status === 200) yield put(deleteProductDiscountSuccess(payload));
  } catch (error) {
    yield put(deleteProductDiscountFailure(error));
  }
}

export function* onDeleteProductDiscountStart() {
  yield takeLatest(
    ProductActionTypes.DELETE_PRODUCT_DISCOUNT_START,
    deleteProductDiscount
  );
}

export function* uploadProductImage({ payload }) {
  try {
    const res = yield call(axios.call, "post", `${URL.S3}`, {
      files: [
        { extension: payload.name.substring(payload.name.indexOf(".") + 1) },
      ],
    });
    if (res?.status === 200) {
      const url = res?.data?.data[0]?.presigned_url;
      console.log(url);
      yield call(axios.callS3, `${url}`, payload);
      yield put(uploadProductImageSuccess(res?.data?.data[0]?.file_url));
    }
  } catch (error) {
    yield put(uploadProductImageFailure(error));
  }
}

export function* onUploadProductImageStart() {
  yield takeLatest(
    ProductActionTypes.UPLOAD_PRODUCT_IMAGE_START,
    uploadProductImage
  );
}

export function* getProduct({ payload }) {
  try {
    const res = yield call(axios.call, "get", `${URL.GET_PRODUCT}/${payload}`);
    if (res?.status === 200) {
      yield put(getProductSuccess(res?.data?.data));
    }
  } catch (error) {
    yield put(getProductFailure(error));
  }
}
export function* onGetProductStart() {
  yield takeLatest(ProductActionTypes.GET_PRODUCT_START, getProduct);
}

export function* removeProduct({ payload }) {
  const queryParams = Object.entries(payload.queryParams).map((item, idx) => {
    return `${
      item[1]
        ? `${item[0]}=${
            Array.isArray(item[1]) ? `[${item[1].join(",")}]` : item[1]
          }${idx === Object.entries(payload.queryParams).length - 1 ? "" : "&"}`
        : ""
    }`;
  });
  console.log(queryParams);
  try {
    const res = yield call(
      axios.call,
      "delete",
      `${URL.REMOVE_PRODUCT}${payload.id}/?admin_vendor_id=${payload.queryParams.admin_vendor_id}`
    );
    if (res?.status === 200) {
      yield put(removeProductSuccess(payload.id));
    }
  } catch (error) {
    yield put(removeProductFailure(error));
  }
}
export function* onRemoveProductStart() {
  yield takeLatest(ProductActionTypes.REMOVE_PRODUCT_START, removeProduct);
}

export function* getSavingTemp() {
  try {
    const res = yield call(axios.call, "get", `${URL.GET_SAVING_TEMP}`);
    if (res?.status === 200)
      yield put(getSavingTemperatureSuccess(res?.data?.data));
  } catch (error) {
    yield put(getSavingTemperatureFailure(error));
  }
}
export function* onGetSavingTempStart() {
  yield takeLatest(
    ProductActionTypes.GET_SAVING_TEMPERATURE_START,
    getSavingTemp
  );
}

export function* getTags() {
  try {
    const res = yield call(axios.call, "get", `${URL.GET_TAGS}`);
    if (res?.status === 200) yield put(getTagsSuccess(res?.data?.data));
  } catch (error) {
    yield put(getTagsFailure(error));
  }
}
export function* onGetTagsStart() {
  yield takeLatest(ProductActionTypes.GET_TAGS_START, getTags);
}

export function* getProducts({ payload }) {
  const queryParams = Object.entries(payload).map((item, idx) => {
    return `${
      item[1]
        ? `${item[0]}=${
            Array.isArray(item[1]) ? `[${item[1].join(",")}]` : item[1]
          }${idx === Object.entries(payload).length - 1 ? "" : "&"}`
        : ""
    }`;
  });

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_PRODUCTS}?${queryParams.join("")}`
    );
    if (res?.status === 200)
      if (payload.page_number > 1) {
        yield put(
          loadMoreProductsSuccess({
            products: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      } else {
        yield put(
          getProductsSuccess({
            products: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      }
  } catch (error) {
    yield put(getProductsFailure(error));
  }
}
export function* onLoadMoreProductsStart() {
  yield takeEvery(ProductActionTypes.LOAD_MORE_PRODUCTS_START, getProducts);
}
export function* onGetProductsStart() {
  yield takeLatest(ProductActionTypes.GET_PRODUCTS_START, getProducts);
}

export function* getSizes({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_PRODUCT_SIZES}/${payload}`
    );
    if (res?.status === 200) yield put(getSizesSuccess(res?.data?.data));
  } catch (error) {
    yield put(getSizesFailure(error));
  }
}
export function* onGetSizesStart() {
  yield takeLatest(ProductActionTypes.GET_SIZES_START, getSizes);
}

export function* getSizeUnits() {
  try {
    const res = yield call(axios.call, "get", URL.GET_PRODUCT_SIZE_UNITS);
    if (res?.status === 200) yield put(getSizeUnitsSuccess(res?.data?.data));
  } catch (error) {
    yield put(getSizeUnitsFailure(error));
  }
}
export function* onGetSizeUnitsStart() {
  yield takeLatest(ProductActionTypes.GET_SIZE_UNITS_START, getSizeUnits);
}
export function* getMainCategories() {
  try {
    const res = yield call(axios.call, "get", URL.GET_PRODUCT_MAIN_CATEGORIES);
    if (res?.status === 200) yield put(getMainCategorySuccess(res?.data?.data));
  } catch (error) {
    yield put(getMainCategoryFailure(error));
  }
}

export function* onGetMainCategoriesStart() {
  yield takeLatest(ProductActionTypes.GET_CATEGORY_START, getMainCategories);
}

export function* addProduct({ payload }) {
  const data = { ...payload };
  try {
    const res = yield call(axios.call, "post", `${URL.ADD_PRODUCT}`, data);
    if (res?.status === 200) {
      yield put(addProductSuccess(res?.data?.data));
      yield put(resetSuccessConfirmationModal(true));
    } else {
      yield put(addProductFailure(1));
      yield put(resetSuccessConfirmationModal(false));
    }
  } catch (error) {
    yield put(addProductFailure(error));
  }
}

export function* onAddProductStart() {
  yield takeLatest(ProductActionTypes.ADD_PRODUCT_START, addProduct);
}
export function* productsSaga() {
  yield all([
    call(onAddProductStart),
    call(onGetMainCategoriesStart),
    call(onGetSizeUnitsStart),
    call(onGetSizesStart),
    call(onGetProductsStart),
    call(onRemoveProductStart),
    call(onGetSavingTempStart),
    call(onGetTagsStart),
    call(onGetProductStart),
    call(onLoadMoreProductsStart),
    call(onUploadProductImageStart),
    call(onDeleteProductDiscountStart),
    call(onUpdateProductStart),
  ]);
}
