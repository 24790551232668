import UserActionTypes from "../types/user.types";

import { createAction } from "utils";

export const setCurrentUser = (user) =>
  createAction(UserActionTypes.SET_CURRENT_USER, user);

export const phoneSignInStart = (userCredentials) =>
  createAction(UserActionTypes.PHONE_SIGN_IN_START, userCredentials);

export const signInSuccess = (user) =>
  createAction(UserActionTypes.SIGN_IN_SUCCESS, user);

export const signInFailure = (error) =>
  createAction(UserActionTypes.SIGN_IN_FAILURE, error);

export const phoneSignUpStart = (userData) =>
  createAction(UserActionTypes.PHONE_SIGN_UP_START, userData);

export const signUpSuccess = (user) =>
  createAction(UserActionTypes.SIGN_UP_SUCCESS, user);

export const signUpFailure = (error) =>
  createAction(UserActionTypes.SIGN_UP_FAILURE, error);

export const verifyOTPStart = (payload) =>
  createAction(UserActionTypes.VERIFY_OTP_START, payload);

export const verifyOTPSuccess = (token) =>
  createAction(UserActionTypes.VERIFY_OTP_SUCCESS, token);

export const verifyMobileStart = (payload) =>
  createAction(UserActionTypes.VERIFY_MOBILE_NUMBER_START, payload);

export const verifyMobileSuccesss = (data) =>
  createAction(UserActionTypes.VERIFY_MOBILE_NUMBER_SUCCESS, data);

export const verifyMobileFailure = (error) =>
  createAction(UserActionTypes.VERIFY_MOBILE_NUMBER_START, error);

export const verifyNewMobileStart = (payload) =>
  createAction(UserActionTypes.VERIFY_NEW_MOBILE_NUMBER_START, payload);

export const verifyNewMobileSuccess = (data) =>
  createAction(UserActionTypes.VERIFY_NEW_MOBILE_NUMBER_SUCCESS, data);

export const verifyNewMobileFailure = (error) =>
  createAction(UserActionTypes.VERIFY_NEW_MOBILE_NUMBER_START, error);

export const verifyOTPFailure = (error) =>
  createAction(UserActionTypes.VERIFY_OTP_FAILURE, error);

export const resendVerificationMessageStart = (payload) =>
  createAction(UserActionTypes.SEND_VERIFICATION_MESSAGE_START, payload);

export const resendVerificationFailure = (error) =>
  createAction(UserActionTypes.SEND_VERIFICATION_MESSAGE_FAILURE, error);

export const resetPasswordStart = (payload) =>
  createAction(UserActionTypes.RESET_PASSWORD_START, payload);

export const resetPasswordSuccess = () =>
  createAction(UserActionTypes.RESET_PASSWORD_SUCCESS);

export const resetPasswordFailure = (error) =>
  createAction(UserActionTypes.RESET_PASSWORD_FAILURE, error);

export const resetPasswordVerifyOTPSuccess = () =>
  createAction(UserActionTypes.RESET_PASSWORD_VERIFY_OTP_SUCCESS);

export const signoutStart = () => createAction(UserActionTypes.SIGN_OUT_START);

export const signoutSuccess = () =>
  createAction(UserActionTypes.SIGN_OUT_SUCCESS);

export const signoutFailure = (error) =>
  createAction(UserActionTypes.SIGN_OUT_FAILURE, error);

export const changePasswordStart = (payload) =>
  createAction(UserActionTypes.CHANGE_PASSWORD_START, payload);

export const changePasswordSuccess = () =>
  createAction(UserActionTypes.CHANGE_PASSWORD_SUCCESS);

export const changePasswordInformed = () =>
  createAction(UserActionTypes.CHANGE_PASSWORD_INFORMED);

export const changePasswordFailure = (error) =>
  createAction(UserActionTypes.CHANGE_PASSWORD_FAILURE, error);

export const updateMobileStart = (payload) =>
  createAction(UserActionTypes.UPDATE_MOBILE_START, payload);

export const updateMobileSuccess = (mobile) =>
  createAction(UserActionTypes.UPDATE_MOBILE_SUCCESS, mobile);

export const updateMobileFailure = (error) =>
  createAction(UserActionTypes.UPDATE_MOBILE_FAILURE, error);

export const GetFAQStart = () => createAction(UserActionTypes.GET_FAQ_START);

export const GetFAQSuccess = (payload) =>
  createAction(UserActionTypes.GET_FAQ_SUCCESS, payload);

export const GetFAQFailure = (error) =>
  createAction(UserActionTypes.GET_FAQ_FAILURE, error);

export const sendContactUsMessageStart = (payload) =>
  createAction(UserActionTypes.SEND_CONTACT_US_MESSAGE_START, payload);

export const sendContactUsMessageSuccess = (payload) =>
  createAction(UserActionTypes.SEND_CONTACT_US_MESSAGE_SUCCESS, payload);

export const sendContactUsMessageFailure = (error) =>
  createAction(UserActionTypes.SEND_CONTACT_US_MESSAGE_FAILURE, error);

export const GetNotificationStart = () =>
  createAction(UserActionTypes.GET_NOTIFICATION_START);

export const GetNotificationSuccess = (payload) =>
  createAction(UserActionTypes.GET_NOTIFICATION_SUCCESS, payload);

export const GetNotificationFailure = (error) =>
  createAction(UserActionTypes.GET_NOTIFICATION_FAILURE, error);

export const GetNotificationCountStart = () =>
  createAction(UserActionTypes.GET_NOTIFICATION_COUNT_START);

export const GetNotificationCountSuccess = (payload) =>
  createAction(UserActionTypes.GET_NOTIFICATION_COUNT_SUCCESS, payload);

export const GetNotificationCountFailure = (error) =>
  createAction(UserActionTypes.GET_NOTIFICATION_COUNT_FAILURE, error);

export const setNotificationReadStart = (payload) =>
  createAction(UserActionTypes.SET_NOTIFICATION_READ_START, payload);

export const setNotificationReadSuccess = (payload) =>
  createAction(UserActionTypes.SET_NOTIFICATION_READ_SUCCESS, payload);

export const setNotificationReadFailure = (error) =>
  createAction(UserActionTypes.SET_NOTIFICATION_READ_FAILURE, error);

export const setFCMStart = (payload) =>
  createAction(UserActionTypes.SET_FCM_TOKEN_START, payload);

export const setFCMSuccess = (payload) =>
  createAction(UserActionTypes.SET_FCM_TOKEN_SUCCESS, payload);

export const setFCMFailure = (error) =>
  createAction(UserActionTypes.SET_FCM_TOKEN_FAILURE, error);

export const setSelectedVendor = (payload) =>
  createAction(UserActionTypes.SET_SELECTED_VENDOR, payload);

export const getSystemConfigurationStart = (payload) =>
  createAction(UserActionTypes.GET_SYSTEM_CONFIGURATION_START, payload);

export const getSystemConfigurationSuccess = (payload) =>
  createAction(UserActionTypes.GET_SYSTEM_CONFIGURATION_SUCCESS, payload);

export const getSystemConfigurationFailure = (error) =>
  createAction(UserActionTypes.GET_SYSTEM_CONFIGURATION_FAILURE, error);
