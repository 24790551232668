import { createSelector } from "reselect";

const selectVendor = (state) => state.vendor;

export const seletDiscount = createSelector(
  [selectVendor],
  (vendor) => vendor.discount
);

export const selectServingsList = createSelector([selectVendor], (vendor) =>
  vendor.servingTypes?.map((type) => {
    return { value: type.id, label: type.name_ar };
  })
);

export const selectIsVendorLoading = createSelector(
  [selectVendor],
  (vendor) => vendor.loading
);

export const selectIsVendorUpdated = createSelector(
  [selectVendor],
  (vendor) => vendor.isVendorUpdated
);

export const selectVendorMenuCategories = createSelector(
  [selectVendor],
  (vendor) =>
    vendor.vendorMenuCategories?.map((category) => {
      return {
        value: category.menu_category_id,
        label: category.name_ar,
      };
    })
);

export const selectVendorMenuCategoriesIds = createSelector(
  [selectVendor],
  (vendor) =>
    vendor.vendorMenuCategories?.map((category) => {
      return category.menu_category_id;
    })
);

export const selectVendorProfile = createSelector(
  [selectVendor],
  (vendor) => vendor.vendorProfile
);

export const LogoSelector = createSelector(
  [selectVendor],
  (vendor) => vendor.LogoURL
);

export const ImageSelector = createSelector(
  [selectVendor],
  (vendor) => vendor.ImageURL
);

export const allVendorsSelector = createSelector([selectVendor], (vendor) =>
  vendor.vendors?.map((vendor) => {
    return {
      value: vendor.id,
      label: vendor.display_name_ar,
    };
  })
);

export const allCountriesSelector = createSelector([selectVendor], (vendor) =>
  vendor.countries?.map((country) => {
    return {
      value: country.id,
      label: country.name,
    };
  })
);

export const allCurrenciesSelector = createSelector([selectVendor], (vendor) =>
  vendor.currencies?.map((currency) => {
    return {
      value: currency.id,
      label: currency.name_ar,
    };
  })
);

export const allPayoutMethodsSelector = createSelector(
  [selectVendor],
  (vendor) =>
    vendor.payoutMethods?.map((payoutMethod) => {
      return {
        value: payoutMethod.id,
        label: payoutMethod.name_ar,
      };
    })
);

// export const allAreasSelector = createSelector([selectVendor], (vendor) =>
//   vendor.areas?.map((area) => {
//     return {
//       value: area.id,
//       label: area.name_ar,
//     };
//   })
// );

// export const allDeliveryMehtodsSelector = createSelector(
//   [selectVendor],
//   (vendor) =>
//     vendor.deliveryMethods?.map((deliveryMethod) => {
//       return {
//         value: deliveryMethod.id,
//         label: deliveryMethod.name,
//       };
//     })
// );

// export const allVendorAreasSelector = createSelector(
//   [selectVendor],
//   (vendor) => vendor.vendorAreas
// );

// export const allCities = createSelector([selectVendor], (vendor) =>
//   vendor.cities?.map((city) => {
//     return {
//       value: city.id,
//       label: city.name_ar,
//     };
//   })
// );

// export const areasByCitySelector = createSelector([selectVendor], (vendor) =>
//   vendor.areas?.map((area) => {
//     return {
//       value: area.id,
//       label: area.name_ar,
//     };
//   })
// );
