import { PropTypes } from "prop-types";
import Select, { components } from "react-select";
import classnames from "classnames";

import { Typography } from "components/atoms";

import styles from "../FormInput/FormInput.module.css";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label className="flex  items-center">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className={`mr-2 w-4 h-4 mt-3 ${styles.input} ${styles.checkboxOutlined} border-text_777777 `}
          />
          {props.label}
        </label>
      </components.Option>
    </div>
  );
};

const DropDownInput = ({
  styles,
  label,
  labelStyle,
  secondaryLabel,
  placeholder = "",
  value,
  defaultValue,
  borderColor,
  onValueChange,
  options,
  labelSize = "sm",
  classes,
  containerClasses,
  width,
  height,
  textColor = "text-text_d9d9d9",
  classNamePrefix,
  isMulti,
  name,
  id,
  closeOnSelect = false,
  searchable = true,
  tabIndex,
}) => {
  const defaultStyle = {
    dropdownIndicator: (base) => ({
      ...base,
      color: "#777",
    }),
    option: (provided) => ({
      ...provided,
      color: "#777",
    }),
    control: (provided) => ({
      ...provided,
      color: "#777",
    }),
  };
  return (
    <div className={classnames("flex flex-col", containerClasses)}>
      {label && (
        <div className="flex">
          <Typography
            as="p"
            size={labelSize}
            color={textColor}
            classes={classnames("mb-2", labelStyle)}
            text={label}
          />
          <Typography
            as="span"
            size={labelSize}
            color={textColor}
            classes="mb-2 ml-1 rtl:ml-0 rtl:mr-1"
            text={secondaryLabel}
          />
        </div>
      )}
      <Select
        tabIndex={tabIndex}
        getValue={(a) => console.log(a)}
        name={name}
        id={id}
        styles={styles ? styles : defaultStyle}
        isMulti={isMulti}
        className={classnames("text-text_bfbfbf", classes, width, height)}
        classNamePrefix={classNamePrefix}
        onChange={(e) => onValueChange(e)}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        options={options}
        components={isMulti && { Option }}
        closeMenuOnSelect={closeOnSelect}
        hideSelectedOptions={false}
        isSearchable={searchable}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            neutral80: "#777",
            neutral50: "#777",
            neutral60: "#777",
            // neutral20: "#777",
            primary: "#eee",
            primary25: "#eee",
          },
        })}
      />
    </div>
  );
};

DropDownInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Object),
  onValueChange: PropTypes.func,
  classes: PropTypes.string,
  labelClasses: PropTypes.string,
};

export default DropDownInput;
