import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router";
import {
  deleteProductDiscountStart,
  getProductsStart,
} from "Redux/products/actions/products.actions";
import {
  addDiscountStart,
  getDiscountStart,
  updateDiscountStart,
} from "Redux/vendor/actions/vendor.actions";
import {
  hasMoreSelector,
  selectIsProductLoadingMore,
  selectIsProductsLoading,
  selectProductList,
} from "Redux/products/selectors/products.selectors";
import { PromotionsTemplate } from "templates";
import { getPayload } from "utils";
import { seletDiscount } from "Redux/vendor/selectors/vendor.selectors";
import { selectVendorId } from "Redux/user/selectors/user.selectors";

function PromotionsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [setCurrentView] = useOutletContext();

  const products = useSelector(selectProductList);
  const isProductsLoading = useSelector(selectIsProductsLoading);
  const isProductLoadingMore = useSelector(selectIsProductLoadingMore);
  const discount = useSelector(seletDiscount);
  const selectedVendorId = useSelector(selectVendorId);

  const hasMore = useSelector(hasMoreSelector);

  const [openModal, setOpenModal] = useState(false);
  const [openItemDiscountModal, setOpenItemDiscountModal] = useState(false);
  const [page, setPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    search_key: "",
    page_number: 1,
    menu_category_id: "",
    have_discount: true,
    admin_vendor_id: selectedVendorId,
  });

  const getQueryParams = (e) => getPayload(e, queryParams, setQueryParams);

  const handleDeleteProductDiscount = (id) => {
    if (window.confirm("هل أنت متأكد أنك تريد حذف هذا الخصم؟")) {
      dispatch(deleteProductDiscountStart(id));
    } else {
    }
  };

  const handleAddDiscount = (payload) => {
    dispatch(addDiscountStart(payload));
  };

  const handleDeleteVendorDiscount = (id) => {
    if (window.confirm("هل أنت متأكد أنك تريد حذف هذا الخصم؟")) {
      dispatch(
        updateDiscountStart({
          admin_vendor_id: selectedVendorId,
          id: id,
          body: { ...discount, status: "inactive", discount_type: 1 },
        })
      );
      dispatch(
        getProductsStart({
          ...queryParams,
          search_key: "",
          have_discount: true,
        })
      );
    } else {
    }
  };

  useEffect(() => {
    setQueryParams({ ...queryParams, admin_vendor_id: selectedVendorId });
  }, [selectedVendorId]);

  useEffect(() => {
    setCurrentView("الخصومات");
  }, []);

  useEffect(() => {
    dispatch(getDiscountStart(selectedVendorId));
  }, [dispatch, selectedVendorId]);

  useEffect(() => {
    if (products) setPage((prev) => prev + 1);
  }, [products]);

  useEffect(() => {
    if (openItemDiscountModal === false) {
      dispatch(
        getProductsStart({
          ...queryParams,
          search_key: "",
          have_discount: true,
        })
      );
    } else {
      dispatch(
        getProductsStart({
          ...queryParams,
          have_discount: false,
        })
      );
    }
  }, [dispatch, openItemDiscountModal, queryParams]);

  useEffect(() => {
    setPage(1);
  }, [queryParams]);

  return (
    <PromotionsTemplate
      handleDeleteProductDiscount={handleDeleteProductDiscount}
      handleDeleteVendorDiscount={handleDeleteVendorDiscount}
      discount={discount}
      setQueryParams={setQueryParams}
      getQueryParams={getQueryParams}
      queryParams={queryParams}
      page={page}
      hasMore={hasMore}
      products={products}
      navigate={navigate}
      openModal={openModal}
      setOpenModal={setOpenModal}
      handleAddDiscount={handleAddDiscount}
      isProductsLoading={isProductsLoading}
      isProductLoadingMore={isProductLoadingMore}
      openItemDiscountModal={openItemDiscountModal}
      setOpenItemDiscountModal={setOpenItemDiscountModal}
    />
  );
}

export default PromotionsPage;
