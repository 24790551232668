import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";

import { getPayload, StorageUtils } from "utils";

import { resetPasswordStart } from "Redux/user/actions/user.actions";
import { selectPasswordResetStatus } from "Redux/user/selectors/user.selectors";

import { CustomButton, Typography } from "components/atoms";
import { FormInput } from "components/molecules";
import { ValidationsWrapper } from "components/Containers";

import styles from "./ResetPasswordForm.module.css";

const ResetPasswordForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const password_reset = useSelector(selectPasswordResetStatus);

  const [newPassword, setNewPassword] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      user: {
        mobile_number: state?.mobile,
        type: "vendor",
      },
      ...newPassword,
    };
    resetPasswordHandler(payload);
  };

  const resetPasswordHandler = (payload) => {
    dispatch(resetPasswordStart(payload));
  };

  useEffect(() => {
    if (password_reset)
      navigate("/login", {
        state: {
          mobile: state?.mobile,
        },
      });
  }, [password_reset, navigate, state]);

  return (
    <ValidationsWrapper
      rules={["password", "confirm_password"]}
      initialValues={{
        password: "",
        confirm_password: "",
      }}
      className={styles.form}
    >
      {({ FormikHandler }) => {
        return (
          <div className={styles.formcontainer}>
            <div className={styles.formHeader}>
              <Typography
                as="h1"
                size="9xl"
                color="text-text_white"
                text={t("Create new password")}
              />

              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                fontWeight="font-normal"
                lineHeight="leading-[1.23]"
                classes="mt-3 lg:mt-6 3xl:mt-8"
                text={t("Please set your new password")}
              />
            </div>
            <div className={styles.formInputsContainer}>
              <div className="mt-5 lg:mt-6 3xl:mt-10 md:w-3/4 lg:w-3/5 xl:w-4/6 mx-auto">
                <FormInput
                  id="Password"
                  name="password"
                  type="password"
                  width="w-full "
                  height="h-10"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label={t("Create new password")}
                  labelSize="sm"
                  value={FormikHandler.values.password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("password", e.target.value);
                    getPayload(e, newPassword, setNewPassword);
                  }}
                  error={FormikHandler.errors.password}
                />
              </div>
              <div className="mt-5 lg:mt-6 3xl:mt-10 md:w-3/4 lg:w-3/5 xl:w-4/6 mx-auto">
                <FormInput
                  id="confirmPassword"
                  name="password_confirmation"
                  type="password"
                  width="w-full"
                  height="h-10"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label={t("Confirm password")}
                  labelSize="sm"
                  value={FormikHandler.values.confirm_password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue(
                      "confirm_password",
                      e.target.value
                    );
                    getPayload(e, newPassword, setNewPassword);
                  }}
                  error={FormikHandler.errors.confirm_password}
                />
              </div>
            </div>
            <div className="mt-5 lg:mt-8 3xl:mt-10 ">
              <CustomButton
                width="w-56"
                height="h-10"
                label={t("Reset password")}
                disabled={!FormikHandler.isValid}
                action={handleSubmit}
              />
            </div>
          </div>
        );
      }}
    </ValidationsWrapper>
  );
};

export default ResetPasswordForm;

/* 
  <div className="mt-5 lg:mt-6 3xl:mt-10 flex justify-between md:w-3/4 lg:w-3/5 xl:w-4/6 mx-auto">
    <FormInput
      id="rememberMe"
      name="rememberMe"
      type="checkbox"
      width="w-4"
      height="h-4"
      containerClasses="checkboxOutlined"
      label={t("Remember me")}
      labelSize="xs"
    />
  </div>
*/
