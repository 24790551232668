import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";

import { PropTypes } from "prop-types";
import { Typography } from "components/atoms";
import classnames from "classnames";
import styles from "./FormInput.module.css";
import { useState } from "react";

const FormInput = ({
  fullWidth = true,
  label,
  labelSize,
  type,
  id,
  name,
  placeholder = "",
  value,
  onValueChange,
  onFocus,
  onBlur,
  checked,
  defaultChecked,
  required = false,
  readOnly = false,
  hidden = false,
  inputRef,
  error,
  hint,
  OpenClose = false,
  width,
  height,
  containerHeight,
  containerClasses = "",
  inputTypeClass,
  borderRadius,
  bgColor = "bg-text_white ",
  textColor = "text-text_grey ",
  borderColor = "border-warm_grey ",
  Resize = false,
  defaultValue,
  textareaRows = "4",
  tabIndex,
  onKeyDown,
  font,
}) => {
  const [fieldType, setFieldType] = useState(type);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  return (
    <label
      htmlFor={id}
      className={classnames(
        styles[containerClasses],
        "w-full",
        containerHeight,
        font
      )}
    >
      {label && (
        <Typography
          as="p"
          size={labelSize}
          color={textColor}
          classes="mb-2"
          text={label}
        />
      )}
      {type === "textarea" ? (
        <textarea
          id={id}
          name={name}
          ref={inputRef}
          type={type}
          className={classnames(
            hidden ? "hidden" : "block",
            Resize ? "resize-none" : "",
            styles.input,
            styles.textarea,
            width,
            height,
            styles[inputTypeClass],
            bgColor,
            borderColor,
            "focus:border-primary-900 focus:ring-1 focus:ring-primary-900 focus:ring-opacity-50"
          )}
          placeholder={placeholder}
          value={value}
          onChange={onValueChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          readOnly={readOnly}
          rows={textareaRows}
        />
      ) : type === "date" ? (
        <input
          type={type}
          id={id}
          name={name}
          ref={inputRef}
          className={classnames(
            hidden ? "hidden" : "block",
            Resize ? "resize-none" : "",
            styles.input,
            styles.textarea,
            width,
            height,
            styles[inputTypeClass],
            bgColor,
            borderColor,
            "focus:border-primary-900 focus:ring-1 focus:ring-primary-900 focus:ring-opacity-50"
          )}
          placeholder={placeholder}
          value={value}
          onChange={onValueChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          readOnly={readOnly}
          rows={textareaRows}
        />
      ) : (
        <>
          <div className={classnames(fullWidth ? "w-full" : "", "relative")}>
            <input
              tabIndex={tabIndex}
              id={id}
              name={name}
              ref={inputRef}
              type={fieldType}
              defaultValue={defaultValue}
              style={{ borderRadius: borderRadius ? borderRadius : 0 }}
              className={classnames(
                hidden ? "hidden" : "block",
                styles.input,
                { absolute: type === "password" },
                width,
                height,
                styles[inputTypeClass],
                bgColor,
                borderColor,
                "focus:border-primary-900 focus:ring-1 focus:ring-primary-900 focus:ring-opacity-50"
              )}
              placeholder={placeholder}
              checked={checked}
              value={value}
              onChange={onValueChange}
              onFocus={onFocus}
              onBlur={onBlur}
              required={required}
              readOnly={readOnly}
              defaultChecked={defaultChecked}
              onKeyDown={onKeyDown}
            />
          </div>
          {!showPassword && type === "password" && (
            <EyeIcon
              width="20px"
              height="20px"
              className="cursor-pointer relative top-[13%] ltr:left-[93.5%] rtl:right-[93.5%]"
              color="#3b3b3b"
              onClick={() => {
                setFieldType("text");
                togglePasswordVisibility();
              }}
            />
          )}
          {showPassword && type === "password" && (
            <EyeOffIcon
              width="20px"
              height="20px"
              className="cursor-pointer relative top-[13%] ltr:left-[93.5%] rtl:right-[93.5%]"
              color="#3b3b3b"
              onClick={() => {
                togglePasswordVisibility();
                setFieldType("password");
              }}
            />
          )}
        </>
      )}

      {hint && (
        <small className="text-text_white text-xs -mt-2 ml-1">{hint}</small>
      )}
      {error && (
        <small
          className={`text-fail text-sm -mt-2 ml-1 ${
            type === "password" ? "relative top-[22%]" : ""
          }`}
        >
          {error}
        </small>
      )}
    </label>
  );
};

FormInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelSize: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.any,
  onValueChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  hidden: PropTypes.bool,
  inputRef: PropTypes.instanceOf(Object),
  error: PropTypes.string,
  hint: PropTypes.string,
  OpenClose: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  containerClasses: PropTypes.string,
  inputTypeClass: PropTypes.string,
  borderRadius: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
};

export default FormInput;
