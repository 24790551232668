import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik, FormikProvider, Form } from "formik";
import * as yup from "yup";

const ValidationsWrapper = ({
  className,
  children,
  initialValues,
  rules = [],
}) => {
  const { t } = useTranslation();

  const [validationSchema, setValidationSchema] = useState({});

  useEffect(() => {
    const validationSchemas = {
      email: yup.string().email(t("validations.Invalid email")),
      old_password: yup
        .string()
        .required(t("validations.Password is Required"))
        .min(8, t("validations.Password must contain 8 characters at least")),
      password: yup
        .string()
        .required(t("validations.Password is Required"))
        .min(8, t("validations.Password must contain 8 characters at least")),
      confirm_password: yup
        .string()
        .min(8, t("validations.Password must contain 8 characters at least"))
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: yup
            .string()
            .oneOf(
              [yup.ref("password")],
              t("validations.Both password need to be the same")
            ),
        })
        .required(t("validations.Confirm Password Required")),
      phone: yup
        .string()
        .required(t("validations.Phone number is Required"))
        .min(
          [yup.ref("phone"), null].length + 5,
          "رقم الهاتف قصير جدًا ، يُرجى إدخال 11 رقمًا"
        ),
      OTP_code: yup
        .string()
        .required(t("validations.OTP code Required"))
        .length(5, t("validations.OTP code must contain 5 numbers at least")),
      full_name: yup.string().required(t("validations.First Name Required")),
      last_name: yup.string().required(t("validations.Last Name Required")),
      termsOfUse: yup.boolean().oneOf([true], ""),
      website: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          t("Enter correct url")
        ),
      amount: yup.number().required("Discount amount required"),
      exp_date: yup.string().required("Expiration Date required"),
      order_time: yup.number().required("Time of preparation required"),
    };

    const validationSchemaMap = rules.reduce(
      (acc, cur) => ({ ...acc, [cur]: validationSchemas[cur] }),
      {}
    );

    setValidationSchema(validationSchemaMap);
  }, [rules, t]);

  const FormikHandler = useFormik({
    initialValues,
    initialErrors: initialValues,
    validationSchema: yup.object({ ...validationSchema }),
  });

  return (
    <FormikProvider value={FormikHandler}>
      <Form className={className}>{children({ FormikHandler })}</Form>
    </FormikProvider>
  );
};

ValidationsWrapper.propTypes = {
  className: PropTypes.string,
  //children: PropTypes.node,
  initialValues: PropTypes.instanceOf(Object),
  rules: PropTypes.instanceOf(Array),
};

export default React.memo(ValidationsWrapper);
