import { useTranslation } from "react-i18next";

import { Typography } from "components/atoms";
import { NotificationCard } from "components/molecules";
import TimeAgo from "react-timeago";
import englishStrings from "react-timeago/lib/language-strings/en";
import arabicStrings from "react-timeago/lib/language-strings/ar";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import styles from "./Notifications.module.css";

const NotificationsTemplate = ({ Notifications }) => {
  const { t, i18n } = useTranslation();
  const formatter = i18n?.language?.includes("ar")
    ? buildFormatter(arabicStrings)
    : buildFormatter(englishStrings);
  return (
    <>
      <main className={styles.main}>
        <div className="px-6 lg:px-0">
          <Typography
            as="h2"
            size="7xl"
            color="text-text_464646"
            classes="hidden lg:block lg:mb-6"
            text={"الإشعارات"}
          />
        </div>
        {Notifications?.today?.length != 0 ? (
          <div className="mt-8 bg-[#f8f8fe] px-6 lg:px-20 lg:pt-14">
            <Typography
              as="h2"
              size="7xl"
              color="text-text_464646"
              classes="lg:mb-6"
              text="اليوم"
            />
            {Notifications?.today?.map((item) => (
              <NotificationCard
                restaurantImg={null}
                notificationType={item?.attributes?.notification_type}
                dataId={item?.attributes?.data_id}
                notificationTitle={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_title
                    : item?.attributes?.title
                }
                notificationTime={
                  <TimeAgo
                    date={item?.attributes?.sent_at}
                    formatter={formatter}
                  />
                }
                notificationDescription={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_message
                    : item?.attributes?.message
                }
              />
            ))}
          </div>
        ) : (
          ""
        )}

        {Notifications?.yesterday?.length != 0 ? (
          <div className="mt-8 bg-[#f8f8fe] px-6 lg:px-20 lg:pt-14">
            <Typography
              as="h2"
              size="7xl"
              color="text-text_464646"
              classes="lg:mb-6"
              text="في الامس"
            />
            {Notifications?.yesterday?.map((item) => (
              <NotificationCard
                restaurantImg={null}
                notificationType={item?.attributes?.notification_type}
                dataId={item?.attributes?.data_id}
                notificationTitle={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_title
                    : item?.attributes?.title
                }
                notificationTime={
                  <TimeAgo
                    date={item?.attributes?.sent_at}
                    formatter={formatter}
                  />
                }
                notificationDescription={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_message
                    : item?.attributes?.message
                }
              />
            ))}
          </div>
        ) : (
          ""
        )}

        {Notifications?.later?.length != 0 ? (
          <div className="mt-8 bg-[#f8f8fe] px-6 lg:px-20 lg:pt-14">
            <Typography
              as="h2"
              size="7xl"
              t
              color="text-text_464646"
              classes="lg:mb-6"
              text="في وقت لاحق"
            />
            {Notifications?.later?.map((item) => (
              <NotificationCard
                restaurantImg={null}
                notificationType={item?.attributes?.notification_type}
                dataId={item?.attributes?.data_id}
                notificationTitle={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_title
                    : item?.attributes?.title
                }
                notificationTime={
                  <TimeAgo
                    date={item?.attributes?.sent_at}
                    formatter={formatter}
                  />
                }
                notificationDescription={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_message
                    : item?.attributes?.message
                }
              />
            ))}
          </div>
        ) : (
          ""
        )}
      </main>
    </>
  );
};

export default NotificationsTemplate;
