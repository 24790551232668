import { PropTypes } from "prop-types";

import { FormInput } from "components/molecules";

const CheckBoxesGroup = ({
  Options,
  name,
  selectedItems,
  getSelectedItems,
  setFieldValue,
  state,
}) => {
  return (
    <>
      {Options?.map((filter) => (
        <FormInput
          fullWidth={false}
          key={filter?.id}
          name={name}
          type="checkbox"
          width="w-5"
          height="h-5"
          containerClasses="selectInputs"
          inputTypeClass="solidCheckbox"
          label={filter?.name}
          value={filter?.id}
          checked={selectedItems?.includes(filter?.id)}
          onValueChange={(e) => {
            let newSelectedItems = [...selectedItems, +e.target.value];
            if (selectedItems.includes(+e.target.value)) {
              newSelectedItems = newSelectedItems.filter(
                (id) => id !== +e.target.value
              );
            }
            setFieldValue(state, newSelectedItems);
            // getSelectedItems({
            //   target: { name, value: newSelectedItems },
            // });
          }}
          labelSize="xl"
          textColor="text-text_777777"
        />
      ))}
    </>
  );
};

CheckBoxesGroup.propTypes = {
  title: PropTypes.string,
  Options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  name: PropTypes.string,
  selectedItems: PropTypes.arrayOf(PropTypes.number),
  getSelectedItems: PropTypes.func,
};

export default CheckBoxesGroup;
