import {
  allVendorAreasSelector,
  selectIsVendorLoading,
  selectVendorProfile,
} from "Redux/vendor/selectors/vendor.selectors";
import {
  listProfileStart,
  listVendorAreasStart,
} from "Redux/vendor/actions/vendor.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { ProfileTemplate } from "templates";
import axios from "axios";
import { selectVendorId } from "Redux/user/selectors/user.selectors";

function ProfilePage() {
  const [language, setLanguage] = useState(1); // 1 = ar , 2 = en

  const dispatch = useDispatch();
  const selectedVendorId = useSelector(selectVendorId);
  const [queryParams, setQueryParams] = useState({
    admin_vendor_id: selectedVendorId,
  });
  const ProfileData = useSelector(selectVendorProfile);
  const IsProfileDataLoading = useSelector(selectIsVendorLoading);
  // const vendorAreas = useSelector(allVendorAreasSelector);

  useEffect(() => {
    axios.defaults.headers["Accept-Language"] = language == 1 ? "ar" : "en";
    dispatch(listProfileStart(queryParams));
    // dispatch(listVendorAreasStart());
  }, [language]);

  return (
    <ProfileTemplate
      ProfileData={ProfileData}
      IsProfileDataLoading={IsProfileDataLoading}
      language={language}
      setLanguage={setLanguage}
      // vendorAreas={vendorAreas}
    />
  );
}

export default ProfilePage;
