import { takeLatest, all, call, put, takeEvery } from "redux-saga/effects";

import OrdersActionTypes from "../types/orders.types";

import * as URL from "services/URLs";

import getAxios from "utils/Axios";
import {
  listOrdersFailure,
  listOrdersSuccess,
  updateOrderStatusSuccess,
  updateOrderStatusFailure,
  loadMoreOrdersSuccess,
  updateOrderSuccess,
  updateOrderFailure,
} from "../actions/order.actions";

const axios = getAxios();

export function* listOrders({ payload }) {
  const queryParams = Object.entries(payload).map((item, idx) => {
    return `${
      item[1]
        ? `${item[0]}=${
            Array.isArray(item[1]) ? `[${item[1].join(",")}]` : item[1]
          }${idx === Object.entries(payload).length - 1 ? "" : "&"}`
        : ""
    }`;
  });

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.ORDERS}?${
        typeof queryParams === "string" ? queryParams : queryParams.join("")
      }`
    );
    if (res?.status === 200)
      if (payload.page_number > 1) {
        yield put(
          loadMoreOrdersSuccess({
            orders: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      } else {
        yield put(
          listOrdersSuccess({
            orders: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      }
  } catch (error) {
    yield put(listOrdersFailure(error));
  }
}

export function* onListOrdersStart() {
  yield takeLatest(OrdersActionTypes.LIST_ORDERS_START, listOrders);
}
export function* onLoadMoreOrdersStart() {
  yield takeEvery(OrdersActionTypes.LOAD_MORE_ORDERS_START, listOrders);
}

export function* updateOrderStatus({ payload }) {
  const data = { ...payload };
  const parameter = data.order.status === "completed" ? "current" : "new";
  try {
    const res = yield call(
      axios.call,
      "put",
      `${URL.UPDATE_ORDER_STATUS}/${data.id}`,
      { order: data.order, admin_vendor_id: data.admin_vendor_id }
    );
    if (res?.status === 200) {
      data?.action && data?.action();
      yield put(updateOrderStatusSuccess({ status: parameter }));
    }
  } catch (error) {
    yield put(updateOrderStatusFailure(error));
  }
}

export function* updateOrder({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "put",
      `${URL.UPDATE_ORDER}/${payload.id}`,
      payload.body
    );
    if (res?.status === 200) yield put(updateOrderSuccess(payload.id));
  } catch (error) {
    yield put(updateOrderFailure(error));
  }
}

export function* onUpdateOrderStart() {
  yield takeLatest(OrdersActionTypes.UPDATE_ORDER_START, updateOrder);
}

export function* onUpdateOrderStatusStart() {
  yield takeLatest(
    OrdersActionTypes.UPDATE_ORDER_STATUS_START,
    updateOrderStatus
  );
}
// export function* onUpdateOrderSuccess() {
//   yield takeLatest(OrdersActionTypes.UPDATE_ORDER_STATUS_SUCCESS, listOrders);
// }

export function* ordersSagas() {
  yield all([
    call(onListOrdersStart),
    call(onUpdateOrderStatusStart),
    // call(onUpdateOrderSuccess),
    call(onLoadMoreOrdersStart),
    call(onUpdateOrderStart),
  ]);
}
