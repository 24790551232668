import { PropTypes } from "prop-types";
import classNames from "classnames";

import { CloseIcon } from "utils/helpers/SVGsSrc";

import { Typography } from "components/atoms";

const ModalHeader = ({
  ToggleModalVisibility,
  heading,
  hasShadow = true,
  textAlignment = "text-center",
}) => {
  return (
    <div
      className={classNames("p-4 xl:p-8 ", textAlignment, {
        "shadow-[0_3px_6px_0_rgb(0,0,0,0.16)]": hasShadow,
      })}
    >
      <div
        onClick={ToggleModalVisibility}
        className="flex justify-end cursor-pointer"
      >
        <CloseIcon title="close" />
      </div>
      <Typography as="h3" size="3xl" fontWeight="font-bold" text={heading} />
    </div>
  );
};

ModalHeader.propTypes = {
  ToggleModalVisibility: PropTypes.func,
  heading: PropTypes.string,
};

export default ModalHeader;
