import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { userReducer } from "./user";
import categoriesReducer from "./categories/reducer/categories.reducer";
import ordersReducer from "./orders/reducer/orders.reducer";
import productsReducer from "./products/reducer/products.reducer";
import vendorReducer from "./vendor/reducer/vendor.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  user: userReducer,
  categories: categoriesReducer,
  orders: ordersReducer,
  products: productsReducer,
  vendor: vendorReducer,
});

export default persistReducer(persistConfig, rootReducer);
