import VendorActionTypes from "../types/vendor.types";
import { createAction } from "utils";

export const updateDiscountStart = (payload) =>
  createAction(VendorActionTypes.UPDATE_DISCOUNT_START, payload);

export const updateDiscountSucess = (payload) =>
  createAction(VendorActionTypes.UPDATE_DISCOUNT_SUCCESS, payload);

export const updateDiscountFailure = (error) =>
  createAction(VendorActionTypes.UPDATE_DISCOUNT_FAILURE, error);

export const getDiscountStart = (payload) =>
  createAction(VendorActionTypes.GET_DISCOUNT_START, payload);

export const getDiscountSuccess = (payload) =>
  createAction(VendorActionTypes.GET_DISCOUNT_SUCCESS, payload);

export const getDiscountFailure = (error) =>
  createAction(VendorActionTypes.GET_DISCOUNT_FAILURE, error);

export const addDiscountStart = (payload) =>
  createAction(VendorActionTypes.ADD_DISCOUNT_START, payload);

export const addDiscountSuccess = () =>
  createAction(VendorActionTypes.ADD_DISCOUNT_SUCCESS);

export const addDiscountFailure = (error) =>
  createAction(VendorActionTypes.ADD_DISCOUNT_FAILURE, error);

export const addMenuCategoriesStart = (payload) =>
  createAction(VendorActionTypes.ADD_VENDOR_MENU_CATEGORY_START, payload);

export const addMenuCategoriesSuccess = () =>
  createAction(VendorActionTypes.ADD_VENDOR_MENU_CATEGORY_SUCCESS);

export const addMenuCategoriesFailure = (error) =>
  createAction(VendorActionTypes.ADD_VENDOR_MENU_CATEGORY_FAILURE, error);

export const listServingTypesStart = () =>
  createAction(VendorActionTypes.LIST_SERVING_TYPES_START);

export const listServingTypesSuccess = (servingTypes) =>
  createAction(VendorActionTypes.LIST_SERVING_TYPES_SUCCESS, servingTypes);

export const listServingTypesFailure = (error) =>
  createAction(VendorActionTypes.LIST_SERVING_TYPES_FAILURE, error);

export const getVendorMenuCategoriesStart = () =>
  createAction(VendorActionTypes.GET_VENDOR_MENU_CATEGORIES_START);

export const getVendorMenuCategoriesSuccess = (vendorMenuCategories) =>
  createAction(
    VendorActionTypes.GET_VENDOR_MENU_CATEGORIES_SUCCESS,
    vendorMenuCategories
  );

export const getVendorMenuCategoriesFailure = (error) =>
  createAction(VendorActionTypes.GET_VENDOR_MENU_CATEGORIES_FAILURE, error);

// export const updateOrderStatusStart = (payload) =>
//   createAction(VendorActionTypes.UPDATE_ORDER_STATUS_START, payload);

// export const updateOrderStatusSuccess = (orders) =>
//   createAction(VendorActionTypes.UPDATE_ORDER_STATUS_SUCCESS, orders);

// export const updateOrderStatusFailure = (error) =>
//   createAction(VendorActionTypes.UPDATE_ORDER_STATUS_FAILURE, error);

export const listProfileStart = (payload) =>
  createAction(VendorActionTypes.LIST_PROFILE_START, payload);

export const listProfileSuccess = (profileData) =>
  createAction(VendorActionTypes.LIST_PROFILE_SUCCESS, profileData);

export const listProfileFailure = (error) =>
  createAction(VendorActionTypes.LIST_PROFILE_FAILURE, error);

export const updateProfileStart = (newData) =>
  createAction(VendorActionTypes.UPDATE_PROFILE_START, newData);

export const updateProfileSuccess = (profileData) =>
  createAction(VendorActionTypes.UPDATE_PROFILE_SUCCESS, profileData);

export const updateProfileInformed = () =>
  createAction(VendorActionTypes.UPDATE_PROFILE_INFORMED);

export const updateProfileFailure = (error) =>
  createAction(VendorActionTypes.UPDATE_PROFILE_FAILURE, error);

export const uploadLogoStart = (payload) =>
  createAction(VendorActionTypes.UPLOAD_LOGO_START, payload);

export const uploadLogoSuccess = (imageUrl) =>
  createAction(VendorActionTypes.UPLOAD_LOGO_SUCCESS, imageUrl);

export const uploadLogoFailure = (error) =>
  createAction(VendorActionTypes.UPLOAD_LOGO_FAILURE, error);

export const LogoProgressBarValue = (payload) =>
  createAction(VendorActionTypes.LOGO_PROGRESS_BAR_START, payload);

export const uploadImageStart = (payload) =>
  createAction(VendorActionTypes.UPLOAD_IMAGE_START, payload);

export const uploadImageSuccess = (imageUrl) =>
  createAction(VendorActionTypes.UPLOAD_IMAGE_SUCCESS, imageUrl);

export const uploadImageFailure = (error) =>
  createAction(VendorActionTypes.UPLOAD_IMAGE_FAILURE, error);

export const ImageProgressBarValue = (payload) =>
  createAction(VendorActionTypes.IMAGE_PROGRESS_BAR_START, payload);

export const getAllVendorsStart = (payload) =>
  createAction(VendorActionTypes.GET_ALL_VENDORS_START, payload);

export const getAllVendorsSuccess = (payload) =>
  createAction(VendorActionTypes.GET_ALL_VENDORS_SUCCESS, payload);

export const getAllVendorsFailure = (error) =>
  createAction(VendorActionTypes.GET_ALL_VENDORS_FAILURE, error);

//These also need to be removed
// export const listAllAreasStart = () =>
//   createAction(VendorActionTypes.LIST_ALL_AREAS_START);

// export const listAllAreasSuccess = (payload) =>
//   createAction(VendorActionTypes.LIST_ALL_AREAS_SUCCESS, payload);

// export const listAllAreasFailure = (error) =>
//   createAction(VendorActionTypes.LIST_ALL_AREAS_FAILURE, error);

// export const listAllDeliveryMehtodsStart = (payload) =>
//   createAction(VendorActionTypes.LIST_ALL_DELIVERY_METHODS_START, payload);

// export const listAllDeliveryMethodsSuccess = (payload) =>
//   createAction(VendorActionTypes.LIST_ALL_DELIVERY_METHODS_SUCCESS, payload);

// export const listAllDeliveryMethodsFailure = (error) =>
//   createAction(VendorActionTypes.LIST_ALL_DELIVERY_METHODS_FAILURE, error);

// export const listVendorAreasStart = () =>
//   createAction(VendorActionTypes.LIST_VENDOR_AREAS_START);

// export const listVendorAreasSuccess = (payload) =>
//   createAction(VendorActionTypes.LIST_VENDOR_AREAS_SUCCESS, payload);

// export const listVendorAreasFailure = (error) =>
//   createAction(VendorActionTypes.LIST_VENDOR_AREAS_FAILURE, error);

// export const updateVendorAreaStart = (payload) =>
//   createAction(VendorActionTypes.UPDATE_VENDOR_AREA_START, payload);

// export const updateVendorAreaSucces = (payload) =>
//   createAction(VendorActionTypes.UPDATE_VENDOR_AREA_SUCCESS, payload);

// export const updateVendorAreaFailure = (error) =>
//   createAction(VendorActionTypes.UPDATE_VENDOR_AREA_FAILURE, error);

// export const listALLCitiesStart = () =>
//   createAction(VendorActionTypes.LIST_ALL_CITIES_START);

// export const listALLCitiesSuccess = (payload) =>
//   createAction(VendorActionTypes.LIST_ALL_CITIES_SUCCESS, payload);

// export const listAllCitiesFailure = (error) =>
//   createAction(VendorActionTypes.LIST_ALL_CITIES_FAILURE, error);

// export const listAreasByCityStart = (payload) =>
//   createAction(VendorActionTypes.LIST_AREAS_BY_CITY_START, payload);

// export const listAreasByCitySuccess = (payload) =>
//   createAction(VendorActionTypes.LIST_AREAS_BY_CITY_SUCCESS, payload);

// export const listAreasByCityFailure = (error) =>
//   createAction(VendorActionTypes.LIST_AREAS_BY_CITY_FAILURE, error);
// All the way till here

export const listAllCountriesStart = () =>
  createAction(VendorActionTypes.LIST_ALL_COUNTRIES_START);

export const listAllCountriesSuccess = (payload) =>
  createAction(VendorActionTypes.LIST_ALL_COUNTRIES_SUCCESS, payload);

export const listAllCountriesFailure = (error) =>
  createAction(VendorActionTypes.LIST_ALL_COUNTRIES_FAILURE, error);

export const listAllCurrenciesStart = () =>
  createAction(VendorActionTypes.LIST_ALL_CURRENCIES_START);

export const listAllCurrenciesSuccess = (payload) =>
  createAction(VendorActionTypes.LIST_ALL_CURRENCIES_SUCCESS, payload);

export const listAllCurrenciesFailure = (error) =>
  createAction(VendorActionTypes.LIST_ALL_CURRENCIES_FAILURE, error);

export const listAllPayoutMethodsStart = () =>
  createAction(VendorActionTypes.LIST_ALL_PAYOUT_METHODS_START);

export const listAllPayoutMethodsSuccess = (payload) =>
  createAction(VendorActionTypes.LIST_ALL_PAYOUT_METHODS_SUCCESS, payload);

export const listAllPayoutMethodsFailure = (error) =>
  createAction(VendorActionTypes.LIST_ALL_PAYOUT_METHODS_FAILURE, error);
