import { useEffect, useState } from "react";

import { useLocation, useNavigate, useRoutes } from "react-router";
import { ToastContainer } from "react-toastify";

import { StorageUtils } from "utils";

import {
  GetNotificationCountStart,
  GetNotificationStart,
  setFCMStart,
} from "Redux/user/actions/user.actions";
import { GetToken, onMessageListener } from "utils/firebase/firebase.utils";
import { showToast, toastTypes } from "utils/helpers/Toast";
import Routes from "Routes";

import "react-toastify/dist/ReactToastify.css";
import {
  selectFCM,
  selectCurrentUser,
} from "Redux/user/selectors/user.selectors";
import { useDispatch, useSelector } from "react-redux";
import { selectVendorProfile } from "Redux/vendor/selectors/vendor.selectors";
import { listProfileStart } from "Redux/vendor/actions/vendor.actions";
import { set } from "lodash";

function App() {
  const dispatch = useDispatch();
  const [isToken, setToken] = useState(null);
  const currentUser = useSelector(selectCurrentUser);
  const FCMToken = useSelector(selectFCM);
  const vendorProfile = useSelector(selectVendorProfile);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [approved, setApproved] = useState(true);

  // useEffect(() => {
  //   if (vendorProfile?.extras?.account_status[2]) {
  //     setApproved(true);
  //   }
  // }, [vendorProfile]);

  // useEffect(() => {
  //   if (currentUser?.extras?.account_status[2]) {
  //     setApproved(true);
  //   } else {
  //     setApproved(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!approved) {
  //     if (currentUser) {
  //       dispatch(listProfileStart());
  //     }
  //     if (
  //       pathname === "/" ||
  //       pathname === "/who_we_are" ||
  //       pathname === "/help"
  //     ) {
  //       navigate("/under_review");
  //     } else {
  //       navigate(pathname);
  //     }
  //     // if (pathname === "/my_account") {
  //     //   navigate("/my_account");
  //     // } else if (pathname === "/settings") {
  //     //   navigate("/settings");
  //     // } else if (pathname === "/edit_profile") {
  //     //   navigate("/edit_profile");
  //     // } else if (pathname === "/login") {
  //     //   navigate("/login");
  //     // } else {
  //     //   navigate("/Under_Review");
  //     // }
  //   }
  //   console.log("testing logic", vendorProfile);
  // }, [pathname]);

  useEffect(() => {
    GetToken(setToken);
    if (isToken && currentUser && isToken != FCMToken) {
      dispatch(setFCMStart({ token: isToken }));
    }
  }, [isToken, currentUser]);

  onMessageListener()
    .then((payload) => {
      showToast(toastTypes.SUC, payload.notification.title);
      console.log(payload);
      dispatch(GetNotificationCountStart());
      dispatch(GetNotificationStart());
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    const currentLang = StorageUtils.getItems(localStorage, ["i18nextLng"])[
      "i18nextLng"
    ];
    document.body.dir = currentLang === "ar" ? "rtl" : "ltr";
    document.body.lang = currentLang;
  }, []);

  const appRoutes = useRoutes(Routes);

  return (
    <div className="font-Raleway">
      <ToastContainer />
      {appRoutes}
    </div>
  );
}

export default App;
