import * as yup from "yup";

import { Circle, Line } from "rc-progress";
import { CustomButton, Img, Spinner, Typography } from "components/atoms";
import { Days, hours } from "utils/helpers/ArrayHelper";
import {
  DeliveryAreas,
  MapLocationModal,
  RadioGroup,
  SuccessConfirmationModal,
} from "components/organisms";
import { DropDownInput, FormInput } from "components/molecules";
import { Location, PlusSign, Upload } from "utils/helpers/SVGsSrc";
import { getIn, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";

import { StorageUtils } from "utils";
import { listAreasByCityStart } from "Redux/vendor/actions/vendor.actions";
import { useDispatch } from "react-redux";

//website regex for validation
const regMatch =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

const validationSchema = yup.object().shape({
  email: yup.string().email("يرجى إدخال البريد الإلكتروني الصحيح"),
  website: yup.string().matches(regMatch, "الرجاء إدخال موقع ويب صالح"),
  en: yup.object().shape({
    vendor_name_en: yup
      .string()
      .required("الرجاء إدخال اسم البائع باللغة الإنجليزية"),
    description_en: yup
      .string()
      .required("الرجاء إدخال وصف البائع باللغة الإنجليزية"),
    display_name_en: yup
      .string()
      .required("الرجاء إدخال اسم البائع المعروض باللغة الإنجليزية"),
  }),
  ar: yup.object().shape({
    vendor_name_ar: yup
      .string()
      .required("الرجاء إدخال اسم البائع بالعربية ثم بالإنجليزية"),
    description_ar: yup
      .string()
      .required("الرجاء إدخال وصف البائع بالعربية ثم بالإنجليزية"),
    display_name_ar: yup
      .string()
      .required("الرجاء إدخال اسم البائع المعروض بالعربية ثم بالإنجليزية"),
  }),
});

function EditProfileTemplate({
  EditProfileHandler,
  closeConfirmation,
  selectedVendorId,
  isVendorLoading,
  isVendorUpdated,
  setImagePercent,
  setImagePicture,
  mainCategories,
  setCLickedName,
  setLogoPercent,
  setLogoPicture,
  onImageChange,
  ImagePercent,
  ImagePicture,
  onLogoChange,
  servingTypes,
  LogoPercent,
  LogoPicture,
  ProfileData,
  setLanguage,
  ImageUrl,
  language,
  LogoUrl,
  countries,
  currencies,
  payoutMethods,
  areas,
  deliveryMethods,
  vendorAreas,
  handleUpdateVendorDeliveryAreas,
  cities,
  areasByCity,
}) {
  const dispatch = useDispatch();
  const inputLogoFile = useRef(null);
  const inputImageFile = useRef(null);
  const [IsMap, setIsMap] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [ServingChoices, setServingChoices] = useState([]);
  const [MainCategoryChoices, setMainCategoryChoices] = useState([]);
  const [selectedWorkingDays, setSelectedWorkingDays] = useState([]);
  const [fromTo, setFromTo] = useState([{}, {}, {}, {}, {}, {}, {}]);
  const workingDays = [
    {
      week_day_id: 1,
      from: "0",
      to: "0",
      is_working_day: false,
    },
    {
      week_day_id: 2,
      from: "0",
      to: "0",
      is_working_day: false,
    },
    {
      week_day_id: 3,
      from: "0",
      to: "0",
      is_working_day: false,
    },
    {
      week_day_id: 4,
      from: "0",
      to: "0",
      is_working_day: false,
    },
    {
      week_day_id: 5,
      from: "0",
      to: "0",
      is_working_day: false,
    },
    {
      week_day_id: 6,
      from: "0",
      to: "0",
      is_working_day: false,
    },
    {
      week_day_id: 7,
      from: "0",
      to: "0",
      is_working_day: false,
    },
  ];

  const formik = useFormik({
    initialValues: {
      initial_cost: 0,
      max_delivery_distance: "",
      delivery_ranges_attributes: [],
      country: null,
      currency: null,
      payoutMethods: null,
      iban: "",
      swift_code: "",
      bank_name: "",
      bank_address: "",
      beneficiary_account_number: "",
      beneficiary_address: "",
      areas: [],
      en: {
        vendor_name_en: "",
        description_en: "",
        display_name_en: "",
        beneficiary_name_en: "",
      },
      ar: {
        vendor_name_ar: "",
        description_ar: "",
        display_name_ar: "",
        beneficiary_name_ar: "",
      },
      area_id: "",
      city_id: "",
      area_name: "",
      city_name: "",
      logo: "",
      image: "",
      full_name: "",
      vendor_type: "",
      mobile_number: "",
      website: "",
      address: "",
      manual_address: "",
      email: "",
      minimum_order: "",
      auto_accept_orders: "",
      does_delivery: "",
      allow_pickup: "",
      accept_online_payment: "",
      delivery_details: { delivery_fee: "" },
      lng: JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )?.lng,
      lat: JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )?.lat,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      EditProfileHandler({
        ...values,
        vendor_working_times: selectedWorkingDays,
        serving_type_ids: ServingChoices?.map((item) => item.value),
        main_category_ids: MainCategoryChoices?.map((item) => item.value),
        admin_vendor_id: selectedVendorId,
      });
    },
  });

  const [addressName, setAddressName] = useState("");

  useEffect(() => {
    if (isVendorUpdated) setIsUpdated(true);
  }, [isVendorLoading, isVendorUpdated]);

  useEffect(() => {}, [selectedWorkingDays]);

  useEffect(() => {
    formik.setFieldValue("logo", LogoUrl);
    formik.setFieldValue("image", ImageUrl);
  }, [LogoUrl, ImageUrl]);

  useEffect(() => {
    if (vendorAreas) {
      // const filteredVendorAreas = vendorAreas.map((area) => {
      //   return {
      //     id: area.id,
      //     area_id: area.area_id,
      //     delivery_third_party_id: area.delivery_third_party_id,
      //     vendor_delivery_price: area.vendor_delivery_price,
      //   };
      // });
      formik.setFieldValue("areas", vendorAreas);
    }
  }, [vendorAreas]);
  console.log(formik.values.areas);

  useEffect(() => {
    if (ProfileData) {
      if (ProfileData?.logo) {
        formik.setFieldValue("logo", ProfileData?.logo);
        setLogoPicture(ProfileData?.logo);
      }
      if (ProfileData?.image) {
        formik.setFieldValue("image", ProfileData?.image);
        setImagePicture(ProfileData?.image);
      }
      if (ProfileData?.extras?.vendor_name_ar === null) {
        formik.setFieldValue("ar.vendor_name_ar", "");
      } else {
        formik.setFieldValue(
          "ar.vendor_name_ar",
          ProfileData?.extras?.vendor_name_ar
        );
      }
      if (ProfileData?.description_ar === null) {
        formik.setFieldValue("ar.description_ar", "");
      } else {
        formik.setFieldValue("ar.description_ar", ProfileData?.description_ar);
      }
      if (ProfileData?.display_name_ar === null) {
        formik.setFieldValue("ar.display_name_ar", "");
      } else {
        formik.setFieldValue(
          "ar.display_name_ar",
          ProfileData?.display_name_ar
        );
      }

      if (ProfileData?.extras?.vendor_name_en === null) {
        formik.setFieldValue("en.vendor_name_en", "");
      } else {
        formik.setFieldValue(
          "en.vendor_name_en",
          ProfileData?.extras?.vendor_name_en
        );
      }
      if (ProfileData?.description_en === null) {
        formik.setFieldValue("en.description_en", "");
      } else {
        formik.setFieldValue("en.description_en", ProfileData?.description_en);
      }
      if (ProfileData?.display_name_en === null) {
        formik.setFieldValue("en.display_name_en", "");
      } else {
        formik.setFieldValue(
          "en.display_name_en",
          ProfileData?.display_name_en
        );
      }
      formik.setFieldValue("city_name", ProfileData?.city_name_ar);
      formik.setFieldValue("city_id", ProfileData?.city_id);
      formik.setFieldValue("area_name", ProfileData?.area_name_ar);
      formik.setFieldValue("area_id", ProfileData?.area_id);
      formik.setFieldValue("full_name", ProfileData?.extras?.full_name);
      formik.setFieldValue(
        "vendor_type",
        ProfileData?.extras?.vendor_type
          ? parseInt(Object.keys(ProfileData?.extras?.vendor_type)?.at(0))
          : ""
      );
      formik.setFieldValue("mobile_number", ProfileData?.mobile_number);
      formik.setFieldValue("website", ProfileData?.website);
      formik.setFieldValue("address", ProfileData?.address);
      formik.setFieldValue("manual_address", ProfileData?.manual_address);
      formik.setFieldValue("email", ProfileData?.email);
      formik.setFieldValue("minimum_order", ProfileData?.minimum_order);
      formik.setFieldValue(
        "auto_accept_orders",
        ProfileData?.extras?.auto_accept_orders
      );
      formik.setFieldValue("does_delivery", ProfileData?.does_delivery);
      formik.setFieldValue(
        "allow_pickup",
        ProfileData?.pickup_details?.is_pickup_available
      );

      formik.setFieldValue(
        "accept_online_payment",
        ProfileData?.accept_online_payment
      );
      formik.setFieldValue("delivery_details", ProfileData?.delivery_details);
      setSelectedWorkingDays([
        ...workingDays?.filter((itemA) => {
          return !ProfileData?.working_times.find((itemB) => {
            return itemA.week_day_id == itemB.week_day_id;
          });
        }),
        ...ProfileData?.working_times
          ?.filter((itemA) => {
            return workingDays.find((itemB) => {
              return itemA.week_day_id == itemB.week_day_id;
            });
          })
          .map((item) => ({
            id: item.id,
            week_day_id: item.week_day_id,
            from: item.from,
            to: item.to,
            is_working_day: item.is_working_day,
          })),
      ]);
      setServingChoices(
        ProfileData?.serving_types?.map((foodType) => ({
          value: foodType?.id,
          label: foodType?.name,
        }))
      );
      setMainCategoryChoices(
        ProfileData?.main_categories?.map((category) => ({
          value: category?.id,
          label: category?.name,
        }))
      );
      formik.setFieldValue("lat", ProfileData?.lat);
      formik.setFieldValue("lng", ProfileData?.lng);
      formik.setFieldValue(
        "delivery_ranges_attributes",
        ProfileData?.delivery_ranges
      );
      formik.setFieldValue(
        "max_delivery_distance",
        ProfileData?.max_delivery_distance
      );
      if (ProfileData?.delivery_ranges) {
        formik.setFieldValue(
          "initial_cost",
          ProfileData?.delivery_ranges[0]?.initial_cost
        );
      }
      formik.setFieldValue("country", {
        value: ProfileData?.country?.id,
        label: ProfileData?.country?.name_ar,
      });
      formik.setFieldValue("currency", {
        value: ProfileData?.currency?.id,
        label: ProfileData?.currency?.name_ar,
      });
      formik.setFieldValue("payoutMethods", {
        value: ProfileData?.vendor_payout_method?.id,
        label: ProfileData?.vendor_payout_method?.name_ar,
      });
      formik.setFieldValue("iban", ProfileData?.iban);
      formik.setFieldValue("swift_code", ProfileData?.swift_code);
      formik.setFieldValue("bank_name", ProfileData?.bank_name);
      formik.setFieldValue("bank_address", ProfileData?.bank_address);
      formik.setFieldValue(
        "beneficiary_account_number",
        ProfileData?.beneficiary_account_number
      );
      formik.setFieldValue(
        "beneficiary_name_en",
        ProfileData?.beneficiary_name_en
      );
      formik.setFieldValue(
        "beneficiary_address",
        ProfileData?.beneficiary_address
      );
    }
  }, [ProfileData]);

  useEffect(() => {
    const newRanges = formik.values.delivery_ranges_attributes.map((range) => {
      return { ...range, initial_cost: formik.values.initial_cost };
    });
    console.log(newRanges);
    formik.setFieldValue("delivery_ranges_attributes", newRanges);
  }, [formik.values.initial_cost]);
  // Handling Choices
  const [ServingsTypes, setServingsTypes] = useState([]);
  const [Categories, setCategories] = useState([]);

  useEffect(() => {
    const ServingType = servingTypes?.map((foodType) => ({
      value: foodType?.value,
      label: foodType?.label,
    }));
    const categoryTypes = mainCategories?.map((category) => ({
      value: category?.value,
      label: category?.label,
    }));
    setServingsTypes(ServingType);
    setCategories(categoryTypes);
  }, [mainCategories, servingTypes]);

  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-end">
        <DropDownInput
          styles={{
            option: (base, { isFocused }) => ({
              ...base,
              color: "#707070",
              fontSize: "18px",
              fontWeight: "500",
              backgroundColor: isFocused ? "#cbcbcb" : null,
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: "#777",
            }),
            // Language dropdown styles
            menu: (base) => ({
              ...base,
              backgroundColor: "#f8f8fe",
              border: 0,
            }),
            menuList: (base) => ({
              ...base,
              border: 0,
            }),

            control: (base) => ({
              ...base,
              flexDirection: "row",
              width: "10.938rem",
              height: "3.938rem",
              backgroundColor: "#f8f8fe",
              border: 0,
              boxShadow: "none",
            }),
            menuList: (base) => ({ ...base, border: "0px" }),
            placeholder: (base) => ({
              ...base,
              color: "#707070",
              fontWeight: "350",
              fontSize: "22px",
              fontStretch: "normal",
              fontStyle: "normal",
            }),
          }}
          searchable={false}
          closeOnSelect={true}
          name="menu"
          id="menu"
          options={[
            { value: 1, label: "العربية" },
            { value: 2, label: "الإنجليزية" },
          ]}
          width="w-full"
          placeholder="اللغة"
          defaultValue={{ value: 1, label: "العربية" }}
          onValueChange={(e) => {
            setLanguage(e.value);
          }}
        />
      </div>
      {!isVendorLoading ? (
        <>
          <div className="w-full mt-10 pb-6 bg-text_f8f8fe text-right ">
            <div className="px-6 py-10 w-full flex flex-col items-center ">
              {LogoPercent === 0 || LogoPercent === 100 ? (
                <>
                  {" "}
                  {ImagePicture ? (
                    <Img
                      fileName={ImagePicture}
                      containerClasses="cursor-pointer w-full h-96 relative"
                      action={() => {
                        setCLickedName("image");
                        inputImageFile.current.click();
                      }}
                    />
                  ) : (
                    <div className="w-full h-96 relative"></div>
                  )}
                  <Img
                    fileName={LogoPicture}
                    containerClasses={
                      "md:h-[150px] h-[80px] cursor-pointer absolute mt-20"
                    }
                    action={() => {
                      setCLickedName("logo");
                      inputLogoFile.current.click();
                    }}
                  />
                  <input
                    type="file"
                    id="file"
                    ref={inputLogoFile}
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg, image/png, image/webp, image/gif"
                    onChange={(event) => {
                      onLogoChange(event);
                    }}
                  />
                  <CustomButton
                    height="h-10"
                    label={"تغيير الشعار"}
                    // boxShadowClass={"goBoxShadow"}
                    // buttonTypeClass="outlined"
                    // textColor="text-primary-900"
                    // borderColor="border-primary-900"
                    // classes="bg-[#ffffff] border-2"
                    classes=" bg-transparent text-primary-900"
                    action={() => {
                      setCLickedName("logo");
                      inputLogoFile.current.click();
                    }}
                  />
                </>
              ) : (
                <div className="mx-6">
                  <Typography
                    as="span"
                    size="md"
                    color="text-primary-900"
                    lineHeight="leading-[1.21]"
                    classes="flex justify-center"
                    text={LogoPercent + "%"}
                  />
                  <div className="flex justify-center">
                    <Circle
                      percent={LogoPercent}
                      strokeWidth={2}
                      strokeColor="#D3D3D3"
                      className="w-20 h-20"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mx-6">
              {ImagePercent === 0 || ImagePercent === 100 ? (
                <div className="w-full pt-6">
                  <input
                    type="file"
                    id="file"
                    ref={inputImageFile}
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg, image/png, image/webp, image/gif"
                    onChange={(event) => {
                      onImageChange(event);
                    }}
                  />
                  <div className="flex justify-between items-center border-b-warm_grey/50 border-b-[1px]">
                    <Typography
                      as="span"
                      size="2xl"
                      color="text-warm_grey"
                      lineHeight="leading-[1.21]"
                      text={"الصورة"}
                      action={() => {
                        setCLickedName("image");
                        inputImageFile.current.click();
                      }}
                    />
                    <div className="flex items-center">
                      {/* <CustomButton
                        height="h-10"
                        label={"عرض"}
                        classes="mt-3 bg-transparent text-primary-900 px-2"
                        action={() => {
                          // image preview
                        }}
                      /> */}
                      <CustomButton
                        height="h-10"
                        label={"رفع صورة الخلفية"}
                        classes="mt-3 bg-transparent text-primary-900 px-2"
                        BeforeIcon={<Upload className="mx-2 w-[13px]" />}
                        action={() => {
                          setCLickedName("image");
                          inputImageFile.current.click();
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mx-6">
                  <Typography
                    as="span"
                    size="2xl"
                    color="text-primary-900"
                    lineHeight="leading-[1.21]"
                    classes="flex justify-center py-3"
                    text={ImagePercent + "%"}
                  />
                  <Line
                    percent={ImagePercent}
                    strokeWidth={1}
                    strokeColor="#bf0022"
                    className="w-50"
                  />
                </div>
              )}
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label="إسم المطعم"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                id="vendor_name"
                name="vendor_name"
                width="w-full"
                height="h-9"
                onValueChange={(e) => {
                  if (language === 1) {
                    formik.setFieldValue("ar.vendor_name_ar", e.target.value);
                  } else {
                    formik.setFieldValue("en.vendor_name_en", e.target.value);
                  }
                }}
                value={
                  language === 1
                    ? formik.values.ar.vendor_name_ar
                    : formik.values.en.vendor_name_en
                }
                error={
                  formik.values.ar.vendor_name_ar
                    ? getIn(formik.touched, "en.vendor_name_en") &&
                      getIn(formik.errors, "en.vendor_name_en")
                    : getIn(formik.touched, "ar.vendor_name_ar") &&
                      getIn(formik.errors, "ar.vendor_name_ar")
                }
              />
            </div>
            <div className="mx-6 my-6">
              <FormInput
                label="الوصف"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                id="description"
                name="description"
                width="w-full"
                height="h-32"
                type="textarea"
                onValueChange={(e) => {
                  if (language == 1) {
                    formik.setFieldValue("ar.description_ar", e.target.value);
                  } else {
                    formik.setFieldValue("en.description_en", e.target.value);
                  }
                }}
                value={
                  language === 1
                    ? formik.values.ar.description_ar
                    : formik.values.en.description_en
                }
                error={
                  formik.values.ar.description_ar
                    ? getIn(formik.touched, "en.description_en") &&
                      getIn(formik.errors, "en.description_en")
                    : getIn(formik.touched, "ar.description_ar") &&
                      getIn(formik.errors, "ar.description_ar")
                }
              />
            </div>
          </div>
          <div className="w-full mt-10 pb-6 bg-text_f8f8fe text-right">
            <div className="mx-6 pt-6">
              <FormInput
                label="إسم الشخص المسئول"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                id="full_name"
                name="full_name"
                width="w-full"
                height="h-9"
                onValueChange={(e) => {
                  formik.setFieldValue("full_name", e.target.value);
                }}
                value={formik.values.full_name}
              />
            </div>
            <div className="mx-6 my-6">
              <Typography
                as="span"
                size="2xl"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                text={"نوع العمل"}
              />
              <RadioGroup
                containerClasses={"flex"}
                itemWidth="w-[30%]"
                name={"vendor_type"}
                id={"vendor_type"}
                options={[
                  { id: 0, name: "عمل فردي" },
                  { id: 1, name: "مؤسسة" },
                ]}
                state={formik.values.vendor_type}
                onValueChange={(e) => {
                  formik.setFieldValue("vendor_type", parseInt(e.target.value));
                }}
              />
            </div>
            <div className="mx-6 my-6">
              <DropDownInput
                label="نوع الخدمة التي تقدمها"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                placeholder={"اختر"}
                width="w-full"
                height="h-10"
                classes="basic-multi-select"
                classNamePrefix="select"
                isMulti={true}
                options={ServingsTypes}
                value={ServingChoices}
                onValueChange={(e) => {
                  setServingChoices(e);
                }}
              />
            </div>
            <div className="mx-6 my-6">
              <DropDownInput
                label="نوع الطعام الذي تقدمه"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                placeholder={"اختر"}
                width="w-full"
                height="h-10"
                classes="basic-multi-select"
                classNamePrefix="select"
                isMulti={true}
                options={Categories}
                value={MainCategoryChoices}
                onValueChange={(e) => {
                  setMainCategoryChoices(e);
                }}
              />
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label={"إسم المطعم الذي يظهر للمستخدمين"}
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                id="display_name"
                name="display_name"
                width="w-full"
                height="h-9"
                onValueChange={(e) => {
                  if (language == 1) {
                    formik.setFieldValue("ar.display_name_ar", e.target.value);
                  } else {
                    formik.setFieldValue("en.display_name_en", e.target.value);
                  }
                }}
                value={
                  language == 1
                    ? formik.values.ar.display_name_ar
                    : formik.values.en.display_name_en
                }
                error={
                  formik.values.ar.display_name_ar
                    ? getIn(formik.touched, "en.display_name_en") &&
                      getIn(formik.errors, "en.display_name_en")
                    : getIn(formik.touched, "ar.display_name_ar") &&
                      getIn(formik.errors, "ar.display_name_ar")
                }
              />
            </div>
          </div>
          <Typography
            as="h1"
            size="2xl"
            color="text-text_464646"
            fontWeight="font-bold"
            lineHeight="leading-[2]"
            classes={"mt-10 text-right px-6 md:p-0"}
            text={"تعديل بيانات الإتصال"}
          />
          <div className="w-full mt-10 pb-6 bg-text_f8f8fe text-right">
            <div className="mx-6 pt-6">
              <FormInput
                font="font-SFProText"
                label="رقم الهاتف"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                id="mobile_number"
                name="mobile_number"
                width="w-full"
                height="h-9"
                onValueChange={(e) => {
                  formik.setFieldValue("mobile_number", e.target.value);
                }}
                value={formik.values.mobile_number}
              />
            </div>
            <div
              className={
                !formik.values.area ? " mx-6 pt-6 border-b-2" : "mx-6 pt-6"
              }
              onClick={() => {
                setIsMap(!IsMap);
              }}
            >
              {!formik.values.lng && !formik.values.lat ? (
                <div className="flex justify-between h-full">
                  <Typography
                    as="h1"
                    size="2xl"
                    color="text-warm_grey"
                    lineHeight="leading-[2]"
                    classes={"mt-10 text-right px-6 md:p-0 cursor-pointer"}
                    text={"حدد الموقع"}
                  />
                  <Location className="mt-12 cursor-pointer" />
                </div>
              ) : (
                <FormInput
                  label="الموقع"
                  labelSize={"2xl"}
                  textColor={"text-warm_grey"}
                  borderColor="border-warm_grey/50"
                  width="w-full"
                  height="h-9"
                  // onValueChange={(e) => {
                  //   formik.setFieldValue("area", e.target.value);
                  // }}
                  readOnly
                  value={formik.values.address}
                />
              )}
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label="العنوان"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                id="address"
                name="address"
                width="w-full"
                height="h-9"
                onValueChange={(e) => {
                  formik.setFieldValue("manual_address", e.target.value);
                }}
                value={formik.values.manual_address}
              />
            </div>

            <div className="mx-6 pt-6">
              <FormInput
                label="البريد الإلكتروني"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                id="email"
                name="email"
                width="w-full"
                height="h-9"
                onValueChange={(e) => {
                  formik.setFieldValue("email", e.target.value);
                }}
                value={formik.values.email}
                error={
                  getIn(formik.touched, "email") &&
                  getIn(formik.errors, "email")
                }
              />
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label="موقع الكتروني"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                id="website"
                name="website"
                width="w-full"
                height="h-9"
                onValueChange={(e) => {
                  formik.setFieldValue("website", e.target.value);
                }}
                value={formik.values.website}
                error={
                  getIn(formik.touched, "website") &&
                  getIn(formik.errors, "website")
                }
              />
            </div>
          </div>
          <Typography
            as="h1"
            size="2xl"
            color="text-text_464646"
            fontWeight="font-bold"
            lineHeight="leading-[2]"
            classes={"mt-10 text-right px-6 md:p-0"}
            text={"تعديل بيانات الدفع"}
          />
          <div className="w-full mt-10 pb-6 bg-text_f8f8fe text-right">
            <div className="mx-6 pt-6">
              <FormInput
                label="إسم البنك"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                width="w-full"
                height="h-9"
                value={formik.values.bank_name}
                onValueChange={(e) => {
                  formik.setFieldValue("bank_name", e.target.value);
                }}
              />
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label="عنوان البنك"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                width="w-full"
                height="h-9"
                value={formik.values.bank_address}
                onValueChange={(e) => {
                  formik.setFieldValue("bank_address", e.target.value);
                }}
              />
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label="رمز SWIFT"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                width="w-full"
                height="h-9"
                value={formik.values.swift_code}
                onValueChange={(e) => {
                  formik.setFieldValue("swift_code", e.target.value);
                }}
              />
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label="IBAN"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                width="w-full"
                height="h-9"
                value={formik.values.iban}
                onValueChange={(e) => {
                  formik.setFieldValue("iban", e.target.value);
                }}
              />
            </div>
            <div className="mx-6 my-6">
              <DropDownInput
                classes="font-SFProText"
                label="العملة"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                searchable={false}
                closeOnSelect={true}
                styles={{
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#777",
                  }),
                }}
                value={formik.values.currency}
                placeholder="اختر العملة"
                options={currencies}
                onValueChange={(e) => {
                  formik.setFieldValue("currency", e);
                }}
              />
            </div>
            <div className="mx-6 ">
              <FormInput
                label="رقم حساب المستفيد"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                width="w-full"
                height="h-9"
                value={formik.values.beneficiary_account_number}
                onValueChange={(e) => {
                  formik.setFieldValue(
                    "beneficiary_account_number",
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label="أسم المستفيد"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                width="w-full"
                height="h-9"
                value={formik.values.en.beneficiary_name_en}
                onValueChange={(e) => {
                  formik.setFieldValue(
                    "en.beneficiary_name_en",
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label="عنوان المستفيد"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                width="w-full"
                height="h-9"
                value={formik.values.beneficiary_address}
                onValueChange={(e) => {
                  formik.setFieldValue("beneficiary_address", e.target.value);
                }}
              />
            </div>
            {/* <div className="mx-6 pt-6">
              <FormInput
                label="إسم البنك"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                width="w-full"
                height="h-9"
                // onValueChange={(e) => {
                //   formik.setFieldValue("email", e.target.value);
                // }}
                // value={formik.values.email}
              />
            </div>
            <div className="mx-6 pt-6">
              <FormInput
                label="رقم الحساب البنكي"
                font="font-SFProText"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                width="w-full"
                height="h-9"
                // onValueChange={(e) => {
                //   formik.setFieldValue("email", e.target.value);
                // }}
                // value={formik.values.email}
              />
            </div> */}

            <div className="mx-6 my-6">
              <DropDownInput
                classes="font-SFProText"
                label="طريقة الدفع"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                searchable={false}
                closeOnSelect={true}
                styles={{
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#777",
                  }),
                }}
                value={formik.values.payoutMethods}
                placeholder="اختر طريقة الدفع"
                options={payoutMethods}
                onValueChange={(e) => {
                  formik.setFieldValue("payoutMethods", e);
                }}
              />
            </div>

            <div className="mx-6 my-6">
              <DropDownInput
                classes="font-SFProText"
                label="البلد"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                searchable={false}
                closeOnSelect={true}
                styles={{
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#777",
                  }),
                }}
                value={formik.values.country}
                placeholder="اختر البلد"
                options={countries}
                onValueChange={(e) => {
                  formik.setFieldValue("country", e);
                }}
              />
            </div>
          </div>
          <div className="w-full mt-10 pb-6 bg-text_f8f8fe text-right pt-10 pr-10">
            <Typography
              as="span"
              size="2xl"
              color="text-warm_grey"
              lineHeight="leading-[1.21]"
              text={"أيام العمل"}
            />
            <div className="pt-6 mx-6 w-full flex flex-wrap mt">
              {Days.map((item, index) => (
                <div className="w-[35%] ">
                  <FormInput
                    checked={
                      selectedWorkingDays?.find(
                        (data) => data.week_day_id == item.id
                      )?.is_working_day
                    }
                    borderColor="border-warm_grey/50"
                    id={item?.id}
                    name={"days"}
                    fullWidth={false}
                    type="checkbox"
                    width="w-5"
                    height="h-5"
                    containerClasses="selectInputs"
                    inputTypeClass="solidCheckbox"
                    label={item?.name}
                    value={item?.id}
                    onValueChange={(e) => {
                      setSelectedWorkingDays(
                        selectedWorkingDays?.find(
                          (data) => data.week_day_id == e.target.value
                        )?.is_working_day
                          ? [
                              ...selectedWorkingDays?.filter(
                                (days) => days.week_day_id != e.target.value
                              ),
                              {
                                ...selectedWorkingDays?.find(
                                  (data) => data.week_day_id == e.target.value
                                ),
                                is_working_day: false,
                              },
                            ]
                          : [
                              ...selectedWorkingDays?.filter(
                                (days) => days.week_day_id != e.target.value
                              ),
                              {
                                ...selectedWorkingDays?.find(
                                  (data) => data.week_day_id == e.target.value
                                ),
                                is_working_day: true,
                              },
                            ]
                      );
                    }}
                    labelSize="2xl"
                    textColor="text-text_777777"
                  />
                  {selectedWorkingDays?.find(
                    (data) => data.week_day_id == item.id
                  )?.is_working_day && (
                    <div className="w-full flex justify-around">
                      <DropDownInput
                        classes="font-SFProText"
                        containerClasses={"!flex-row items-center"}
                        labelStyle="mx-2"
                        width={"w-[100px]"}
                        label="من"
                        labelSize={"md"}
                        textColor={"text-warm_grey"}
                        searchable={true}
                        closeOnSelect={true}
                        styles={{
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#777",
                          }),
                        }}
                        placeholder="1"
                        options={hours}
                        value={{
                          value: selectedWorkingDays?.find(
                            (data) => data.week_day_id == item.id
                          )?.from,
                          label: selectedWorkingDays?.find(
                            (data) => data.week_day_id == item.id
                          )?.from,
                        }}
                        onValueChange={(e) => {
                          setSelectedWorkingDays([
                            ...selectedWorkingDays?.filter(
                              (data) => data.week_day_id != item.id
                            ),
                            {
                              ...selectedWorkingDays?.find(
                                (data) => data.week_day_id == item.id
                              ),
                              from: e.value,
                            },
                          ]);
                        }}
                      />
                      <DropDownInput
                        containerClasses={"!flex-row items-center"}
                        labelStyle="mx-2"
                        label="إلي"
                        width={"w-[100px]"}
                        labelSize={"md"}
                        textColor={"text-warm_grey"}
                        searchable={true}
                        closeOnSelect={true}
                        styles={{
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#777",
                          }),
                        }}
                        placeholder="1"
                        options={hours}
                        value={{
                          value: selectedWorkingDays?.find(
                            (data) => data.week_day_id == item.id
                          )?.to,
                          label: selectedWorkingDays?.find(
                            (data) => data.week_day_id == item.id
                          )?.to,
                        }}
                        onValueChange={(e) => {
                          setSelectedWorkingDays([
                            ...selectedWorkingDays?.filter(
                              (data) => data.week_day_id != item.id
                            ),
                            {
                              ...selectedWorkingDays?.find(
                                (data) => data.week_day_id == item.id
                              ),
                              to: e.value,
                            },
                          ]);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="mx-6 pt-6">
              <FormInput
                label="الحد الأدنى للطلبات"
                font="font-SFProText"
                labelSize={"2xl"}
                textColor={"text-warm_grey"}
                borderColor="border-warm_grey/50"
                id="minimum_order"
                name="minimum_order"
                width="w-full"
                height="h-9"
                onValueChange={(e) => {
                  formik.setFieldValue("minimum_order", e.target.value);
                }}
                value={formik.values.minimum_order}
              />
            </div>
          </div>
          <div className="w-full mt-10 bg-text_f8f8fe text-right">
            <div className="mx-6 pt-6">
              <Typography
                as="span"
                size="2xl"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                text={"هل ترغب في قبول الطلبات تلقائيا ؟"}
              />
              <RadioGroup
                containerClasses={"flex"}
                itemWidth="w-[30%]"
                name={"type"}
                id={"type"}
                options={[
                  { id: 1, name: "نعم" },
                  { id: 2, name: "لا" },
                ]}
                state={formik.values.auto_accept_orders ? 1 : 2}
                onValueChange={(e) => {
                  formik.setFieldValue(
                    "auto_accept_orders",
                    e.target.value == 1 ? true : false
                  );
                }}
              />
            </div>
            <div className="mx-6 pt-6">
              <Typography
                as="span"
                size="2xl"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                text={"هل تقبل الدفع الإلكتروني ؟"}
              />
              <RadioGroup
                containerClasses={"flex"}
                itemWidth="w-[30%]"
                name={"accept_online_payment"}
                id={"accept_online_payment"}
                options={[
                  { id: 1, name: "نعم" },
                  { id: 2, name: "لا" },
                ]}
                state={formik.values.accept_online_payment ? 1 : 2}
                onValueChange={(e) => {
                  formik.setFieldValue(
                    "accept_online_payment",
                    e.target.value == 1 ? true : false
                  );
                }}
              />
            </div>
            <div className="mx-6 pt-6">
              <Typography
                as="span"
                size="2xl"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                text={"هل ترغب في تسليم الطلبات للعميل من موقعك ؟"}
              />
              <RadioGroup
                containerClasses={"flex"}
                itemWidth="w-[30%]"
                name={"allow_pickup"}
                id={"allow_pickup"}
                options={[
                  { id: 1, name: "نعم" },
                  { id: 2, name: "لا" },
                ]}
                state={formik.values.allow_pickup ? 1 : 2}
                onValueChange={(e) => {
                  formik.setFieldValue(
                    "allow_pickup",
                    e.target.value == 1 ? true : false
                  );
                }}
              />
            </div>
            <div className="mx-6 pt-6">
              <Typography
                as="span"
                size="2xl"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                text={"هل تقدم خدمة التوصيل؟"}
              />
              <RadioGroup
                containerClasses={"flex"}
                itemWidth="w-[30%]"
                name={"does_delivery"}
                id={"does_delivery"}
                options={[
                  { id: 1, name: "نعم" },
                  { id: 2, name: "لا" },
                ]}
                state={formik.values.does_delivery ? 1 : 2}
                onValueChange={(e) => {
                  formik.setFieldValue(
                    "does_delivery",
                    e.target.value == 1 ? true : false
                  );
                }}
              />
              <div className="mb-4">
                {formik.values.does_delivery ? (
                  <FormInput
                    font="font-SFProText"
                    label=" رسوم التوصيل الأولية"
                    labelSize={"2xl"}
                    textColor={"text-warm_grey"}
                    borderColor="border-warm_grey/50"
                    id="minimum_order"
                    name="minimum_order"
                    width="w-full"
                    height="h-9"
                    value={formik.values.initial_cost}
                    onValueChange={(e) =>
                      formik.setFieldValue(
                        "initial_cost",
                        Number(e.target.value)
                      )
                    }
                  />
                ) : (
                  <FormInput
                    font="font-SFProText"
                    label="أقصى عدد كيلومترات يتم التوصيل إليه"
                    labelSize={"2xl"}
                    textColor={"text-warm_grey"}
                    borderColor="border-warm_grey/50"
                    id="minimum_order"
                    name="minimum_order"
                    width="w-full"
                    height="h-9"
                    value={formik.values.max_delivery_distance}
                    onValueChange={(e) =>
                      formik.setFieldValue(
                        "max_delivery_distance",
                        Number(e.target.value)
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
          {formik.values.does_delivery ? (
            formik.values.delivery_ranges_attributes?.map(
              (deliveryRange, index) => {
                if (deliveryRange?._destroy) {
                  return <> </>;
                } else {
                  return (
                    <DeliveryAreas
                      doesDelivery={ProfileData?.does_delivery}
                      id={index}
                      formik={formik}
                      deliveryRange={deliveryRange}
                      areas={areas}
                      deliveryMethods={deliveryMethods}
                    />
                  );
                }
              }
            )
          ) : (
            <></>
          )}
          {formik.values.does_delivery ? (
            <div className="mt-8 mb-10 flex justify-items-start">
              <div className="ml-4 mr-6  mt-1">
                <PlusSign
                  height="100%"
                  width="100%"
                  className="w-5 h-5 cursor-pointer"
                  onClick={() =>
                    formik.setFieldValue("delivery_ranges_attributes", [
                      ...formik.values.delivery_ranges_attributes,
                      {
                        delivery_type: "now", //now or later
                        fixed_price: true, //true or false
                        price: 0, //float
                        from_radius: "",
                        radius: 0, //float
                        initial_cost: formik.values.initial_cost, //in
                      },
                    ])
                  }
                />
              </div>

              <Typography
                as="span"
                size="2xl"
                color="text-primary-900"
                lineHeight="leading-[1.21]"
                classes="ml-36 cursor-pointer"
                text={"إضافة المزيد"}
                click={() =>
                  formik.setFieldValue("delivery_ranges_attributes", [
                    ...formik.values.delivery_ranges_attributes,
                    {
                      delivery_type: "now", //now or later
                      fixed_price: true, //true or false
                      price: 0, //float
                      from_radius: "",
                      radius: 0, //float
                      initial_cost: formik.values.initial_cost, //in
                    },
                  ])
                }
              />
            </div>
          ) : (
            <></>
          )}
          <div className="px-6 pb-6 mt-10">
            <CustomButton
              height="h-10"
              width="w-52"
              label={"حفظ"}
              textColor="text-primary-50"
              action={formik.handleSubmit}
            />
          </div>{" "}
        </>
      ) : (
        <div className="py-16">
          <Spinner />
        </div>
      )}

      <SuccessConfirmationModal
        isOpen={isUpdated}
        ToggleModalVisibility={() => setIsUpdated(false)}
        onClosAction={closeConfirmation}
        heading={"لقد تلقينا تعديلاتك بنجاح"}
        subHeadings={["سيتم مراجعة التعديلات و تطبيقها فور مراجعتها"]}
      />
      <MapLocationModal
        addressName={addressName}
        setAddressName={setAddressName}
        isOpen={IsMap}
        ToggleModalVisibility={() => setIsMap(!IsMap)}
        selectedCenter={
          sessionStorage.getItem("distance_cords")
            ? {
                lat: JSON.parse(
                  StorageUtils.getItems(sessionStorage, ["distance_cords"])[
                    "distance_cords"
                  ]
                )?.lat,
                lng: JSON.parse(
                  StorageUtils.getItems(sessionStorage, ["distance_cords"])[
                    "distance_cords"
                  ]
                )?.lng,
              }
            : {
                lat: 30.04442,
                lng: 31.23576,
              }
        }
        handleSelectedAddress={(selectedAddress, location) => {
          // Just for validation

          formik.setFieldValue(
            "address",
            selectedAddress?.formatted_address
            // selectedAddress?.address_components?.find(
            //   (item) => item.types[0] === "route"
            // )?.long_name ??
            //   +", " +
            //     selectedAddress?.address_components?.find(
            //       (item) => item.types[0] === "administrative_area_level_2"
            //     )?.long_name
          );
          //  JSON.parse(
          //    StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          //      "distance_cords"
          //    ]
          //  )?.lng;
          //  JSON.parse(
          //    StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          //      "distance_cords"
          //    ]
          //  )?.lat;
          formik.setFieldValue("lng", location?.lng);
          formik.setFieldValue("lat", location?.lat);
          // formik.setFieldValue(
          //   "street_name",
          //   selectedAddress?.address_components?.find(
          //     (item) => item.types[0] === "route"
          //   )?.long_name
          // );
        }}
        zoomLevel={17}
        zIndex={"z-40"}
      />
    </div>
  );
}

export default EditProfileTemplate;
