import { Typography } from "components/atoms";
import { DropDownInput, FormInput } from "components/molecules";
import { RadioGroup } from "components/organisms";
import { getIn } from "formik";
import { useEffect, useState } from "react";
import { Delete, PlusSign, RedClose } from "utils/helpers/SVGsSrc";

const CustomSection = ({
  data,
  id,
  setFieldValue,
  language,
  productOptionsAr,
  productOptionsEn,
  formik,
}) => {
  const [showMaxOptions, setShowMaxOptions] = useState(1);
  const [displayedMaxOptions, setDisplayedMaxOptions] = useState({
    value: 1,
    label: 1,
  });
  useEffect(() => {
    if (formik.values.ar.product_options[id].max > 1) {
      setShowMaxOptions(2);
      setDisplayedMaxOptions({
        value: formik.values.ar.product_options[id].max,
        label: formik.values.ar.product_options[id].max,
      });
    }
  }, []);
  useEffect(() => {
    if (showMaxOptions == 1) {
      setFieldValue(`ar.product_options[${id}].max`, 1);
      setFieldValue(`en.product_options[${id}].max`, 1);
      setFieldValue(`ar.product_options[${id}].min`, 1);
      setFieldValue(`en.product_options[${id}].min`, 1);
    }
  }, [showMaxOptions]);

  const addOptionRow = () => {
    //Adds an option to the section
    setFieldValue(`ar.product_options[${id}].option_values`, [
      // ...data.option_values,
      ...formik.values.ar.product_options[id].option_values,
      {
        name: "",
      },
    ]);
    setFieldValue(`en.product_options[${id}].option_values`, [
      // ...data.option_values,
      ...formik.values.en.product_options[id].option_values,
      {
        name: "",
      },
    ]);
  };

  const setValue = (e, attribute) => {
    if (language === 1) {
      setFieldValue(`ar.product_options[${id}].${attribute}`, e.target.value);
    } else {
      setFieldValue(`en.product_options[${id}].${attribute}`, e.target.value);
    }
  };

  const setOptionValues = (e, attribute, index) => {
    //attribute =  "name"
    if (language === 1) {
      setFieldValue(
        `ar.product_options[${id}].option_values[${index}].${attribute}`,
        e.target.value
      );
    } else if (language === 2) {
      setFieldValue(
        `en.product_options[${id}].option_values[${index}].${attribute}`,
        e.target.value
      );
    }
  };

  return (
    <div className="bg-[#f8f8fe] py-6 mt-6">
      <div className="mx-6 ">
        <FormInput
          value={data.name}
          label={"عنوان الفقرة"}
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          width="w-full"
          height="h-9"
          placeholder="مثال : نوع الخبز - الأطباق الجانبية - مستوى التسوية …"
          onValueChange={(e) => {
            setValue(e, "name");
          }}
          error={
            getIn(formik.touched, `ar.product_options[${id}].name`) &&
            getIn(formik.errors, `ar.product_options[${id}].name`)
              ? getIn(formik.touched, `ar.product_options[${id}].name`) &&
                getIn(formik.errors, `ar.product_options[${id}].name`)
              : getIn(formik.touched, `en.product_options[${id}].name`) &&
                getIn(formik.errors, `en.product_options[${id}].name`)
          }
        />
      </div>
      <div className="mx-6 my-6">
        <FormInput
          value={data.description}
          label={"ملاحظة إضافية"}
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          width="w-full"
          height="h-9"
          placeholder="مثال : إختيار واحد فقط"
          onValueChange={(e) => {
            // setFieldValue(
            //   `ar.product_options[${id}].description`,
            //   e.target.value
            // );
            setValue(e, "description");
          }}
        />
      </div>
      <div className="mx-6 my-6">
        {data?.option_values?.map((option, index) => {
          return (
            <div className="my-8">
              <div className="lg:flex lg:justify-between ">
                <FormInput
                  width="w-full"
                  height="h-9"
                  value={option.name}
                  onValueChange={(e) => {
                    setOptionValues(e, "name", index);
                  }}
                />
                <div className=" mt-3 lg:mr-6 cursor-pointer flex justify-center">
                  <RedClose
                    onClick={() => {
                      setFieldValue(
                        `ar.product_options[${id}].option_values`,
                        data?.option_values?.filter((option, t) => t != index)
                      );
                      setFieldValue(
                        `en.product_options[${id}].option_values`,
                        data?.option_values?.filter((option, t) => t != index)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
        <div className="mt-8 mb-10 flex justify-items-start">
          <div className="ml-4  mt-1">
            <PlusSign
              height="100%"
              width="100%"
              className="w-5 h-5 cursor-pointer"
              onClick={addOptionRow}
            />
          </div>
          <Typography
            as="span"
            size="2xl"
            color="text-primary-900"
            lineHeight="leading-[1.21]"
            classes="ml-36 cursor-pointer"
            text={"إضافة المزيد"}
            click={addOptionRow}
          />
        </div>

        <div>
          <Typography
            as="span"
            size="2xl"
            color="text-warm_grey"
            lineHeight="leading-[1.21]"
            text={"يتم إختيار"}
          />
        </div>
        <RadioGroup
          name={"Custom" + id}
          id={"Custom" + id}
          options={[
            { id: 1, name: "إختيار واحد فقط" },
            { id: 2, name: "أكثر من إختيار" },
          ]}
          state={showMaxOptions}
          onValueChange={(e) => {
            setShowMaxOptions(e.target.value);
            // if (e.target.value === 1) {
            //   setFieldValue(`ar.product_options[${id}].max`, 1);
            //   setFieldValue(`en.product_options[${id}].max`, 1);
            //   setFieldValue(`ar.product_options[${id}].min`, 1);
            //   setFieldValue(`en.product_options[${id}].min`, 1);
            // }
          }}
        />

        {showMaxOptions == 2 ? (
          <div className="mr-9">
            <DropDownInput
              label="أكثر عدد يمكن إختياره"
              labelSize={"xl"}
              textColor={"text-warm_grey"}
              searchable={false}
              closeOnSelect={true}
              styles={{
                dropdownIndicator: (base) => ({
                  ...base,
                  color: "#777",
                }),
                control: (base) => ({
                  ...base,
                  width: "27.875rem",
                }),
              }}
              placeholder="1"
              options={[
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 },
                { value: 5, label: 5 },
                { value: 6, label: 6 },
              ]}
              value={displayedMaxOptions}
              onValueChange={(e) => {
                setFieldValue(`ar.product_options[${id}].max`, e.value);
                setFieldValue(`en.product_options[${id}].max`, e.value);
                setFieldValue(`ar.product_options[${id}].min`, e.value);
                setFieldValue(`en.product_options[${id}].min`, e.value);
                setDisplayedMaxOptions(e);
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="flex justify-end">
          <Delete
            className="cursor-pointer"
            onClick={() => {
              setFieldValue(
                "ar.product_options",
                productOptionsAr.filter((option, t) => t !== id)
              );
              setFieldValue(
                "en.product_options",
                productOptionsEn.filter((option, t) => t !== id)
              );
            }}
          />
          <Typography
            as="span"
            size="2xl"
            color="text-[#ff0009]"
            lineHeight="leading-[1.21]"
            classes="cursor-pointer mr-4"
            text={"حذف"}
            click={() => {
              setFieldValue(
                "ar.product_options",
                productOptionsAr.filter((option, t) => t !== id)
              );
              setFieldValue(
                "en.product_options",
                productOptionsEn.filter((option, t) => t !== id)
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomSection;
