import { CustomButton, Typography } from "components/atoms";
import { DropDownInput, FormInput } from "components/molecules";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { SplitScreen } from "components/Layouts";
import { SuccessConfirmationModal } from "components/organisms";
import { ValidationsWrapper } from "components/Containers";
import classNames from "classnames";
import { getPayload } from "utils";
import { listCategoriesStart } from "Redux/categories/actions/categories.actions";
import { listServingTypesStart } from "Redux/vendor/actions/vendor.actions";
import { phoneSignUpStart } from "Redux/user/actions/user.actions";
import { selectCategoriesList } from "Redux/categories/selectors/categories.selectors";
import { selectCurrentUser } from "Redux/user";
import { selectServingsList } from "Redux/vendor/selectors/vendor.selectors";
import styles from "./SignUpForm.module.css";
import { useTranslation } from "react-i18next";

const SignUpForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const categories = useSelector(selectCategoriesList);
  const servingTypes = useSelector(selectServingsList);

  const [userData, setUserData] = useState({
    full_name: "",
    mobile_number: "",
    password: "",
    email: "",
    serving_type_ids: [],
    main_category_ids: [],
    vendor_type: 0,
  });

  const [foodTypes, setFoodTypes] = useState([]);

  const phoneSignUpStartHandler = (userData) => {
    dispatch(phoneSignUpStart(userData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    phoneSignUpStartHandler(userData);
  };

  useEffect(() => {
    dispatch(listCategoriesStart());
    dispatch(listServingTypesStart());
  }, [dispatch]);

  useEffect(() => {
    const foodTypes = categories?.map((foodType) => ({
      value: foodType?.id,
      label: foodType?.name,
    }));
    setFoodTypes(foodTypes);
  }, [categories]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const ToggleModalVisibility = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    if (currentUser && !currentUser?.mobile_verified) setIsModalOpen(true);
    else if (currentUser && currentUser?.mobile_verified) navigate("/");
  }, [currentUser, navigate, isModalOpen]);

  return (
    <ValidationsWrapper
      className={classNames(styles.form, " px-6 lg:px-8")}
      rules={[
        "full_name",
        "phone",
        "email",
        "website",
        "termsOfUse",
        "password",
        "confirm_password",
      ]}
      initialValues={{
        full_name: "",
        phone: "",
        email: "",
        website: "",
        password: "",
        confirm_password: "",
        termsOfUse: false,
      }}
    >
      {({ FormikHandler }) => {
        return (
          <div>
            <Typography
              as="h1"
              size="6xl"
              color="text-text_white"
              classes="px-2"
              text={t("Create New Account")}
            />
            <Typography
              as="p"
              size="lg"
              color="text-text_grey"
              fontWeight="font-medium"
              lineHeight="leading-[1.61]"
              classes="mt-2 lg:mt-3 3xl:mt-4 px-2"
              text={t("Please enter your data to create an account")}
            />
            <SplitScreen
              containerClasses={styles.formInputsContainer}
              leftPaneClasses="w-full lg:w-[48%]"
              rightPaneClasses="w-full lg:w-[48%]"
            >
              <div>
                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    tabIndex={1}
                    id="firstName"
                    name="full_name"
                    type="text"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("First Name")}
                    labelSize="sm"
                    value={FormikHandler.values.full_name}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("full_name", e.target.value);
                      getPayload(e, userData, setUserData);
                    }}
                    error={FormikHandler.errors.full_name}
                  />
                </div>

                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    font="font-SFProText"
                    tabIndex={3}
                    id="email"
                    name="email"
                    type="email"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Email (Optional)")}
                    labelSize="sm"
                    value={FormikHandler.values.email}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("email", e.target.value);
                      getPayload(e, userData, setUserData);
                    }}
                    error={FormikHandler.errors.email}
                  />
                </div>

                <div className="mt-5 lg:mt-6 3xl:mt-10 ">
                  <DropDownInput
                    tabIndex={5}
                    label={t("Offered food")}
                    placeholder={t("Select from menu")}
                    width="w-full"
                    height="h-full"
                    classes="basic-multi-select"
                    classNamePrefix="select"
                    isMulti={true}
                    options={foodTypes}
                    value={userData?.main_category_ids}
                    onValueChange={(e) => {
                      getPayload(e, userData, setUserData, "main_category_ids");
                    }}
                  />

                  {/* <div className="mt-5 lg:mt-6 3xl:mt-10">
                    <FormInput
                      tabIndex={7}
                      id="Password"
                      name="password"
                      type="password"
                      width="w-full"
                      height="h-10"
                      containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                      containerClasses="defaultInput"
                      label={t("Password")}
                      labelSize="sm"
                      value={FormikHandler.values.password}
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue("password", e.target.value);
                        getPayload(e, userData, setUserData);
                      }}
                      error={FormikHandler.errors.password}
                    />
                  </div> */}
                </div>
              </div>
              <div>
                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    font="font-SFProText"
                    tabIndex={2}
                    id="mobileNumber"
                    name="mobile_number"
                    type="tel"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Mobile Number")}
                    labelSize="sm"
                    value={FormikHandler.values.phone}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("phone", e.target.value);
                      getPayload(e, userData, setUserData);
                    }}
                    error={FormikHandler.errors.phone}
                  />
                </div>
                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    font="font-SFProText"
                    tabIndex={4}
                    id="website"
                    name="website"
                    type="url"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Website") + t("(Optional)")}
                    secondaryLabel={t("(Optional)")}
                    labelSize="sm"
                    value={FormikHandler.values.website}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("website", e.target.value);
                      getPayload(e, userData, setUserData);
                    }}
                    error={FormikHandler.errors.website}
                  />
                </div>
                <div className="mt-5 lg:mt-6 3xl:mt-10 ">
                  <DropDownInput
                    tabIndex={6}
                    label={t("Offered Service")}
                    placeholder={t("Select from menu")}
                    width="w-full"
                    height="h-10"
                    classes="basic-multi-select"
                    classNamePrefix="select"
                    isMulti={true}
                    options={servingTypes}
                    value={userData?.serving_type_ids}
                    onValueChange={(e) => {
                      getPayload(e, userData, setUserData, "serving_type_ids");
                    }}
                  />
                </div>
                {/* <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    tabIndex={8}
                    id="confirmPassword"
                    name="confirm_password"
                    type="password"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Confirm password")}
                    labelSize="sm"
                    value={FormikHandler.values.confirm_password}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue(
                        "confirm_password",
                        e.target.value
                      );
                    }}
                    error={FormikHandler.errors.confirm_password}
                  />
                </div> */}
              </div>
            </SplitScreen>

            <SplitScreen
              containerClasses={styles.formInputsContainer}
              leftPaneClasses="w-full lg:w-[48%]"
              rightPaneClasses="w-full lg:w-[48%]"
            >
              <div className="w-full">
                {" "}
                <FormInput
                  font="font-SFProText"
                  tabIndex={7}
                  id="Password"
                  name="password"
                  type="password"
                  width="w-full"
                  height="h-10"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label={t("Password")}
                  labelSize="sm"
                  value={FormikHandler.values.password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("password", e.target.value);
                    getPayload(e, userData, setUserData);
                  }}
                  error={FormikHandler.errors.password}
                />
              </div>
              <div className="w-full">
                <FormInput
                  font="font-SFProText"
                  tabIndex={8}
                  id="confirmPassword"
                  name="confirm_password"
                  type="password"
                  width="w-full"
                  height="h-10"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label={t("Confirm password")}
                  labelSize="sm"
                  value={FormikHandler.values.confirm_password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue(
                      "confirm_password",
                      e.target.value
                    );
                  }}
                  error={FormikHandler.errors.confirm_password}
                />
              </div>
            </SplitScreen>

            <div className="mt-5 lg:mt-6 3xl:mt-10 px-2">
              <Typography
                as="p"
                size="xl"
                color="text-text_d9d9d9"
                classes="mb-2"
                text={t("Work type")}
              />
              <div className="flex w-full lg:w-1/2">
                <FormInput
                  id="indevidual"
                  name="vendor_type"
                  type="radio"
                  width="w-5"
                  height="h-5"
                  fullWidth={false}
                  containerClasses="selectInputs"
                  label={t("indevidual")}
                  value={0}
                  onValueChange={(e) => {
                    getPayload(
                      {
                        target: { name: e.target.name, value: +e.target.value },
                      },
                      userData,
                      setUserData
                    );
                  }}
                  checked={userData.vendor_type === 0}
                  labelSize="xl"
                  textColor="text-text_d9d9d9"
                />
                <FormInput
                  id="corporate"
                  name="vendor_type"
                  type="radio"
                  fullWidth={false}
                  width="w-5"
                  height="h-5"
                  containerClasses="selectInputs"
                  label={t("corporate")}
                  labelSize="xl"
                  textColor="text-text_d9d9d9"
                  value={1}
                  onValueChange={(e) => {
                    getPayload(
                      {
                        target: { name: e.target.name, value: +e.target.value },
                      },
                      userData,
                      setUserData
                    );
                  }}
                  checked={userData.vendor_type === 1}
                />
              </div>
            </div>
            <div className="mt-5 lg:mt-6 3xl:mt-10 px-2">
              <FormInput
                id="agree"
                name="agree"
                type="checkbox"
                fullWidth={false}
                width="w-4"
                bgColor="bg_transparent"
                height="h-4"
                containerClasses="selectInputs"
                inputTypeClass="checkboxOutlined"
                label={
                  <>
                    {t("I agree to the")}
                    <Typography
                      as="a"
                      href="https://vendor.ablanazira.com/privacy_policy"
                      classes="underline underline-offset-2"
                      text={"  الشروط و الأحكام و سياسة الخصوصية"}
                    />
                  </>
                }
                labelSize="xs"
                value={FormikHandler.values.termsOfUse}
                onValueChange={(e) => {
                  FormikHandler.setFieldValue("termsOfUse", e.target.checked);
                }}
                error={FormikHandler.errors.termsOfUse}
              />
            </div>
            <div className="mt-5 lg:mt-8 3xl:mt-10 mr-1 md:mr-2 rtl:ml-1 md:rtl:ml-2">
              <CustomButton
                width="w-full"
                height="h-10"
                label={t("Sign Up")}
                disabled={!FormikHandler.isValid}
                action={handleSubmit}
              />
            </div>
            <div className="mt-5 3xl:mt-12 flex justify-center ">
              <Typography
                as="p"
                size="sm"
                color="text-text_white"
                text={t("Already have an account ?")}
              />

              <Link to="/Login">
                <Typography
                  as="span"
                  size="sm"
                  color="text-text_white"
                  fontWeight="font-bold"
                  classes="ml-1 rtl:mr-1 cursor-pointer "
                  text={t("Sign in")}
                />
              </Link>
            </div>
            <SuccessConfirmationModal
              isOpen={isModalOpen}
              ToggleModalVisibility={ToggleModalVisibility}
              onClosAction={() =>
                navigate("/Verify_mobile_number", {
                  state: {
                    scope: "signup-verification",
                  },
                })
              }
              heading={t("We have recieved your data successfully")}
              subHeadings={[
                t("Thanks for your interest in joining us"),
                t("We will check your data and activate your account ASAP"),
              ]}
            />
          </div>
        );
      }}
    </ValidationsWrapper>
  );
};

export default SignUpForm;
