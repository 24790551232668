const RadioGroup = ({
  state,
  options,
  onValueChange,
  name,
  id,
  containerClasses,
  itemWidth,
}) => {
  return (
    <>
      <div className={containerClasses}>
        {options?.map((option) => {
          return (
            <div
              className={`form-check flex justify-start mb-6 mt-6 ${itemWidth}`}
            >
              <div className="mt-2">
                <input
                  className="form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name={name}
                  id={id}
                  value={option.id}
                  checked={option.id == state}
                  onChange={onValueChange}
                />
              </div>

              <label className="mr-4 text-text_3b3b3b text-xl">
                {option.name}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RadioGroup;
