import ProductActionTypes from "../types/products.types";

const INITIAL_STATE = {
  progressBarValue: null,
  productImageURL: null,
  products: null,
  discountedProducts: null,
  product: null,
  mainCategories: null,
  savingTemp: null,
  tags: null,
  sizeUnits: null,
  sizes: null,
  error: false,
  loading: true,
  loadingMore: false,
  hasMore: true,
  total_pages: 0,
  addProductError: null,
  successConfirmationModal: false,
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case ProductActionTypes.LIST_ORDERS_SUCCESS:
    //   return {
    //     ...state,
    //     orders: action.payload,
    //   };
    // case ProductActionTypes.LIST_ORDERS_FAILURE:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };
    case ProductActionTypes.PROGRESS_BAR_START:
      return {
        ...state,
        progressBarValue: action.payload,
      };
    case ProductActionTypes.DELETE_PRODUCT_DISCOUNT_SUCCESS:
      const filteredProducts = state.products.filter(
        (product) => action.payload !== product.id
      );
      return {
        ...state,
        loading: false,
        products: filteredProducts,
      };
    case ProductActionTypes.DELETE_PRODUCT_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ProductActionTypes.DELETE_PRODUCT_DISCOUNT_START:
      return {
        ...state,
        loading: true,
      };
    case ProductActionTypes.UPLOAD_PRODUCT_IMAGE_START:
      return {
        ...state,
      };
    case ProductActionTypes.UPLOAD_PRODUCT_IMAGE_SUCCESS:
      return {
        ...state,
        productImageURL: action.payload,
      };
    case ProductActionTypes.UPLOAD_PRODUCT_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ProductActionTypes.LOAD_MORE_PRODUCTS_START:
      return {
        ...state,
        loadingMore: true,
      };
    case ProductActionTypes.LOAD_MORE_PRODUCTS_SUCCESS:
      const newProductsList = action.payload.products;
      const { products } = state;
      return {
        ...state,
        products: [...products, ...newProductsList],
        loading: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages
            ? true
            : false,
      };

    case ProductActionTypes.GET_PRODUCT_START:
      return {
        ...state,
        loading: true,
      };

    case ProductActionTypes.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ProductActionTypes.GET_TAGS_START:
      return {
        ...state,
        loading: true,
      };
    case ProductActionTypes.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_TAGS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ProductActionTypes.GET_SAVING_TEMPERATURE_START:
      return {
        ...state,
        loading: true,
      };
    case ProductActionTypes.GET_SAVING_TEMPERATURE_SUCCESS:
      return {
        ...state,
        savingTemp: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_SAVING_TEMPERATURE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ProductActionTypes.REMOVE_PRODUCT_START:
      return {
        ...state,
        loading: true,
      };
    case ProductActionTypes.REMOVE_PRODUCT_SUCCESS:
      const filteredProductsList = state.products.filter(
        (product) => action.payload != product.id
      );
      console.log(filteredProductsList);
      return {
        ...state,
        loading: false,
        products: filteredProductsList,
      };
    case ProductActionTypes.REMOVE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_PRODUCTS_START:
      return {
        ...state,
        loading: true,
        products: null,
      };
    case ProductActionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        loading: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };
    case ProductActionTypes.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_SIZES_START:
      return {
        ...state,
        loading: true,
      };
    case ProductActionTypes.GET_SIZES_SUCCESS:
      return {
        ...state,
        sizes: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_SIZES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_SIZE_UNITS_START:
      return {
        ...state,
        loading: true,
      };
    case ProductActionTypes.GET_SIZE_UNITS_SUCCESS:
      return {
        ...state,
        sizeUnits: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_SIZE_UNITS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_CATEGORY_START:
      return {
        ...state,
        loading: true,
      };
    case ProductActionTypes.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        mainCategories: action.payload,
        loading: false,
      };
    case ProductActionTypes.GET_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ProductActionTypes.ADD_PRODUCT_START:
      return {
        ...state,
        loading: true,
        addProductError: null,
      };
    case ProductActionTypes.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        successConfirmationModal: true,
      };
    case ProductActionTypes.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        addProductError: action.payload,
        loading: false,
        successConfirmationModal: false,
      };
    case ProductActionTypes.UPDATE_PRODUCT_START:
      return {
        ...state,
        addProductError: null,
      };
    case ProductActionTypes.UPDATE_PRODUCT_SUCCESS:
      const newProducts = [...state.products];
      const productIndex = newProducts.findIndex(
        (product) => action.payload.id == product.id
      );

      newProducts[productIndex].is_available_by_vendor =
        action.payload.attributes.is_available_by_vendor;

      return {
        ...state,
        products: newProducts,
        loading: false,
      };
    case ProductActionTypes.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        addProductError: action.payload,
        loading: false,
      };
    case ProductActionTypes.RESET_SUCCESS_CONFIRMATION_MODAL:
      return {
        ...state,
        successConfirmationModal: action.payload,
      };
    default:
      return state;
  }
};

export default productsReducer;
