const VendorActionTypes = {
  UPDATE_DISCOUNT_START: "UPDATE_DISCOUNT_START",
  UPDATE_DISCOUNT_SUCCESS: "UPDATE_DISCOUNT_SUCCESS",
  UPDATE_DISCOUNT_FAILURE: "UPDATE_DISCOUNT_FAILURE",
  GET_DISCOUNT_START: "GET_DISCOUNT_START",
  GET_DISCOUNT_SUCCESS: "GET_DISCOUNT_SUCCESS",
  GET_DISCOUNT_FAILURE: "GET_DISCOUNT_FAILURE",
  ADD_DISCOUNT_START: "ADD_DISCOUNT_START",
  ADD_DISCOUNT_SUCCESS: "ADD_DISCOUNT_SUCCESS",
  ADD_DISCOUNT_FAILURE: "ADD_DISCOUNT_FAILURE",
  ADD_VENDOR_MENU_CATEGORY_START: "ADD_VENDOR_MENU_CATEGORY_START",
  ADD_VENDOR_MENU_CATEGORY_SUCCESS: "ADD_VENDOR_MENU_CATEGORY_SUCCESS",
  ADD_VENDOR_MENU_CATEGORY_FAILURE: "ADD_VENDOR_MENU_CATEGORY_FAILURE",
  GET_VENDOR_MENU_CATEGORIES_START: "GET_VENDOR_MENU_CATEGORIES_START",
  GET_VENDOR_MENU_CATEGORIES_SUCCESS: "GET_VENDOR_MENU_CATEGORIES_SUCCESS",
  GET_VENDOR_MENU_CATEGORIES_FAILURE: "GET_VENDOR_MENU_CATEGORIES_FAILURE",
  LIST_SERVING_TYPES_START: "LIST_SERVING_TYPES_START",
  LIST_SERVING_TYPES_SUCCESS: "LIST_SERVING_TYPES_SUCCESS",
  LIST_SERVING_TYPES_FAILURE: "LIST_SERVING_TYPES_FAILURE",
  LIST_PROFILE_START: "LIST_PROFILE_START",
  LIST_PROFILE_SUCCESS: "LIST_PROFILE_SUCCESS",
  LIST_PROFILE_FAILURE: "LIST_PROFILE_FAILURE",
  UPDATE_PROFILE_START: "UPDATE_PROFILE_START",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  UPDATE_PROFILE_INFORMED: "UPDATE_PROFILE_INFORMED",
  UPLOAD_LOGO_START: "UPLOAD_LOGO_START",
  UPLOAD_LOGO_SUCCESS: "UPLOAD_LOGO_SUCCESS",
  UPLOAD_LOGO_FAILURE: "UPLOAD_LOGO_FAILURE",
  LOGO_PROGRESS_BAR_START: "LOGO_PROGRESS_BAR_START",
  UPLOAD_IMAGE_START: "UPLOAD_IMAGE_START",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",
  IMAGE_PROGRESS_BAR_START: "IMAGE_PROGRESS_BAR_START",
  GET_ALL_VENDORS_START: "GET_ALL_VENDORS_START",
  GET_ALL_VENDORS_SUCCESS: "GET_ALL_VENDORS_SUCCESS",
  GET_ALL_VENDORS_FAILURE: "GET_ALL_VENDORS_FAILURE",
  LIST_ALL_AREAS_START: "LIST_ALL_AREAS_START",
  LIST_ALL_AREAS_SUCCESS: "LIST_ALL_AREAS_SUCCESS",
  LIST_ALL_AREAS_FAILURE: "LIST_ALL_AREAS_FAILURE",
  LIST_ALL_DELIVERY_METHODS_START: "LIST_ALL_DELIVERY_METHODS_START",
  LIST_ALL_DELIVERY_METHODS_SUCCESS: "LIST_ALL_DELIVERY_METHODS_SUCCESS",
  LIST_ALL_DELIVERY_METHODS_FAILURE: "LIST_ALL_DELIVERY_METHODS_FAILURE",
  LIST_VENDOR_AREAS_START: "LIST_VENDOR_AREAS_START",
  LIST_VENDOR_AREAS_SUCCESS: "LIST_VENDOR_AREAS_SUCCESS",
  LIST_VENDOR_AREAS_FAILURE: "LIST_VENDOR_AREAS_FAILURE",
  UPDATE_VENDOR_AREA_START: "UPDATE_VENDOR_AREA_START",
  UPDATE_VENDOR_AREA_SUCCESS: "UPDATE_VENDOR_AREA_SUCCESS",
  UPDATE_VENDOR_AREA_FAILURE: "UPDATE_VENDOR_AREA_FAILURE",

  LIST_ALL_COUNTRIES_START: "LIST_ALL_COUNTRIES_START",
  LIST_ALL_COUNTRIES_FAILURE: "LIST_ALL_COUNTRIES_FAILURE",
  LIST_ALL_COUNTRIES_SUCCESS: "LIST_ALL_COUNTRIES_SUCCESS",

  LIST_ALL_CURRENCIES_START: "LIST_ALL_CURRENCIES_START",
  LIST_ALL_CURRENCIES_FAILURE: "LIST_ALL_CURRENCIES_FAILURE",
  LIST_ALL_CURRENCIES_SUCCESS: "LIST_ALL_CURRENCIES_SUCCESS",

  LIST_ALL_PAYOUT_METHODS_START: "LIST_ALL_PAYOUT_METHODS_START",
  LIST_ALL_PAYOUT_METHODS_FAILURE: "LIST_ALL_PAYOUT_METHODS_FAILURE",
  LIST_ALL_PAYOUT_METHODS_SUCCESS: "LIST_ALL_PAYOUT_METHODS_SUCCESS",

  //Needs to be removed (Carefully because it crashes everything if removed)
  // LIST_ALL_CITIES_START: "LIST_ALL_CITIES_START",
  // LIST_ALL_CITIES_FAILURE: "LIST_ALL_CITIES_FAILURE",
  // LIST_ALL_CITIES_SUCCESS: "LIST_ALL_CITIES_SUCCESS",
  // LIST_AREAS_BY_CITY_START: "LIST_AREAS_BY_CITY_START",
  // LIST_AREAS_BY_CITY_FAILURE: "LIST_AREAS_BY_CITY_FAILURE",
  // LIST_AREAS_BY_CITY_SUCCESS: "LIST_AREAS_BY_CITY_SUCCESS",
  // These
};

export default VendorActionTypes;
