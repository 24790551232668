import {
  addMenuCategoriesStart,
  getVendorMenuCategoriesStart,
} from "Redux/vendor/actions/vendor.actions";
import {
  getMainCategoryStart,
  getProductsStart,
  removeProductStart,
  updateProductStart,
} from "Redux/products/actions/products.actions";
import {
  hasMoreSelector,
  selectIsProductLoadingMore,
  selectIsProductsLoading,
  selectMainCategories,
  selectProductList,
} from "Redux/products/selectors/products.selectors";
import {
  selectIsVendorLoading,
  selectVendorMenuCategories,
} from "Redux/vendor/selectors/vendor.selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";

import { selectVendorId } from "Redux/user/selectors/user.selectors";

const { MenuTemplate } = require("templates");

const MenuPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [setCurrentView] = useOutletContext();

  const mainCategories = useSelector(selectMainCategories);
  const products = useSelector(selectProductList);
  const vendorMenuCategories = useSelector(selectVendorMenuCategories);
  const isProductsLoading = useSelector(selectIsProductsLoading);
  const isProductLoadingMore = useSelector(selectIsProductLoadingMore);
  const isVendorLoading = useSelector(selectIsVendorLoading);
  const hasMore = useSelector(hasMoreSelector);
  const selectedVendorId = useSelector(selectVendorId);

  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [highlightedCategory, setHighlightedCategory] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    page_number: 1,
    menu_category_id: "",
    admin_vendor_id: selectedVendorId,
  });

  const handleDelete = (id) => {
    if (window.confirm("هل أنت متأكد أنك تريد حذف هذا المنتج؟")) {
      dispatch(removeProductStart({ id: id, queryParams: queryParams }));
    } else {
    }
  };

  const handleAvailability = (id, body) => {
    dispatch(updateProductStart({ id: id, body: body }));
  };

  const handleSave = (e, newMenuCategories) => {
    e.preventDefault();
    dispatch(addMenuCategoriesStart(newMenuCategories));
  };

  const handleUpdate = (id) => {
    navigate(`/edit_item/${id}`);
  };

  useEffect(() => {
    setCurrentView("القائمة");
  }, []);

  useEffect(() => {
    if (products) setPage((prev) => prev + 1);
  }, [products]);

  useEffect(() => {
    setPage(1);
  }, [queryParams]);

  useEffect(() => {
    dispatch(getMainCategoryStart());
    dispatch(getVendorMenuCategoriesStart());
  }, []);

  useEffect(() => {
    dispatch(getProductsStart(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    setDisplayedProducts(products);

    setSelectedCategories(vendorMenuCategories);
    const filteredCategories = mainCategories?.filter((category) => {
      return !vendorMenuCategories?.some((cat) => {
        return category.value === cat.value;
      });
    });
    setDisplayedCategories(filteredCategories);
  }, [products, mainCategories]);

  useEffect(() => {
    setQueryParams({ ...queryParams, admin_vendor_id: selectedVendorId });
  }, [selectedVendorId]);

  return (
    <MenuTemplate
      page={page}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      hasMore={hasMore}
      displayedCategories={displayedCategories}
      setDisplayedCategories={setDisplayedCategories}
      activeFilter={activeFilter}
      setActiveFilter={setActiveFilter}
      displayedProducts={displayedProducts}
      products={products}
      setDisplayedProducts={setDisplayedProducts}
      handleDelete={handleDelete}
      vendorMenuCategories={vendorMenuCategories}
      handleSave={handleSave}
      handleUpdate={handleUpdate}
      highlightedCategory={highlightedCategory}
      setHighlightedCategory={setHighlightedCategory}
      selectedCategories={selectedCategories}
      setSelectedCategories={setSelectedCategories}
      isProductsLoading={isProductsLoading}
      isProductLoadingMore={isProductLoadingMore}
      isVendorLoading={isVendorLoading}
      handleAvailability={handleAvailability}
      parent={"menu"}
    />
  );
};

export default MenuPage;
