import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { selectCurrentUser } from "Redux/user";
import { HomeTemplate } from "templates";
import { StorageUtils } from "utils";
const HomePage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/") {
      navigate("/main/orders/new");
    } else {
      navigate(pathname);
    }
  }, []);
  const [UserCoords, setUserCoords] = useState(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  const detectUserCoords = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          const distance_cords = {
            lat: latitude,
            lng: longitude,
          };
          StorageUtils.saveItems(sessionStorage, [
            { name: "distance_cords", data: JSON.stringify(distance_cords) },
          ]);
          setUserCoords(distance_cords);
        }
      );
    }
  };
  useEffect(() => {
    detectUserCoords();
  }, []);
  return <HomeTemplate />;
};

export default HomePage;
