import { PlusSign, RedClose } from "utils/helpers/SVGsSrc";

import { FormInput } from "components/molecules";
import { Typography } from "components/atoms";
import { getIn } from "formik";

function AdditionsRow({
  title,
  name,
  price,
  index,
  id,
  setFieldValue,
  language,
  productOptionsAr,
  productOptionsEn,
  formik,
}) {
  const setOptionValues = (e, attribute) => {
    //attribute =  "name" , "price"
    if (language === 1) {
      setFieldValue(
        `ar.product_options[${id}].option_values[${index}].${attribute}`,
        e.target.value
      );
    } else {
      setFieldValue(
        `en.product_options[${id}].option_values[${index}].${attribute}`,
        e.target.value
      );
    }
  };
  const setPriceValues = (e, attribute) => {
    //attribute =  "name" , "price"

    setFieldValue(
      `ar.product_options[${id}].option_values[${index}].${attribute}`,
      e.target.value
    );

    setFieldValue(
      `en.product_options[${id}].option_values[${index}].${attribute}`,
      e.target.value
    );
  };
  return (
    <div className="mx-6 my-6 ">
      <div className="lg:flex lg:justify-between ">
        <FormInput
          label={title}
          labelSize={"2xl"}
          textColor={"text-warm_grey"}
          width="w-full"
          height="h-9"
          value={name}
          onValueChange={(e) => {
            setOptionValues(e, "name");
          }}
          error={
            getIn(
              formik.touched,
              `ar.product_options[${id}].option_values[${index}].name`
            ) &&
            getIn(
              formik.errors,
              `ar.product_options[${id}].option_values[${index}].name`
            )
              ? getIn(
                  formik.touched,
                  `ar.product_options[${id}].option_values[${index}].name`
                ) &&
                getIn(
                  formik.errors,
                  `ar.product_options[${id}].option_values[${index}].name`
                )
              : getIn(
                  formik.touched,
                  `en.product_options[${id}].option_values[${index}].name`
                ) &&
                getIn(
                  formik.errors,
                  `en.product_options[${id}].option_values[${index}].name`
                )
          }
        />
        <div className="lg:w-1/4 lg:mr-10 ">
          <FormInput
            font={"font-SFProText"}
            label={"السعر"}
            labelSize={"2xl"}
            textColor={"text-warm_grey"}
            width="w-full"
            height="h-9"
            value={price}
            onValueChange={(e) => {
              setPriceValues(e, "price_addition");
            }}
          />
        </div>
        <div className="lg:mt-14 mt-8 lg:mr-6 cursor-pointer flex justify-center">
          <RedClose
            onClick={() => {
              const filteredArExtras = productOptionsAr.option_values?.filter(
                (option, t) => t !== index
              );
              setFieldValue(
                `ar.product_options[${id}].option_values`,
                filteredArExtras
              );
              const filteredEnExtras = productOptionsEn.option_values?.filter(
                (option, t) => t !== index
              );
              setFieldValue(
                `en.product_options[${id}].option_values`,
                filteredEnExtras
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
const AdditionsSection = ({
  title,
  setFieldValue,
  id,
  language,
  productOptionsAr,
  productOptionsEn,
  formik,
}) => {
  const addOptionRow = () => {
    //Adds an option to the section
    setFieldValue(`ar.product_options[${id}].option_values`, [
      ...productOptionsAr.option_values,
      {
        name: "",
        price_addition: 0,
      },
    ]);
    setFieldValue(`en.product_options[${id}].option_values`, [
      ...productOptionsEn.option_values,
      {
        name: "",
        price_addition: 0,
      },
    ]);
  };
  return (
    <div className="bg-[#f8f8fe] mt-6 pt-3">
      {/* Rendering extras (الإضافات/ عناصر يمكن جمعها مع العنصر) options conditonally Based on language choice (Arabic/English) */}
      {language === 1
        ? productOptionsAr?.option_values.map((option, index) => {
            return (
              <AdditionsRow
                formik={formik}
                productOptionsAr={productOptionsAr}
                productOptionsEn={productOptionsEn}
                language={language}
                title={title}
                name={option.name}
                price={option.price_addition}
                index={index}
                id={id}
                setFieldValue={setFieldValue}
              />
            );
          })
        : productOptionsEn?.option_values.map((option, index) => {
            return (
              <AdditionsRow
                formik={formik}
                productOptionsAr={productOptionsAr}
                productOptionsEn={productOptionsEn}
                language={language}
                title={title}
                name={option.name}
                price={option.price_addition}
                index={index}
                id={id}
                setFieldValue={setFieldValue}
              />
            );
          })}
      <div className="mt-8 flex justify-items-start">
        <div className="ml-4 mr-6 mt-1">
          <PlusSign
            height="100%"
            width="100%"
            className="w-5 h-5 cursor-pointer"
            onClick={addOptionRow}
          />
        </div>
        <Typography
          as="span"
          size="2xl"
          color="text-primary-900"
          lineHeight="leading-[1.21]"
          classes="ml-36 cursor-pointer mb-10"
          text={"إضافة المزيد"}
          click={addOptionRow}
        />
      </div>
    </div>
  );
};

export default AdditionsSection;
