import { DropDownInput, SubNav } from "components/molecules";
import { Outlet, useLocation, useNavigate } from "react-router";

function TheMainTemplate({
  currentView,
  setCurrentView,
  currentUser,
  vendors,
  handleSelectVendor,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <>
      {" "}
      <main className=" min-h-screen flex-col 2xl:px-28">
        <SubNav
          buttonList={[
            {
              name: "الطلبات",
              handleClick: () => {
                setCurrentView("الطلبات");
                navigate("/main/orders/new");
              },
              style:
                pathname === "/main/orders/new" ||
                pathname === "/main/orders/current" ||
                pathname === "/main/orders/completed" ||
                pathname === "/main/orders/under_review"
                  ? "bg-[#bf0022] text-white hover:bg-[#bf0022] hover:text-white border  border-[#bf0022]  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full"
                  : "bg-white text-[#bf0022] hover:bg-[#bf0022] hover:text-white border  border-[#bf0022]  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full",
            },
            {
              name: "القائمة",
              handleClick: () => {
                setCurrentView("القائمة");
                navigate("/main/menu");
              },
              style:
                pathname === "/main/menu"
                  ? "bg-[#bf0022] text-white hover:bg-[#bf0022] hover:text-white border  border-[#bf0022]  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full"
                  : "bg-white text-[#bf0022] hover:bg-[#bf0022] hover:text-white border  border-[#bf0022]  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full",
            },
            {
              name: "الخصومات",
              handleClick: () => {
                setCurrentView("الخصومات");
                navigate("/main/promotions");
              },
              style:
                pathname === "/main/promotions"
                  ? "bg-[#bf0022] text-white hover:bg-[#bf0022] hover:text-white border  border-[#bf0022]  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full"
                  : "bg-white text-[#bf0022] hover:bg-[#bf0022] hover:text-white border  border-[#bf0022]  px-4 py-2 mx-0 outline-none focus:shadow-outline w-full",
            },
          ]}
        />
        {currentUser?.is_admin ? (
          <div className="mt-4">
            <DropDownInput
              styles={{
                option: (base, { isFocused }) => ({
                  ...base,
                  color: "#707070",
                  fontSize: "18px",
                  fontWeight: "500",
                  backgroundColor: isFocused ? "#cbcbcb" : null,
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: "#777",
                }),
                // Language dropdown styles
                menu: (base) => ({
                  ...base,
                  backgroundColor: "#f8f8fe",
                  border: 0,
                }),
                menuList: (base) => ({
                  ...base,
                  border: 0,
                }),

                control: (base) => ({
                  ...base,
                  flexDirection: "row",
                  width: "10.938rem",
                  height: "3.938rem",
                  backgroundColor: "#f8f8fe",
                  border: 0,
                  boxShadow: "none",
                }),
                menuList: (base) => ({ ...base, border: "0px" }),
                placeholder: (base) => ({
                  ...base,
                  color: "#707070",
                  fontWeight: "350",
                  fontSize: "22px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                }),
              }}
              searchable={true}
              closeOnSelect={true}
              name="menu"
              id="menu"
              options={vendors}
              width="w-full"
              placeholder="البائع"
              onValueChange={(e) => {
                handleSelectVendor(e.value);
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <Outlet context={[setCurrentView]} />
      </main>
    </>
  );
}

export default TheMainTemplate;
