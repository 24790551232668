import LoginBg from "assets/Images/Login/LoginBg.png";

import SignUpBg from "assets/Images/SignUp/SignUpBg.png";

import VerifyMobileBg from "assets/Images/VerifyMobileNumber/VerifyMobileBg.png";

import PrivacyPolicyBg from "assets/Images/PrivacyPolicy/PrivacyPolicyBg.png";

import AppStore from "assets/Images/AppStore/appStore.png";

import GooglePlay from "assets/Images/GooglePlay/googlePlay.png";

import UploadImage from "assets/Images/UploadImage/UploadImage.png";

import PlaceholderLogo from "assets/Images/PlaceholderLogo/logo.webp";

import EmptyMenuLogo from "assets/Images/EmptyMenuLogo/emptyMenu.png";

import UnderReviewLogo from "assets/Images/UnderReviewLogo/data_review.png";

import FAQLogo from "assets/Images/FAQ/faq.webp";
import FAQLogo2x from "assets/Images/FAQ/faq@2x.webp";
import FAQLogo3x from "assets/Images/FAQ/faq@3x.webp";

import ChatLogo from "assets/Images/Chat/chat.webp";
import ChatLogo2x from "assets/Images/Chat/chat@2x.webp";
import ChatLogo3x from "assets/Images/Chat/chat@3x.webp";

/*** HARD CODED DATA ***/

import MaskPlaceHolder from "assets/Images/MaskPlaceHolder/Mask.webp";
import MaskPlaceHolder2x from "assets/Images/MaskPlaceHolder/Mask@2x.webp";
import MaskPlaceHolder3x from "assets/Images/MaskPlaceHolder/Mask@3x.webp";

import Holmes from "assets/Images/Holmes/Holmes.webp";
import Holmes2x from "assets/Images/Holmes/Holmes@2x.webp";
import Holmes3x from "assets/Images/Holmes/Holmes@3x.webp";

const ImagesSrc = {
  LoginBg,
  SignUpBg,
  VerifyMobileBg,
  PrivacyPolicyBg,
  AppStore,
  GooglePlay,
  UploadImage,
  PlaceholderLogo,
  EmptyMenuLogo,
  UnderReviewLogo,
  FAQLogo,
  FAQLogo2x,
  FAQLogo3x,
  ChatLogo,
  ChatLogo2x,
  ChatLogo3x,

  // HARD CODED DATA

  MaskPlaceHolder,
  MaskPlaceHolder2x,
  MaskPlaceHolder3x,
  Holmes,
  Holmes2x,
  Holmes3x,
};

export default ImagesSrc;
