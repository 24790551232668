import { CustomButton, Typography } from "components/atoms";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { StorageUtils, getPayload } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { FormInput } from "components/molecules";
import { ValidationsWrapper } from "components/Containers";
import { phoneSignInStart } from "Redux/user/actions/user.actions";
import { selectCurrentUser } from "Redux/user/selectors/user.selectors";
import styles from "./LoginForm.module.css";
import { useTranslation } from "react-i18next";

const LoginForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const [rememberMeChecked, setRememberMeChecked] = useState(true);
  const [pass, setPass] = useState("");

  const [userCredentials, setCredentials] = useState({
    mobile_number: state?.mobile ?? "",
    password: "",
  });

  const phoneSignInStartHandler = (userCredentials) =>
    dispatch(phoneSignInStart(userCredentials));

  const handleSubmit = async (event) => {
    phoneSignInStartHandler(userCredentials);
  };

  useEffect(() => {
    if (rememberMeChecked) {
      localStorage.setItem("rememberMeChecked", true);
      localStorage.setItem("password", pass);
    } else {
      localStorage.setItem("rememberMeChecked", false);
      localStorage.setItem("password", "");
    }
  }, [rememberMeChecked]);

  useEffect(() => {
    if (rememberMeChecked && pass) {
      localStorage.setItem("password", pass);
    }
  }, [pass]);

  useEffect(() => {
    if (currentUser && !currentUser?.is_verified) {
      navigate("/Verify_mobile_number", {
        state: {
          scope: "verification",
          mobile: userCredentials?.mobile_number,
        },
      });
    } else if (localStorage.getItem("access_token")) {
      navigate("/main/orders/new");
      StorageUtils.removeItems(localStorage, [
        ("login_mobile_number", "varification_token"),
      ]);
    }
  }, [currentUser]);

  return (
    <ValidationsWrapper
      initialValues={{
        phone: userCredentials?.mobile_number ?? "",
        password: localStorage.getItem("password")
          ? localStorage.getItem("password")
          : "",
      }}
      rules={["phone", "password"]}
    >
      {({ FormikHandler }) => {
        return (
          <div className={styles.form}>
            <div>
              <Typography
                as="h1"
                size="6xl"
                color="text-text_white"
                text={t("Welcome back")}
              />

              <Typography
                as="p"
                size="lg"
                color="text-text_grey"
                fontWeight="font-medium"
                lineHeight="leading-[1.61]"
                classes="mt-2 lg:mt-3 3xl:mt-4"
                text={t("Please login to your account.")}
              />

              <div className={styles.formInputsContainer}>
                <FormInput
                  font="font-SFProText"
                  id="mobileNumber"
                  name="mobile_number"
                  type="tel"
                  width="w-full"
                  height="h-10"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label={t("Mobile Number")}
                  labelSize="sm"
                  value={FormikHandler.values.phone}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("phone", e.target.value);
                    getPayload(e, userCredentials, setCredentials);
                  }}
                  error={FormikHandler.errors.phone}
                />
                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    font="font-SFProText"
                    id="Password"
                    name="password"
                    type="password"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Password")}
                    labelSize="sm"
                    value={FormikHandler.values.password}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("password", e.target.value);
                      getPayload(e, userCredentials, setCredentials);
                      setPass(e.target.value);
                    }}
                    error={
                      FormikHandler.errors.password ===
                      FormikHandler.initialValues.password
                        ? ""
                        : FormikHandler.errors.password
                    }
                  />
                </div>
              </div>

              <div className="mt-5 lg:mt-6 3xl:mt-10 flex ">
                {/* <FormInput
                  id="rememberMe"
                  name="rememberMe"
                  type="checkbox"
                  width="w-4"
                  height="h-4"
                  fullWidth={false}
                  containerClasses="selectInputs"
                  inputTypeClass="checkboxOutlined"
                  bgColor="bg_transparent"
                  label={"تذكر كلمة المرور"}
                  labelSize="xs"
                  checked={rememberMeChecked}
                  onValueChange={() => setRememberMeChecked(!rememberMeChecked)}
                /> */}
                <Typography
                  as="span"
                  size="xs"
                  color="text-text_grey"
                  text={"نسيت كلمة المرور ؟"}
                  classes={`w-full ltr:text-right rtl:text-left ${
                    FormikHandler.values.phone && !FormikHandler.errors.phone
                      ? "cursor-pointer"
                      : "cursor-not-allowed"
                  }  `}
                  click={() => {
                    if (
                      FormikHandler.values.phone &&
                      !FormikHandler.errors.phone
                    ) {
                      navigate("/Verify_mobile_number", {
                        state: {
                          scope: "reset_password",
                          mobile: FormikHandler.values.phone,
                        },
                      });
                    }
                  }}
                />
              </div>

              <div className="mt-5 lg:mt-8 3xl:mt-10">
                <CustomButton
                  width="w-full"
                  height="h-10"
                  label={t("Sign in")}
                  disabled={!FormikHandler.isValid}
                  action={handleSubmit}
                />
              </div>

              <div className="mt-8 3xl:mt-12 flex justify-center">
                <Typography
                  as="p"
                  size="sm"
                  color="text-text_white"
                  text={"مستخدم جديد ؟"}
                />

                <Link to="/SignUp">
                  <Typography
                    as="span"
                    size="sm"
                    color="text-text_white"
                    fontWeight="font-bold"
                    classes="ml-1 rtl:mr-1 cursor-pointer "
                    text={t("Sign Up")}
                  />
                </Link>
              </div>
            </div>
          </div>
        );
      }}
    </ValidationsWrapper>
  );
};

export default LoginForm;
