import VendorActionTypes from "../types/vendor.types";

const INITIAL_STATE = {
  LogoProgressBarValue: null,
  ImageProgressBarValue: null,
  LogoURL: null,
  ImageURL: null,
  discount: null,
  vendorProfile: null,
  servingTypes: null,
  vendorMenuCategories: null,
  error: null,
  loading: false,
  isVendorUpdated: false,
  vendors: null,
  countries: null,
  currencies: null,
  payoutMethods: null,
};

const vendorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VendorActionTypes.UPDATE_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VendorActionTypes.UPDATE_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        discount: action.payload,
      };
    case VendorActionTypes.UPDATE_DISCOUNT_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.GET_DISCOUNT_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        discount: action.payload,
      };
    case VendorActionTypes.GET_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VendorActionTypes.ADD_VENDOR_MENU_CATEGORY_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.ADD_VENDOR_MENU_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VendorActionTypes.ADD_VENDOR_MENU_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case VendorActionTypes.ADD_DISCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case VendorActionTypes.GET_VENDOR_MENU_CATEGORIES_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.GET_VENDOR_MENU_CATEGORIES_SUCCESS:
      return {
        ...state,
        vendorMenuCategories: action.payload,
        loading: false,
      };
    case VendorActionTypes.GET_VENDOR_MENU_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case VendorActionTypes.LIST_SERVING_TYPES_SUCCESS:
      return {
        ...state,
        servingTypes: action.payload,
        loading: false,
      };
    case VendorActionTypes.LIST_SERVING_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case VendorActionTypes.LIST_PROFILE_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.LIST_PROFILE_SUCCESS:
      return {
        ...state,
        vendorProfile: action.payload,
        loading: false,
      };
    case VendorActionTypes.LIST_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case VendorActionTypes.UPDATE_PROFILE_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        vendorProfile: action.payload,
        isVendorUpdated: true,
        loading: false,
      };
    case VendorActionTypes.UPDATE_PROFILE_INFORMED:
      return {
        ...state,
        isVendorUpdated: false,
      };
    case VendorActionTypes.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case VendorActionTypes.LOGO_PROGRESS_BAR_START:
      return {
        ...state,
        LogoProgressBarValue: action.payload,
      };
    case VendorActionTypes.UPLOAD_LOGO_START:
      return {
        ...state,
      };
    case VendorActionTypes.UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        LogoURL: action.payload,
      };
    case VendorActionTypes.UPLOAD_LOGO_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case VendorActionTypes.IMAGE_PROGRESS_BAR_START:
      return {
        ...state,
        LogoProgressBarValue: action.payload,
      };
    case VendorActionTypes.UPLOAD_IMAGE_START:
      return {
        ...state,
      };
    case VendorActionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        ImageURL: action.payload,
      };
    case VendorActionTypes.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case VendorActionTypes.GET_ALL_VENDORS_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.GET_ALL_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        vendors: action.payload,
      };
    case VendorActionTypes.GET_ALL_VENDORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VendorActionTypes.LIST_ALL_COUNTRIES_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.LIST_ALL_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VendorActionTypes.LIST_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };
    case VendorActionTypes.LIST_ALL_CURRENCIES_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.LIST_ALL_CURRENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VendorActionTypes.LIST_ALL_CURRENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: action.payload,
      };
    case VendorActionTypes.LIST_ALL_PAYOUT_METHODS_START:
      return {
        ...state,
        loading: true,
      };
    case VendorActionTypes.LIST_ALL_PAYOUT_METHODS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VendorActionTypes.LIST_ALL_PAYOUT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        payoutMethods: action.payload,
      };
    // case VendorActionTypes.LIST_ALL_AREAS_START:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case VendorActionTypes.LIST_ALL_AREAS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    // case VendorActionTypes.LIST_ALL_AREAS_SUCCESS:
    //   return {
    //     ...state,
    //     areas: action.payload,
    //     loading: false,
    //   };
    // case VendorActionTypes.LIST_ALL_DELIVERY_METHODS_START:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case VendorActionTypes.LIST_ALL_DELIVERY_METHODS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    // case VendorActionTypes.LIST_ALL_DELIVERY_METHODS_SUCCESS:
    //   return {
    //     ...state,
    //     deliveryMethods: action.payload,
    //     loading: false,
    //   };
    // case VendorActionTypes.LIST_VENDOR_AREAS_START:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case VendorActionTypes.LIST_VENDOR_AREAS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    // case VendorActionTypes.LIST_VENDOR_AREAS_SUCCESS:
    //   return {
    //     ...state,
    //     vendorAreas: action.payload,
    //     loading: false,
    //   };
    // case VendorActionTypes.UPDATE_VENDOR_AREA_START:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case VendorActionTypes.UPDATE_VENDOR_AREA_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     vendorAreas: action.payload,
    //   };
    // case VendorActionTypes.UPDATE_VENDOR_AREA_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    // case VendorActionTypes.LIST_ALL_CITIES_START:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case VendorActionTypes.LIST_ALL_CITIES_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     cities: action.payload,
    //   };
    // case VendorActionTypes.LIST_ALL_CITIES_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    // case VendorActionTypes.LIST_AREAS_BY_CITY_START:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case VendorActionTypes.LIST_AREAS_BY_CITY_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     areas: action.payload.areas,
    //   };
    // case VendorActionTypes.LIST_AREAS_BY_CITY_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    default:
      return state;
  }
};

export default vendorReducer;
