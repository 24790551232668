import {
  CustomSlider,
  Footer,
  NavBar,
  PopularFavCard,
  ReOrderCard,
  SubNav,
} from "components/molecules";
import { Outlet, useNavigate } from "react-router";
import styles from "./Home.module.css";
const HomeTemplate = () => {
  return (
    <main>
      <NavBar />
      <div className="container  mx-auto mt-32">
        <Outlet />
      </div>

      <Footer />
    </main>
  );
};

export default HomeTemplate;
