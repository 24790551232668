import { useOrders } from "Hooks/useOrders/useOrders";
import { UnderReviewOrdersTemplate } from "templates";

function UnderReviewOrdersPage() {
  const [isLoading, isLoadingMore, hasMore, page, queryParams, orders] =
    useOrders("request_changes");
  // const dispatch = useDispatch();
  // const orders = useSelector(selectOrdersList);
  // const isLoading = useSelector(selectIsOrdersLoading);
  // const isLoadingMore = useSelector(selectIsOrdersLoadingMore);
  // const hasMore = useSelector(selectHasMore);
  // const [page, setPage] = useState(1);
  // const [queryParams, setQueryParams] = useState({
  //   status: "request_changes",
  //   page_number: 1,
  // });
  // useEffect(() => {
  //   if (orders) setPage((prev) => prev + 1);
  // }, [orders]);

  // useEffect(() => {
  //   setPage(1);
  // }, [queryParams]);

  // useEffect(() => {
  //   dispatch(listOrdersStart(queryParams));
  // }, [dispatch, queryParams]);

  // const handleChangeTimeSubmit = (id, time) => {
  //   dispatch(
  //     updateOrderStart({ id: id, body: { order: { time_in_minutes: time } } })
  //   );
  // };

  return (
    <UnderReviewOrdersTemplate
      // handleChangeTimeSubmit={handleChangeTimeSubmit}
      hasMore={hasMore}
      queryParams={queryParams}
      // setQueryParams={setQueryParams}
      orders={orders}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      page={page}
    />
  );
}

export default UnderReviewOrdersPage;
