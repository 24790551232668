import { CustomButton, MapMarker } from "components/atoms";
import { Modal, ModalHeader } from "components/molecules";
import { useEffect, useState } from "react";

import { GPSRed } from "utils/helpers/SVGsSrc";
import GoogleMapReact from "google-map-react";
import { PropTypes } from "prop-types";
import { StorageUtils } from "utils";
import classNames from "classnames";
import styles from "components/organisms/Autocomplete/Autocomplete.module.css";
import { useTranslation } from "react-i18next";

/*let autoComplete;

const MapAutoComplete = ({ detectUserCoords, addPlace, map, mapAPI }) => {
  const { t } = useTranslation();
  const autoCompleteRef = useRef(null);

  const onPlaceChanged = () => {
    const place = autoComplete.getPlace();

    addPlace(place);
  };

  useEffect(() => {
    const options = {
      types: ["establishment"],
      componentRestrictions: { country: "eg" },
      strictBounds: true,
    };

    autoComplete = new mapAPI.places.Autocomplete(
      autoCompleteRef.current,
      options
    );
    autoComplete.addListener("place_changed", onPlaceChanged);
  }, []);

  return (
    <div className="h-[42px] md:h-[58px] absolute left-1/2 top-[15%] transform -translate-x-1/2">
      <div className="h-[42px] md:h-[58px]">
        <FormInput
          containerClasses="flex"
          type="search"
          borderRadius="8px"
          bgColor="bg-bg_f5f5f5"
          textColor="text-warm_grey"
          height="h-[42px] md:h-[58px]"
          width="w-[310px] md:w-[530px] lg:w-[656px] 3xl:w-[820px]"
          placeholder={t("Search for city , area , street nam…")}
          inputRef={autoCompleteRef}
        />
        <div onClick={detectUserCoords} className={styles.icon}>
          <GPSRed width="100%" height="100%" title="Detect location" />
        </div>
      </div>
    </div>
  );
}; */

const MapLocationModal = ({
  isOpen,
  ToggleModalVisibility,
  selectedCenter,
  handleSelectedAddress,
  detectUserCoords,
  zoomLevel,
  zIndex = "z-30",
}) => {
  const { t } = useTranslation();
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapAPI, setMapAPI] = useState(null);

  const [DetectCenter, setDetectCenter] = useState(selectedCenter);
  // const [places, setPlaces] = useState([]);

  const [location, setLocation] = useState(selectedCenter);

  const [zoom /* , setZoom */] = useState(zoomLevel);

  const [address, setAddress] = useState("");
  const [center, setCenter] = useState(selectedCenter);

  // useEffect(() => {
  //   console.log({ address }, { center }, { location });
  // }, [address, center, location]);

  /* 
    const addPlace = (place) => {
      setPlaces([place]);
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setZoom(17);
      setLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      generateAddress();
    }; 
  */

  const onDrag = (value) => {
    setLocation({
      lat: value.center.lat(),
      lng: value.center.lng(),
    });
  };

  const onDragEnd = () => {
    generateAddress();
  };

  const onChange = ({ center, zoom }) => {
    setCenter({ lat: center?.lat, lng: center?.lng });
  };

  const apiHasLoaded = (map, maps) => {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapAPI(maps);

    generateAddress();
  };

  const generateAddress = () => {
    const geocoder = mapAPI && new mapAPI.Geocoder();

    geocoder?.geocode({ location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setAddress({
            ...results[0],
            structured_formatting: {
              main_text: results[0].formatted_address,
              secondary_text: "",
            },
          });
        } else {
          alert("No results found");
        }
      } else {
        alert("Geocoder failed due to: " + status);
      }
    });
  };

  useEffect(() => {
    if (!address) {
      generateAddress();
    }
  });

  useEffect(() => {
    if (DetectCenter) generateAddress();
  }, [DetectCenter]);

  const detectUserCoordsOnMap = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          const distance_cords = {
            lat: latitude,
            lng: longitude,
          };
          setCenter(distance_cords);
          setLocation(distance_cords);
          setDetectCenter(distance_cords);
        }
      );
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      ToggleModalVisibility={ToggleModalVisibility}
      zIndex={zIndex}
    >
      <div className="bg-white w-[100%] lg:w-[60%] ">
        <ModalHeader
          ToggleModalVisibility={ToggleModalVisibility}
          heading={"الموقع"}
        />

        <div className="relative" style={{ height: "70vh", width: "100%" }}>
          <div
            onClick={() => {
              detectUserCoordsOnMap();
            }}
            className={classNames(styles.MapIcon, "absolute top-20 z-40")}
          >
            <GPSRed width="100%" height="100%" title="Detect location" />
          </div>
          <GoogleMapReact
            center={center}
            zoom={zoom}
            draggable={true}
            onChange={onChange}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
              libraries: ["places", "geometry"],
            }}
            yesIWantToUseGoogleMapApiInternals
            shouldUnregisterMapOnUnmount
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
          >
            <MapMarker lat={center.lat} lng={center.lng} />
          </GoogleMapReact>
        </div>

        {/* 
          {mapApiLoaded && mapAPI !== null && (
            <MapAutoComplete
              detectUserCoords={detectUserCoords}
              addPlace={addPlace}
              map={mapInstance}
              mapAPI={mapAPI}
            />
          )} 
        */}

        <div className="p-4 shadow-[0_3px_6px_0_rgb(0,0,0,0.16)]">
          <CustomButton
            boxShadowClass="shadow-[0_3px_6px_0_rgb(0,0,0,0.16)]"
            borderRadius="4px"
            height="h-10"
            width="w-[310px] md:w-[530px] lg:w-[656px] 3xl:w-[820px]"
            label={"موقعك"}
            textColor="text-primary-50"
            action={() => {
              handleSelectedAddress(address, location);
              ToggleModalVisibility();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

MapLocationModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
  children: PropTypes.node,
};

export default MapLocationModal;

/* const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setDraggable(true);
    generateAddress();
  };

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setDraggable(false);
    setLocation({
      lat: mouse.lat,
      lng: mouse.lng,
    });
  }; */
