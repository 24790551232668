import CategoriesActionTypes from "../types/categories.types";

const INITIAL_STATE = {
  categories: null,
  error: null,
  loading: false,
};

const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoriesActionTypes.LIST_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    case CategoriesActionTypes.LIST_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
