import { useMemo } from "react";

import { PropTypes } from "prop-types";
import classnames from "classnames";

import styles from "./Typography.module.css";

const Typography = ({
  as,
  size,
  color,
  fontWeight,
  lineHeight,
  style,
  classes,
  text,
  href,
  target,
  click,
  Ref,
  Key = "",
}) => {
  const TypographyClasses = useMemo(() => {
    return {
      xs: "x-small",
      sm: "small",
      lg: "large",
      xl: "x-large",
      "2xl": "twoX-large",
      "3xl": "threeX-large",
      "4xl": "fourX-large",
      "5xl": "fiveX-large",
      "6xl": "sixX-large",
      "7xl": "sevenX-large",
      "8xl": "eightX-large",
      "9xl": "nineX-large",
      "10xl": "tenX-large",
    };
  }, []);

  const TypographyTypes = {
    TypographyTypesElements: (TypographyClasses) => {
      return {
        h1: (
          <h1
            key={Key}
            ref={Ref}
            style={style}
            className={classnames(
              color,
              fontWeight,
              lineHeight,
              classes,
              styles[TypographyClasses[size]]
            )}
            onClick={click}>
            {text}
          </h1>
        ),
        h2: (
          <h2
            key={Key}
            ref={Ref}
            style={style}
            className={classnames(
              color,
              fontWeight,
              lineHeight,
              classes,
              styles[TypographyClasses[size]]
            )}
            onClick={click}>
            {text}
          </h2>
        ),
        h3: (
          <h3
            key={Key}
            ref={Ref}
            style={style}
            className={classnames(
              color,
              fontWeight,
              lineHeight,
              classes,
              styles[TypographyClasses[size]]
            )}
            onClick={click}>
            {text}
          </h3>
        ),
        h4: (
          <h4
            key={Key}
            ref={Ref}
            style={style}
            className={classnames(
              color,
              fontWeight,
              lineHeight,
              classes,
              styles[TypographyClasses[size]]
            )}
            onClick={click}>
            {text}
          </h4>
        ),
        h5: (
          <h5
            key={Key}
            ref={Ref}
            style={style}
            className={classnames(
              color,
              fontWeight,
              lineHeight,
              classes,
              styles[TypographyClasses[size]]
            )}
            onClick={click}>
            {text}
          </h5>
        ),
        h6: (
          <h6
            key={Key}
            ref={Ref}
            style={style}
            className={classnames(
              color,
              fontWeight,
              lineHeight,
              classes,
              styles[TypographyClasses[size]]
            )}
            onClick={click}>
            {text}
          </h6>
        ),
        p: (
          <p
            key={Key}
            ref={Ref}
            style={style}
            className={classnames(
              color,
              fontWeight,
              lineHeight,
              classes,
              styles[TypographyClasses[size]]
            )}
            onClick={click}>
            {text}
          </p>
        ),
        a: (
          <a
            key={Key}
            ref={Ref}
            href={href}
            target={target}
            rel="noreferrer"
            style={style}
            className={classnames(
              color,
              fontWeight,
              lineHeight,
              classes,
              styles[TypographyClasses[size]]
            )}>
            {text}
          </a>
        ),
        span: (
          <span
            key={Key}
            ref={Ref}
            style={style}
            className={classnames(
              color,
              fontWeight,
              lineHeight,
              classes,
              styles[TypographyClasses[size]]
            )}
            onClick={click}>
            {text}
          </span>
        ),
      };
    },
  };

  return TypographyTypes.TypographyTypesElements(TypographyClasses)[as];
};

Typography.propTypes = {
  as: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.string,
  classes: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  href: PropTypes.string,
  target: PropTypes.string,
};

export default Typography;
