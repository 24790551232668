import { all, call } from "redux-saga/effects";

import { userSagas } from "./user/sagas/user.sagas";
import { categoriesSagas } from "./categories/sagas/categories.sagas";
import { ordersSagas } from "./orders/sagas/orders.sagas";
import { productsSaga } from "./products/sagas/products.sagas";
import { vendorSagas } from "./vendor/sagas/vendor.sagas";

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(categoriesSagas),
    call(ordersSagas),
    call(productsSaga),
    call(vendorSagas),
  ]);
}
