import { createSelector } from "reselect";

const selectProducts = (state) => state.products;

export const productImageSelector = createSelector(
  [selectProducts],
  (products) => products.productImageURL
);

export const hasMoreSelector = createSelector(
  [selectProducts],
  (products) => products.hasMore
);
export const selectProductList = createSelector(
  [selectProducts],
  (products) => products.products
);

export const selectIsProductsLoading = createSelector(
  [selectProducts],
  (products) => products.loading
);

export const selectIsProductLoadingMore = createSelector(
  [selectProducts],
  (products) => products.loadingMore
);

export const selectProduct = createSelector(
  [selectProducts],
  (products) => products.product
);

export const selectMainCategories = createSelector(
  [selectProducts],
  (products) =>
    products.mainCategories?.map((category) => {
      return { value: category.id, label: category.name_ar };
    })
);

export const selectSavingTemp = createSelector([selectProducts], (products) =>
  products.savingTemp?.map((temp) => {
    return { value: temp.id, label: temp.name_ar };
  })
);

export const selectTags = createSelector([selectProducts], (products) =>
  products.tags?.map((tag) => {
    return { ...tag, name: tag.name_ar };
  })
);

export const selectSizeUnits = createSelector([selectProducts], (products) =>
  products.sizeUnits?.map((unit) => {
    return { value: unit.id, label: unit.name_ar };
  })
);

export const selectSizes = createSelector([selectProducts], (products) =>
  products.sizes?.map((size) => {
    return {
      value: size.id,
      label: size.name_ar,
    };
  })
);

export const selectError = createSelector(
  [selectProducts],
  (products) => products.error
);

export const selectAddProductError = createSelector(
  [selectProducts],
  (products) => products.addProductError
);

export const selectSuccessConfirmationModalState = createSelector(
  [selectProducts],
  (products) => products.successConfirmationModal
);
