import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetNotificationStart } from "Redux/user/actions/user.actions";

import { selectNotifications } from "Redux/user/selectors/user.selectors";

import { NotificationsTemplate } from "templates";

const NotificationsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetNotificationStart());
  }, []);

  const Notifications = useSelector(selectNotifications);

  return <NotificationsTemplate Notifications={Notifications} />;
};

export default NotificationsPage;
