import React from "react";

import { VerifyMobileForEditForm } from "../../Auth/AuthComponents";
import { Footer, NavBar } from "components/molecules";

const VerifyMobileForEditTemplate = () => {
  return (
    <>
      <NavBar />
      <VerifyMobileForEditForm process={"change"} />
      <Footer />
    </>
  );
};

export default VerifyMobileForEditTemplate;
