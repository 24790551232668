import classnames from "classnames";
import { PropTypes } from "prop-types";

import styles from "./CustomButton.module.css";

const CustomButton = ({
  label,
  AfterIcon,
  BeforeIcon,
  buttonTypeClass = "primary",
  bgImage,
  width,
  height,
  action,
  disabled = false,
  type,
  borderRadius,
  borderColor = "border-text_white",
  textColor = "text-text_white ",
  classes,
  boxShadowClass = null,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={action}
      style={{
        backgroundImage: bgImage ? `url(${bgImage})` : "none",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: borderRadius ? borderRadius : "",
      }}
      className={classnames(
        styles.button,
        width,
        height,
        classes,
        styles[buttonTypeClass],
        {
          [styles.disabled]: disabled,
        },
        { [borderColor]: buttonTypeClass === "outlined" },
        { [textColor]: buttonTypeClass === "outlined" },
        { [styles[boxShadowClass]]: boxShadowClass }
      )}
    >
      <div className="flex items-center justify-center ">
        {BeforeIcon ?? ""}
        {label ?? ""}
        {AfterIcon ?? ""}
      </div>
    </button>
  );
};

CustomButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonTypeClass: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  borderRadius: PropTypes.string,
  boxShadowClass: PropTypes.string,
};

export default CustomButton;
