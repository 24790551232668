import { PropTypes } from "prop-types";
import classnames from "classnames";

import { ImagesSrc } from "utils";

import styles from "./FullScreenBgLayout.module.css";

const FullScreenBgLayout = ({
  children,
  backgroundImage,
  glassContainerWidth,
  glassContainerHight,
  glassContainerStyles,
  signup,
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${ImagesSrc[backgroundImage]})` }}
      className={signup ? styles.containerSignup : styles.container}
    >
      <div
        className={signup ? styles.innerContainerSignup : styles.innerContainer}
      >
        <div
          className={
            signup
              ? classnames(
                  glassContainerWidth,
                  glassContainerHight,
                  styles.glassContainerSignup,
                  glassContainerStyles
                )
              : classnames(
                  glassContainerWidth,
                  glassContainerHight,
                  styles.glassContainer,
                  glassContainerStyles
                )
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

FullScreenBgLayout.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  glassContainerWidth: PropTypes.string,
  glassContainerHight: PropTypes.string,
};

export default FullScreenBgLayout;
