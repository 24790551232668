import { CustomButton, Img, Typography } from "components/atoms";

import { ImagesSrc } from "utils";
import { useEffect } from "react";
import { useState } from "react";

function ProductDataTemplate({ product, handleEdit, handleDelete }) {
  const [language, setLanguage] = useState(1);
  const [customOptions, setCustomOptions] = useState([]);
  useEffect(() => {
    const filteredOptions = product?.product_options.splice(
      2,
      product?.product_options.length
    );
    setCustomOptions(filteredOptions);
  }, [product]);
  return (
    <div className="flex 2xl:px-28  mt-32">
      <div className="container mx-auto mt-10 w-1/2">
        {/* Name and description Section*/}
        <div className="bg-[#f8f8fe] px-10 mb-14 py-10">
          <div>
            <Typography
              as="span"
              size="4xl"
              color="text-text_3b3b3b"
              lineHeight="leading-[1.21]"
              text={language === 1 ? product?.name_ar : product?.name_en}
            />
          </div>
          <div>
            <Typography
              as="span"
              size="xl"
              color="text-warm_grey"
              n
              lineHeight="leading-[1.21]"
              text={
                language === 1
                  ? product?.description_ar
                  : product?.description_en
              }
            />
          </div>
        </div>
        {/* Main section */}
        <div className="bg-[#f8f8fe]">
          <div className="flex justify-start border-b-2 px-10 py-10">
            <div className="w-1/4  ml-10">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"الفئة"}
              />
            </div>
            <div className="xl:grid xl:grid-cols-5 md:grid-cols-4 grid grid-cols-1 w-3/4">
              {product?.menu_categories?.map((category) => {
                return (
                  <div>
                    <Typography
                      as="span"
                      size="xl"
                      color="text-text_3b3b3b"
                      lineHeight="leading-[1.21]"
                      text={
                        language === 1 ? category?.name_ar : category?.name_en
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-start border-b-2 px-10 py-10">
            <div className="w-1/4  ml-10">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"درجة حرارة الحفظ"}
              />
            </div>
            <div className=" w-3/4">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={
                  language === 1
                    ? product?.saving_temperature_ar
                    : product?.saving_temperature_en
                }
              />
            </div>
          </div>
          <div className="flex justify-start border-b-2 px-10 py-10">
            <div className="w-1/4 ml-10">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"وقت التحضير"}
              />
            </div>
            <div className="w-3/4">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                classes="font-SFProText"
                text={product?.time_in_minutes + " دقيقة"}
              />
            </div>
          </div>
          <div className="flex justify-start border-b-2 px-10 py-10">
            <div className="w-1/4 ml-10">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"خواص تميز العنصر"}
              />
            </div>
            <div className="xl:grid xl:grid-cols-5 md:grid-cols-4 grid grid-cols-1 w-3/4">
              {product?.tags?.map((tag) => {
                return (
                  <div>
                    <Typography
                      as="span"
                      size="xl"
                      color="text-text_3b3b3b"
                      lineHeight="leading-[1.21]"
                      text={language === 1 ? tag?.name_ar : tag?.name_en}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-start border-b-2 px-10 py-10">
            <div className="w-1/4  ml-10">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"وحدة الحجم"}
              />
            </div>
            <div className=" w-3/4">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={
                  language === 1
                    ? product?.sizes[0].size_unit_name_ar
                    : product?.sizes[0].size_unit_name_en
                }
              />
            </div>
          </div>
          <div className="flex justify-start border-b-2 px-10 py-10">
            <div className="w-1/4 ml-10">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"الأحجام المتاحة"}
              />
            </div>
            <div className="xl:grid xl:grid-cols-5 md:grid-cols-4 grid grid-cols-1 w-3/4">
              {product?.sizes?.map((size) => {
                return (
                  <div>
                    <Typography
                      classes="font-SFProText"
                      as="span"
                      size="xl"
                      color="text-text_3b3b3b"
                      lineHeight="leading-[1.21]"
                      text={
                        language === 1 ? size?.size_name_ar : size?.size_name_en
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-start border-b-2 px-10 py-10">
            <div className="w-1/4 ml-10">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"الأسعار"}
              />
            </div>
            <div className="xl:grid xl:grid-cols-5 md:grid-cols-4 grid grid-cols-1 w-3/4">
              {product?.sizes?.map((size) => {
                return (
                  <div>
                    <Typography
                      classes={"font-SFProText"}
                      as="span"
                      size="xl"
                      color="text-text_3b3b3b"
                      lineHeight="leading-[1.21]"
                      text={size?.price + " ج.م"}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-start border-b-2 px-10 py-10">
            <div className="w-1/4 ml-10">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"الأيام التي يكون متوفر بها"}
              />
            </div>
            <div className="xl:grid xl:grid-cols-5 md:grid-cols-4 grid grid-cols-1 w-3/4">
              {product?.week_days?.map((day) => {
                return (
                  <div>
                    <Typography
                      as="span"
                      size="xl"
                      color="text-text_3b3b3b"
                      lineHeight="leading-[1.21]"
                      text={day?.day}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-start border-b-2 px-10 py-10">
            <div className="w-1/4  ml-10">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={"قيمة الخصم"}
              />
            </div>
            <div className=" w-3/4">
              <Typography
                as="span"
                size="xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                text={
                  product?.discount
                    ? product?.discount.type[0]
                      ? product?.discount.value + " ج.م"
                      : product?.discount.value + "%"
                    : "لا يوجد خصم"
                }
              />
            </div>
          </div>
        </div>
        {/* Options with prices section */}
        <div className="my-10">
          <Typography
            as="span"
            size="2xl"
            color="text-text_3b3b3b"
            lineHeight="leading-[1.21]"
            fontWeight="font-bold"
            text="الإضافات"
          />
        </div>
        <div className="bg-[#f8f8fe] my-10">
          {product?.product_options[0]?.option_values.map((option) => {
            return (
              <div className="flex justify-between border-b-2 px-10 py-10">
                <div>
                  <Typography
                    as="span"
                    size="xl"
                    color="text-text_3b3b3b"
                    lineHeight="leading-[1.21]"
                    text={language === 1 ? option?.name_ar : option?.name_en}
                  />
                </div>
                <div>
                  <Typography
                    classes="font-SFProText"
                    as="span"
                    size="xl"
                    color="text-text_3b3b3b"
                    lineHeight="leading-[1.21]"
                    text={option?.price_addition + " ج.م"}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="my-10">
          <Typography
            as="span"
            size="2xl"
            color="text-text_3b3b3b"
            lineHeight="leading-[1.21]"
            fontWeight="font-bold"
            text="عناصر يمكن جمعها مع العنصر"
          />
        </div>
        <div className="bg-[#f8f8fe] my-10">
          {product?.product_options[1]?.option_values.map((option) => {
            return (
              <div className="flex justify-between border-b-2 px-10 py-10">
                <div>
                  <Typography
                    as="span"
                    size="xl"
                    color="text-text_3b3b3b"
                    lineHeight="leading-[1.21]"
                    text={language === 1 ? option?.name_ar : option?.name_en}
                  />
                </div>
                <div>
                  <Typography
                    as="span"
                    size="xl"
                    color="text-text_3b3b3b"
                    lineHeight="leading-[1.21]"
                    classes="font-SFProText"
                    text={option?.price_addition + " ج.م"}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {customOptions?.map((customOption) => {
          return (
            <div className="my-10">
              <Typography
                as="span"
                size="2xl"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.21]"
                fontWeight="font-bold"
                text={
                  language === 1 ? customOption?.name_ar : customOption?.name_en
                }
              />
              <div className="bg-[#f8f8fe] my-10">
                {customOption?.option_values.map((option) => {
                  return (
                    <div className="flex justify-between border-b-2 px-10 py-10">
                      <div>
                        <Typography
                          as="span"
                          size="xl"
                          color="text-text_3b3b3b"
                          lineHeight="leading-[1.21]"
                          text={
                            language === 1 ? option?.name_ar : option?.name_en
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="flex justify-around">
          <div className="w-1/4">
            <CustomButton
              width="w-full"
              height="h-10"
              label={"تعديل"}
              action={handleEdit}
            />
          </div>
          <div className="w-1/4">
            <CustomButton
              boxShadowClass={"goBoxShadow"}
              buttonTypeClass="outlined"
              textColor="text-primary-900"
              borderColor="border-primary-900"
              classes="bg-[#ffffff] border-2"
              width="w-full"
              height="h-10"
              label={"حذف"}
              action={handleDelete}
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-10  w-1/3 ">
        <div className="mx-6">
          <Img
            fileName={
              product?.image ? product?.image : ImagesSrc.PlaceholderLogo
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ProductDataTemplate;
