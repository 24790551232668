const OrdersActionTypes = {
  UPDATE_ORDER_START: "UPDATE_ORDER_START",
  UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
  UPDATE_ORDER_FAILURE: "UPDATE_ORDER_FAILURE",
  LIST_ORDERS_START: "LIST_ORDERS_START",
  LIST_ORDERS_SUCCESS: "LIST_ORDERS_SUCCESS",
  LIST_ORDERS_FAILURE: "LIST_ORDERS_FAILURE",
  LOAD_MORE_ORDERS_START: "LOAD_MORE_ORDERS_START",
  LOAD_MORE_ORDERS_FAILURE: "LOAD_MORE_ORDERS_FAILURE",
  LOAD_MORE_ORDERS_SUCCESS: "LOAD_MORE_ORDERS_SUCCESS",
  UPDATE_ORDER_STATUS_START: "UPDATE_ORDER_STATUS_START",
  UPDATE_ORDER_STATUS_SUCCESS: " UPDATE_ORDER_STATUS_SUCCESS",
  UPDATE_ORDER_STATUS_FAILURE: "UPDATE_ORDER_STATUS_FAILURE",
};

export default OrdersActionTypes;
