import { useState, useEffect, useCallback } from "react";

let countdown = null;

const useCountDown = (countSecs) => {
  const [timer, setTimer] = useState(countSecs);

  const handleCountDown = useCallback(() => {
    let counter = countSecs;
    clearInterval(countdown);
    countdown = setInterval(() => {
      setTimer(--counter);
    }, 1000);
    setTimeout(() => {
      clearInterval(countdown);
      setTimer(0);
    }, countSecs * 1000);
  }, [countSecs]);

  useEffect(() => {
    handleCountDown();
  }, [handleCountDown]);

  return { timer, handleCountDown };
};

export default useCountDown;
