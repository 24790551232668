import { StorageUtils, eventEmitter } from "utils";
import { showToast, toastTypes } from "utils/helpers/Toast";

import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_MODE
    : window.location.origin === "https://vendorstaging.ablanazira.com"
    ? process.env.REACT_APP_DEV_MODE
    : process.env.REACT_APP_PRO_MODE;

axios.defaults.baseURL = BASE_URL;

const token = StorageUtils.getItems(localStorage, ["access_token"])[
  "access_token"
];

axios.defaults.headers.common["Authorization"] = token
  ? `Bearer ${token}`
  : null;
axios.defaults.headers.common["Accept-Language"] = "ar-EG, ar";
const CommonHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const getAxios = () => {
  const callS3 = async (url = "", data = {}) => {
    let instance = axios.create({
      onUploadProgress: function (progressEvent) {
        eventEmitter.emit(
          "UPLOAD_PROGRESS",
          Math.floor(100 * (progressEvent.loaded / progressEvent.total))
        );
      },
    });
    delete instance.defaults.headers.common;
    try {
      const response = await instance.put(url, data);
      return response;
    } catch (error) {
      showToast(toastTypes.ERR, error?.response?.data?.message);
      console.log({ error });
    }
  };
  const setHeaders = (header, value) => {
    axios.defaults.headers.common[header] = value;
  };

  const call = async (method = "get", url = "", data = {}, headers = {}) => {
    try {
      const response = await axios[method](url, data, {
        ...CommonHeaders,
        headers,
      });

      return response;
    } catch (error) {
      if (!(method === "get" && url.includes("/cart/cart")))
        showToast(toastTypes.ERR, error?.response?.data?.message);
      console.log({ error });
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        if (
          url.includes("/auth/send_verification_message") ||
          url.includes("/auth/verify_otp") ||
          url.includes("/auth/change_password") ||
          url.includes("/update_mobile")
        ) {
        } else {
          localStorage.clear();
          window.location.replace("/login");
        }
      }
    }
  };

  return {
    setHeaders,
    call,
    callS3,
  };
};

export default getAxios;
