import { PropTypes } from "prop-types";

import FadeLoader from "react-spinners/FadeLoader";

const Spinner = ({ loading, color = "#bf0022" }) => {
  return (
    <div className="w-full h-full py-10 grid place-items-center items-center">
      <FadeLoader color={color} loading={loading} />
    </div>
  );
};

Spinner.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.bool,
};

export default Spinner;
