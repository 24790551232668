import React from "react";

import { FullScreenBgLayout } from "components/Layouts";
import { VerifyMobileNumberForm } from "../AuthComponents";

const VerifyMobileNumberTemplate = () => {
  return (
    <FullScreenBgLayout
      backgroundImage="VerifyMobileBg"
      glassContainerWidth="md:w-4/5 xl:w-1/2"
      glassContainerHight="md:h-3/4"
      glassContainerStyles="px-6 lg:px-8"
    >
      <VerifyMobileNumberForm />
    </FullScreenBgLayout>
  );
};

export default VerifyMobileNumberTemplate;
