import { CustomButton, Typography } from "components/atoms";
import { FullStar, Location, PhoneIcon } from "utils/helpers/SVGsSrc";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import ChangeDurationModal from "../ChangeDurationModal";
import { selectVendorProfile } from "Redux/vendor/selectors/vendor.selectors";

function OrderDetails({
  order,
  queryParams,
  handleChangeTimeSubmit,
  handleAccept,
  handleReject,
  handleDeliver,
}) {
  const currentUser = useSelector(selectVendorProfile);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [stars, setStars] = useState([<></>, <></>, <></>, <></>, <></>]);
  const [deliveryRatingStars, setDeliveryRatingStars] = useState([
    <></>,
    <></>,
    <></>,
    <></>,
    <></>,
  ]);
  const [date, setDate] = useState("");
  const [creationDate, setCreationDate] = useState("");

  const addressFormatter = (order) => {
    let formattedAddress = order?.customer_address
      ? "اسم الشارع: " +
        order?.customer_address?.street_name +
        " -" +
        " رقم المبنى: " +
        order?.customer_address?.building +
        " -" +
        " رقم الدور: " +
        order?.customer_address?.floor_number +
        " -" +
        " رقم الشقة: " +
        order?.customer_address?.apartment_number
      : "لا يوجد عنوان متاح";

    if (order?.customer_address?.landmark) {
      return (
        formattedAddress +
        " - " +
        "معلم معروف: " +
        order?.customer_address?.landmark
      );
    }

    return formattedAddress;
  };

  useEffect(() => {
    if (order?.order_rating) {
      setStars(
        stars.fill(<FullStar className="w-4 h-4" />, 0, order?.order_rating)
      );
    }
    if (order?.delivery_rating) {
      setDeliveryRatingStars(
        deliveryRatingStars.fill(
          <FullStar className="w-4 h-4" />,
          0,
          order?.delivery_rating
        )
      );
    }
  }, [order]);

  useEffect(() => {
    const utc = new Date(order?.delivery_date_time_vendor);
    const cdate = new Date(order?.created_at);
    setDate(
      utc.toLocaleString("ar-Eg", { dateStyle: "short", timeStyle: "short" })
    );
    setCreationDate(
      cdate.toLocaleString("ar-Eg", { dateStyle: "short", timeStyle: "short" })
    );
  }, [order]);

  return (
    <div>
      {/* Modal for Changing Order Duration*/}
      <ChangeDurationModal
        ToggleModalVisibility={setModalOpen}
        modalOpen={modalOpen}
        handleChangeTimeSubmit={handleChangeTimeSubmit}
        id={order?.id}
        successModalOpen={successModalOpen}
        setSuccessModalOpen={setSuccessModalOpen}
      />

      {/* Section1 */}
      <div className="border-b-2 my-4">
        {/* Row */}
        <div className="flex justify-between mb-4 mx-14">
          <Typography
            as="span"
            size="2xl"
            color="text_3b3b3b"
            lineHeight="leading-[1.21]"
            classes={"font-SFProText"}
            text={" الطلب رقم " + order?.hash_id}
          />
          <div className="flex justify-evenly">
            <Typography
              as="span"
              size="2xl"
              color="text_3b3b3b"
              classes="ml-14"
              lineHeight="leading-[1.21]"
              text={creationDate}
            />
            {/* <Typography
              as="span"
              size="2xl"
              color="text_3b3b3b"
              classes=""
              lineHeight="leading-[1.21]"
              text={"    ٢٢:١٥ "}
            /> */}
          </div>
        </div>
      </div>
      <div className="border-b-2 my-4">
        {/* Row */}
        <div className="flex justify-between mb-4 mx-14">
          <Typography
            as="span"
            size="lg"
            color="text_3b3b3b"
            lineHeight="leading-[1.21]"
            classes={"font-SFProText"}
            text={"نوع الطلب"}
          />
          <div className="flex justify-evenly">
            <Typography
              as="span"
              size="lg"
              color="text_3b3b3b"
              classes="ml-14"
              lineHeight="leading-[1.21]"
              text={order?.cart?.is_pickup ? "استلام من موقعي" : "توصيل"}
            />
          </div>
        </div>
      </div>
      {queryParams.status === "current" ? (
        <div className="border-b-2 my-4">
          {/* Row */}
          <div className="flex justify-between mb-4 mx-14">
            <Typography
              as="span"
              size="lg"
              fontWeight="font-semibold"
              color="text_3b3b3b"
              lineHeight="leading-[1.21]"
              text={"مدة العمل على الطلب"}
            />
            <div className="flex justify-evenly">
              <Typography
                as="span"
                size="xl"
                fontWeight="font-normal"
                color="text_3b3b3b"
                classes={"font-SFProText"}
                lineHeight="leading-[1.21]"
                text={order?.cart?.delivery_time + " " + "دقيقة  "}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Section 2 / CART ITEMS*/}
      <div className=" border-b-2 ">
        {order?.cart?.cart_items.map((item) => (
          <>
            <div className="flex justify-between mx-14 my-4">
              <Typography
                as="span"
                size="lg"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                text={item?.product_name + " " + "x" + item?.quantity}
              />
              <Typography
                as="span"
                size="lg"
                color="text-warm_grey"
                classes={"font-SFProText"}
                lineHeight="leading-[1.21]"
                text={item?.price === -1 ? "" : item?.price}
              />
            </div>

            <div className="flex justify-between mx-14 my-4">
              <Typography
                as="span"
                size="lg"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                text={item?.size?.size_name}
              />
              {/* <Typography
                as="span"
                size="lg"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                classes={"font-SFProText"}
                text={item?.size?.price}
              /> */}
            </div>

            {/* Extra options for the order */}
            {item?.options?.map((option) => (
              <>
                <div className="flex justify-between mx-14 mb-3">
                  <Typography
                    as="span"
                    size="lg"
                    color="text-warm_grey"
                    lineHeight="leading-[1.21]"
                    text={option?.option_value?.sub_option_name}
                  />
                  <Typography
                    as="span"
                    size="lg"
                    color="text-warm_grey"
                    classes={"font-SFProText"}
                    lineHeight="leading-[1.21]"
                    text={
                      option?.option_value?.price === -1
                        ? ""
                        : option?.option_value?.price
                    }
                  />
                </div>
              </>
            ))}
            <div className="flex justify-between mx-14 my-4">
              <Typography
                as="span"
                size="lg"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                text={"تعليمات خاصة"}
              />
              <Typography
                as="span"
                size="lg"
                color="text-warm_grey"
                lineHeight="leading-[1.21]"
                text={item?.special_instructions}
              />
            </div>
          </>
        ))}
      </div>
      {/* Section 3 */}
      <div className=" border-b-2">
        <div className="flex justify-between mx-14 my-4">
          <Typography
            as="span"
            size="2xl"
            classes="my-4"
            color="text_3b3b3b"
            lineHeight="leading-[1.21]"
            text={"ملاحظات إضافية"}
          />
        </div>
        <div className="flex justify-between mx-14 mb-3">
          <Typography
            as="span"
            size="lg"
            color="text-warm_grey"
            classes=""
            lineHeight="leading-[1.21]"
            text={order?.cart?.special_instructions}
          />
        </div>
      </div>
      {/* Section 4 / INVOICE DETAILS */}
      <div className=" border-b-2">
        <div className="flex justify-between mx-14 my-6">
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes=""
            lineHeight="leading-[1.21]"
            text="المجموع"
          />
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes={"font-SFProText"}
            lineHeight="leading-[1.21]"
            text={order?.invoice_details?.sub_total}
          />
        </div>
        <div className="flex justify-between mx-14 my-6">
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes=""
            lineHeight="leading-[1.21]"
            text="خصم البائع"
          />
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes={"font-SFProText"}
            lineHeight="leading-[1.21]"
            text={order?.cart?.vendor_discount + "-"}
          />
        </div>
        <div className="flex justify-between mx-14 my-6">
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes=""
            lineHeight="leading-[1.21]"
            text="خصم ترويجي"
          />
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes={"font-SFProText"}
            lineHeight="leading-[1.21]"
            text={order?.cart?.discount + "-"}
          />
        </div>
        <div className="flex justify-between mx-14 my-6">
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes=""
            lineHeight="leading-[1.21]"
            text="إجمالي الخصم"
          />
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes={"font-SFProText"}
            lineHeight="leading-[1.21]"
            text={order?.invoice_details?.discount + "-"}
          />
        </div>

        <div className="flex justify-between mx-14 my-6">
          {!order?.cart?.is_pickup && (
            <>
              <Typography
                as="span"
                size="xl"
                color="text-warm_grey"
                classes=""
                lineHeight="leading-[1.21]"
                text="قيمة التوصيل"
              />
              <Typography
                as="span"
                size="xl"
                color="text-warm_grey"
                classes={"font-SFProText"}
                lineHeight="leading-[1.21]"
                text={order?.invoice_details?.delivery_fee}
              />
            </>
          )}
        </div>
        <div className="flex justify-between mx-14 my-6">
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes=""
            lineHeight="leading-[1.21]"
            text="الإجمالي"
          />
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes={"font-SFProText"}
            lineHeight="leading-[1.21]"
            text={order?.invoice_details?.total}
          />
        </div>
        <div className="flex justify-between mx-14 my-6">
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes=""
            lineHeight="leading-[1.21]"
            text="طريقة الدفع"
          />
          <Typography
            as="span"
            size="xl"
            color="text-warm_grey"
            classes=""
            lineHeight="leading-[1.21]"
            text={
              order?.payment_type[1]
                ? order?.payment_type[1]
                : order?.payment_type[0]
            }
          />
        </div>
      </div>
      {/* Section 5 */}
      {queryParams.status === "new" ? (
        <div className=" border-b-2">
          <div className="flex justify-between mx-14 my-4">
            <Typography
              as="span"
              size="2xl"
              classes="my-4"
              color="text_3b3b3b"
              lineHeight="leading-[1.21]"
              text={"مدة العمل على الطلب"}
            />
          </div>
          <div className="flex justify-between mx-14 my-4">
            <Typography
              as="span"
              size="2xl"
              color="text-warm_grey"
              classes={"font-SFProText"}
              lineHeight="leading-[1.21]"
              text={order?.cart?.time_in_minutes + " " + "دقيقة  "}
            />
            <Typography
              as="span"
              size="2xl"
              color="text-primary-900"
              classes="cursor-pointer"
              lineHeight="leading-[1.21]"
              text="تغيير المدة"
              click={() => setModalOpen(!modalOpen)}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Section 6 */}
      <div className=" border-b-2">
        <div className="flex justify-between mx-14">
          <Typography
            as="span"
            size="2xl"
            classes="my-4"
            color="text_3b3b3b"
            lineHeight="leading-[1.21]"
            text={"ميعاد التسليم"}
          />
        </div>
        <div className="flex justify-items-start mx-14 mb-3 ">
          <Typography
            as="span"
            size="2xl"
            color="text-warm_grey"
            classes="ml-14"
            lineHeight="leading-[1.21]"
            text={date}
          />
          {/* <Typography
            as="span"
            size="2xl"
            color="text-warm_grey"
            lineHeight="leading-[1.21]"
            text="١١:٠٠ صباحا"
          /> */}
        </div>
      </div>
      {/* Section 7 */}
      {queryParams.status === "completed" ? (
        <div className=" border-b-2">
          <div className="flex justify-between mx-14 my-6">
            <Typography
              as="span"
              size="xl"
              color="text-warm_grey"
              lineHeight="leading-[1.21]"
              text={"تقييم الطلب"}
            />
            <div className="flex">{stars}</div>
          </div>
        </div>
      ) : (
        <div className=" border-b-2">
          <div className="flex justify-between mx-14">
            <Typography
              as="span"
              size="2xl"
              classes="my-4"
              color="text_3b3b3b"
              lineHeight="leading-[1.21]"
              text={"بيانات العميل"}
            />
          </div>
          <div className="flex justify-items-start mx-14 mb-3">
            <div className="w-4 h-6 ml-14">
              <Location width="100%" height="100%" title="location" />
            </div>
            <Typography
              as="span"
              size="lg"
              color="text-warm_grey"
              classes=""
              lineHeight="leading-[1.21]"
              text={
                // order?.customer_address?.address +
                addressFormatter(order)
              }
            />
          </div>
          <div className="flex justify-items-start mx-14 mb-3">
            <div className="w-4 h-6 ml-14">
              <Location width="100%" height="100%" title="location" />
            </div>
            <Typography
              as="span"
              size="lg"
              color="text-warm_grey"
              classes="ml-10"
              lineHeight="leading-[1.21]"
              text={order?.customer_address?.address}
            />
            <a
              href={`http://maps.google.com/maps?z=12&t=m&q=loc:${order?.customer_address?.lat}+${order?.customer_address?.lng}`}
              target={"_blank"}
            >
              <Typography
                as="span"
                size="lg"
                color="text-primary-900"
                lineHeight="leading-[1.21]"
                text={"الاتجاهات"}
              />
            </a>
          </div>
          {order?.customer_address?.mobile && (
            <div className="flex justify-items-start mx-14 mb-3">
              <div className="w-4 h-6 ml-14">
                <PhoneIcon width="100%" height="100%" title="Phone" />
              </div>
              <Typography
                as="span"
                size="lg"
                color="text-warm_grey"
                classes=""
                lineHeight="leading-[1.21]"
                text={order?.customer_address?.mobile}
              />
            </div>
          )}
        </div>
      )}

      {/* section 8 */}
      {queryParams.status === "new" ? (
        <div className=" border-b-4 mt-10 pb-10">
          <div className="flex justify-center mx-14 mb-3 mt-3">
            <div className="flex justify-between">
              <div className="w-52 pr-4">
                <CustomButton
                  width="w-full"
                  height="h-10"
                  label={"قبول"}
                  action={() => handleAccept(order?.id)}
                />
              </div>
              <div className="w-52 mr-10 pl-4">
                <CustomButton
                  boxShadowClass={"goBoxShadow"}
                  buttonTypeClass="outlined"
                  textColor="text-primary-900"
                  borderColor="border-primary-900"
                  classes="bg-[#ffffff] border-2"
                  width="w-full"
                  height="h-10"
                  label={"رفض"}
                  action={() => handleReject(order?.id)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : queryParams.status === "current" ? (
        <div className=" border-b-2">
          <div className="flex justify-center mx-14 my-10">
            <div className="flex justify-between">
              <div className="w-52">
                <CustomButton
                  width="w-full"
                  height="h-10"
                  label={"تسليم الطلب"}
                  action={() => handleDeliver(order.id)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : queryParams.status !== "request_changes" ? (
        <div className=" border-b-2">
          <div className="flex justify-between mx-14 my-6">
            <Typography
              as="span"
              size="xl"
              color="text-warm_grey"
              lineHeight="leading-[1.21]"
              text={"تقييم خدمة التوصيل"}
            />
            <div className="flex">{deliveryRatingStars}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default OrderDetails;
