import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listOrdersStart } from "Redux/orders/actions/order.actions";
import {
  selectHasMore,
  selectIsOrdersLoading,
  selectIsOrdersLoadingMore,
  selectOrdersList,
} from "Redux/orders/selectors/orders.selectors";
import { selectVendorId } from "Redux/user/selectors/user.selectors";

export const useOrders = (status) => {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrdersList);
  const isLoading = useSelector(selectIsOrdersLoading);
  const isLoadingMore = useSelector(selectIsOrdersLoadingMore);
  const hasMore = useSelector(selectHasMore);
  const selectedVendorId = useSelector(selectVendorId);
  const [page, setPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    status: status,
    page_number: 1,
    admin_vendor_id: selectedVendorId,
  });
  useEffect(() => {
    setQueryParams({ ...queryParams, admin_vendor_id: selectedVendorId });
  }, [selectedVendorId]);

  useEffect(() => {
    if (orders) setPage((prev) => prev + 1);
  }, [orders]);

  useEffect(() => {
    setPage(1);
  }, [queryParams]);

  useEffect(() => {
    dispatch(listOrdersStart(queryParams));
  }, [dispatch, queryParams]);

  return [isLoading, isLoadingMore, hasMore, page, queryParams, orders];
};
