import {
  GetNotificationCountStart,
  GetNotificationStart,
  getSystemConfigurationStart,
  setNotificationReadStart,
} from "Redux/user/actions/user.actions";
import {
  selectCurrentUser,
  selectNotifications,
  selectNotificationsCount,
} from "Redux/user/selectors/user.selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { AblaNaziraLogo } from "utils/helpers/SVGsSrc";
import { NavLink } from "react-router-dom";
import { NotificationsPopOver } from "components/organisms";
import { Typography } from "components/atoms";
import axios from "axios";
import classNames from "classnames";
import styles from "./NavBar.module.css";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

// import { Switch } from "@headlessui/react";

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const i18nDir = i18n.dir();
  const [activeLink, setActiveLink] = useState("");
  const [showPopOver, setShowPopOver] = useState(false);
  useEffect(() => {
    document.body.dir = i18nDir;
    document.body.lang = i18n.language;
    axios.defaults.headers["Accept-Language"] = i18n.language?.split("-")[0];
  }, [i18nDir, i18n.language]);

  useEffect(() => {
    if (location.pathname == "/") {
      setActiveLink("main");
    } else if (location.pathname == "/my_account") {
      setActiveLink("MyAccount");
    } else if (location.pathname == "/about") {
      setActiveLink("WhoAreWe");
    } else if (location.pathname == "/help") {
      setActiveLink("HelpAndSupport");
    }
  }, [location]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const currentUser = useSelector(selectCurrentUser);
  const NotificationsCount = useSelector(selectNotificationsCount);
  const Notifications = useSelector(selectNotifications);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      dispatch(GetNotificationCountStart());
      dispatch(GetNotificationStart());
      dispatch(getSystemConfigurationStart());
    }
  }, [currentUser]);

  const setNotificationsRead = (ID) => {
    dispatch(setNotificationReadStart({ id: ID }));
  };

  return (
    <nav className={styles.nav}>
      <div className={styles.container}>
        <div className={styles.navTopPartContainer}>
          <NavLink to="/main/orders/new">
            <div className={styles.logo} onClick={() => setActiveLink("main")}>
              <AblaNaziraLogo width="100%" height="100%" title="Abla Nazira" />
            </div>
          </NavLink>
        </div>

        <div
          className={classNames(styles.iconsContainer, {
            [styles.iconsContainerUser]: currentUser,
          })}
        >
          {/* <div className={styles.icon}>
            <CartIcon width="100%" height="100%" title="cart" />
          </div> */}
          <div>
            {
              <NavLink
                to="/main/orders/new"
                onClick={() => {
                  setActiveLink("main");
                }}
              >
                <Typography
                  as="span"
                  size="2xl"
                  color={
                    activeLink === "main"
                      ? "text-primary-900"
                      : "text-warm_grey"
                  }
                  lineHeight="leading-[1.21]"
                  classes="cursor-pointer ml-16"
                  text={"الرئيسية"}
                />
              </NavLink>
            }
          </div>
          <div>
            {
              <NavLink
                to="/my_account"
                onClick={() => {
                  setActiveLink("MyAccount");
                }}
              >
                <Typography
                  as="span"
                  size="2xl"
                  color={
                    activeLink === "MyAccount"
                      ? "text-primary-900"
                      : "text-warm_grey"
                  }
                  lineHeight="leading-[1.21]"
                  classes="cursor-pointer ml-16 "
                  text={"حسابي"}
                />
              </NavLink>
            }
          </div>
          <div>
            {
              <NavLink
                to="/about"
                onClick={() => {
                  setActiveLink("WhoAreWe");
                }}
              >
                <Typography
                  as="span"
                  size="2xl"
                  color={
                    activeLink === "WhoAreWe"
                      ? "text-primary-900"
                      : "text-warm_grey"
                  }
                  lineHeight="leading-[1.21]"
                  classes="cursor-pointer ml-16"
                  text={"من نحن"}
                />
              </NavLink>
            }
          </div>
          <div>
            {
              <NavLink
                to="/help"
                onClick={() => {
                  setActiveLink("HelpAndSupport");
                }}
              >
                <Typography
                  as="span"
                  size="2xl"
                  color={
                    activeLink === "HelpAndSupport"
                      ? "text-primary-900"
                      : "text-warm_grey"
                  }
                  lineHeight="leading-[1.21]"
                  classes="cursor-pointer ml-0"
                  text={"المساعدة والدعم"}
                />
              </NavLink>
            }
          </div>
          <div
            className={styles.icon}
            onClick={() => {
              dispatch(GetNotificationCountStart());
              dispatch(GetNotificationStart());
              setShowPopOver(!showPopOver);
            }}
          >
            <NotificationsPopOver
              currentUser={currentUser}
              NotificationsCount={NotificationsCount}
              Notifications={Notifications}
              setNotificationsRead={setNotificationsRead}
              show={showPopOver}
            />
          </div>

          {/* <div>
            <LanguageIcon
              width="100%"
              height="100%"
              title="language"
              onClick={() =>
                changeLanguage(getCurrentLng() === "ar" ? "en" : "ar")
              }
            />

          </div> */}
          {/* {currentUser && (
            <div className={styles.icon}>
              <PopOver
                label={
                  <Account width="100%" height="100%" title="user account" />
                }
              >
                <div
                  onClick={() => {
                    dispatch(signoutStart());
                  }}
                  className="flex w-[285px] py-[20px] px-[16px] cursor-pointer "
                >
                  <div className="mr-[19px]">
                    <LogoutIcon />
                  </div>
                  <Typography as="p" text={t("Logout")} />
                </div>
              </PopOver>
            </div>
          )} */}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
