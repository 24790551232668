import { FullScreenBgLayout } from "components/Layouts";
import { ResetPasswordForm } from "../AuthComponents";

const ResetPasswordTemplate = () => {
  return (
    <FullScreenBgLayout
      backgroundImage="PrivacyPolicyBg"
      glassContainerWidth="md:w-4/5 xl:w-1/2"
      glassContainerHight="md:h-3/4"
      glassContainerStyles="px-6 lg:px-8"
    >
      <ResetPasswordForm />
    </FullScreenBgLayout>
  );
};

export default ResetPasswordTemplate;
