import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  getProductStart,
  removeProductStart,
} from "Redux/products/actions/products.actions";
import { selectProduct } from "Redux/products/selectors/products.selectors";
import { ProductDataTemplate } from "templates";

function ProductDataPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const product = useSelector(selectProduct);
  useEffect(() => {
    dispatch(getProductStart(id));
  }, []);

  const handleDelete = () => {
    if (window.confirm("هل أنت متأكد أنك تريد حذف هذا المنتج؟")) {
      dispatch(removeProductStart({ id: id }));
      navigate("/", {
        state: {
          currentView: "القائمة",
        },
      });
    } else {
    }
  };

  const handleEdit = () => {
    navigate(`/Edit_item/${id}`);
  };
  return (
    <ProductDataTemplate
      product={product}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  );
}

export default ProductDataPage;
