const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  PHONE_SIGN_IN_START: "PHONE_SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  PHONE_SIGN_UP_START: "SIGN_UP_START",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  VERIFY_OTP_START: "VERIFY_OTP_START",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
  VERIFY_MOBILE_NUMBER_START: "VERIFY_MOBILE_NUMBER_START",
  VERIFY_MOBILE_NUMBER_SUCCESS: "VERIFY_MOBILE_NUMBER_SUCCESS",
  VERIFY_MOBILE_NUMBER_FAILURE: "VERIFY_MOBILE_NUMBER_FAILURE",
  VERIFY_NEW_MOBILE_NUMBER_START: "VERIFY_NEW_MOBILE_NUMBER_START",
  VERIFY_NEW_MOBILE_NUMBER_SUCCESS: "VERIFY_NEW_MOBILE_NUMBER_SUCCESS",
  VERIFY_NEW_MOBILE_NUMBER_FAILURE: "VERIFY_NEW_MOBILE_NUMBER_FAILURE",
  SEND_VERIFICATION_MESSAGE_START: "SEND_VERIFICATION_MESSAGE_START",
  SEND_VERIFICATION_MESSAGE_SUCCESS: "SEND_VERIFICATION_MESSAGE_SUCCESS",
  SEND_VERIFICATION_MESSAGE_FAILURE: "SEND_VERIFICATION_MESSAGE_FAILURE",
  RESET_PASSWORD_VERIFY_OTP_SUCCESS: "RESET_PASSWORD_VERIFY_OTP_SUCCESS",
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  CHANGE_PASSWORD_INFORMED: "CHANGE_PASSWORD_INFORMED",
  UPDATE_MOBILE_START: "UPDATE_MOBILE_START",
  UPDATE_MOBILE_SUCCESS: "UPDATE_MOBILE_SUCCESS",
  UPDATE_MOBILE_FAILURE: "UPDATE_MOBILE_FAILURE",
  GET_FAQ_START: "GET_FAQ_START",
  GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
  GET_FAQ_FAILURE: "GET_FAQ_FAILURE",
  SEND_CONTACT_US_MESSAGE_START: "SEND_CONTACT_US_MESSAGE_START",
  SEND_CONTACT_US_MESSAGE_SUCCESS: "SEND_CONTACT_US_MESSAGE_SUCCESS",
  SEND_CONTACT_US_MESSAGE_FAILURE: "SEND_CONTACT_US_MESSAGE_FAILURE",
  GET_NOTIFICATION_START: "GET_NOTIFICATION_START",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILURE: "GET_NOTIFICATION_FAILURE",
  GET_NOTIFICATION_COUNT_START: "GET_NOTIFICATION_COUNT_START",
  GET_NOTIFICATION_COUNT_SUCCESS: "GET_NOTIFICATION_COUNT_SUCCESS",
  GET_NOTIFICATION_COUNT_FAILURE: "GET_NOTIFICATION_COUNT_FAILURE",
  SET_NOTIFICATION_READ_START: "SET_NOTIFICATION_READ_START",
  SET_NOTIFICATION_READ_SUCCESS: "SET_NOTIFICATION_READ_SUCCESS",
  SET_NOTIFICATION_READ_FAILURE: "SET_NOTIFICATION_READ_FAILURE",
  SET_FCM_TOKEN_START: "SET_FCM_TOKEN_START",
  SET_FCM_TOKEN_SUCCESS: "SET_FCM_TOKEN_SUCCESS",
  SET_FCM_TOKEN_FAILURE: "SET_FCM_TOKEN_FAILURE",
  SET_SELECTED_VENDOR: "SET_SELECTED_VENDOR",
  GET_SYSTEM_CONFIGURATION_START: "GET_SYSTEM_CONFIGURATION_START",
  GET_SYSTEM_CONFIGURATION_SUCCESS: "GET_SYSTEM_CONFIGURATION_SUCCESS",
  GET_SYSTEM_CONFIGURATION_FAILURE: "GET_SYSTEM_CONFIGURATION_FAILURE",
};

export default UserActionTypes;
