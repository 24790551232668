import {
  getMainCategoryStart,
  getSavingTemperatureStart,
  getSizeUnitsStart,
  getTagsStart,
} from "Redux/products/actions/products.actions";
import {
  getVendorMenuCategoriesStart,
  listServingTypesStart,
} from "Redux/vendor/actions/vendor.actions";
import {
  selectAddProductError,
  selectError,
  selectIsProductsLoading,
  selectSavingTemp,
  selectSizeUnits,
  selectSizes,
  selectSuccessConfirmationModalState,
  selectTags,
} from "Redux/products/selectors/products.selectors";
import {
  selectServingsList,
  selectVendorMenuCategories,
} from "Redux/vendor/selectors/vendor.selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { AddItemTemplate } from "templates";
import { eventEmitter } from "utils";
import { useNavigate } from "react-router";

const AddItemPage = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState(1);
  const [percent, setPercent] = useState(0);
  const dispatch = useDispatch();
  const servingTypesOptions = useSelector(selectServingsList);
  const vendorMenuCategoriesOptions = useSelector(selectVendorMenuCategories);
  const sizeUnitsOptions = useSelector(selectSizeUnits);
  const savingTempsOptions = useSelector(selectSavingTemp);
  const sizesOptions = useSelector(selectSizes);
  const tags = useSelector(selectTags);
  const isProductsLoading = useSelector(selectIsProductsLoading);
  const successConfirmationModalState = useSelector(
    selectSuccessConfirmationModalState
  );
  const isError = useSelector(selectAddProductError);
  useEffect(() => {
    dispatch(getTagsStart());
    dispatch(getSavingTemperatureStart());
    dispatch(listServingTypesStart());
    dispatch(getMainCategoryStart());
    dispatch(getSizeUnitsStart());
    dispatch(getVendorMenuCategoriesStart());
  }, []);

  useEffect(() => {
    eventEmitter.on("UPLOAD_PROGRESS", (percent) => {
      setPercent(percent);
    });

    return function cleanup() {
      eventEmitter.off("UPLOAD_PROGRESS");
    };
  }, []);
  return (
    <AddItemTemplate
      successConfirmationModalState={successConfirmationModalState}
      isError={isError}
      sizesOptions={sizesOptions}
      navigate={navigate}
      percent={percent}
      language={language}
      setLanguage={setLanguage}
      servingTypesOptions={servingTypesOptions}
      vendorMenuCategoriesOptions={vendorMenuCategoriesOptions}
      sizeUnitsOptions={sizeUnitsOptions}
      savingTempsOptions={savingTempsOptions}
      tags={tags}
      isProductsLoading={isProductsLoading}
    />
  );
};

export default AddItemPage;
