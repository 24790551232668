const ProductActionTypes = {
  PROGRESS_BAR_START: "PROGRES_BAR_START",
  UPDATE_PRODUCT_START: "UPDATE_PRODUCT_START",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",
  DELETE_PRODUCT_DISCOUNT_START: "DELETE_PRODUCT_DISCOUNT_START",
  DELETE_PRODUCT_DISCOUNT_SUCCESS: "DELETE_PRODUCT_DISCOUNT_SUCCESS",
  DELETE_PRODUCT_DISCOUNT_FAILURE: "DELETE_PRODUCT_DISCOUNT_FAILURE",
  UPLOAD_PRODUCT_IMAGE_START: "UPLOAD_PRODUCT_IMAGE_START",
  UPLOAD_PRODUCT_IMAGE_SUCCESS: "UPLOAD_PRODUCT_IMAGE_SUCCESS",
  UPLOAD_PRODUCT_IMAGE_FAILURE: "UPLOAD_PRODUCT_IMAGE_FAILURE",
  LOAD_MORE_PRODUCTS_START: "LOAD_MORE_PRODUCTS_START",
  LOAD_MORE_PRODUCTS_SUCCESS: "LOAD_MORE_PRODUCTS_SUCCESS",
  LOAD_MORE_PRODUCTS_FAILURE: "LOAD_MORE_PRODUCTS_FAILURE",
  REMOVE_PRODUCT_START: "REMOVE_PRODUCT_START",
  REMOVE_PRODUCT_SUCCESS: "REMOVE_PRODUCT_SUCCESS",
  REMOVE_PRODUCT_FAILURE: "REMOVE_PRODUCT_FAILURE",
  GET_PRODUCTS_START: "GET_PRODUCTS_START",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",
  GET_PRODUCT_START: "GET_PRODUCT_START",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
  ADD_PRODUCT_START: "ADD_PRODUCT_START",
  ADD_PRODUCT_SUCCESS: " ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",
  GET_CATEGORY_START: "GET_CATEGORY_START",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",
  GET_SAVING_TEMPERATURE_START: " GET_SAVING_TEMPERATURE_START",
  GET_SAVING_TEMPERATURE_SUCCESS: "GET_SAVING_TEMPERATURE_SUCCESS",
  GET_SAVING_TEMPERATURE_FAILURE: "GET_SAVING_TEMPERATURE_FAILURE",
  GET_TAGS_START: "GET_TAGS_START",
  GET_TAGS_SUCCESS: "GET_TAGS_SUCCESS",
  GET_TAGS_FAILURE: "GET_TAGS_FAILURE",
  GET_SIZE_UNITS_START: "GET_SIZE_UNITS_START",
  GET_SIZE_UNITS_SUCCESS: "GET_SIZE_UNITS_SUCCESS",
  GET_SIZE_UNITS_FAILURE: "GET_SIZE_UNITS_FAILURE",
  GET_SIZES_START: "GET_SIZES_START",
  GET_SIZES_SUCCESS: "GET_SIZES_SUCCESS",
  GET_SIZES_FAILURE: "GET_SIZES_FAILURE",
  RESET_SUCCESS_CONFIRMATION_MODAL: "RESET_SUCCESS_CONFIRMATION_MODAL",
};

export default ProductActionTypes;
