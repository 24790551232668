import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import {
  changePasswordInformed,
  changePasswordStart,
  updateMobileStart,
} from "Redux/user/actions/user.actions";
import {
  selectIsPasswordChanged,
  selectUnverifiedMobile,
} from "Redux/user/selectors/user.selectors";
import {
  listProfileStart,
  updateProfileStart,
} from "Redux/vendor/actions/vendor.actions";
import { selectVendorProfile } from "Redux/vendor/selectors/vendor.selectors";

import { SettingsTemplate } from "templates";

function SettingsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const closeConfirmation = () => dispatch(changePasswordInformed());

  const IsPasswordChanged = useSelector(selectIsPasswordChanged);
  const unVerifiedMobile = useSelector(selectUnverifiedMobile);
  const currentUser = useSelector(selectVendorProfile);

  useEffect(() => {
    dispatch(listProfileStart({}));
  }, []);

  useEffect(() => {
    if (IsPasswordChanged) {
      setIsPasswordUpdated(true);
    }
  }, [IsPasswordChanged]);

  useEffect(() => {
    if (unVerifiedMobile) {
      navigate("/verify_mobile", {
        state: {
          scope: "verification",
          mobile: unVerifiedMobile,
        },
      });
    }
  }, [unVerifiedMobile]);

  const UpdatePasswordHandler = (UpdatePassword) => {
    dispatch(changePasswordStart(UpdatePassword));
  };
  const PhoneUpdateHandler = (UpdatePhone) => {
    dispatch(updateMobileStart(UpdatePhone));
  };
  const handleSetNotificationOnReview = (notification_on_review) => {
    dispatch(
      updateProfileStart({ notification_on_review: notification_on_review })
    );
  };
  return (
    <SettingsTemplate
      handleSetNotificationOnReview={handleSetNotificationOnReview}
      currentUser={currentUser}
      isPasswordUpdated={isPasswordUpdated}
      closeConfirmation={closeConfirmation}
      setIsPasswordUpdated={setIsPasswordUpdated}
      UpdatePasswordHandler={UpdatePasswordHandler}
      PhoneUpdateHandler={PhoneUpdateHandler}
    />
  );
}

export default SettingsPage;
