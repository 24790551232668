import { useOrders } from "Hooks/useOrders/useOrders";
import { useDispatch, useSelector } from "react-redux";
import {
  listOrdersStart,
  updateOrderStatusStart,
} from "Redux/orders/actions/order.actions";
import { selectVendorId } from "Redux/user/selectors/user.selectors";
import { CurrentOrdersTemplate } from "templates";

function CurrentOrdersPage() {
  const dispatch = useDispatch();
  const [isLoading, isLoadingMore, hasMore, page, queryParams, orders] =
    useOrders("current");
  const selectedVendorId = useSelector(selectVendorId);
  // const orders = useSelector(selectOrdersList);
  // const isLoading = useSelector(selectIsOrdersLoading);
  // const isLoadingMore = useSelector(selectIsOrdersLoadingMore);
  // const hasMore = useSelector(selectHasMore);
  // const [page, setPage] = useState(1);
  // const [queryParams, setQueryParams] = useState({
  //   status: "current",
  //   page_number: 1,
  // });
  // useEffect(() => {
  //   if (orders) setPage((prev) => prev + 1);
  // }, [orders]);

  // useEffect(() => {
  //   setPage(1);
  // }, [queryParams]);

  // useEffect(() => {
  //   dispatch(listOrdersStart(queryParams));
  // }, [dispatch, queryParams]);

  // const handleChangeTimeSubmit = (id, time) => {
  //   dispatch(
  //     updateOrderStart({ id: id, body: { order: { time_in_minutes: time } } })
  //   );
  // };
  const handleDeliver = async (orderId) => {
    dispatch(
      updateOrderStatusStart({
        order: { status: "completed" },
        id: orderId,
        admin_vendor_id: selectedVendorId,
        action: () => {
          dispatch(
            listOrdersStart({
              status: "current",
              admin_vendor_id: selectedVendorId,
            })
          );
        },
      })
    );
  };

  return (
    <CurrentOrdersTemplate
      hasMore={hasMore}
      queryParams={queryParams}
      // setQueryParams={setQueryParams}
      orders={orders}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      page={page}
      handleDeliver={handleDeliver}
    />
  );
}

export default CurrentOrdersPage;
