import { Img, Typography } from "components/atoms";

import { DropDownInput } from "components/molecules";
import { ImagesSrc } from "utils";
import ProfileCard from "components/molecules/Cards/ProfileCard";
import { WithSpinner } from "Hocs";

const ProfileBody = ({ ProfileData, vendorAreas }) => {
  return (
    <>
      {ProfileData?.image ? (
        <div className="w-full h-96 mr">
          <div className="h-full relative flex justify-center">
            <Img
              fileName={ProfileData?.image}
              containerClasses={"h-full w-full absolute"}
            />
            <Img
              fileName={
                ProfileData?.logo ? ProfileData?.logo : ImagesSrc.Holmes2x
              }
              containerClasses={"md:h-[150px] h-[80px] absolute z-10 mt-20"}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-[120px] md:h-fit bg-text_f8f8fe py-8 flex justify-center">
          <Img
            fileName={
              ProfileData?.logo ? ProfileData?.logo : ImagesSrc.Holmes2x
            }
            containerClasses={"md:h-[150px] h-[80px]"}
          />
        </div>
      )}
      {/* <div className="w-full mt-8   mb-96">
        {ProfileData?.image ? (
          
            </div>
            <div className=" absolute z-10">
              {ProfileData?.logo ? (
                <Img
                  fileName={ProfileData?.logo}
                  containerClasses={"md:h-[150px] h-[80px]"}
                />
              ) : (
                <Img
                  fileName={ImagesSrc.Holmes2x}
                  fileName2x={ImagesSrc.Holmes2x}
                  fileName3x={ImagesSrc.Holmes3x}
                  containerClasses={"md:h-[150px] h-[80px]"}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="w-full h-[120px] md:h-fit bg-text_f8f8fe py-8 flex justify-center">
            {ProfileData?.logo ? (
              <Img
                fileName={ProfileData?.logo}
                containerClasses={"md:h-[150px] h-[80px]"}
              />
            ) : (
              <Img
                fileName={ImagesSrc.Holmes2x}
                fileName2x={ImagesSrc.Holmes2x}
                fileName3x={ImagesSrc.Holmes3x}
                containerClasses={"md:h-[150px] h-[80px]"}
              />
            )}
          </div>
        )}
      </div> */}
      {/* <div
        className={`w-full ${
          ProfileData?.image ? "mt-16 md:mt-[80px]" : "mt-10"
        } bg-text_f8f8fe p-6 text-right`}
      ></div> */}
      <div className={`w-full  bg-text_f8f8fe p-6 text-right`}>
        <Typography
          as="h1"
          size="2xl"
          color="text-text_464646"
          fontWeight="font-bold"
          lineHeight="leading-[2]"
          text={ProfileData?.display_name}
        />
        <p
          size="md"
          color="text-warm-grey"
          lineHeight="leading-[1.5]"
          text={ProfileData?.description}
          style={{
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {ProfileData?.description}
        </p>
      </div>
      <div className="w-full mt-10 bg-text_f8f8fe text-right">
        <ProfileCard
          Title={"إسم الشخص المسئول"}
          Value={ProfileData?.extras?.full_name}
        />
        <ProfileCard
          Title={"الرقم المسلسل"}
          Value={ProfileData?.unique_number}
        />
        <ProfileCard
          Title={"نوع العمل"}
          Value={
            ProfileData?.extras?.vendor_type
              ? Object.keys(ProfileData?.extras?.vendor_type)?.at(0) == 0
                ? "عمل فردي"
                : Object.keys(ProfileData?.extras?.vendor_type)?.at(0) == 1
                ? "مؤسسة"
                : ""
              : ""
          }
        />
        <ProfileCard
          Title={"نوع الخدمة التي تقدمها"}
          Value={ProfileData?.serving_types
            ?.map((item) => item.name)
            .join(", ")}
        />
        <ProfileCard
          Title={"نوع الطعام الذي تقدمه"}
          Value={ProfileData?.main_categories
            ?.map((item) => item.name)
            .join(", ")}
        />
        <ProfileCard
          Title={"إسم المطعم الذي يظهر للمستخدمين"}
          Value={ProfileData?.display_name}
        />
      </div>
      <Typography
        as="h1"
        size="2xl"
        color="text-text_464646"
        fontWeight="font-bold"
        lineHeight="leading-[2]"
        classes={"mt-10 text-right px-6 md:p-0"}
        text={"بيانات الإتصال"}
      />
      <div className="w-full mt-10 bg-text_f8f8fe text-right">
        <ProfileCard Title={"رقم الهاتف"} Value={ProfileData?.mobile_number} />
        <ProfileCard Title={"الموقع"} Value={ProfileData?.address} />
        <ProfileCard Title={"العنوان"} Value={ProfileData?.manual_address} />
        <ProfileCard Title={"البريد الإلكتروني"} Value={ProfileData?.email} />
        <ProfileCard Title={"موقع الكتروني"} Value={ProfileData?.website} />
      </div>
      <Typography
        as="h1"
        size="2xl"
        color="text-text_464646"
        fontWeight="font-bold"
        lineHeight="leading-[2]"
        classes={"mt-10 text-right px-6 md:p-0"}
        text={"بيانات الدفع"}
      />
      <div className="w-full mt-10 bg-text_f8f8fe text-right">
        <ProfileCard
          Title={"طريقة الدفع"}
          Value={ProfileData?.vendor_payout_method?.name}
        />
        <ProfileCard Title={"إسم البنك"} Value={ProfileData?.bank_name} />
        <ProfileCard
          Title={"رقم الحساب البنكي"}
          Value={ProfileData?.swift_code}
        />
      </div>
      <div className="w-full mt-10 bg-text_f8f8fe text-right">
        <ProfileCard
          Type="paragraph"
          Title={"أيام العمل"}
          Value={
            <div>
              {ProfileData?.working_times?.map(
                (item) =>
                  item.is_working_day && (
                    <Typography
                      as="h1"
                      size="xl"
                      color="text-text_3b3b3b"
                      fontWeight="font-semibold"
                      lineHeight="leading-[2]"
                      classes={"font-SFProText"}
                      text={`${item?.day} : من ${item?.from} إلي ${item?.to} `}
                    />
                  )
              )}
            </div>
          }
        />
        <ProfileCard
          Title={"الحد الأدنى للطلبات"}
          Value={`${ProfileData?.minimum_order} ج.م.`}
        />
      </div>
      <div className="w-full mt-10 bg-text_f8f8fe text-right">
        <ProfileCard
          Type="state"
          Title={"يتم قبول الطلبات تلقائياً"}
          Value={ProfileData?.extras?.auto_accept_orders}
        />
        {/* <ProfileCard
          Type="state"
          Title={
            ProfileData?.does_delivery
              ? ` يتم تقديم خدمة التوصيل : ${ProfileData?.delivery_details?.delivery_fee} ج.م`
              : "يتم تقديم خدمة التوصيل"
          }
          Value={ProfileData?.does_delivery}
        /> */}
        <ProfileCard
          Type="state"
          Title={"يتم قبول الدفع الإلكتروني"}
          Value={ProfileData?.accept_online_payment}
        />
        <ProfileCard
          Type="state"
          Title={"يتم تسليم الطلبات من موقعك"}
          Value={ProfileData?.pickup_details?.is_pickup_available}
        />
        <ProfileCard
          Type="state"
          Title={"يتم تقديم خدمة التوصيل"}
          Value={ProfileData?.does_delivery}
        />
      </div>
      {vendorAreas?.map((vendorArea) => {
        return (
          <div className="w-full mt-10 bg-text_f8f8fe text-right">
            <ProfileCard Title={"المنطقة"} Value={vendorArea.area_name_en} />
            <ProfileCard
              Title={"طريقة التوصيل"}
              Value={vendorArea.delivery_third_party_name}
            />
            <ProfileCard
              Title={"قيمة التوصيل"}
              Value={vendorArea.vendor_delivery_price}
            />
          </div>
        );
      })}
      ;
    </>
  );
};

function ProfileTemplate({
  ProfileData,
  IsProfileDataLoading,
  language,
  setLanguage,
  vendorAreas,
}) {
  const ProfileContainer = WithSpinner(ProfileBody);
  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-end">
        <DropDownInput
          styles={{
            option: (base, { isFocused }) => ({
              ...base,
              color: "#707070",
              fontSize: "18px",
              fontWeight: "500",
              backgroundColor: isFocused ? "#cbcbcb" : null,
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: "#777",
            }),
            // Language dropdown styles
            menu: (base) => ({
              ...base,
              backgroundColor: "#f8f8fe",
              border: 0,
            }),
            menuList: (base) => ({
              ...base,
              border: 0,
            }),

            control: (base) => ({
              ...base,
              flexDirection: "row",
              width: "10.938rem",
              height: "3.938rem",
              backgroundColor: "#f8f8fe",
              border: 0,
              boxShadow: "none",
            }),
            menuList: (base) => ({ ...base, border: "0px" }),
            placeholder: (base) => ({
              ...base,
              color: "#707070",
              fontWeight: "350",
              fontSize: "22px",
              fontStretch: "normal",
              fontStyle: "normal",
            }),
          }}
          searchable={false}
          closeOnSelect={true}
          name="menu"
          id="menu"
          options={[
            { value: 1, label: "العربية" },
            { value: 2, label: "الإنجليزية" },
          ]}
          width="w-full"
          placeholder="اللغة"
          defaultValue={{ value: 1, label: "العربية" }}
          onValueChange={(e) => {
            setLanguage(e.value);
          }}
        />
      </div>
      <ProfileContainer
        isLoading={IsProfileDataLoading}
        ProfileData={ProfileData}
        vendorAreas={vendorAreas}
      />
    </div>
  );
}

export default ProfileTemplate;
