import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import TimeAgo from "react-timeago";
import englishStrings from "react-timeago/lib/language-strings/en";
import arabicStrings from "react-timeago/lib/language-strings/ar";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

import { NotificationIcon } from "utils/helpers/SVGsSrc";

import { PopOver } from "components/molecules";
import { Typography } from "components/atoms";
import classNames from "classnames";
import { setNotificationReadStart } from "Redux/user/actions/user.actions";
import { useDispatch } from "react-redux";

const NotificationItem = ({ data }) => {
  const { i18n } = useTranslation();

  const formatter = i18n?.language?.includes("ar")
    ? buildFormatter(arabicStrings)
    : buildFormatter(englishStrings);

  return (
    <div
      className={classNames(
        { "bg-bg_e8e8e8/50": !data?.is_seen },
        "flex justify-between py-3 lg:py-4 px-2 lg:px-4 cursor-pointer hover:bg-bg_e8e8e8 border-t-[1px] border-warm_grey/23"
      )}
    >
      <Typography
        as="p"
        size="sm"
        color="text-warm_grey"
        classes="w-[210px] max-w-[210px] ellipseText line-clamp-1 "
        text={i18n.language == "ar" ? data?.ar_title : data?.title}
      />

      <Typography
        as="span"
        size="sm"
        color="text-warm_grey"
        text={<TimeAgo date={data?.sent_at} formatter={formatter} />}
      />
    </div>
  );
};

const NotificationsPopOver = ({
  currentUser,
  NotificationsCount,
  Notifications,
  setNotificationsRead,
  show,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <PopOver
      // panelClasses={`translate-x-1/2   ${
      //   currentUser
      //     ? "ltr:-right-[90%] rtl:-left-[90%] lg:rtl:-right-[90%] lg:rtl:left-[unset] lg:ltr:-translate-x-1/2 lg:ltr:-left-[90%] lg:ltr:right-[unset]"
      //     : "right-1/2 "
      // }`}
      panelClasses={`translate-x-1/2   ${
        currentUser
          ? "translate-x-0 md:-translate-x-full rtl:-translate-x-full md:rtl:translate-x-0 left-1/2"
          : "right-1/2 "
      }`}
      label={
        <>
          {NotificationsCount && NotificationsCount?.notifications?.unseen ? (
            <span className="absolute right-[-3px] top-[-5px] bg-primary-900 rounded-full h-[18px] w-[18px] text-text_white font-SFProText flex justify-center items-center">
              {NotificationsCount?.notifications?.unseen}
            </span>
          ) : (
            ""
          )}
          <NotificationIcon width="100%" height="100%" title="notification" />
        </>
      }
      show={show}
    >
      <div
        className={`w-[90vw] md:w-[400px] max-h-[85vh] h-full overflow-auto`}
      >
        <NavLink to="/Notifications">
          <Typography
            as="h1"
            size="xl"
            color="text-text_3b3b3b"
            fontWeight="font-bold"
            classes="px-6 py-4 text-center cursor-pointer "
            text={t("Notifications")}
          />
        </NavLink>

        <div className=" h-full max-h-[325px]">
          {Notifications?.today?.map((item) => (
            <NavLink
              to="/Notifications"
              onClick={() => setNotificationsRead(item?.attributes?.id)}
            >
              <NotificationItem data={item?.attributes} />
            </NavLink>
          ))}
          {Notifications?.yesterday?.map((item) => (
            <NavLink
              to="/Notifications"
              onClick={() => setNotificationsRead(item?.attributes?.id)}
            >
              <NotificationItem data={item?.attributes} />
            </NavLink>
          ))}
          {Notifications?.later?.map((item) => (
            <NavLink
              to="/Notifications"
              onClick={() => setNotificationsRead(item?.attributes?.id)}
            >
              <NotificationItem data={item?.attributes} />
            </NavLink>
          ))}
          <NavLink to="/Notifications">
            <Typography
              as="h1"
              size="xl"
              color="text-primary-900"
              fontWeight="font-bold"
              classes="px-6 py-4 text-center cursor-pointer border-t-[1px] border-bg_e8e8e8"
              text={t("Show all notification")}
            />
          </NavLink>
        </div>
      </div>
    </PopOver>
  );
};

export default NotificationsPopOver;
